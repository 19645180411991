import { Pipe, PipeTransform } from '@angular/core';
import { getSupportedLang } from '@utils';
import { Locale, parseISO, format, getYear } from 'date-fns';

/**
 * @note
 * import separado corrigi o bug -> https://trello.com/c/u0AUaWNx
 * Pois o modo usando barrel `/locales/` não carrega corretamente as traduções
 * @see https://github.com/date-fns/date-fns/issues/2188#issuecomment-777424919
 */
import enUS from 'date-fns/locale/en-US';
import ptBR from 'date-fns/locale/pt-BR';
import es from 'date-fns/locale/es';

@Pipe({
  name: 'calendarSubheader',
})
export class CalendarSubheaderPipe implements PipeTransform {
  transform(value: any): string {
    if (!value) {
      return '';
    }

    const locale = this.getLocale();
    const date = parseISO(value);
    const dateFormatString = this.getDateString(date, locale);
    const formattedString = format(date, dateFormatString, { locale });

    return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
  }

  /**
   * Se o ano for diferente do atual, o mês deve ser abreviado e o ano deve aparecer em frente, por exemplo:
   * Dez de 2015, Dec 2015, Dic de 2015
   * No date-fns, o formato da data é `MMM 'de' y`, as aspas simples funcionam como escape e o 'de' só deve aparecer se não for em inglês
   * Caso seja o ano atual, aparece somente o mês em extenso
   * Dezembro, December, Deciembre
   * No date-fns, o formato é `MMMM`
   */
  getDateString(date: Date, locale: Locale): string {
    return new Date().getFullYear() === getYear(date)
      ? 'MMMM'
      : `MMM ${locale !== enUS ? "'de' " : ''}y`;
  }

  getLocale(): Locale {
    const navLang: string = getSupportedLang();

    switch (navLang) {
      case 'en-US':
        return enUS;
      case 'es-ES':
        return es;
      default:
        return ptBR;
    }
  }
}
