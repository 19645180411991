import { CatalogProduct } from '@core/models/moblets';

export class AddToCart {
  static readonly type = '[cart] Add To Cart';
  constructor(public product: CatalogProduct, public catalogId: number) {}
}

export class RemoveProduct {
  static readonly type = '[cart] Remove product';
  constructor(public product: CatalogProduct) {}
}

export class ClearCart {
  static readonly type = '[cart] Clear cart';
  constructor() {}
}
