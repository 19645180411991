import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { Address } from '@core/models/address/address.model';
import { AddressState } from '@core/state/address/address.state';
import { ActionSheetController } from '@ionic/angular';
import { TranslocoService, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

const scope: string = 'settings';

@Component({
  selector: 'fabapp-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: scope }],
})
export class AddressListComponent implements OnInit {
  withoutNumber = 'S/N';
  @Select(AddressState.getAddresses) public addresses$: Observable<Address[]>;

  @Output() public editAddress: EventEmitter<Address> = new EventEmitter();
  @Output() public removeAddress: EventEmitter<Address> = new EventEmitter();
  @Output() public favoriteAddress: EventEmitter<Address> = new EventEmitter();

  private scopeAndCurrentLang: string;
  constructor(
    public actionSheetController: ActionSheetController,
    private translocoService: TranslocoService,
  ) {
    this.scopeAndCurrentLang = `${scope}/${this.translocoService.getActiveLang()}`;
  }

  ngOnInit(): void {}

  public async addressAction(address: Address): Promise<void> {
    const { edit, deleteTxt } = await this.translocoService
      .selectTranslateObject('addressPage', {}, this.scopeAndCurrentLang)
      .toPromise();

    const actionSheet: HTMLIonActionSheetElement =
      await this.actionSheetController.create({
        buttons: [
          {
            text: edit,
            icon: 'create',
            handler: () => {
              this.editAddress.emit(address);
            },
          },
          {
            text: deleteTxt,
            role: 'destructive',
            icon: 'trash',
            handler: () => {
              this.removeAddress.emit(address);
            },
          },
        ],
      });
    await actionSheet.present();
  }

  public changeFavoriteAddress(address: Address): void {
    if (address?.favorite) {
      return;
    }
    this.favoriteAddress.emit(address);
  }

  trackById(index: number, address: Address): string {
    return address?.id;
  }
}
