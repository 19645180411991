function getCurrentLang(): string {
  let navLang: string =
    window.navigator['userLanguage'] || window.navigator.language;

  navLang = navLang.toLowerCase();
  const supportedLangs: { [key: string]: string } = {
    'pt-BR': 'pt',
    'en-US': 'en',
    'es-ES': 'es',
  };

  let lang: string = Object.keys(supportedLangs).find((key: string) =>
    navLang.startsWith(supportedLangs[key]),
  );

  return lang || 'pt-BR';
}

export function getSupportedLang(): string {
  return getCurrentLang();
}
