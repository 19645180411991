export class ResetPasswordCodeRequest {
  static readonly type = '[ResetPassword] Request code';
  constructor(public username: string) {}
}

export class ResetPasswordConfirmationCode {
  static readonly type = '[ResetPassword] Confirmation code';
  constructor(public username: string, public code: string) {}
}

export class ResetPassword {
  static readonly type = '[ResetPassword] Reset password';
  constructor(
    public username: string,
    public code: string,
    public password: string,
    public passwordConfirmation: string,
  ) {}
}
