import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
} from '@angular/router';
import { NavigationService } from '@core/services';
import { AuthState, LoadRestrictedPages } from '@core/state/auth';
import { ToastController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthSegmentedRestrictionGuard implements CanActivateChild {
  private readonly scope: string;
  constructor(
    private readonly store: Store,
    private readonly toastCtrl: ToastController,
    private readonly translocoService: TranslocoService,
    private readonly navigationService: NavigationService,
    private readonly router: Router,
  ) {
    this.scope = `settings/${this.translocoService.getActiveLang()}`;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
  ): boolean | Observable<boolean> {
    const instanceId: number = next.params.id;
    // se for preview libera o acesso
    if (
      !instanceId ||
      !this.store.selectSnapshot(AuthState.isAuthenticated) ||
      window['preview']
    ) {
      return true;
    }

    // carrega as pagina se necessário
    // retorna se é possível acessar pagina ou não
    return this.store.dispatch(new LoadRestrictedPages()).pipe(
      switchMap(() => this.store.select(AuthState.canAccessPage(instanceId))),
      tap((canAccessPage: boolean) => this.triggerToast(canAccessPage)),
    );
  }

  public triggerToast(canAccessPage: boolean): void {
    const userLoggedCannotAccess: boolean =
      !canAccessPage && this.store.selectSnapshot(AuthState.isAuthenticated);

    if (userLoggedCannotAccess) {
      this.showMessage();
    }
  }

  private async showMessage(): Promise<void> {
    /**
     * Direciona o usuário para home, caso seja a primeira navegação,
     * Essa validação corrige o problema de redirecionar o usuário que
     * Tentar abrir uma página no qual ele não tenha acesso
     */
    if (this.router['navigationId'] === 1) {
      this.navigationService.returnToHomePage();
    }

    const message: string = await this.translocoService
      .selectTranslate('authSegmentedRestriction.noAccess', {}, this.scope)
      .toPromise();

    const toast: HTMLIonToastElement = await this.toastCtrl.create({
      message,
      duration: 5000,
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
          side: 'end',
        },
      ],
    });

    await toast.present();
  }
}
