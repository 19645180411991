import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppDef } from '@core/models';
import { NavigationService } from '@core/services/navigation.service';
import { CoreState } from '@core/state/core/core.state';
import { LoadStore } from '@core/state/moblets';
import { Search, SearchState } from '@core/state/search';
import { FabappDeliveryNavigateService } from '@fabapp-delivery/fabapp-delivery-navigate.service';
import { IonSearchbar, ModalController, NavParams } from '@ionic/angular';
import { TranslocoService, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

const TRANSLATION_SCOPE: string = 'search';
let LANG: string;
@Component({
  selector: 'fabapp-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
  providers: [
    NavParams,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: TRANSLATION_SCOPE,
    },
    FabappDeliveryNavigateService,
  ],
})
export class SearchPageComponent implements OnInit, OnDestroy {
  @Input() partialAppedef: Partial<AppDef>;
  @ViewChild(IonSearchbar) searchBarRef: IonSearchbar;
  public hasDelivery: boolean = false;
  results = null;
  loading = false;
  isEmpty = false;
  filter: string = '';
  groupId: number;
  mobletId: number;

  searchTitle: Observable<string>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private modalCtrl: ModalController,
    private translocoService: TranslocoService,
    private navigationService: NavigationService,
    private store: Store,
    private navParams: NavParams,
    private fabappDeliveryNavigateService: FabappDeliveryNavigateService,
  ) {
    LANG = this.translocoService.getActiveLang();
    this.groupId = this.navParams.get('groupId');
    this.mobletId = this.navParams.get('mobletId');
  }

  ngOnInit(): void {
    // Gargalo para dar focus no input
    setTimeout(async () => {
      if (this.searchBarRef) {
        await this.searchBarRef.setFocus();
      }
    }, 300);

    this.store
      .select(SearchState.items)
      .pipe(skip(1), takeUntil(this.destroy$))
      .subscribe((items: []) => {
        this.results = items;
        this.isEmpty = items.length < 1;
      });

    this.store
      .select(CoreState.menuEnabled)
      .pipe(takeUntil(this.destroy$))
      .subscribe((menuEnabled) => {
        this.hasDelivery = !menuEnabled;
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ionViewWillLeave() {
    this._resetSearch();
  }

  async search(event: Event): Promise<any> {
    event.preventDefault();

    if (!this.searchBarRef.value) {
      return;
    }
    this.filter = this.searchBarRef.value;
    this.loading = true;

    this.mobletId = this.hasDelivery
      ? this.store.selectSnapshot(CoreState.currentStoreId)
      : this.mobletId;

    await this.store
      .dispatch(
        new Search({
          filter: this.filter,
          groupId: this.groupId,
          mobletId: this.mobletId,
        }),
      )
      .toPromise();
    this.loading = false;
  }

  goToItem(result: any): void {
    const superClass: string = this.navigationService.getPageSuperClass(
      result.moblet,
    );
    const isFabappDeliveryEnabled: boolean = this.store.selectSnapshot(
      CoreState.isFabappDeliveryEnabled,
    );
    // Quando for delivery abre como modal o invés de navegar
    if (superClass === 'fabapp-delivery') {
      this.store.dispatch(new LoadStore(result.moblet)); // Carrega os dados da loja
      this.fabappDeliveryNavigateService.goToProductDetail(
        result.item.id,
        !isFabappDeliveryEnabled,
      );
      this._resetSearch();
    } else {
      this.navigationService.navigate({
        instanceId: +result.moblet,
        itemId: result.item.id,
      });
    }

    if (!isFabappDeliveryEnabled) {
      this.dismiss();
    }
  }

  private _resetSearch() {
    this.searchBarRef.value = '';
    this.results = null;
    this.isEmpty = false;
  }

  dismiss(): void {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }

  trackById(index: number, result: any): number {
    return +result?.item?.id;
  }
}
