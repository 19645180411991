import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Colors } from '@core/models';
import { WebMoblet } from '@core/models/moblets';
import { AppDefState } from '@core/state/appdef';
import { AuthState, LoadRestrictedPages } from '@core/state/auth';
import {
  NativeOpenWebView
} from '@core/state/react-native/react-native.actions';
import { ReactNativeState } from '@core/state/react-native/react-native.state';
import {
  ThemeableBrowser,
  ThemeableBrowserObject,
  ThemeableBrowserOptions
} from '@ionic-native/themeable-browser/ngx';
import { isPlatform, NavController, Platform } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { convertToBoolean } from '@utils';
import { Subscription } from 'rxjs';
import * as tinycolor from 'tinycolor2';
import { MobletService } from './moblet.service';
import { TemplateParser } from './template-parser/template-parser';
const { Toast, Browser } = Plugins;

export enum WebMobletsRoutes {
  'uframe' = 'uframe',
  'm-tawkto' = 'm-tawkto',
  'iframe' = 'iframe',
  'gloria_food' = 'gloria-food',
}

@Injectable({ providedIn: 'root' })
export class InAppBrowserService {
  private _superClass: string;

  private templateParser: TemplateParser;
  private browser: ThemeableBrowserObject;
  private options: ThemeableBrowserOptions;
  private urlsOpenSystem: Array<string>;

  constructor(
    private store: Store,
    private platform: Platform,
    private navCtrl: NavController,
    protected mobletService: MobletService,
    private themeableBrowser: ThemeableBrowser,
    private translocoService: TranslocoService,
    private httpClient: HttpClient,
  ) {
    this.templateParser = new TemplateParser(store);
  }

  public get superClass(): string {
    return this._superClass;
  }

  public set superClass(value: string) {
    this._superClass = value;
  }

  public get isReactNative(): boolean {
    return this.store.selectSnapshot(ReactNativeState.getIsReactNative);
  }

  /**
   * carrega os dados do moblet para pegar a URL
   * @returns void
   */
  public async getMobletInfo(instanceId: number): Promise<void> {
    const cannotOpenMoblet: boolean = await this.cannotOpenMoblet(instanceId);
    if (cannotOpenMoblet) {
      return;
    }

    const subscription: Subscription = this.mobletService
      .loadMoblet(instanceId)
      .subscribe(
        async (moblet: WebMoblet) => {
          if (subscription) {
            subscription.unsubscribe();
          }
          this.treatMobletInfo(moblet);
        },
        async (err: any) => {
          if (subscription) {
            subscription.unsubscribe();
          }
          this.feedbackToast(await this.getTranslate('couldNotLoad'));
        },
      );
  }

  private async treatMobletInfo(moblet: WebMoblet): Promise<void> {
    // moblet.url: é para usar a url do moblet delivery
    // moblet?.frame?.url: é o moblet web
    const urlOfMoblet: string = moblet?.frame?.url || moblet.url;
    const mobletHasUrl: boolean = !!urlOfMoblet;
    let url: string = mobletHasUrl
      ? this.templateParser.parse(urlOfMoblet)
      : null;

    if (this.isTawkTo) {
      url = this.tawkToUrl(moblet).url;
    }

    if (!url) {
      return this.feedbackToast(await this.getTranslate('couldNotOpen'));
    }

    if (this.isTawkToInWeb) {
      InAppBrowserService.windowOpen(url);
      return;
    }

    if (this.isReactNative) {
      this.store.dispatch(new NativeOpenWebView(url));
      return;
    }

    await this.openBrowser(url);
  }

  private tawkToUrl(body: WebMoblet): WebMoblet {
    let url: string = '';
    if (body?.id?.startsWith('http')) {
      url = body?.id;
    } else {
      url = `https://tawk.to/chat/${body?.id}/default`;
    }

    return { ...body, url };
  }

  /**
   * deve existir o valor da superClass para a validação
   * @returns boolean
   */
  public get mustOpenBrowser(): boolean {
    if (!this.superClass) {
      throw new Error('superClass deve conter um valor');
    }

    if (!this.platform.is('capacitor') && !this.isReactNative) {
      return false;
    }

    return (
      this.superClass === WebMobletsRoutes.iframe ||
      this.superClass === WebMobletsRoutes.gloria_food ||
      (this.isTawkTo && isPlatform('ios'))
    );
  }

  public get isTawkToInWeb(): boolean {
    if (!this.superClass) {
      throw new Error('superClass deve conter um valor');
    }

    const isNativeOrNotIos: boolean =
      this.platform.is('capacitor') || !isPlatform('ios');
    if (isNativeOrNotIos) {
      return false;
    }

    return this.isTawkTo;
  }

  private get isTawkTo(): boolean {
    return this.superClass === WebMobletsRoutes['m-tawkto'];
  }

  public async openBrowser(
    url: string,
    target: string = '_blank',
  ): Promise<void> {
    try {
      if (this.platform.is('ios')) {
        return this.openCapacitorBrowser(url, target);
      }

      this.themeableBrowserOptions();
      this.browser = this.themeableBrowser.create(url, target, this.options);
      this.themeableBrowserLoad(url);
    } catch (error) {
      this.feedbackToast(await this.getTranslate('couldNotOpen'));
    }
  }

  private async openCapacitorBrowser(url: string, target: string) {
    const { header_color } = this.store.selectSnapshot<Colors>(
      AppDefState.getColors,
    );

    await Browser.open({ url, windowName: target, toolbarColor: header_color });
  }

  private themeableBrowserOptions(): void {
    const { header_color } = this.store.selectSnapshot<Colors>(
      AppDefState.getColors,
    );

    const iconName: string = tinycolor(header_color).isDark()
      ? 'white'
      : 'black';

    this.options = {
      statusbar: {
        color: header_color,
      },
      toolbar: {
        height: 56,
        color: header_color,
      },
      backButton: {
        wwwImage: `assets/icons/chevron_left_${iconName}.png`,
        align: 'right',
        event: 'backPressed',
      },
      forwardButton: {
        wwwImage: `assets/icons/chevron_right_${iconName}.png`,
        align: 'right',
        event: 'forwardPressed',
      },
      closeButton: {
        wwwImage: `assets/icons/arrow_back_${iconName}.png`,
        align: 'left',
        event: 'closePressed',
      },
      clearsessioncache: true,
    };
  }

  /**
   * @description
   * método indentifica se deve abrir dentro ou fora do app
   * @returns void
   */
  private async themeableBrowserLoad(url: string): Promise<void> {
    if (!this.urlsOpenSystem) {
      this.urlsOpenSystem = await this.getUrlsOpenSystem();
    }

    const browserLoadstart: Subscription = this.browser
      .on('loadstart')
      .subscribe(async (event: any) => {
        const nextUrlNavigation: string = this.getDomain(event.url);
        const isOpenExternal = this.urlsOpenSystem.find((v) =>
          nextUrlNavigation.includes(v),
        );

        if (isOpenExternal) {
          this.browser.executeScript({
            code: 'window.history.back()',
          });

          // Abre navegador nativo
          this.browser = this.themeableBrowser.create(
            event.url,
            '_system',
            this.options,
          );

          await this.themeableBrowserLoad(url);
        }
      });

    // on themeable browser finished
    const browserExit: Subscription = this.browser.on('exit').subscribe(() => {
      this.browser.close();
      this.browser = null;
      browserLoadstart.unsubscribe();
      browserExit.unsubscribe();
    });
  }

  async getUrlsOpenSystem(): Promise<Array<string>> {
    const url: string = 'https://static.fabapp.com/0/urls-open-system.json';

    return (await this.httpClient.get(url).toPromise()) as Array<string>;
  }

  private async feedbackToast(text: string): Promise<void> {
    await Toast.show({
      text,
      duration: 'long',
      position: 'bottom',
    });
  }

  private async cannotOpenMoblet(instanceId: number): Promise<boolean> {
    let access_control = convertToBoolean(
      this.store.selectSnapshot(AppDefState.getPage(instanceId))
        ?.access_control,
    );

    if (!access_control) {
      return false;
    }

    const isNotLoggedAndLoginRequired: boolean =
      convertToBoolean(access_control) && !this.isAuthenticated;
    if (isNotLoggedAndLoginRequired) {
      this.redirectToLogin();
      return true;
    }

    await this.store.dispatch(new LoadRestrictedPages()).toPromise();
    const canAccessPage: boolean = this.store.selectSnapshot(
      AuthState.canAccessPage(instanceId),
    );

    const userLoggedCannotAccess: boolean =
      !canAccessPage && this.isAuthenticated;

    if (userLoggedCannotAccess) {
      this.feedbackToast(await this.getTranslate('noAccess'));
      return true;
    }

    return false;
  }

  private get isAuthenticated(): boolean {
    return this.store.selectSnapshot(AuthState.isAuthenticated);
  }

  private redirectToLogin(): void {
    this.navCtrl.navigateForward(['/auth/login']);
  }

  private getDomain(url: string): string {
    let hostname: string;

    // find & remove protocol (http, ftp, etc.) and get hostname
    if (url.indexOf('//') > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }

    // find & remove port number
    hostname = hostname.split(':')[0];
    // find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
  }

  async getTranslate(key: string): Promise<string> {
    const scope: string = `in-app-browser/${this.translocoService.getActiveLang()}`;
    return await this.translocoService
      .selectTranslate(key, {}, scope)
      .toPromise();
  }

  static windowOpen(url: string, target = '_blank', features?: string) {
    // window.open() não funciona no IOS -> https://caniuse.com/?search=window.open
    if (isPlatform('ios')) {
      window.location.href = url;
      return;
    }

    window.open(url, target, features);
  }

  /**
   * This method has a override window open for open Browser of Capacitor
   * @returns
   */
  overrideWindowOpen(): void {
    if (isPlatform('ios') && !isPlatform('capacitor')) {
      // window.open() não funciona no IOS -> https://caniuse.com/?search=window.open
      delete window.open;
      window.open = (url?: string) => {
        window.location.href = url;
        return null;
      };
      return;
    }

    if (!isPlatform('capacitor')) {
      return;
    }

    delete window.open;
    window.open = (url?: string) => {
      this.openBrowser(url, '_system');
      return null;
    };
  }
}
