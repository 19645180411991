import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { TermAndPrivacy } from '@core/models/themis';
import { AdmobAdsService } from '@core/services/ads';
import { ThemisService } from '@core/services/themis/themis.service';
import { AuthState } from '@core/state/auth';
import { ModalController } from '@ionic/angular';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Store } from '@ngxs/store';

const TRANSLATION_SCOPE: string = 'lgpd';

@Component({
  selector: 'app-preview-new-term-to-accept',
  templateUrl: './preview-new-term-to-accept.component.html',
  styleUrls: ['./preview-new-term-to-accept.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: TRANSLATION_SCOPE },
    },
  ],
})
export class PreviewNewTermToAcceptComponent implements OnInit {
  @Input() type: 'terms' | 'privacy-policies';
  @Input() id: string;

  term: TermAndPrivacy;
  content: string;
  isLoading = false;

  isSubmitting = false;
  success = false;
  constructor(
    private modalCtrl: ModalController,
    private themisService: ThemisService,
    private cdr: ChangeDetectorRef,
    private store: Store,
    private adsService: AdmobAdsService,
  ) {}

  ngOnInit() {
    this.adsService.hideBanner();
    this.loadTerm();
  }

  async loadTerm() {
    try {
      this.isLoading = true;
      this.cdr.markForCheck();

      const item = await this.themisService
        .getCurrent(this.id, this.type)
        .toPromise();
      this.content = item.content;
      this.cdr.markForCheck();
    } finally {
      this.isLoading = false;
      this.cdr.markForCheck();
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  async acceptTerm() {
    if (this.success || this.isSubmitting) {
      return;
    }

    try {
      this.isSubmitting = true;
      this.cdr.markForCheck();

      const userId = this.store.selectSnapshot(AuthState.getUser).id;

      const key = this.type === 'terms' ? 'termVersionId' : 'policyVersionId';
      const item = await this.themisService
        .acceptTerm({
          userId,
          [key]: this.id,
        })
        .toPromise();

      this.success = true;
      setTimeout(() => this.dismiss(), 250);
    } finally {
      this.isSubmitting = false;
      this.cdr.markForCheck();
    }
  }
}
