import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { AppInfo, ErrorApiEnum } from '@core/models';
import { NavigationService } from '@core/services/navigation.service';
import { AppDefState } from '@core/state/appdef';
import { FacebookLogin } from '@core/state/auth';
import { environment } from '@environments/environment';
import { LoadingController } from '@ionic/angular';
import { Store } from '@ngxs/store';

@Injectable({ providedIn: 'root' })
export class FacebookService {
  appInfo: AppInfo;

  constructor(
    private store: Store,
    private navigationService: NavigationService,
    private loadingController: LoadingController,
  ) {}

  init(): void {
    this.appInfo = this.store.selectSnapshot(AppDefState.getInfo);
    if (this.appInfo.fb_access_key) {
      this.fbInit(this.appInfo.fb_access_key);
    }
  }

  private fbInit(fbAppId: string): void {
    window['FB']?.init({
      appId: fbAppId,
      cookie: true, // enable cookies to allow the server to access the session
      xfbml: true, // parse social plugins on this page
      version: 'v5.0', // use graph api current version
    });
  }

  async signIn(): Promise<void> {
    try {
      // caso não tenha a chave do facbook lança uma erro como provider not configured
      if (!this.appInfo.fb_access_key) {
        throw new HttpErrorResponse({
          error: { error: ErrorApiEnum.PROVIDER_NOT_CONFIGURED },
        });
      }

      await this.signInWithFacebook();
      this.navigationService.returnToHomePage();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async signInWithFacebook(): Promise<void> {
    let loading: HTMLIonLoadingElement;
    try {
      const result: any = await Plugins.FacebookLogin.login({
        permissions: environment.facebook.permissions,
      });

      if (!result.accessToken.token) {
        return;
      }

      loading = await this.presentLoading();

      await this.store
        .dispatch(new FacebookLogin(result.accessToken))
        .toPromise();
    } catch (error) {
      throw error;
    } finally {
      if (loading) {
        loading.dismiss();
      }
    }
  }

  async signOut(): Promise<void> {
    await Plugins.FacebookLogin.logout();
  }

  private async presentLoading(): Promise<HTMLIonLoadingElement> {
    const loading: HTMLIonLoadingElement = await this.loadingController.create({
      message: 'Loading...',
    });
    await loading.present();

    return loading;
  }
}
