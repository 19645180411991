import { Component, OnInit } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { CoreState } from '@core/state/core/core.state';
import { ModalController, ToastController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { legacyCopyToClipboard } from '@utils';
import { format } from 'date-fns-tz';
import ptBR from 'date-fns/locale/pt-BR';
import { Subscription } from 'rxjs';

const { Clipboard } = Plugins;

@Component({
  selector: 'app-modal-pix-key',
  templateUrl: './modal-pix-key.component.html',
  styleUrls: ['./modal-pix-key.component.scss'],
})
export class ModalPixKeyComponent implements OnInit {
  pixKey: string;
  pixSubscription: Subscription;
  currentDate: string;

  constructor(
    public modalController: ModalController,
    private toastCtrl: ToastController,
    private store: Store,
  ) {}

  ngOnInit() {
    this.getKeyPix();
    this.currentDate = format(new Date(), 'dd MMMM yyyy', { locale: ptBR });
  }

  private getKeyPix() {
    this.pixSubscription = this.store
      .select(CoreState.currentStore)
      .subscribe((store) => {
        if (store?.pixKey) {
          this.pixKey = store.pixKey;
        }
      });
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async copyToClipboard(code: string) {
    Clipboard.write({
      string: code,
    });

    if (navigator.clipboard) {
      await navigator.clipboard.writeText(code);
    }

    legacyCopyToClipboard(code);

    const toast: HTMLIonToastElement = await this.toastCtrl.create({
      message: 'Copiado para área de transferência',
      duration: 2000,
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
          side: 'end',
        },
      ],
    });

    await toast.present();
  }
}
