import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DivulgationModalComponent } from './divulgation-modal.component';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [DivulgationModalComponent],
  imports: [CommonModule, IonicModule, TranslocoModule],
})
export class DivulgationModalModule {}
