import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { IonicImageLoaderModule } from '../image-loader/ionic-image-loader.module';
import { EmptyStateComponent } from './empty-state.component';
import { EmptyStateDirective } from './empty-state.directive';
import { LoaderComponent } from './loader/loader.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  imports: [IonicModule, IonicImageLoaderModule, TranslocoModule],
  exports: [EmptyStateComponent, EmptyStateDirective, LoaderComponent],
  declarations: [EmptyStateComponent, EmptyStateDirective, LoaderComponent],
})
export class EmptyStateModule {}
