import { Injectable } from '@angular/core';
import { CancelLoadStore } from '@core/state/moblets';
import { Coupon } from '@fabapp-delivery/models/promotions/coupon/coupon.model';
import { Promotions } from '@fabapp-delivery/models/promotions/coupon/promotions.model';
import { FDI_Campaign } from '@fabapp-delivery/models/promotions/reward/campaign.model';
import { CartService } from '@fabapp-delivery/services';
import { PromotionsService } from '@fabapp-delivery/services/promotions.service';
import { AlertController } from '@ionic/angular';
import {
  Action,
  Actions,
  ofAction,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { DeliveryCartActions } from '../cart/cart.actions';
import { CartState } from '../cart/cart.state';
import {
  ApplyCoupon,
  ClearSelectedCoupon,
  LoadCoupons,
  RemoveCoupon,
  SetSelectedCoupon,
  ShowConfirmationToRemoveCoupon,
} from './promotions.action';

interface PromotionsStateModel {
  coupons: Array<Coupon>;
  reward: FDI_Campaign;
  promotionsCount: number;
  couponSelected: Partial<Coupon>;
  punctuation: number;
  progress: number;
}

@State<PromotionsStateModel>({
  name: 'promotions',
  defaults: {
    coupons: null,
    reward: null,
    promotionsCount: 0,
    couponSelected: null,
    punctuation: 0,
    progress: 0,
  },
})
@Injectable()
export class PromotionsState {
  constructor(
    private couponService: PromotionsService,
    private cartService: CartService,
    private store: Store,
    private alertCtrl: AlertController,
    private actions$: Actions,
  ) {}

  @Selector()
  static getPunctuation(state: PromotionsStateModel): number {
    return state.punctuation;
  }

  @Selector()
  static getProgress(state: PromotionsStateModel): number {
    return state.progress;
  }

  @Selector()
  static getReward(state: PromotionsStateModel): FDI_Campaign {
    return state.reward;
  }

  @Selector()
  static getCouponSelected(state: PromotionsStateModel): Partial<Coupon> {
    return state.couponSelected;
  }

  @Selector()
  static getPromotionsCount(state: PromotionsStateModel): number {
    return state.promotionsCount;
  }

  @Selector()
  static getCoupons(state: PromotionsStateModel): Array<Coupon> {
    return state.coupons;
  }

  @Action(ShowConfirmationToRemoveCoupon)
  async showConfirmation(ctx: StateContext<PromotionsStateModel>) {
    const alertPopup: HTMLIonAlertElement = await this.alertCtrl.create({
      message: 'Cupom inválido, atualizar carrinho',
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            alertPopup.dismiss(true);
            ctx.dispatch(new RemoveCoupon());
          },
        },
      ],
    });

    alertPopup.present();
    return await alertPopup.onDidDismiss();
  }

  @Action(ClearSelectedCoupon)
  async clear(ctx: StateContext<PromotionsStateModel>) {
    ctx.patchState({ couponSelected: null });
  }

  @Action(LoadCoupons)
  load(ctx: StateContext<PromotionsStateModel>) {
    return this.couponService.promotions().pipe(
      tap(({ coupons, reward }) => {
        const data: Partial<PromotionsStateModel> = {
          coupons,
          reward,
          promotionsCount: coupons.length,
          punctuation: reward?.wallets?.length ? reward.wallets[0].points : 0,
        };

        data.progress = this._getProgressValue(data.punctuation, reward);
        ctx.patchState(data);
      }),
      catchError((err) => {
        ctx.patchState({ coupons: null, reward: null, promotionsCount: 0 });
        return throwError(err);
      }),
      takeUntil(this.actions$.pipe(ofAction(CancelLoadStore))),
    );
  }

  private async _treatPromotions() {
    try {
      const { coupons, reward }: Promotions = await this.couponService
        .promotions()
        .toPromise();
      const data: Partial<PromotionsStateModel> = {
        coupons,
        reward,
        promotionsCount: coupons.length,
        punctuation: reward?.wallets?.length ? reward.wallets[0].points : 0,
      };

      data.progress = this._getProgressValue(data.punctuation, reward);

      return data;
    } catch (error) {
      throw error;
    }
  }

  private _getProgressValue(punctuation: number, reward: FDI_Campaign) {
    if (punctuation === 0) {
      return 0;
    }

    const multiple = punctuation * 100;
    const progressValue: number = multiple / reward.requiredOrders;
    return progressValue;
  }

  @Action(RemoveCoupon)
  remove(ctx: StateContext<PromotionsStateModel>) {
    const cartId: string = this.store.selectSnapshot(CartState.getCartId);

    return this.cartService
      .removeCoupon(cartId)
      .pipe(
        tap(() =>
          ctx.dispatch([
            new DeliveryCartActions.GetFees(),
            new ClearSelectedCoupon(),
          ]),
        ),
      );
  }

  @Action(ApplyCoupon)
  applyCoupon(
    ctx: StateContext<PromotionsStateModel>,
    { coupon }: ApplyCoupon,
  ) {
    const { id } = coupon;

    ctx.patchState({ couponSelected: coupon });

    const cartId: string = this.store.selectSnapshot(CartState.getCartId);
    return this.cartService
      .applyCoupon(cartId, id)
      .pipe(tap(() => ctx.dispatch(new DeliveryCartActions.GetFees())));
  }

  @Action(SetSelectedCoupon)
  async setSelectedCoupon(
    ctx: StateContext<PromotionsStateModel>,
    { coupon }: SetSelectedCoupon,
  ) {
    ctx.patchState({ couponSelected: coupon });
  }
}
