import { Pipe, PipeTransform } from '@angular/core';
import { translate, TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'relativeTime',
})
export class RelativeTimePipe implements PipeTransform {
  scopeAndCurrentLang;

  constructor(private translocoService: TranslocoService) {
    this.translocoService.getTranslation(this.translocoService.getActiveLang());
    this.scopeAndCurrentLang = `${this.translocoService.getActiveLang()}`;
  }

  transform(value: string): string {
    let date: Date = new Date(value);
    /* If an invalid string was passed, the epoch will be `NaN` */
    if (isNaN(date.getTime())) {
      let [minutes, hour] = value.split('-');
      // date -> dd/MM/yyyy - hh:MM:ss
      // formatação para yyyy-MM-dd hh:MM:ss
      minutes = minutes
        .replace(/\//g, '-')
        .trim()
        .split('-')
        .reverse()
        .join('-');
      value = `${minutes}${hour}`;
      date = new Date(value);
    }

    const differenceInSeconds = Math.floor(
      (+new Date() - date.getTime()) / 1000,
    );

    if (differenceInSeconds < 10) {
      return translate('second', { counter: 1 }, this.scopeAndCurrentLang);
    }

    // All values are in seconds
    const timeIntervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };
    let counter: number;
    for (const i of Object.keys(timeIntervals)) {
      counter = Math.floor(differenceInSeconds / timeIntervals[i]);
      if (counter > 0) {
        if (counter === 1) {
          // singular (1 day ago)
          return translate(i, { counter }, this.scopeAndCurrentLang);
        } else {
          // plural (2 days ago)
          return translate(i + 's', { counter }, this.scopeAndCurrentLang);
        }
      }
    }
    return value;
  }
}
