import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { User } from '@core/models';
import { InAppBrowserService } from '@core/services';
import { CpfCnpjValidator } from '@core/validators';
import { environment } from '@environments/environment';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { PhoneHelper } from '../user-form/user-phone-helper.service';

const TRANSLATION_SCOPE: string = 'user-form';

const FIELDS = {
  NAME: 'name',
  DOCUMENT_NUMBER: 'document_number',
  EMAIL: 'email',
  PHONE_NUMBER: 'phone_number',
  PASSWORD: 'password',
  DDI: 'ddi',
  TERMS: 'terms',
};
@Component({
  selector: 'fabapp-delivery-user-form',
  templateUrl: './delivery-user-form.component.html',
  styleUrls: ['./delivery-user-form.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: TRANSLATION_SCOPE, alias: 'userForm' },
    },
  ],
})
export class DeliveryUserFormComponent implements OnInit {
  @Input() isSubmitting = false;
  @Output() submitForm: EventEmitter<User> = new EventEmitter();

  public form: FormGroup;

  private _user: User;

  @Input() public set user(value: User) {
    this._user = {
      ...value,
      phone_number: this.phoneHelper.treatPhone(value.phone_number),
    };
  }

  public get user(): User {
    return this._user;
  }

  public phoneHelper: PhoneHelper;
  public readonly FIELDS = FIELDS;
  public loading = true;
  constructor() {
    this.phoneHelper = new PhoneHelper();
    this.form = this._getFields();
  }

  ngOnInit() {
    this.loading = false;
    this._mergeForm();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.isSubmitting) {
      this.isSubmitting = simpleChanges.isSubmitting.currentValue;
    }
  }

  private _getFields() {
    return new FormGroup({
      [this.FIELDS.NAME]: new FormControl(null, Validators.required),
      [this.FIELDS.DOCUMENT_NUMBER]: new FormControl(
        '',
        CpfCnpjValidator.validate,
      ),
      [this.FIELDS.EMAIL]: new FormControl('', Validators.email),
      [this.FIELDS.PHONE_NUMBER]: new FormControl(null, Validators.required),
      [this.FIELDS.PASSWORD]: new FormControl(null, [
        Validators.required,
        Validators.minLength(6),
      ]),
      [this.FIELDS.DDI]: new FormControl(null),
      [this.FIELDS.TERMS]: new FormControl(false, Validators.requiredTrue),
    });
  }

  private _mergeForm(): void {
    if (this.user) {
      this.form.removeControl(this.FIELDS.TERMS);
      this.form.removeControl(this.FIELDS.PASSWORD);
      this.form.patchValue(this.user);
      this.form.updateValueAndValidity();
    } else {
      this.form.removeControl(this.FIELDS.DOCUMENT_NUMBER);
      this.form.removeControl(this.FIELDS.EMAIL);
    }
  }

  public onSubmit(): void {
    const data = this._treatData();
    this.submitForm.emit(data);
  }

  private _treatData(): User {
    const data = this.form.getRawValue();

    const phoneNumber = this.phoneHelper.phoneWithDDI(
      this._getControl(this.FIELDS.PHONE_NUMBER).value,
    );
    data[this.FIELDS.PHONE_NUMBER] = phoneNumber;
    delete data[this.FIELDS.DDI];

    if (data[this.FIELDS.DOCUMENT_NUMBER]) {
      const digits: RegExp = /[^\d]+/g;
      data[this.FIELDS.DOCUMENT_NUMBER] = data[
        this.FIELDS.DOCUMENT_NUMBER
      ].replace(digits, '');
    }

    // note: o hera não aceita propriedade `null` para o `email`
    if (data.hasOwnProperty(this.FIELDS.EMAIL) && !data[this.FIELDS.EMAIL]) {
      data[this.FIELDS.EMAIL] = '';
    }

    return data;
  }

  public getCallingCode(ddi: number): void {
    this.phoneHelper.ddi = ddi;
    this.form.get('ddi').setValue(ddi);
  }

  public setMask(mask: string): void {
    this.phoneHelper.mask = mask;
  }

  private _getControl(control: string): AbstractControl {
    return this.form.get(control);
  }

  public showUseTerms(): void {
    InAppBrowserService.windowOpen(environment.useTermsUrl, '_system');
  }
}
