import { Injectable } from '@angular/core';
import {
  CampaignsTotalGQL,
  CampaignsTotalQuery,
} from '@core/queries/campaigns-total.graphql-gen';
import { QueryRef } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationService } from './navigation.service';

type CampaignData = CampaignsTotalQuery['campaigns']['data'];
type CampaignPageInfo = CampaignsTotalQuery['campaigns']['pageInfo'];

@Injectable({ providedIn: 'root' })
export class CampaignsTotalService {
  private campaignTotalRef: QueryRef<CampaignsTotalQuery>;

  constructor(
    private campaignsTotalGQL: CampaignsTotalGQL,
    private navigationService: NavigationService,
  ) {
    this.campaignTotalRef = this.campaignsTotalGQL.watch(null, {
      fetchPolicy: 'no-cache',
    });
  }

  public canActivate(instanceId: number): Observable<boolean> {
    return this.campaignTotalRef.valueChanges.pipe(
      map(
        (result: ApolloQueryResult<CampaignsTotalQuery>) =>
          result.data.campaigns,
      ),
      map(({ data, pageInfo }: CampaignsTotalQuery['campaigns']) =>
        this._resolveRedirect(data, pageInfo, instanceId),
      ),
    );
  }

  private _resolveRedirect(
    data: CampaignData,
    pageInfo: CampaignPageInfo,
    instanceId: number,
  ): boolean {
    if (pageInfo.total > 1) {
      return true;
    }

    if (pageInfo.total === 1) {
      const itemId: string = data[0].id;
      this.navigationService.navigate({
        instanceId,
        itemId,
      });
      return false;
    }

    return true;
  }

  public refetchTotal(): void {
    this.campaignTotalRef.refetch();
  }
}
