import { Injectable, NgZone } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { NavigationService } from '../../services/navigation.service';
import {
  Back,
  HandleLinkClick,
  Navigate,
  InternalNavigation,
  ReturnToHomePage,
} from './navigation.actions';

export interface NavigationStateModel {}

@State<NavigationStateModel>({
  name: 'navigation',
  defaults: {},
})
@Injectable()
export class NavigationState {
  constructor(
    private navigationService: NavigationService,
    private ngZone: NgZone,
  ) {}

  @Action(Navigate)
  async navigate(
    ctx: StateContext<NavigationStateModel>,
    { instanceId, itemId },
  ): Promise<any> {
    this.navigationService.navigate({ instanceId, itemId });
  }

  @Action(InternalNavigation)
  async internalNavigation(
    ctx: StateContext<NavigationStateModel>,
    data: { path: string },
  ): Promise<any> {
    this.navigationService.internalNavigation(data.path);
  }

  @Action(Back)
  async back(
    ctx: StateContext<NavigationStateModel>,
    { instanceId, itemId },
  ): Promise<any> {
    this.navigationService.back({ instanceId, itemId });
  }

  @Action(ReturnToHomePage)
  returnToHomePage(ctx: StateContext<NavigationStateModel>): void {
    this.ngZone.run(() => this.navigationService.returnToHomePage());
  }

  @Action(HandleLinkClick)
  handleLinkClick(ctx: StateContext<NavigationStateModel>, { event }): void {
    this.navigationService.handleLinkClick(event);
  }
}
