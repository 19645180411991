import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AdRedirectTypeEnum } from '@core/enums/fabapp-ad/ad-redirect-type.enum';
import { FabAppAdTypeEnum } from '@core/enums/fabapp-ad/fab-app-ad-type.enum';
import { FAB_AdCampaign } from '@core/models';
import { FAB_Ad } from '@core/models/fabapp-ads/ad.model';
import { NavigationService } from '@core/services/navigation.service';
import {
  FabappAdsState,
  SetAdsAsClicked,
  SetAdsAsViewed,
} from '@core/state/ads';
import { IonSlides } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'fabapp-ad-carousel',
  templateUrl: 'fabapp-ad-carousel.component.html',
  styleUrls: ['fabapp-ad-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FabAppAdCarouselComponent {
  @Input() effect: 'fade' | 'coverflow' | 'flip' = 'fade';
  slidesPerView = window.innerWidth < 768 ? 1 : 3;
  @Select(FabappAdsState.adCarouselCampaign)
  adCarouselCampaign$: Observable<FAB_AdCampaign>;
  public readonly carouselDefaultSizes = { height: 600, width: 750 };

  constructor(
    private navigationService: NavigationService,
    private store: Store,
  ) {}

  slidesDidLoad(slides: IonSlides): void {
    slides.startAutoplay();
  }

  slideChanged(slides: IonSlides): void {
    slides.stopAutoplay();
  }

  enableAutoPlay(slides: IonSlides): void {
    setTimeout(() => {
      slides.startAutoplay();
    }, 1000);
  }

  async triggerVisualization(slides: IonSlides) {
    const index = await slides.getActiveIndex();
    const ads: Array<FAB_Ad> = this.store.selectSnapshot(
      FabappAdsState.adCarouselCampaign,
    ).ads;
    this.store.dispatch(
      new SetAdsAsViewed(FabAppAdTypeEnum.CAROUSEL, ads[index]),
    );
  }

  carouselNavigation(ad: FAB_Ad): any {
    this.store.dispatch(new SetAdsAsClicked(FabAppAdTypeEnum.CAROUSEL, ad));

    if (ad.adRedirectId == AdRedirectTypeEnum.NOT_DIRECT) {
      return;
    }

    if (ad.adRedirectId == AdRedirectTypeEnum.INTERNAL_LINK) {
      this.navigationService.internalNavigation(ad.link);
      return;
    }

    if (ad.link && typeof ad.link !== 'number') {
      window.open(ad.link, '_blank');
    }
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }
}
