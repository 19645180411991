import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ComplementTreated } from '../../../models/item';
import { ComplementService } from '../complements.service';

@Component({
  selector: 'fabapp-complement-quantity-selection',
  templateUrl: './complement-quantity-selection.component.html',
  styleUrls: ['./complement-quantity-selection.component.scss'],
})
export class ComplementSelectionQuantityComponent implements OnInit {
  public readonly initialQuantity: number = 1;
  public readonly defaultValue: number = 0;

  @Input('control')
  set setControl(control: FormControl) {
    this.control = control;
  }
  public control: FormControl;

  @Input('quantity')
  set catchQuantity(quantity: number) {
    this.quantity = quantity;
  }
  public quantity: number = this.defaultValue;

  @Input('counterRangeMax')
  public set setCounterRangeMax(value: number) {
    this.counterRangeMax = value;
  }
  public counterRangeMax: number;

  @Input('counterRangeMin')
  public set setCounterRangeMin(value: number) {
    this.counterRangeMin = value;
  }
  public counterRangeMin: number;

  @Input() public label: string;
  @Input() public description: string;
  @Input() public aggregateValue: number;

  @Output() public change: EventEmitter<number> = new EventEmitter<number>();
  public canAddMore: boolean = true;
  public subscription: Subscription;

  @Input('currencyId')
  public set setCurrencyId(value: string) {
    this.currencyId = value;
  }
  currencyId: string;
  constructor() {}

  ngOnInit(): void {
    this.subscription = this.control.valueChanges.subscribe(
      (complements: Array<ComplementTreated>) =>
        this.canDisableIncrement(complements),
    );
  }

  private canDisableIncrement(complements: Array<ComplementTreated>): void {
    const currentQuantity = ComplementService.currentQuantity(complements);
    this.canAddMore = currentQuantity < this.counterRangeMax;
  }

  public getCurrentQuantity(complements: Array<ComplementTreated>): number {
    return complements.reduce(
      (previous: number, currentValue) => (previous += currentValue.quantity),
      0,
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public setQuantity(quantity: number): void {
    this.quantity = quantity;
    this.change.emit(quantity);
  }
}
