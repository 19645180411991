import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { OptionSortType, SortType } from '../../models';

@Component({
  selector: 'app-orderer',
  template: `
    <ion-list>
      <ng-container *ngFor="let ordenation of ordenations">
        <ion-item
          button
          *ngFor="let item of ordenation.options; trackBy: trackById"
          (click)="selectOrder(item)"
        >
          <ion-label>{{ item.title }}</ion-label>
        </ion-item>
      </ng-container>
    </ion-list>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdererComponent implements OnInit, OnChanges {
  /**
   * tipos de ordenções
   */
  @Input() ordenations: SortType[];
  constructor(
    private cd: ChangeDetectorRef,
    private popover: PopoverController,
  ) {}

  ngOnInit(): void {}

  selectOrder(order: OptionSortType): void {
    this.popover.dismiss({ order });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.ordenations?.currentValue) {
      this.ordenations = changes.ordenations.currentValue;
      this.cd.markForCheck();
    }
  }

  trackById(order: OptionSortType): number {
    return order.id;
  }
}
