import { Pipe, PipeTransform } from '@angular/core';
import { HomeLayoutEnum } from '@core/enums';
import { AppLayout } from '@core/models';

@Pipe({ name: 'hideDuplicatedHome' })
export class HideDuplicatedHomePipe implements PipeTransform {
  /**
   * @description
   * quando `layout_type` for `page_home`
   * e o primeiro indice não é mostrado
   */
  transform({ layout_type }: AppLayout, index: number): boolean {
    if (layout_type !== HomeLayoutEnum.PAGE_HOME) {
      return true;
    }
    return index !== 0;
  }
}
