import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { PwaInstallComponent } from './pwa-install.component';

@NgModule({
  imports: [CommonModule, TranslocoModule, IonicModule],
  declarations: [PwaInstallComponent],
  exports: [PwaInstallComponent],
})
export class PwaInstallModule {}
