import { Pipe, PipeTransform } from '@angular/core';
import { getSupportedLang } from '@utils';
import { formatDistanceStrict, setSeconds } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import ptBR from 'date-fns/locale/pt-BR';

@Pipe({
  name: 'timeBySeconds',
})
export class TimeBySecondsPipe implements PipeTransform {
  scopeAndCurrentLang;

  constructor() {}

  transform(value: number) {
    const locale = this.getLocale();
    const now = setSeconds(new Date(), 0);
    return formatDistanceStrict(now, setSeconds(now, value), {
      locale,
      roundingMethod: 'floor',
    });
  }

  getLocale(): Locale {
    const navLang: string = getSupportedLang();

    switch (navLang) {
      case 'en-US':
        return enUS;
      case 'es-ES':
        return es;
      default:
        return ptBR;
    }
  }
}
