<ng-container *transloco="let t">
  <section
    class="wrapper"
    *ngIf="pwaService.showPopup() | async as canShow"
    [class.show]="canShow && !hide"
  >
    <p class="text">{{ t('pwaInstall.text') }}</p>
    <div>
      <ion-button class="cancel" fill="clear" (click)="hideWrapper()">{{
        t('pwaInstall.cancel')
      }}</ion-button>
      <ion-button
        color="light"
        shape="round"
        (click)="pwaService.addToHomeScreen()"
        >{{ t('pwaInstall.install') }}</ion-button
      >
    </div>
  </section>
</ng-container>
