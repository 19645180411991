import { Injectable } from '@angular/core';
import { DeliveryMethod } from '@core/enums';
import { MobletService } from '@core/services/moblet.service';
import { StoreService } from '@fabapp-delivery/services/store.service';
import { DeliveryCartActions } from '@fabapp-delivery/state/cart/cart.actions';
import {
  Action,
  Actions,
  createSelector,
  ofAction,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { concat } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CancelLoadStore } from './moblets.actions';
import { SetCurrentStore } from '../core/core.actions';
import {
  CommentAdded,
  LoadGroup,
  LoadMoblet,
  LoadMobletItem,
  LoadStore,
  RefreshMoblet,
  UpdateGroupPageLayout,
  UpdatePageData,
} from './moblets.actions';

@State({
  name: 'moblets',
})
@Injectable()
export class MobletsState {
  constructor(
    private mobletService: MobletService,
    private storeService: StoreService,
    private store: Store,
    private actions$: Actions,
  ) {}

  static getMoblet(id: number): (state: any) => any {
    return createSelector([MobletsState], (state: any) => {
      return state[id];
    });
  }

  static getMobletItem(
    mobletId: number,
    itemId: number | string,
  ): (state: any) => any {
    return createSelector([MobletsState], (state: any) => {
      if (state[mobletId]) {
        return (
          state[mobletId].items
            .map((item: any) => (item.item ? item.item : item))
            // tslint:disable-next-line: triple-equals
            .find((item: any) => item.id == itemId)
        );
      }

      return null;
    });
  }

  @Action(LoadMobletItem)
  loadMobletItem(ctx: StateContext<any>, { id, item }: LoadMobletItem): any {
    const itemLoaded: any = this.store.selectSnapshot(
      MobletsState.getMobletItem(id, item),
    );
    if (itemLoaded) {
      return itemLoaded;
    }

    return this.mobletService.loadMoblet(id).pipe(
      // TODO tipar com moblet
      tap((moblet: any) => {
        const state: any = ctx.getState();
        ctx.setState({
          ...state,
          [id]: moblet,
        });
      }),
    );
  }

  @Action(RefreshMoblet)
  async refreshMoblet(
    ctx: StateContext<any>,
    { id, page, version, extraParams, fabAppVersion }: RefreshMoblet,
  ): Promise<void> {
    const state: any = ctx.getState();
    const moblet: any = await this.mobletService
      .loadMoblet(id, page, version, extraParams, fabAppVersion)
      .toPromise();

    ctx.setState({
      ...state,
      [id]: moblet,
    });
  }

  @Action(LoadMoblet)
  async loadMoblet(
    ctx: StateContext<any>,
    { id, page, version, extraParams, fabAppVersion }: LoadMoblet,
  ): Promise<any> {
    const state: any = ctx.getState();
    let moblet: any = state[id];

    const mobletFromApi: any = await this.mobletService
      .loadMoblet(id, page, version, extraParams, fabAppVersion)
      .toPromise();

    if (moblet) {
      let items = [...moblet.items, ...mobletFromApi.items];
      if (page === 1) {
        items = moblet.items.filter((localItem) =>
          mobletFromApi.items.find((apiItem) => localItem.id === apiItem.id),
        );
      }

      moblet = {
        ...moblet,
        ...mobletFromApi,
        items,
      };
    } else {
      moblet = mobletFromApi;
    }

    ctx.setState({
      ...state,
      [id]: moblet,
    });
  }

  @Action(LoadStore)
  loadStore(ctx: StateContext<any>, { id }: LoadMoblet) {
    return concat(
      ctx.dispatch(new SetCurrentStore(null, id)),
      this.storeService.getStore().pipe(
        tap((storeFromApi) => {
          const defaultDeliveryMethod = storeFromApi.hasDelivery
            ? DeliveryMethod.DELIVERY
            : DeliveryMethod.TAKE_AWAY;

          ctx.dispatch([
            new SetCurrentStore(storeFromApi),
            new DeliveryCartActions.SetDefaultDeliveryMethod(
              defaultDeliveryMethod,
            ),
          ]);

          ctx.patchState({
            [id]: storeFromApi,
          });
        }),
        takeUntil(this.actions$.pipe(ofAction(CancelLoadStore))),
      ),
    );
  }

  @Action(CancelLoadStore)
  cancelLoadStore(ctx: StateContext<any>) {
    // empty action
  }

  @Action(LoadGroup)
  async loadGroup(ctx: StateContext<any>, { id }: LoadMoblet): Promise<void> {
    try {
      const [group, groupLayout] = await this.mobletService.loadGroup(id);
      const state: any = ctx.getState();

      ctx.setState({
        ...state,
        [id]: {
          ...group,
          layout: groupLayout?.layout ? groupLayout?.layout : null,
          carosels: groupLayout?.carosels ? groupLayout.carosels : null,
        },
      });
    } catch (error) {
      console.log('loadGroup Error:', error);
      throw error;
    }
  }

  @Action(UpdateGroupPageLayout)
  updateLayout(
    ctx: StateContext<any>,
    { id, layout }: UpdateGroupPageLayout,
  ): void {
    const state: any = ctx.getState();
    const currentGroup: any = state[id];

    ctx.patchState({
      [id]: {
        ...currentGroup,
        layout,
      },
    });
  }

  @Action(CommentAdded)
  commentAdded(ctx: StateContext<any>): void {
    return;
  }

  @Action(UpdatePageData)
  updateGroupPage(ctx: StateContext<any>, { page }: UpdatePageData): void {
    // atualizar os dados de uma página
    const state: any = ctx.getState();
    const id: number = +page.instance.id;
    ctx.patchState({
      [id]: {
        ...state[id],
        ...page,
      },
    });
  }
}
