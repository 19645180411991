import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FabappAdsState } from '@core/state/ads';
import { MobletsPodcastState } from '@core/state/moblets';
import { ReactNativeState } from '@core/state/react-native/react-native.state';
import { Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AudioEvents, AudioService } from './audio.service';

@Component({
  selector: 'fabapp-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
  providers: [AudioService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioPlayerComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() item: any;
  @Input() showNext: boolean = true;
  @Input() showPrevious: boolean = true;

  @ViewChild('player') playerElementRef: ElementRef;

  isReactNative: boolean = false;
  isPlaying: boolean = false;
  isLoading: boolean = false;
  currentTime: string = '0:00';
  duration: string = '0:00';
  durationNumber: number = 0;
  range = new FormControl(0);

  @Output() currentItem: EventEmitter<any> = new EventEmitter();

  subscriptions: Subscription[] = [];
  mobletId: number;
  destroy$: Subject<boolean> = new Subject<boolean>();
  audioLoadedRef: EventListenerOrEventListenerObject;

  hasBanner: Observable<boolean>;
  constructor(
    private cdr: ChangeDetectorRef,
    public audioService: AudioService,
    private store: Store,
    private route: ActivatedRoute,
    private readonly platform: Platform,
  ) {}

  ngOnInit(): void {
    this.hasBanner = this.store.select(FabappAdsState.isShowingBanner);
    this.isReactNative = this.store.selectSnapshot(
      ReactNativeState.getIsReactNative,
    );

    if (this.isReactNative) return;

    const subscription: Subscription = this.platform.pause.subscribe(() =>
      this.audioService.stop(),
    );

    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    if (this.isReactNative) return;

    this.audioService.stop();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.audioService.player.removeEventListener(
      AudioEvents.LOADEDDATA,
      this.audioLoadedRef,
    );
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe(),
    );
  }

  ngAfterViewInit(): void {
    if (this.isReactNative) {
      return;
    }
    this.audioService.player = this.playerElementRef.nativeElement;
    this._bindPlayerEvents();
    this.mobletId = +this.route.snapshot.params.id;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.item?.currentValue) {
      this.reset();
      const item: any = changes.item.currentValue;

      this.item = {
        ...item,
        audio: item?.audio + '?' + new Date().getTime(), // faz com que a url seja 'única'
      };

      this.audioService.duration = this.item.footer;
      this.cdr.markForCheck();
    }

    if (changes?.showNext?.currentValue) {
      this.showNext = changes.showNext.currentValue;
    }

    if (changes?.showPrevious?.currentValue) {
      this.showPrevious = changes.showPrevious.currentValue;
    }

    this.cdr.markForCheck();
  }

  toogle(): void {
    this.isPlaying ? this.audioService.stop() : this.play();
  }

  play(): void {
    this.audioService.play();
  }

  onPrevious(): void {
    this.audioService.stop();
    this.notPlaying();
    this.playPrevious();
  }

  playPrevious(): void {
    if (!this.mobletId) {
      console.log('Property mobletId not found');
    }

    const itemPrevious: Observable<any> = this.store
      .select<any>(
        MobletsPodcastState.getItemPrevious(this.mobletId, this.item?.id),
      )
      .pipe(takeUntil(this.destroy$));

    this.resolve(itemPrevious);
  }

  onNext(): void {
    this.audioService.stop();
    this.notPlaying();
    this.playNext();
  }

  playNext(): void {
    const nextItem: Observable<any> = this.store
      .select<any>(
        MobletsPodcastState.getItemNext(this.mobletId, this.item?.id),
      )
      .pipe(takeUntil(this.destroy$));
    this.resolve(nextItem);
  }

  resolve(obs: Observable<any>): void {
    const subscription: Subscription = obs.subscribe((audio: any) => {
      this.item = audio;
      this.currentItem.emit(audio);
      this.cdr.markForCheck();
    });
    this.subscriptions.push(subscription);
  }

  seek(): void {
    this.audioService.seek(this.range.value);
    this.cdr.markForCheck();
  }

  private _bindPlayerEvents(): void {
    if (!this.audioService.player) {
      console.log('cannot addEventListeners, Audio player not found');
      return;
    }

    this.audioService.player.addEventListener(AudioEvents.PLAYING, () =>
      this.playing(),
    );

    this.audioService.player.addEventListener(AudioEvents.PAUSE, () =>
      this.notPlaying(),
    );

    this.audioService.player.addEventListener(AudioEvents.TIMEUPDATE, () =>
      this.updateTime(),
    );

    this.audioService.player.addEventListener(AudioEvents.LOADSTART, () => {
      this.isLoading = true;
      this.cdr.markForCheck();
    });

    this.audioLoadedRef = () => {
      this.updateDuration();
      this.play();
    };

    this.audioService.player.addEventListener(
      AudioEvents.LOADEDDATA,
      this.audioLoadedRef,
    );
  }

  playing(): void {
    this.isPlaying = true;
    this.cdr.markForCheck();
  }

  private notPlaying(): void {
    this.isPlaying = false;
    this.cdr.markForCheck();
  }

  private updateDuration(): void {
    this.isLoading = false;
    this.duration = this.audioService.durationFormatted;
    this.durationNumber = this.audioService.player.duration;
    this.cdr.markForCheck();
  }

  private updateTime(): void {
    this.range.setValue(this.audioService.player.currentTime);
    this.currentTime = this.audioService.currenTimeFormatted;
    this.cdr.markForCheck();
  }

  reset(): void {
    this.isPlaying = false;
    this.isLoading = false;
    this.currentTime = '0:00';
    this.duration = '0:00';
    this.range.setValue(0);
    this.cdr.markForCheck();
  }
}
