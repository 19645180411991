import { AppInfo, AppLayout, Colors, Page } from '@core/models';

export class LoadAppDef {
  static readonly type = '[app] Load AppDef';
}

export class SetSearch {
  static readonly type = '[app] Set Search in AppDef';
  constructor(public search: boolean) {}
}

export class SetColors {
  static readonly type = '[app] Set Colors in AppDef';
  constructor(public colors: Colors) {}
}

export class SetNewMoblet {
  static readonly type = '[app] Set New Moblet in AppDef';
  /**
   * @description
   * adicionado novo moblet ao array de pages
   */
  constructor(public moblet: Page) {}
}

export class SetPages {
  static readonly type = '[app] Set Pages in AppDef';
  /**
   * @description
   * troca todas a pages da AppDef
   */
  constructor(public pages: Page[]) {}
}

export class MergeLayout {
  static readonly type = '[app] Merge Layout in AppDef';
  /**
   * @description
   * destinado a mesclagem do layout da home dentro do AppDef
   */
  constructor(public layout: Partial<AppLayout>) {}
}

export class MergeAppInfo {
  static readonly type = '[app] Merge App Info';
  /**
   * @description
   * destinado a mesclagem de informações do app
   */
  constructor(public appInfo: Partial<AppInfo>) {}
}

export class SetHeaderPattern {
  static readonly type = '[app] Set Header Pattern in AppDef';
  constructor(public headerPattern: string) {}
}

export class SetHeaderLogo {
  static readonly type = '[app] Set Header Logo in AppDef';
  constructor(public logo: string) {}
}
