export function AutoUnsubscribe(obs$: any[] = []): any {
  return (constructor: any): void => {
    const orig: any = constructor.prototype.ngOnDestroy;
    constructor.prototype.ngOnDestroy = function(): void {
      // tslint:disable-next-line: forin
      for (const prop in this) {
        const property: any = this[prop];
        if (
          typeof property.unsubscribe === 'function' &&
          !obs$.includes(property)
        ) {
          obs$.push(property);
        }
      }
      for (const ob$ of obs$) {
        ob$.unsubscribe();
      }
      orig.apply();
    };
  };
}
