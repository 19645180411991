<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        *ngIf="!(isFabappDelivery$ | async)"
        [defaultHref]="navigationService.getHomeRoute()"
      >
      </ion-back-button>
    </ion-buttons>
    <ion-title>Pedidos</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [scrollEvents]="true">
  <ion-refresher slot="fixed" (ionRefresh)="reload($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <ng-container *ngIf="paginateOrder; else loader">
    <ion-list *ngIf="paginateOrder?.data.length > 0; else emptyState">
      <ng-container>
        <div
          *ngFor="let item of paginateOrder.data; trackBy: trackById"
          class="marketplace-order"
        >
          <span class="marketplace-order__date">
            {{ item.createdAt | date:'E. dd/MM/yyyy' }}
          </span>
          <div
            class="marketplace-order__card"
            (click)="navigateScreen('order-detail', item)"
          >
            <div class="marketplace-order__card-header">
              <img
                class="marketplace-order__card-icon"
                [src]="item.store.logo.url"
                alt=""
              />

              <span class="marketplace-order__card-title">
                {{ item.store.name }}
              </span>

              <button class="marketplace-order__card-action">
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
            <hr />
            <div class="marketplace-order__card-body">
              <span class="marketplace-order__card-information">
                Pedido #{{ item.orderNumber }}
              </span>
              <span class="marketplace-order__card-information">
                Realizado às {{ item.createdAt | date:'HH\'h\'mm' }}
              </span>
              <span
                class="
                  marketplace-order__card-information
                  marketplace-order__card-information--bold
                "
              >
                Total {{ item.amountFinal | currency: 'BRL' }}
              </span>

              <ng-container [ngSwitch]="item.status.id">
                <span
                  *ngSwitchCase="StatusDelivery.PENDING"
                  class="
                    marketplace-order__card-information
                    marketplace-order__card-information--black
                    marketplace-order__card-information--end
                  "
                >
                  Pendente
                </span>
                <span
                  *ngSwitchCase="StatusDelivery.PREPARING"
                  class="
                    marketplace-order__card-information
                    marketplace-order__card-information--yellow
                    marketplace-order__card-information--end
                  "
                >
                  Preparando
                </span>
                <span
                  *ngSwitchCase="StatusDelivery.DELIVERING"
                  class="
                    marketplace-order__card-information
                    marketplace-order__card-information--yellow
                    marketplace-order__card-information--end
                  "
                >
                  {{ item.deliveryMethodId == deliveryMethodEnum.DELIVERY ?
                  'Saiu para entrega' : 'Pronto para retirada' }}
                </span>
                <span
                  *ngSwitchCase="StatusDelivery.DELIVERED"
                  class="
                    marketplace-order__card-information
                    marketplace-order__card-information--green
                    marketplace-order__card-information--end
                  "
                >
                  Concluído
                </span>
                <span
                  *ngSwitchCase="StatusDelivery.CANCELED"
                  class="
                    marketplace-order__card-information
                    marketplace-order__card-information--red
                    marketplace-order__card-information--end
                  "
                >
                  Cancelado
                </span>
              </ng-container>
            </div>
            <app-make-rating
              *ngIf="item.store.hasClassification && (item.status.id === StatusDelivery.DELIVERING || item.status.id === StatusDelivery.DELIVERED)"
              [initialRatingInStars]="item?.rating?.rating"
              [title]="item?.rating?.rating ? 'Pedido avaliado' : 'Avalie seu pedido'"
              [disabled]="true"
              [styleType]="'inline'"
              (click)="navigateScreen('order-detail', item)"
            ></app-make-rating>
          </div>
        </div>
      </ng-container>

      <ion-infinite-scroll threshold="100px" (ionInfinite)="loadData($event)">
        <ion-infinite-scroll-content loadingText="Carregando mais pedidos...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-list>
  </ng-container>
</ion-content>

<ng-template #loader>
  <div class="spinner-wrapper">
    <ion-spinner></ion-spinner>
  </div>
</ng-template>

<ng-template #emptyState>
  <app-empty-state></app-empty-state>
</ng-template>
