<div class="rating-tile" [class]="styleType">
  <div class="title" *ngIf="title">{{ title }}</div>
  <div class="subtitle" *ngIf="subtitle">{{ subtitle }}</div>

  <ion-buttons class="rating-stars" mode="md">
    <ion-button
      [disabled]="disabled"
      class="star"
      *ngFor="let index of [1, 2, 3, 4, 5]"
      (click)="toRate(index); $event.stopPropagation()"
    >
      <ion-icon
        slot="icon-only"
        [name]="index <= initialRatingInStars ? 'star' : 'star-outline'"
      ></ion-icon>
    </ion-button>
  </ion-buttons>

  <div class="feedback" *ngIf="haveFeedback && !disabled">
    <p class="label" *ngIf="initialRatingInStars != 5">
      O que podemos melhorar?
    </p>
    <p class="label" *ngIf="initialRatingInStars == 5">O que mais gostou?</p>

    <div class="options-feedback">
      <ion-chip
        *ngFor="let option of tagOrder; let i = index"
        (click)="selectOptionFeedbackOrder(option.id, i)"
        [style.--color]="option?.selected ? '#fff' : '#706F6F'"
        [style.--background]="option?.selected ? '#FE950C' : '#FFF'"
        [style.border]="option?.selected ? 'none' : '1px solid #706F6F'"
      >
        <ion-label>{{ option.title }}</ion-label>
      </ion-chip>
    </div>

    <p class="label">Deixe um comentário</p>

    <ion-item class="input">
      <ion-input
        [value]="comment"
        (ionChange)="inputChanged($event)"
        placeholder="Comentário (opcional)"
        [maxlength]="150"
      ></ion-input>
    </ion-item>
    <p class="count-comment">{{ comment.length }}/150</p>
  </div>
</div>
