import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { PwaInstallBannerComponent } from './pwa-install-banner.component';

@NgModule({
  declarations: [PwaInstallBannerComponent],
  imports: [CommonModule, TranslocoModule, IonicModule],
  exports: [PwaInstallBannerComponent],
})
export class PwaInstallBannerModule {}
