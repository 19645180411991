<div class="document-form">
  <span class="title">Para realizar o pagamento pelo App, digite seu CPF</span>

  <ion-input
    [formControl]="documentInput"
    placeholder="Digite o CPF"
    [style.fontSize.px]="documentInput.value.length > 0 ? 30 : 14"
    [brmasker]="{ person: true }"
    type="tel"
  ></ion-input>

  <div
    *ngIf="documentInput.invalid && documentInput.touched"
    class="control-error"
  >
    <span>CPF inválido</span>
  </div>
</div>

<ion-button
  class="btn-full"
  (click)="validateDocument()"
  [disabled]="documentInput.invalid || isSubmitting"
  >Fazer pedido</ion-button
>
