import { Injectable } from '@angular/core';
import { HeraService } from '@core/services/hera.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  ResetPassword,
  ResetPasswordCodeRequest,
  ResetPasswordConfirmationCode,
} from './reset-password.actions';
import { Login } from '../auth';

export interface ResetPasswordModel {
  loading: boolean;
  username: string;
  code: string;
  password: string;
  password_confirmation: string;
}

@State<ResetPasswordModel>({
  name: 'reset_password',
  defaults: {
    loading: false,
    username: null,
    code: null,
    password: null,
    password_confirmation: null,
  },
})
@Injectable()
export class ResetPasswordState {
  constructor(private store: Store, private heraService: HeraService) {}

  @Selector()
  static username(state: ResetPasswordModel): string {
    return state.username;
  }

  @Selector()
  static code(state: ResetPasswordModel): string {
    return state.code;
  }

  @Action(ResetPasswordCodeRequest)
  async resetPasswordCodeRequest(
    ctx: StateContext<ResetPasswordModel>,
    payload: { username: string },
  ): Promise<any> {
    ctx.patchState(payload);
    await this.heraService
      .resetPasswordCodeRequest(payload.username)
      .toPromise();
  }

  @Action(ResetPasswordConfirmationCode)
  async resetPasswordConfirmationCode(
    ctx: StateContext<ResetPasswordModel>,
    payload: { username: string; code: string },
  ): Promise<any> {
    ctx.patchState(payload);
    await this.heraService
      .resetPasswordConfirmationCode(payload.username, payload.code)
      .toPromise();
  }

  @Action(ResetPassword)
  async resetPassword(
    ctx: StateContext<ResetPasswordModel>,
    payload: {
      username: string;
      code: string;
      password: string;
      passwordConfirmation: string;
    },
  ): Promise<any> {
    ctx.patchState(payload);
    const response = await this.heraService
      .resetPassword(
        payload.username,
        payload.code,
        payload.password,
        payload.passwordConfirmation,
      )
      .toPromise();

    await this.store
      .dispatch(
        new Login({ email: payload.username, password: payload.password }),
      )
      .toPromise();
    this.resetState(ctx);
  }

  private resetState(ctx: StateContext<ResetPasswordModel>): void {
    ctx.patchState({
      loading: false,
      username: null,
      code: null,
      password: null,
      password_confirmation: null,
    });
  }
}
