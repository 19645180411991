import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { List, ListDef } from '../../models';

@Component({
  selector: 'app-carousel-list',
  templateUrl: './carousel-list.component.html',
  styleUrls: ['./carousel-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselListComponent implements OnInit, OnChanges {
  @Input() items: List[];
  // output for emit a navigation
  @Output() navigate = new EventEmitter();

  @Input() listDef: ListDef;

  /**
   * opções do slide
   */
  slideOpts: any;
  constructor(private cd: ChangeDetectorRef) {
    this.slideOpts = {
      initialSlide: 0,
      speed: 400,
      slidesPerView: 1.2,
      spaceBetween: 8,
      centeredSlides: true,
      slidesOffsetBefore: -10,
    };
    this.cd.markForCheck();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items.currentValue) {
      this.items = changes.items.currentValue;
      this.cd.markForCheck();
    }
  }

  handle(item: any, seeRating: boolean = false): void {
    if (seeRating) {
      this.navigate.emit({ ...item, seeRating });
      return;
    }
    this.navigate.emit(item);
  }

  trackById(index: number, item: any): number | void {
    return index;
  }
}
