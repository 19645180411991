import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { InterstitialGrumftModalComponent } from './interstitial-grumft-modal.component';

@NgModule({
  declarations: [InterstitialGrumftModalComponent],
  imports: [CommonModule, IonicModule, TranslocoModule],
})
export class InterstitialGrumftModalModule {}
