import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { PaymentMethod as PaymentMethodEnum } from '@core/enums';
import { CoreState } from '@core/state/core/core.state';
import { FDDeleteBrand } from '@core/state/fd-payment-methods/fd-payment-methods.action';
import { LoadStore, MobletsState } from '@core/state/moblets';
import { PaymentMethodType } from '@fabapp-delivery/enums/payment-method-type.enum';
import { FDI_PaymentMethodWithCard } from '@fabapp-delivery/models/cart/cart.model';
import { FDI_CartFees } from '@fabapp-delivery/models/cart/fees.model';
import {
  FDI_Store,
  FDI_StorePaymentMethod,
  PaymentMethod,
} from '@fabapp-delivery/models/store';
import { DeliveryCartActions } from '@fabapp-delivery/state/cart/cart.actions';
import { CartState } from '@fabapp-delivery/state/cart/cart.state';
import {
  ActionSheetController,
  AlertController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { CurrencyMaskConfig } from 'ngx-currency';
import { Observable, Subscription } from 'rxjs';
import { FDPaymentMethod } from 'src/app/pages/settings/payment-methods/models/payment-method.model';
import { PaymentMethodComponent } from 'src/app/pages/settings/payment-methods/pages/payment-method/payment-method.component';

@Component({
  selector: 'fabapp-payment-method-modal',
  templateUrl: './payment-method-modal.component.html',
  styleUrls: ['./payment-method-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodModalComponent implements OnInit, OnDestroy {
  @Select(CartState.getFees) fees$: Observable<FDI_CartFees>;
  private _mobletSubscription: Subscription;
  currentTab: PaymentMethodType = PaymentMethodType.ONLINE;
  standardPaymentMethods: FDI_StorePaymentMethod[] = [];
  fabappPayMethods: FDI_StorePaymentMethod[] = [];

  customCurrencyMaskConfig: Partial<CurrencyMaskConfig>;
  paymentMethodEnum = PaymentMethodEnum;
  paymentMethodTypeEnum = PaymentMethodType;
  hasCreditCard: boolean;

  cashback = {
    cashBackValue: 0,
    noCashBack: false,
  };

  paymentMethodSubscription: Subscription;
  selectedPaymentMethod: FDI_PaymentMethodWithCard;
  storeId: number;

  constructor(
    public modalCtrl: ModalController,
    private store: Store,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private actionSheetCtrl: ActionSheetController,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.customCurrencyMaskConfig = {
      allowZero: true,
      decimal: ',',
      precision: 2, //Brazilian decimal digits
      thousands: '.',
      nullable: true,
      prefix: 'R$ ',
    };

    this.setPaymentMethodListener();
    this.compareWithSelectMethod = this.compareWithSelectMethod.bind(this);
    this.setMobletSubscription();
    this.storeId = this.store.selectSnapshot(CoreState.currentStoreId);

    this.hasCreditCard =
      this.fabappPayMethods.filter(
        (method) => method.id === this.paymentMethodEnum.ONLINE_CREDIT_CARD,
      ).length > 0;
  }

  ngOnDestroy() {
    this._mobletSubscription.unsubscribe();
    this.paymentMethodSubscription?.unsubscribe();
  }

  private setPaymentMethodListener() {
    this.paymentMethodSubscription = this.store
      .select(CartState.getSelectedPaymentMethod)
      .subscribe((selectedPaymentMethod) => {
        this.selectedPaymentMethod = selectedPaymentMethod;

        if (selectedPaymentMethod.method?.id === this.paymentMethodEnum.MONEY) {
          this.cashback = {
            cashBackValue: selectedPaymentMethod.cashback,
            noCashBack: selectedPaymentMethod.noCashBack,
          };
        }
      });
  }

  async savePaymentMethod(
    method: PaymentMethod,
    card?: FDPaymentMethod,
  ): Promise<void> {
    this.selectedPaymentMethod = { ...this.selectedPaymentMethod, method };

    const fees = this.store.selectSnapshot(CartState.getFees);
    const { cashBackValue, noCashBack } = this.cashback;

    const needCashback =
      method.id === this.paymentMethodEnum.MONEY &&
      !noCashBack &&
      (cashBackValue === 0 || cashBackValue * 100 < fees.total);

    if (needCashback) {
      this.feedbackToaster('Verifique o troco');
      return;
    }

    this.store.dispatch(
      new DeliveryCartActions.SetPaymentMethod(
        method,
        card,
        cashBackValue,
        noCashBack,
      ),
    );

    this.modalCtrl.dismiss();
  }

  setMobletSubscription(): void {
    const storeId = this.store.selectSnapshot(CoreState.currentStoreId);

    this._mobletSubscription = this.store
      .select(MobletsState.getMoblet(storeId))
      .subscribe((store: FDI_Store) => {
        this.standardPaymentMethods = store?.paymentMethods || [];
        this.fabappPayMethods = store?.fabappPayMethods || [];

        this.cdr.markForCheck();
        this.setActiveTabByMethods();
      });
  }

  setActiveTabByMethods() {
    if (!this.selectedPaymentMethod?.method) {
      this.currentTab =
        this.fabappPayMethods.length > 0
          ? PaymentMethodType.ONLINE
          : PaymentMethodType.DELIVERY;

      return;
    }

    if (this.standardPaymentMethods.find(this.compareWithSelectMethod)) {
      this.currentTab = PaymentMethodType.DELIVERY;
    } else if (this.fabappPayMethods.find(this.compareWithSelectMethod)) {
      this.currentTab = PaymentMethodType.ONLINE;
    }
  }

  compareWithSelectMethod(paymentCategory: FDI_StorePaymentMethod) {
    return !!paymentCategory?.methods?.find(
      (method) => method.id == this.selectedPaymentMethod?.method?.id,
    );
  }

  tabChanged(event) {
    this.currentTab = event.detail.value;
  }

  private async feedbackToaster(message: string): Promise<void> {
    const toast: HTMLIonToastElement = await this.toastCtrl.create({
      message,
      position: 'top',
      duration: 3000,
      buttons: [
        {
          text: 'ok',
        },
      ],
    });
    toast.present();
  }

  async cardAction(data?: FDPaymentMethod) {
    const actionSheetRef = await this.actionSheetCtrl.create({
      buttons: [
        {
          handler: () => this.openCreateOrEdit(data),
          icon: 'pencil',
          text: 'Editar',
        },
        {
          handler: () => this.confirmDelete(data),
          icon: 'trash',
          text: 'Excluir',
        },
      ],
    });

    actionSheetRef.present();
  }

  async openCreateOrEdit(data: FDPaymentMethod = null) {
    const modalRef = await this.modalCtrl.create({
      component: PaymentMethodComponent,
      componentProps: {
        id: data?.id,
        paymentMethod: data,
      },
    });

    try {
      await modalRef.present();

      const modal = await modalRef.onWillDismiss();

      if (modal.data?.reload) {
        this.reloadStore();
      }
    } catch (err) {
      console.error(err);
    }
  }

  async confirmDelete({ lastDigits, id }: FDPaymentMethod): Promise<void> {
    const alert: HTMLIonAlertElement = await this.alertCtrl.create({
      header: 'Confirmar ação',
      message: `Você gostaria de remover o cartão com o final ***${lastDigits}`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Confirmar',
          cssClass: 'danger',
          handler: () => {
            this.store.dispatch(new FDDeleteBrand(id));
            this.reloadStore();
          },
        },
      ],
    });

    await alert.present();
  }

  reloadStore() {
    this.store.dispatch(new LoadStore(this.storeId));
  }
}
