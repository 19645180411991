import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UpdateUser } from '@core/state/auth';
import { CpfCnpjValidator } from '@core/validators';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-ask-user-document',
  templateUrl: './ask-user-document.component.html',
  styleUrls: ['./ask-user-document.component.scss'],
})
export class AskUserDocumentComponent {
  @Output()
  closeBottomSheet: EventEmitter<boolean> = new EventEmitter<boolean>();
  documentInput = new FormControl('', [
    Validators.required,
    CpfCnpjValidator.validate,
  ]);
  isSubmitting = false;

  constructor(public store: Store) {}

  async validateDocument() {
    try {
      this.isSubmitting = true;
      await this.store
        .dispatch(
          new UpdateUser({
            document_number: this.documentInput.value.replace(/[^\d]+/g, ''),
          }),
        )
        .toPromise();

      this.closeBottomSheet.emit(false);
    } catch (err: any) {
      this.closeBottomSheet.emit(true);
    } finally {
      this.isSubmitting = false;
    }
  }
}
