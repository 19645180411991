<ion-header class="ion-no-border">
  <fabapp-header [isModal]="isModal" color="transparent"> </fabapp-header>
</ion-header>

<ng-container *transloco="let t">
  <ion-content class="ion-padding-horizontal">
    <div class="vertical-center">
      <div class="content">
        <div class="head ion-text-center">
          <h1 class="ion-padding-top featured">
            {{t('confirmationCode.'+resetType+'.title')}}
          </h1>
          <p
            [innerHTML]="'confirmationCode.'+resetType + '.message' | transloco: {username: username} "
          ></p>
        </div>

        <form [formGroup]="form">
          <ion-list class="ion-no-padding" lines="none">
            <ion-item>
              <ion-label position="floating"
                >{{t('confirmationCode.fields.code.label')}}</ion-label
              >
              <ion-input
                outline
                class="ion-text-uppercase"
                formControlName="code"
                type="text"
                maxlength="10"
              ></ion-input>
            </ion-item>
          </ion-list>
        </form>
        <div class="ion-text-end ion-padding-vertical">
          <a (click)="presentLoading()">
            {{ ((timeToResend > 0) ? 'confirmationCode.buttons.resendTime' :
            'confirmationCode.buttons.resend') | transloco: {time: timeToResend}
            }}
          </a>
        </div>
      </div>
    </div>
  </ion-content>

  <ion-footer class="ion-no-border">
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col col-12>
            <ion-button
              [disabled]="isSubmitting"
              expand="block"
              color="primary"
              [validate]="form"
              (click)="confimCode()"
            >
              {{t('confirmationCode.buttons.verify')}}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-footer>
</ng-container>
