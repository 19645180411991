import { Page } from '@core/models';
import { HeraService } from '@core/services';
import { Store } from '@ngxs/store';
import { AppDefState } from '../../state/appdef/appdef.state';

export class RestrictionSegmented {
  constructor(
    protected readonly store: Store,
    protected readonly heraService: HeraService,
  ) {}

  public async loadRestrictedPages(): Promise<any> {
    const pages: number[] = await this.heraService.loadRestrictedPages();
    const flattened_pages: Page[] = this.store.selectSnapshot(
      AppDefState.getFlatenedPages,
    );

    const restrictedPages: any = this.mapRestrictedPages(
      flattened_pages,
      pages,
    );

    return restrictedPages;
  }

  private mapRestrictedPages(flattenedPages: Page[], pages: number[]): any {
    const newDictionary: any = {};

    // criar um objeto com true ou false com as keys sendo o instanceId
    flattenedPages.forEach((page: Page) => {
      const { access_type, page_id, instance, access_control } = page;
      if (window['preview'] || access_control !== 'true') {
        newDictionary[instance.id] = true;
      }

      // TODO: colocar enum
      const hasntSegmentedRestriction: boolean =
        !access_type || access_type === '0' || access_type === '1';
      if (hasntSegmentedRestriction) {
        newDictionary[instance.id] = true;
        return;
      }

      // valida se o pages tem o pageid de algum item do flattenedPages
      const canAccess: boolean = pages.some(
        (pageId: number) => pageId === page_id,
      );
      newDictionary[instance.id] = canAccess;
    });

    return newDictionary;
  }
}
