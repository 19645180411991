import { AdOptions, AdPosition, AdSize } from '@capacitor-community/admob';
import { isPlatform } from '@ionic/core';

export class BannerBuilder {
  private _adIdAndroidBanner: string = '';
  private _adIdIosBanner: string = '';
  private _isTesting: boolean = false;

  build(): AdOptions {
    return {
      adId: isPlatform('android')
        ? this._adIdAndroidBanner
        : this._adIdIosBanner,
      adSize: AdSize.SMART_BANNER,
      position: AdPosition.BOTTOM_CENTER,
      isTesting: this._isTesting,
      margin: 0,
    };
  }

  public isTesting(value: boolean): this {
    this._isTesting = value;
    return this;
  }

  public adIdAndroidBanner(value: string): this {
    this._adIdAndroidBanner = value;
    return this;
  }

  public adIdIosBanner(value: string): this {
    this._adIdIosBanner = value;
    return this;
  }
}
