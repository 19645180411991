interface NavigateToMoblet {
  mobletId: any;
  superClass?: string;
  itemId?: string | number;
}
// -------------------------------------------------------------------
// navigations
export class CommunicateNavigateBack {
  static readonly type = '[app] Communicate to Navigate back';
  constructor(public type: 'root' | 'back') {}
}

export class CommunicateNavigationToSignup {
  static readonly type = '[app] Communicate Navigation To Signup';
}

export class CommunicateNavigationToLogin {
  static readonly type = '[app] Communicate Navigation To Login';
}

export class CommunicateInternalNavigation {
  static readonly type = '[app] Communicate Internal Navigation';
  constructor(
    public path: string,
    public queryParams?: Record<string, string>,
  ) {}
}

export class CommunicateNavigateToMoblet {
  static readonly type = '[app] Communicate Navigate to Moblet';
  constructor(public data: NavigateToMoblet) {}
}
// end navigations
// -------------------------------------------------------------------
