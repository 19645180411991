export enum Communicator {
  COLORS = 'colors',
  /**
   * @deprecated // FIXME: ATENÇÃO: MÉTODO SERÁ DEPRECATED, POIS O HEADER_PATTTERN O SUBSTITUIRÁ
   */
  HEADER_IMAGE = 'headerImage',
  HEADER_PATTERN = 'headerPattern',
  HEADER_LOGO = 'headerLogo',
  /**
   * @description
   * evento modifica a imagem do background da home
   */
  BACKGROUND = 'background',
  SEARCH = 'search',
  NEW_MOBLET = 'newMoblet',
  MOBLET_ITEM = 'mobletItem',

  /**
   * @description evento para mudanças no layout da home
   */
  LAYOUT = 'layout',
  OPEN_MOBLET = 'openMoblet',
  OPEN_MOBLET_ITEM = 'openMobletItem',
  OPEN_SIGNUP_PAGE = 'openSignUpPage',
  OPEN_LOGIN_PAGE = 'openLoginPage',
  /**
   * @deprecated
   * evento não é usado no portal
   */
  GET_MOBLET = 'getMoblet',
  /**
   * @description evento destinado a abrir um grupo de abas
   * ATENÇÃO: ESTE MÉTODO PODE VIRAR O Communicator.OPEN_MOBLET
   */
  MOBLET_GROUP = 'mobletGroup',
  POP = 'pop',
  POPROOT = 'popRoot',
  ADVERTISING = 'advertising',
  PAGE = 'page',
  /**
   * @description
   * evento destinado a trocar a splash
   */
  SPLASH = 'splash',
  /**
   * @description
   * evento destinado as mudanças das opções de acesso no login
   */
  ACCESS_OPTIONS = 'accessOptions',
  PARAMS = 'params',

  /**
   * @description eventos destinado as mudanças do "pages" na AppDef
   */
  PAGES_UPDATED = 'pagesUpdated',
  /**
   * @deprecated
   * evento não é usado no portal
   */
  ITEM = 'item',
  PARAMS_PAGE = 'paramsPage',
  /**
   * @description
   * evento destinado a mudança no form de cadastro
   */
  FORM = 'form',
  /**
   * @description
   * evento destinado a mudança no moblet de form
   * enquanto é editado no editor
   */
  FORM_MOBLET = 'formMoblet',
  /**
   * @description evento destinado a navegação de grupo de abas dentro de grupo de abas
   * ATENÇÃO: ESTE MÉTODO PODE VIRAR O Communicator.OPEN_MOBLET
   */
  GROUP = 'group',
  PAGE_GROUP_LAYOUT = 'pageGroupLayout',
  RELOAD_APP_DEF = 'reloadAppDef',
  RELOAD_MOBLET = 'reloadMoblet',
  /**
   * @description
   * evento destinado a navegação interna (usado pela LOFT PORTARIAS)
   * com padrão '[MOBLET_ID]/[ITEM_ID]'
   */
  INTERNAL_NAVEGATION = 'internalNavigation',
  AUTH_USER = 'authUser',
  SET_HOME_PAGE = 'setHomePage',

  UPDATE_PAGE = 'updatePage',
}
