import { Injectable } from '@angular/core';
import { SetFormMoblet, SetSignupForm } from '@core/state/core/core.actions';
import { UpdateGroupPageLayout } from '@core/state/moblets';
import { Action, State, StateContext, Store } from '@ngxs/store';
import {
  CommunicateUpdateOnFormMoblet,
  CommunicateUpdateOnSignupForm,
  CommunicateUpdateOnGroupPageLayout,
} from './communicator-moblets.action';

@State<any>({
  name: 'communicatorMoblets',
})
@Injectable()
export class CommunicatorMoblets {
  constructor(private store: Store) {}

  @Action(CommunicateUpdateOnSignupForm)
  communicateUpdateOnSignupForm(
    ctx: StateContext<any>,
    { form }: CommunicateUpdateOnSignupForm,
  ): void {
    this.store.dispatch(new SetSignupForm(form));
  }

  @Action(CommunicateUpdateOnFormMoblet)
  communicateUpdateOnFormMoblet(
    ctx: StateContext<any>,
    { formMoblet }: CommunicateUpdateOnFormMoblet,
  ): void {
    this.store.dispatch(new SetFormMoblet(formMoblet));
  }

  @Action(CommunicateUpdateOnGroupPageLayout)
  communicateUpdateOnGroupPageLayout(
    ctx: StateContext<any>,
    { id, layout }: CommunicateUpdateOnGroupPageLayout,
  ): void {
    this.store.dispatch(new UpdateGroupPageLayout(id, layout));
  }
}
