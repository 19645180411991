<ion-list class="additional-items ion-no-padding" [formGroup]="form">
  <ng-container *ngIf="complements.length > 0">
    <ng-container *ngFor="let complement of complements; trackBy: trackByItems">
      <span class="wrapper" #headers [id]="complement.id">
        <fabapp-complement-header
          [title]="complement.name"
          [maxValue]="complement.maxValue"
          [minValue]="complement.minValue"
          [required]="complement.required"
          [showRequiredLabel]="
            form.get(complement?.id)?.errors?.required ||
            form.get(complement?.id)?.errors?.minValue
          "
        >
        </fabapp-complement-header>
      </span>

      <ng-container
        *ngIf="
          complement.maxValue === maxValueForRadioOption;
          else selectionMode
        "
      >
        <ion-radio-group
          [allowEmptySelection]="true"
          [formControlName]="complement.id"
          (ionChange)="updateComplements()"
          mode="md"
          class="additionalitem-radio-container"
        >
          <fabapp-complement-selection-radio
            *ngFor="let item of complement.items"
            [label]="item.name"
            [value]="item.id"
            [description]="item.description"
            [aggregateValue]="item.price"
            [currencyId]="currencyId"
          >
          </fabapp-complement-selection-radio>
        </ion-radio-group>
      </ng-container>

      <ng-template #selectionMode>
        <fabapp-complement-quantity-selection
          *ngFor="let item of complement.items; trackBy: trackByItems"
          [label]="item.name"
          [description]="item.description"
          [aggregateValue]="item.price"
          [counterRangeMax]="complement.maxValue"
          [counterRangeMin]="
            complement.required && form.get(complement.id).errors?.required
              ? complement.minValue
              : 0
          "
          (change)="setQuantity($event, complement, item)"
          [control]="form.get(complement.id)"
          [currencyId]="currencyId"
        >
        </fabapp-complement-quantity-selection>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-content></ng-content>
</ion-list>
