import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Currency } from '@core/models/moblets';
import { getSupportedLang } from '@utils';

@Pipe({
  name: 'currencyformat',
})
export class CurrencyFormatPipe implements PipeTransform {
  transform(value: any, currency: Currency): string {
    /**
     * Para que não cause incoerências de moedas para usuários estrangeiros
     * Ou quando por algum motivo não tiver o parâmetro @parameter `currency`
     * O @parameter `value` não deve ser formatado no padrão brasileiro
     * Card de bug @see https://trello.com/c/G7nbfeEa
     */
    if (!currency) {
      return value;
    }

    if (+value == 0) {
      return null;
    }

    const currencyCode: string = currency ? currency.code : 'BRL';
    const symbolDisplay: string = 'symbol';
    const digits: string = currency
      ? `1.${currency.decimal_digits}-${currency.decimal_digits}`
      : '1.2-2';
    let newValue: any;

    if (!value) {
      return '';
    }

    const navLang: string = getSupportedLang();

    const currencyPipe: CurrencyPipe = new CurrencyPipe(navLang);
    // Caso o 'value' não seja um formato aceito pelo pipe
    try {
      newValue = currencyPipe.transform(
        value,
        currencyCode,
        symbolDisplay,
        digits,
      );
    } catch (error) {
      console.error(error);
      if (typeof value === 'string') {
        const valueParsed: string = value.replace(/,/g, '.');
        value = parseFloat(valueParsed);
      }

      newValue = `${currency.symbol + ' ' + value}`;
    }

    return newValue;
  }
}
