import { DynamicFormField } from '@core/models';
import { FDI_Store } from '@fabapp-delivery/models/store';
import { Form } from 'src/app/moblets/forms/models';

export class SetHomeRoute {
  static readonly type = '[core] Set Home Route';
}

export class LoadSignupForm {
  static readonly type = '[core] Load Signup Form';
}

export class LoadCountriesDDI {
  static readonly type = '[core] Load Countries With DDI';
}

export class SetSignupForm {
  static readonly type = '[core] Set Signup Form';
  constructor(public form: DynamicFormField[]) {}
}

export class SetFormMoblet {
  static readonly type = '[core] Set Form Moblet';
  constructor(public formMoblet: Form) {}
}

export class SetCurrentStore {
  static readonly type = '[core] Set Current Store';
  constructor(public store: FDI_Store, public storeId?: number) {}
}

export namespace Menu {
  export class Disable {
    static readonly type = '[core] Disable side menu';
  }

  export class Enable {
    static readonly type = '[core] Enable side menu';
  }
}

export class SetIsFabappDeliveryEnabled {
  static readonly type = '[core] Set Is Fabapp Delivery Enable';
  constructor(public isFabappDeliveryEnabled: boolean) {}
}
