import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '@core/services/navigation.service';
import { ModalController } from '@ionic/angular';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

const TRANSLATION_SCOPE = 'reset-password-success';
@Component({
  selector: 'fabapp-reset-password-success',
  templateUrl: 'reset-password-success.page.html',
  styleUrls: ['reset-password-success.page.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: TRANSLATION_SCOPE, alias: 'resetPasswordSuccess' },
    },
  ],
})
export class ResetPasswordSuccessPage implements OnInit {
  @Input() isModal = false;

  timeRedirect = 5000;
  constructor(
    private navCtrl: NavigationService,
    public modalController: ModalController,
  ) {}

  ngOnInit(): void {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      this.goToHome();
    }, this.timeRedirect);
  }

  goToHome() {
    if (this.isModal) {
      this.modalController.dismiss({ navigate: true });
      return;
    }

    this.navCtrl.returnToHomePage();
  }
}
