import { Injectable } from '@angular/core';
import { createSelector, State } from '@ngxs/store';
import { MobletsState } from './moblets.state';

@State({
  name: 'mobletspodcast',
})
@Injectable()
export class MobletsPodcastState {
  static treatItems(items: any[]): any[] {
    return items
      .map((item: any) => (item.item ? item.item : item))
      .filter((item: any) => item.id);
  }

  static getItemPrevious(
    mobletId: number,
    itemId: number | string,
  ): (state: any) => any {
    return createSelector([MobletsState], (state: any) => {
      if (state[mobletId]) {
        const itemsTreated: any = MobletsPodcastState.treatItems(
          state[mobletId].items,
        );

        const itemIndex: any = itemsTreated.findIndex(
          (item: any) => item.id === itemId,
        );

        if (itemIndex === -1) {
          return null;
        }

        if (itemIndex === 0) {
          return itemsTreated[itemIndex];
        }

        return itemsTreated[itemIndex - 1];
      }
      return null;
    });
  }

  static getItemNext(
    mobletId: number,
    itemId: number | string,
  ): (state: any) => any {
    return createSelector([MobletsState], (state: any) => {
      if (state[mobletId]) {
        let foundItem: any = null;

        MobletsPodcastState.treatItems(state[mobletId].items).find(
          (item: any, index: number, all: any[]) => {
            if (item.id === itemId) {
              foundItem = all[index + 1] ? all[index + 1] : all[0];
            }
          },
        );

        return foundItem;
      }
      return null;
    });
  }
}
