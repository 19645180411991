import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerComponent implements OnInit {
  /**
   * emite para o parent component que o drawer deve fechar
   */
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
}
