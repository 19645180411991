import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TermAndPrivacy } from '@core/models/themis';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ThemisService {
  constructor(private httpClient: HttpClient) {}

  private get headers(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  checkTerm(userId: string) {
    const url = `${environment.themis.url}/${window['appId']}/terms-and-policies/need-accept/${userId}`;
    return this.httpClient.get<{
      term: TermAndPrivacy;
      policy: TermAndPrivacy;
    }>(url);
  }

  getCurrent(id: string, type: 'terms' | 'privacy-policies' = 'terms') {
    const url = `${environment.themis.url}/app/${window['appId']}/${type}/${id}`;
    return this.httpClient.get<{ id: string; content: string }>(url);
  }

  acceptTerm(data: {
    userId: string;
    termVersionId?: string;
    policyVersionId?: string;
  }) {
    const url = `${environment.themis.url}/${window['appId']}/terms-and-policies/accept`;
    return this.httpClient.post(url, data);
  }
}
