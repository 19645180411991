import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreState } from '@core/state/core/core.state';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable()
export class StoreIdInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const currentStoreId: number = this.store.selectSnapshot(
      CoreState.currentStoreId,
    );

    if (currentStoreId && request.url.includes(environment.shop.url)) {
      request = request.clone({
        setHeaders: {
          'store-id': currentStoreId.toString(),
        },
      });
    }

    return next.handle(request);
  }
}
