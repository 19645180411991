import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FDI_Campaign } from '@fabapp-delivery/models/promotions/reward/campaign.model';

@Component({
  selector: 'fabapp-reward-card',
  templateUrl: './reward-card.component.html',
  styleUrls: ['./reward-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardCardComponent implements OnInit {
  @Input('reward')
  public set setReward(value: FDI_Campaign) {
    this.reward = value;
    this.cdr.markForCheck();
  }
  public reward: FDI_Campaign;
  @Input() large = false;
  @Input('progress')
  public set setProgress(value: number) {
    this.progress = value;
    this.cdr.markForCheck();
  }
  public progress: number = 0;

  @Input('punctuation')
  public set setPunctuation(value: number) {
    this.punctuation = value;
    this.cdr.markForCheck();
  }
  public punctuation: number = 0;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}
}
