export class Search {
  static readonly type = '[Search] Search App';
  // tslint:disable-next-line: typedef
  constructor(
    public payload: { filter: string; mobletId?: number; groupId?: number },
  ) {}
}

export class OpenSearchModal {
  static readonly type = '[Search] Search App';
  // tslint:disable-next-line: typedef
  constructor(
    public animated: boolean = true,
    public mobletId?: number,
    public groupId?: number,
  ) {}
}
