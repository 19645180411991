<div class="box-input">
  <ion-button
    [disabled]="counterValue <= counterRangeMin || disabled"
    (click)="decrement()"
    fill="clear"
    color="dark"
  >
    <ion-icon slot="icon-only" name="remove"></ion-icon>
  </ion-button>
  <div class="number-quantity">{{ counterValue }}</div>
  <ion-button
    [disabled]="counterValue >= counterRangeMax || disabled || !canAddMore"
    (click)="increment()"
    fill="clear"
    color="dark"
  >
    <ion-icon slot="icon-only" name="add"></ion-icon>
  </ion-button>
</div>
