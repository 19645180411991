import { Colors } from './colors.model';
import { convertToBoolean, flatten } from '@utils';

import { Page, AppInfo, AppLayout } from './index';
import { FirebaseConfig } from './firebase-config.model';
// tslint:disable: variable-name
export class AppDef {
  constructor(init?: Partial<AppDef>) {
    const parsed: Partial<AppDef> = {
      ...init,
      default_status: convertToBoolean(init.default_status),
      is_v2: convertToBoolean(init.is_v2),
      left_menu: convertToBoolean(init.left_menu),
      search: convertToBoolean(init.search),
      show_banner: convertToBoolean(init.show_banner),
      header_pattern: this.parseHeaderImage(init.header_pattern),
      google_firebase_config: this.parseFirebaseConfig(
        String(init.google_firebase_config),
      ),
      info: {
        ...init.info,
        headerImage: this.parseHeaderImage(init.info.headerImage),
      },
      layout: {
        ...init.layout,
        grid_columns: +init.layout.grid_columns,
        icon_background: +init.layout.icon_background,
        icon_size: +init.layout.icon_size,
        margin_top: +init.layout.margin_top,
        show_header: convertToBoolean(init.layout.show_header),
        show_icon: convertToBoolean(init.layout.show_icon),
        show_text: convertToBoolean(init.layout.show_text),
        title_size: +init.layout.title_size,
      },
      flattened_pages: flatten(init.pages),
    };

    Object.assign(this, parsed);
  }

  app_id: string;
  background_color?: string;
  colors: Colors;
  custom_banner_id?: string;

  /**
   * @deprecated
   * parametro deve ser evitado o uso devido a mudança
   * do novo sistema pagamento
   */
  default_status: boolean;
  google_analytics_id_native?: string;
  google_analytics_id_web?: string;
  google_firebase_config?: FirebaseConfig;
  google_maps_key: string;
  header_pattern: string;
  info: AppInfo;
  is_v2: boolean;
  logo: string;
  layout: AppLayout;
  left_menu: boolean;
  login_image_id?: string;
  pages?: Page[];
  search: boolean;
  secure_app_share_url: string;
  show_banner: boolean;
  splash_default: boolean;
  flattened_pages?: Page[];

  parseHeaderImage(headerImage: string): string {
    if (headerImage && headerImage !== 'not_found') {
      return (
        "url('" + headerImage + "') center repeat var(--ion-color-primary)"
      );
    } else {
      return null;
    }
  }

  parseFirebaseConfig(firebaseConfig: string): FirebaseConfig {
    if (!firebaseConfig || firebaseConfig === 'undefined') {
      return null;
    }

    firebaseConfig = firebaseConfig
      .replace('apiKey:', '"apiKey":')
      .replace('authDomain:', '"authDomain":')
      .replace('databaseURL:', '"databaseURL":')
      .replace('projectId:', '"projectId":')
      .replace('storageBucket:', '"storageBucket":')
      .replace('messagingSenderId:', '"messagingSenderId":')
      .replace('appId:', '"appId":')
      .replace('measurementId:', '"measurementId":');

    return <FirebaseConfig>JSON.parse(firebaseConfig);
  }
}
