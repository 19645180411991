<ion-tabs (ionTabsDidChange)="tabsChanged($event)" *transloco="let t">
  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="home" class="home-tab">
      <ion-icon></ion-icon>
      <ion-label>{{t('tabs.home')}}</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="search" class="search-tab">
      <ion-icon></ion-icon>
      <ion-label>{{t('tabs.search')}}</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="order-history" class="my-orders-tab">
      <ion-icon></ion-icon>
      <ion-label>{{t('tabs.orders')}}</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="settings" class="settings-tab">
      <ion-icon></ion-icon>
      <ion-label>{{t('tabs.profile')}}</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
