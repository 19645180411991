import { Injectable, NgZone } from '@angular/core';
import { AppInfo, Colors, Page, User } from '@core/models';
import { NavigationService } from '@core/services';
import { AuthState, Login, Logout } from '@core/state/auth';
import { SetHomeRoute } from '@core/state/core/core.actions';
import { RefreshMoblet, UpdatePageData } from '@core/state/moblets';
import { PushDeviceRegister } from '@core/state/push';
import { ModalController, NavController } from '@ionic/angular';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { convertToBoolean } from '@utils';
import {
  AppDefState,
  MergeAppInfo,
  MergeLayout,
  SetColors,
  SetHeaderLogo,
  SetHeaderPattern,
  SetNewMoblet,
  SetPages,
  SetSearch,
} from '../../appdef';
import {
  CommunicateInternalNavigation,
  CommunicateNavigateBack,
  CommunicateNavigateToMoblet,
  CommunicateNavigationToLogin,
  CommunicateNavigationToSignup,
} from '../communicator-navigation.action';
import {
  CommunicateAccessOptionsChange,
  CommunicateAppInfoChange,
  CommunicateAuthUser,
  CommunicateColorChange,
  CommunicateHeaderLogoChange,
  CommunicateHeaderPatternChange,
  CommunicateHomePageChanged,
  CommunicateLayoutChange,
  CommunicateNewMobletAdded,
  CommunicatePagesChange,
  CommunicateReloadMoblet,
  CommunicateSearchChange,
  CommunicateSplashScreenChange,
  CommunicateUpdatePageData,
} from './communicator-appdef.action';

@State<any>({
  name: 'communicator',
})
@Injectable()
export class CommunicatorState {
  constructor(
    private store: Store,
    private navCtrl: NavController,
    private navigationService: NavigationService,
    private ngZOne: NgZone,
    private modalCtrl: ModalController,
  ) {}

  // -------------------------------------------------------------------
  // AppDef
  @Action(CommunicateColorChange)
  public communicateColorChange(
    ctx: StateContext<any>,
    { colors }: CommunicateColorChange,
  ): void {
    this.store.dispatch(new SetColors(this.treatColors(colors)));
  }

  /**
   * @description
   * transforma as propriedades vindas do editor,
   * para o padrão que o editor identifica
   */
  private treatColors(colors: Colors): Colors {
    const newColors: any = colors;

    if (colors['headerColor']) {
      newColors.header_color = colors['headerColor'];
    }
    if (colors['backgroundColor']) {
      newColors.background_color = colors['backgroundColor'];
    }
    if (colors['navigationColor']) {
      newColors.navigation_color = colors['navigationColor'];
    }
    if (colors['fontColor']) {
      newColors.font_color = colors['fontColor'];
    }

    if (colors['primary']) {
      newColors.primary_color = colors['primary'];
    }

    if (colors['advanced_colors']) {
      newColors.advanced_colors_color = colors['advanced_colors'];
    }
    return newColors;
  }

  @Action(CommunicateSearchChange)
  public communicateSearchChange(
    ctx: StateContext<any>,
    { search }: CommunicateSearchChange,
  ): void {
    this.store.dispatch(new SetSearch(convertToBoolean(search)));
  }

  @Action(CommunicateNewMobletAdded)
  public async communicateNewMobletAdded(
    ctx: StateContext<any>,
    { moblet }: CommunicateNewMobletAdded,
  ): Promise<void> {
    this.store.dispatch(new SetNewMoblet(moblet));
  }

  @Action(CommunicateLayoutChange)
  public communicateLayoutChange(
    ctx: StateContext<any>,
    { layout }: CommunicateLayoutChange,
  ): void {
    this.store.dispatch(new MergeLayout(layout));
  }

  @Action(CommunicatePagesChange)
  public communicatePagesChange(
    ctx: StateContext<any>,
    { pages }: CommunicatePagesChange,
  ): void {
    this.store.dispatch(new SetPages(pages));
  }

  @Action(CommunicateAccessOptionsChange)
  public communicateAccessOptionsChange(
    ctx: StateContext<any>,
    { info }: CommunicateAccessOptionsChange,
  ): void {
    const accessOptionApp: Partial<AppInfo> = info;

    if (info['login_image_url'] !== undefined) {
      accessOptionApp.login_image = info['login_image_url'];
    }

    this.store.dispatch(new MergeAppInfo(accessOptionApp));
  }

  @Action(CommunicateAppInfoChange)
  public communicateAppInfoChange(
    ctx: StateContext<any>,
    { info }: CommunicateAppInfoChange,
  ): void {
    // FIXME: código comentado abaixo não será mais usado por causa
    // do app não usar mais o parametro headerImage

    // trata o parametro headerImage para o padrão aceito
    // if (info?.headerImage) {
    //   info = {
    //     ...info,
    //     headerImage: this.parseHeaderImage(info.headerImage),
    //   };
    // }
    this.store.dispatch(new MergeAppInfo(info));
  }

  @Action(CommunicateHeaderPatternChange)
  public communicateheaderPatternChange(
    ctx: StateContext<any>,
    { headerPattern }: CommunicateHeaderPatternChange,
  ): void {
    this.store.dispatch(
      new SetHeaderPattern(this.parseHeaderImage(headerPattern)),
    );
  }

  private parseHeaderImage(headerImage: string): string {
    if (headerImage && headerImage !== 'not_found') {
      return (
        'url("' + headerImage + '") center repeat var(--ion-color-primary)'
      );
    } else {
      return null;
    }
  }

  @Action(CommunicateHeaderLogoChange)
  public communicateHeaderLogoChange(
    ctx: StateContext<any>,
    { logo }: CommunicateHeaderLogoChange,
  ): void {
    this.store.dispatch(new SetHeaderLogo(logo));
  }
  // end AppDef
  // -------------------------------------------------------------------

  // -------------------------------------------------------------------
  // others
  @Action(CommunicateSplashScreenChange)
  public communicateSplashScreenChange(
    ctx: StateContext<any>,
    { splash }: CommunicateSplashScreenChange,
  ): void {
    this.changeSplashScreen(splash);
  }

  private changeSplashScreen(splash: string): void {
    if (!splash) {
      return;
    }

    const splashContainer: HTMLElement = document.querySelector(
      '#splashscreen-home',
    );

    splashContainer.querySelector('img').setAttribute('src', splash);

    // show image when loaded
    splashContainer.querySelector('img').onload = () => {
      splashContainer.classList.remove('-hide');
      setTimeout(() => splashContainer.classList.add('-hide'), 5000);
    };
  }

  @Action(CommunicateReloadMoblet)
  public communicateReloadMoblet(
    ctx: StateContext<any>,
    { mobletId }: CommunicateReloadMoblet,
  ): void {
    // TODO:  melhorar dispatch ou passar responsabilidade para o editor
    const page: Page = this.store.selectSnapshot(AppDefState.getPage(mobletId));
    if (page.moblet_name === 'm-catalog') {
      this.store.dispatch(new RefreshMoblet(mobletId, 1, 3, {}, 3));
      return;
    }

    this.store.dispatch(new RefreshMoblet(mobletId));
  }

  @Action(CommunicateHomePageChanged)
  public communicateHomePageChanged(
    ctx: StateContext<any>,
    { layoutType }: CommunicateHomePageChanged,
  ): void {
    // atualiza o tipo de layout da home
    this.store.dispatch(new MergeLayout({ layout_type: layoutType }));

    // seta a rota da home e navega para a nova rota da home
    this.store
      .dispatch(new SetHomeRoute())
      .subscribe(() => this.navigationService.returnToHomePage());
  }

  @Action(CommunicateUpdatePageData)
  public communicateGroupUpdate(
    ctx: StateContext<any>,
    { page }: CommunicateUpdatePageData,
  ): void {
    this.store.dispatch(new UpdatePageData(page));
  }
  // end others
  // -------------------------------------------------------------------

  // -------------------------------------------------------------------
  // navigations
  @Action(CommunicateNavigateBack)
  public async communicateNavigateBack(
    ctx: StateContext<any>,
    { type }: CommunicateNavigateBack,
  ): Promise<void> {
    const hasModal: HTMLIonModalElement = await this.modalCtrl.getTop();
    // caso haja algum modal na tela ele será retirado
    if (!!hasModal) {
      this.ngZOne.run(() => this.modalCtrl.dismiss());
    }

    if (type === 'back') {
      this.ngZOne.run(() => this.navCtrl.pop());
      return;
    }

    this.ngZOne.run(() => this.navigationService.returnToHomePage());
  }

  @Action(CommunicateNavigationToSignup)
  public communnicateNavigationToSignup(ctx: StateContext<any>): void {
    // faz o logout caso seja necessário para poder poder mostrar a tela de signup
    this.store.dispatch(new Logout());
    this.ngZOne.run(() => this.navCtrl.navigateForward('/auth/signup'));
  }

  @Action(CommunicateNavigationToLogin)
  public communicateNavigationToLogin(ctx: StateContext<any>): void {
    this.ngZOne.run(() => this.navCtrl.navigateForward('/auth/login'));
  }

  @Action(CommunicateInternalNavigation)
  public communicateInternalNavigation(
    ctx: StateContext<any>,
    { path, queryParams }: CommunicateInternalNavigation,
  ): void {
    this.navigationService.internalNavigation(path, queryParams);
  }

  @Action(CommunicateNavigateToMoblet)
  public communicateNavigateToMoblet(
    ctx: StateContext<any>,
    { data }: CommunicateNavigateToMoblet,
  ): void {
    const { itemId, mobletId } = data;
    this.ngZOne.run(() =>
      this.navigationService.navigate({
        instanceId: mobletId,
        itemId,
      }),
    );
  }
  // end navigations
  // -------------------------------------------------------------------

  // -------------------------------------------------------------------
  // others
  @Action(CommunicateAuthUser)
  public communicateAuthUser(
    ctx: StateContext<any>,
    { auth }: CommunicateAuthUser,
  ): void {
    this.ngZOne.run(async () => {
      await this.store.dispatch(new Login(auth)).toPromise();
      await this.store.dispatch(new PushDeviceRegister()).toPromise();

      const user: User = this.store.selectSnapshot(AuthState.getUser);
      if (user) {
        this.navigationService.returnToHomePage();
      }
    });
  }
  // end others
  // -------------------------------------------------------------------
}
