export function convertToBoolean(value: any): boolean {
  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
      return true;
    default:
      return false;
  }
}
