import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: 'empty-state', alias: 'emptyState' },
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateComponent {
  @Input() public message: string = '';
  private _url: string = '';

  @Input()
  public set url(value: string) {
    this._url = value;
    this.cdr.markForCheck();
  }

  public get url(): string {
    return this._url;
  }

  constructor(private cdr: ChangeDetectorRef) {}
}
