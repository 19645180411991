import { Injectable } from '@angular/core';
import {
  Plugins,
  PushNotification,
  PushNotificationActionPerformed,
  PushNotificationToken,
} from '@capacitor/core';
import { NavigationParameters, User } from '@core/models';
import { HeraService } from '@core/services/hera.service';
import { HermesService } from '@core/services/hermes.service';
import { NavigationService } from '@core/services/navigation.service';
import { environment } from '@environments/environment';
import { AlertController, Platform } from '@ionic/angular';
import { translate, TranslocoService } from '@ngneat/transloco';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { AuthState } from '../auth';
import { ReactNativeState } from '../react-native/react-native.state';
import {
  PushClicked,
  PushDeviceRegister,
  PushReceived,
  PushRequestPermision,
  PushUpdateToken,
} from './push.actions';

const { PushNotifications } = Plugins;

export interface PushModel {
  token: string;
}
let lang: string;

@State<PushModel>({
  name: 'push',
  defaults: {
    token: '',
  },
})
@Injectable()
export class PushState {
  alert: HTMLIonAlertElement;

  constructor(
    private store: Store,
    private platform: Platform,
    private heraService: HeraService,
    private hermesService: HermesService,
    private alertController: AlertController,
    private navigationService: NavigationService,
    private translocoService: TranslocoService,
  ) {
    lang = this.translocoService.getActiveLang();
  }

  public get isReactNative(): boolean {
    return this.store.selectSnapshot(ReactNativeState.getIsReactNative);
  }

  public get reactNativePlatform(): string {
    return this.store.selectSnapshot(ReactNativeState.getReactNativePlatform);
  }

  @Action(PushRequestPermision)
  async requestPermision(
    ctx: StateContext<PushModel>,
    action: PushRequestPermision,
  ): Promise<any> {
    if (!this.platform.is('capacitor')) {
      if (this.isReactNative) return;

      this.webRequestPermission();
      this.webListenerReceived();
      return;
    }

    this.nativeRequestPermission();
    this.nativeRegistration();
    this.nativeListenerReceived();
    this.nativeListenerErrors();
    this.nativeListenerClick();
  }

  @Action(PushUpdateToken)
  updateToken(ctx: StateContext<PushModel>, payload: { token: string }): void {
    ctx.patchState({
      token: payload.token,
    });
  }

  @Action(PushDeviceRegister)
  async pushDeviceRegister(ctx: StateContext<PushModel>): Promise<any> {
    const state = ctx.getState();
    if (!state.token) {
      return;
    }
    const user: User = this.getUser();

    await this.heraService
      .deviceRegister(state.token, this.getPlatform(), user?.id)
      .toPromise();
  }

  @Action(PushReceived)
  async pushReceived(
    ctx: StateContext<PushModel>,
    action: PushReceived,
  ): Promise<any> {
    console.log(action);
    const notification: PushNotification = action.payload;

    if (this.alert) {
      await this.alert.dismiss();
    }

    await this.pushAlert(
      notification.data.title,
      notification.data.message,
      this.getNavigationParams(notification.data.intent_url),
    );
    await this.markAsRead(notification.data.notification_id);
  }

  @Action(PushClicked)
  async pushClicked(
    ctx: StateContext<PushModel>,
    pushClicked: PushClicked,
  ): Promise<any> {
    const action: PushNotificationActionPerformed = pushClicked.payload;
    setTimeout(() => {
      this.navigate(
        this.getNavigationParams(action.notification.data.intent_url),
      );
    }, 1000);
    await this.markAsRead(action.notification.data.notification_id);
  }

  private async pushAlert(
    title: string,
    message: string,
    nav: NavigationParameters,
  ): Promise<any> {
    if (nav.itemId === 'home') {
      const closeText: string = translate('modal.close', {}, `${lang}`);

      this.alert = await this.alertController.create({
        header: title,
        message,
        buttons: [
          {
            text: closeText,
            role: 'cancel',
            handler: () => {},
          },
        ],
      });
      return await this.alert.present();
    }

    const cancelText: string = translate('modal.cancel', {}, `${lang}`);
    const goToText: string = translate('modal.goToPage', {}, `${lang}`);

    this.alert = await this.alertController.create({
      header: title,
      message,
      buttons: [
        {
          text: cancelText,
          role: 'cancel',
          handler: () => {},
        },
        {
          text: goToText,
          handler: () => {
            this.navigate(nav);
          },
        },
      ],
    });

    await this.alert.present();
  }

  private navigate(nav: NavigationParameters): void {
    if (nav.itemId === 'home') {
      return this.navigationService.returnToHomePage();
    }
    this.navigationService.navigate(nav);
  }

  private async markAsRead(notificationId: string): Promise<any> {
    const user: User = this.getUser();
    await this.hermesService
      .notificationRead(notificationId, this.getPlatform(), user?.id)
      .toPromise();
  }

  private getUser(): User {
    return this.store.selectSnapshot(AuthState.getUser);
  }

  private getNavigationParams(intentUrl: string): any {
    // Navigation to home
    if (intentUrl.includes('home')) {
      return { instanceId: 0, itemId: 'home' };
    }
    const instanceId: number = Number(intentUrl.split('/')[1]);
    const itemId: string = intentUrl.split('/')[2] || null;
    return { instanceId, itemId };
  }

  private getPlatform(): string {
    let platform: string = this.isReactNative
      ? this.reactNativePlatform
      : 'web';

    if (!this.platform.is('capacitor')) {
      return platform;
    }

    if (this.platform.is('ios')) {
      platform = 'ios';
    } else if (this.platform.is('android')) {
      platform = 'android';
    }
    return platform;
  }

  private async webRequestPermission(): Promise<any> {
    if (typeof Notification === 'undefined') return;

    const result: NotificationPermission = await Notification.requestPermission();

    if (result !== 'granted') {
      return;
    }

    navigator.serviceWorker.getRegistrations().then(async ([registration]) => {
      const webPushKey = environment.vapidPublicKey;

      const applicationServerKey: Uint8Array = this.urlB64ToUint8Array(
        webPushKey,
      );

      const subscription: PushSubscription = await registration.pushManager.subscribe(
        {
          userVisibleOnly: true,
          applicationServerKey,
        },
      );

      const token = JSON.stringify(subscription);

      this.store.dispatch(new PushUpdateToken(token));
      this.store.dispatch(new PushDeviceRegister());
    });
  }

  private nativeRequestPermission(): void {
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.requestPermission().then((result) => {
      if (result.granted) {
        PushNotifications.register();
      } else {
        console.log(result);
        // Show some error
      }
    });
  }

  // On succcess, we should be able to receive notifications
  private nativeRegistration(): void {
    PushNotifications.addListener(
      'registration',
      (token: PushNotificationToken) => {
        this.store.dispatch(new PushUpdateToken(token.value));
        this.store.dispatch(new PushDeviceRegister());
      },
    );
    // this.nativeChannel();
  }

  // Create native channel
  private nativeChannel(): void {
    PushNotifications.createChannel({
      id: 'Default',
      description: 'Default',
      importance: 4,
      visibility: 1,
      name: 'Default',
    })
      .then((success: any) =>
        console.log('Channel Subscribed', JSON.stringify(success)),
      )
      .catch((error: any) =>
        console.error('Channel Error', JSON.stringify(error)),
      );
  }

  private webListenerReceived(): void {
    const serviceWorker: any = navigator.serviceWorker;
    let handler = (event) => {
      if (event.data) {
        const data: any = JSON.parse(event.data);
        const notificationId: string = data.notification_id;
        const notification: PushNotification = { id: notificationId, data };

        const payload: PushNotificationActionPerformed = {
          actionId: notificationId,
          notification,
        };

        this.store.dispatch(new PushClicked(payload));
      }
    };

    serviceWorker?.addEventListener('message', handler);
  }

  // Show us the notification payload if the app is open on our device
  private nativeListenerReceived(): void {
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotification) => {
        this.store.dispatch(new PushReceived(notification));
      },
    );
  }

  // Some issue with your setup and push will not work
  private nativeListenerErrors(): void {
    PushNotifications.addListener('registrationError', (error: any) => {
      alert(
        'Não foi possível registrar o dispositivo para recebimento de Push' +
          JSON.stringify(error),
      );
    });
  }

  // Method called when tapping on a notification
  private nativeListenerClick(): void {
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (action: PushNotificationActionPerformed) => {
        this.store.dispatch(new PushClicked(action));
      },
    );
  }

  private urlB64ToUint8Array(base64String) {
    let padding: string = '='.repeat((4 - (base64String.length % 4)) % 4);
    let base64: string = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    let rawData = window.atob(base64);
    let outputArray = new Uint8Array(rawData.length);

    for (let i: number = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
}
