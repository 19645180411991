import { AppInfo, AppLayout, Colors, Page } from '@core/models';
import { HomeLayout } from '@core/types';

// -------------------------------------------------------------------
// AppDef
export class CommunicateColorChange {
  static readonly type = '[app] Communicate Color Change for AppDef';
  constructor(public colors: Colors) {}
}

export class CommunicateSearchChange {
  static readonly type = '[app] Communicate Search Change for AppDef';
  constructor(public search: boolean) {}
}

export class CommunicateNewMobletAdded {
  static readonly type = '[app] Communicate New Moblet Added for AppDef';
  constructor(public moblet: Page) {}
}

export class CommunicateLayoutChange {
  static readonly type = '[app] Communicate Layout Change for AppDef';
  constructor(public layout: AppLayout) {}
}

export class CommunicatePagesChange {
  static readonly type = '[app] Communicate Pages Change for AppDef';
  /**
   * @description
   * comunica que deve trocar todas as paginas da AppDef
   */
  constructor(public pages: Page[]) {}
}

export class CommunicateAccessOptionsChange {
  static readonly type = '[app] Communicate Access Options Change for AppDef';
  /**
   * @description
   * action destinado as mudanças das opções
   * de acesso no login
   */
  constructor(public info: Partial<AppInfo>) {}
}

export class CommunicateAppInfoChange {
  static readonly type = '[app] Communicate AppInfo Change for AppDef';
  constructor(public info: Partial<AppInfo>) {}
}

export class CommunicateHeaderPatternChange {
  static readonly type = '[app] Communicate Header Pattern Change for AppDef';
  constructor(public headerPattern: string) {}
}

export class CommunicateHeaderLogoChange {
  static readonly type = '[app] Communicate Header Logo Change for AppDef';
  constructor(public logo: string) {}
}

export class CommunicateUpdatePageData {
  static readonly type = '[app] Communicate Update Page Data for AppDef';
  constructor(public page: Page) {}
}

// end AppDef
// -------------------------------------------------------------------

// -------------------------------------------------------------------
// others
export class CommunicateSplashScreenChange {
  static readonly type = '[app] Communicate Splash Screen Change for AppDef';
  /**
   * @description
   * seleciona a splashScreen Web
   * e troca imagem
   */
  constructor(public splash: string) {}
}

export class CommunicateReloadMoblet {
  static readonly type = '[app] Communicate Reload Moblet for AppDef';
  constructor(public mobletId: number) {}
}

export class CommunicateHomePageChanged {
  static readonly type = '[app] Communicate Set Home Page';
  constructor(public layoutType: HomeLayout) {}
}
// end others
// -------------------------------------------------------------------

// -------------------------------------------------------------------
// loft
export class CommunicateAuthUser {
  static readonly type = '[app] Communicate Login App';
  /**
   * @description
   * login e senha
   */
  constructor(public auth: { email: string; password: string }) {}
}
// end loft
// -------------------------------------------------------------------
