<div *ngIf="reward" class="wrapper ion-activatable" [class.-large]="large">
  <ion-ripple-effect *ngIf="!large"></ion-ripple-effect>

  <div class="reward-container">
    <span class="reward-header ion-margin-bottom">
      <!-- icons -->
      <ion-icon
        *ngIf="!large"
        class="headericon couponicon -rewards"
      ></ion-icon>
      <div class="icon-box" *ngIf="large">
        <ion-icon class="award couponicon -rewards"></ion-icon>
      </div>
      <!-- icons -->

      <div class="inner-wrapper">
        <span class="content" [class.-large]="large"
          >{{ reward.requiredOrders - punctuation }}
          {{ reward.requiredOrders > 1 ? 'pedidos' : 'pedido' }} para ganhar a
          recompensa de {{ reward.rewardValue / 100 | currency }}</span
        >
        <ion-icon *ngIf="!large" name="chevron-forward-outline"></ion-icon>
      </div>
    </span>
    <div class="couponprogress-wrapper" [class.-large]="large">
      <div class="header">
        Mínimo de {{ reward.minOrderAmount / 100 | currency }} por pedido. Até
        {{ reward.endDate | date: "dd 'de' MMM 'de' yyyy" }}
      </div>
      <div class="progress" [style.--before-size]="progress + '%'"></div>
    </div>
  </div>
</div>
