<ion-slides [pager]="images.length > 1">
  <ion-slide
    class="image-box"
    *ngFor="let image of images; let i = index; trackBy: trackByIndex"
    (click)="openImage(image, i)"
    [style.backgroundImage]="image && 'url(' + image + ')'"
  >
    <div class="gap"></div>
  </ion-slide>
</ion-slides>
