import { Communicator } from '@core/enums/communicator-of-editor/communicator.enum';
import {
  AppInfo,
  AppLayout,
  Colors,
  DynamicFormField,
  GroupLayoutDef,
  Page,
} from '@core/models';
import { AppDefState } from '@core/state/appdef';
import { Store } from '@ngxs/store';
import { Form } from 'src/app/moblets/forms/models';
import { CommunicatorService } from './communicator.service';

export class CommunicatorTest {
  public colors: Colors = {
    background_color: '#e2eeff',
    font_color: '#010440',
    header_color: '#000000',
    navigation_color: '#FFFFFF',
    primary_color: '#000000',
    advanced_colors_color: true,
  };

  public moblet: Page = {
    blocked: null,
    access_control: 'false',
    access_type: '',
    app_id: '779970',
    background: null,
    background_id: null,
    description: '',
    descriptionHtml: '<br/>',
    has_detail: false,
    icon:
      'https://universo-dev-a.s3.amazonaws.com/be8635a45226bbd5cd004dcf508f34e368923856',
    icon_id: 'be8635a45226bbd5cd004dcf508f34e368923856',
    id: 14371191,
    instance: { id: '2873238' },
    is_folder: '0',
    moblet_name: 'm-forms',
    moblets: [],
    name: 'Formulário',
    order: '2032',
    page_id: 1437113,
    parent_id: null,
    position: 56,
    search: '0',
    type: {
      id: '85',
      custom_login: null,
      proxy: 'https://proxy.dev.universo.mobi/moblets/',
      secureProxy: 'https://proxy.dev.universo.mobi/moblets/',
      superClass: 'm-forms',
    },
    visible: 'true',
  };

  public layout: AppLayout = {
    background: '1',
    gradient: false,
    grid_columns: 3,
    header_logo_alignment: '',
    icon_background: 1,
    icon_format: 'square',
    icon_size: 56,
    icon_spacing: 'normal',
    layout_type: 'grid',
    list_justify: 'left',
    margin_top: 3,
    name: 'default_list',
    search_background: '0.0',
    search_field: 'top',
    show_header: true,
    show_icon: true,
    show_text: true,
    text_color: '#000000',
    title_size: 34,
    type: 'list',
  };

  constructor(
    private communicatorService: CommunicatorService,
    private store: Store,
  ) {}

  colorsTest(colors?: Partial<Colors>): void {
    this.communicatorService.eventCall({
      event: Communicator.COLORS,
      data: { colors: { ...this.colors, ...colors } },
    });
  }

  headerImageTest(url: string = 'https://picsum.photos/800'): void {
    this.communicatorService.eventCall({
      event: Communicator.HEADER_IMAGE,
      data: { headerImage: url },
    });
  }

  headerPatternTest(url: string = 'https://picsum.photos/60'): void {
    this.communicatorService.eventCall({
      event: Communicator.HEADER_PATTERN,
      data: { header_pattern: url },
    });
  }

  backgroundTest(url: string = 'https://picsum.photos/800'): void {
    this.communicatorService.eventCall({
      event: Communicator.BACKGROUND,
      data: { background: url },
    });
  }

  searchTest(): void {
    this.communicatorService.eventCall({
      event: Communicator.SEARCH,
      data: { search: true },
    });
  }

  newMobletTest(): void {
    this.communicatorService.eventCall({
      event: Communicator.NEW_MOBLET,
      data: { moblet: this.moblet },
    });
  }

  layoutTest(): void {
    this.communicatorService.eventCall({
      event: Communicator.LAYOUT,
      data: { layout: this.layout },
    });
  }

  popTest(): void {
    this.communicatorService.eventCall({
      event: Communicator.POP,
      data: null,
    });
  }

  popRootTest(): void {
    this.communicatorService.eventCall({
      event: Communicator.POPROOT,
      data: null,
    });
  }

  reloadAppDefTest(): void {
    this.communicatorService.eventCall({
      event: Communicator.RELOAD_APP_DEF,
      data: null,
    });
  }

  pagesUpdateTest(): void {
    const [page1, ...pages] = this.store.selectSnapshot(AppDefState.getPages);
    this.communicatorService.eventCall({
      event: Communicator.PAGES_UPDATED,
      data: { pages },
    });
  }

  splashTest(): void {
    const splash: string =
      'https://universo-dev-a.s3.amazonaws.com/779970/d173f806a4cf0c0dc94f13771f00bb7980a6b861';

    this.communicatorService.eventCall({
      event: Communicator.SPLASH,
      data: { splash },
    });
  }

  openSignUpPageTest(): void {
    this.communicatorService.eventCall({
      event: Communicator.OPEN_SIGNUP_PAGE,
      data: {},
    });
  }

  openLoginPageTest(): void {
    this.communicatorService.eventCall({
      event: Communicator.OPEN_LOGIN_PAGE,
      data: {},
    });
  }

  internalNavigationTest(): void {
    this.communicatorService.eventCall({
      event: Communicator.INTERNAL_NAVEGATION,
      data: { internalNavigation: '2872396' },
    });
  }

  mobletGroupTest(): void {
    // navega dentro de um grupo de abas
    this.communicatorService.eventCall({
      event: Communicator.MOBLET_GROUP,
      data: { mobletId: 2873327 },
    });
  }

  openMobletTest(): void {
    this.communicatorService.eventCall({
      event: Communicator.OPEN_MOBLET,
      data: { mobletId: 2872483 },
    });
  }

  openMobletItemTest(): void {
    this.communicatorService.eventCall({
      event: Communicator.OPEN_MOBLET_ITEM,
      data: {
        mobletId: 2872396,
        itemId: '9dc14cdbad20bb917273e6035f6fda36',
      },
    });
  }

  formTest(): void {
    const form: DynamicFormField[] = [
      {
        default: true,
        editable: false,
        id: 587,
        label: 'E-mail',
        lang: 'pt-BR',
        mask: null,
        name: 'email',
        order: 0,
        primary: true,
        readonly: false,
        rules: { required: true, email: true },
        type: 'email',
      },
    ];

    this.communicatorService.eventCall({
      event: Communicator.FORM,
      data: { form },
    });
  }

  formMobletTest(): void {
    const formMoblet: Form = {
      answers_count: 25,
      app_id: 779970,
      created_at: '2020-06-30T19:49:50.000000Z',
      deleted_at: null,
      description: 'descrição',
      cover_url:
        'https://s3.amazonaws.com/fabapp-forms/forms/779970/2874257/cover.png',
      gapi_account_id: null,
      gapi_sheet_id: null,
      gapi_sheet_page: null,
      id: 2873238,
      login_required: false,
      report_email: null,
      report_name: null,
      send_report: 0,
      status: 1,
      title: 'titulo',
      type_id: 1,
      updated_at: '2020-07-14T19:30:23.000000Z',
      fields: [],
    };

    this.communicatorService.eventCall({
      event: Communicator.FORM_MOBLET,
      data: { formMoblet },
    });
  }

  /**
   * @description
   * testa a navegação de grupo de abas dentro de grupo de abas
   */
  groupTest(): void {
    this.communicatorService.eventCall({
      event: Communicator.GROUP,
      data: { group: { instance: { id: '2873020' } } },
    });
  }

  reloadMobletTest(): void {
    this.communicatorService.eventCall({
      event: Communicator.RELOAD_MOBLET,
      data: { mobletId: 2873223 },
    });
  }

  accessOptionsTest(): void {
    const accessOptions: Partial<AppInfo> = {
      login_image: 'https://picsum.photos/800',
      google_client_id: null,
      login_google_active: false,
      login_apple_active: false,
      login_facebook_active: false,
    };

    this.communicatorService.eventCall({
      event: Communicator.ACCESS_OPTIONS,
      data: { accessOptions },
    });
  }

  pageGroupLayoutTest(): void {
    const pageLayout: GroupLayoutDef = {
      horizontalPadding: 0,
      itemBorderRadius: 0,
      itemPadding: 0,
      itemsBackgroundColor: '#000000',
      itemsFontColor: '#ffffff',
      layoutType: 'list',
      listLayoutType: 'normal',
      paddingTop: 0,
      pageBackgroundColor: '#ffffff',
      pageBackgroundImage:
        'https://universo-dev-a.s3.amazonaws.com/779970/47e771a253f0f7fcb46eac42eb713f76e119e928',
      showCarousel: false,
      showPageGridIcon: true,
      showPageGridName: true,
      showPageListIcon: false,
      showPageListName: true,
      showpageBackgroundImage: true,
    };

    this.communicatorService.eventCall({
      event: Communicator.PAGE_GROUP_LAYOUT,
      data: { id: 2872941, pageLayout },
    });
  }

  advertisingTest(): void {
    const advertising: Partial<AppInfo> = {
      custom_banner_image: 'https://picsum.photos/800',
      custom_banner_url: '0',
      enable_custom_banner: false,
    };

    this.communicatorService.eventCall({
      event: Communicator.ADVERTISING,
      data: { advertising },
    });
  }

  headerLogoTest(): void {
    this.communicatorService.eventCall({
      event: Communicator.HEADER_LOGO,
      data: {
        logo:
          'https://www.instagram.com/static/images/web/mobile_nav_type_logo.png/735145cfe0a4.png',
      },
    });
  }
}
