<ion-header class="ion-no-border"> </ion-header>

<ion-content class="ion-padding-horizontal" *transloco="let t">
  <div class="vertical-center">
    <div class="content">
      <div class="ion-text-center">
        <fabapp-phone-secure></fabapp-phone-secure>
        <h1 class="ion-padding-top featured">
          {{t('resetPasswordSuccess.title')}}
        </h1>
        <p>
          {{isModal ? t('resetPasswordSuccess.messageForModal') :
          t('resetPasswordSuccess.message') }}
        </p>
      </div>
    </div>
  </div>
</ion-content>
