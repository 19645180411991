import { DeliveryMethod } from '@core/enums';
import {
  FDI_CartItem,
  FDI_OrderObject,
} from '@fabapp-delivery/models/cart/cart.model';
import { PaymentMethod } from '@fabapp-delivery/models/store';
import { FDPaymentMethod } from 'src/app/pages/settings/payment-methods/models/payment-method.model';

export namespace DeliveryCartActions {
  export class Load {
    static readonly type = '[delivery cart] Load Cart';
    constructor() {}
  }

  export class GetFees {
    static readonly type = '[delivery cart] Get Fees';
    constructor() {}
  }

  export class ClearFees {
    static readonly type = '[delivery cart] Clear Fees';
    constructor() {}
  }

  export class Clear {
    static readonly type = '[delivery cart] Clear Cart';
    constructor() {}
  }

  export class Reset {
    static readonly type = '[delivery cart] Reset Cart';
    constructor() {}
  }

  export class SetDeliveryMethod {
    static readonly type = '[delivery cart] Set Delivery Method';
    constructor(public deliveryMethod?: DeliveryMethod) {}
  }

  export class SetDefaultDeliveryMethod {
    static readonly type = '[delivery cart] Set Default Delivery Method';
    constructor(public deliveryMethod: DeliveryMethod) {}
  }

  export namespace Item {
    export class Add {
      static readonly type = '[delivery cart - item] Add Item';
      constructor(public item: FDI_CartItem) {}
    }

    export class Increment {
      static readonly type = '[delivery cart - item] Increment';
      constructor(public item: FDI_CartItem) {}
    }

    export class Decrement {
      static readonly type = '[delivery cart - item] Decrement';
      constructor(public item: FDI_CartItem) {}
    }

    export class Remove {
      static readonly type = '[delivery cart - item] Remove';
      constructor(public item: FDI_CartItem) {}
    }
  }

  export class SetPaymentMethod {
    static readonly type = '[delivery cart - payment method] Set method';
    constructor(
      public method: PaymentMethod,
      public card?: FDPaymentMethod,
      public cashback?: number,
      public noCashBack?: boolean,
    ) {}
  }

  export class ClearPaymentMethod {
    static readonly type = '[delivery cart - payment method] Clear method';
    constructor() {}
  }
}
