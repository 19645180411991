import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ScriptLoaderService } from './script-loader.service';
import { LibsLoaderEnum } from '../enums';

@Injectable({ providedIn: 'root' })
export class GoogleMapsAutocompleteService {
  mapsUrl: string = environment.maps.url;
  public autocomplete: any;
  public addresses$: BehaviorSubject<any> = new BehaviorSubject(null);
  googlePlaces: any;

  constructor(private scriptLoader: ScriptLoaderService) {}

  private async initMap(gMapsKey: string = null): Promise<any> {
    if (!navigator.onLine) {
      return;
    }

    const mapsInstanceNotExist: boolean =
      typeof google === 'undefined' || typeof google.maps === 'undefined';
    if (mapsInstanceNotExist) {
      window['initMap'] = () => {
        return;
      };

      if (!document.body.children['googleMaps']) {
        await this.scriptLoader.loadLib(LibsLoaderEnum.GMAPS, {
          key: gMapsKey,
          libraries: 'places',
          callback: 'initMap',
        });
      }
      return;
    }
  }

  async init(ionInputElement: HTMLInputElement, gMapsKey?: string) {
    await this.initMap(gMapsKey);

    this.autocomplete = new google.maps.places.AutocompleteService();
    let map = new google.maps.Map(document.createElement('div'));

    this.googlePlaces = new google.maps.places.PlacesService(map);

    if (!this.autocomplete) throw new Error('Autocomplete is not initialized');

    if (!this.autocomplete.addListener != null) {
      let timer;
      ionInputElement.addEventListener('input', () => {
        if (!ionInputElement.value) {
          this.clearAddressList();
          return;
        }

        if (timer) {
          clearTimeout(timer);
        }

        timer = setTimeout(() => {
          this.getPlacePredictions(ionInputElement.value);
        }, 800);
      });
    }
  }

  getPlacePredictions(search) {
    this.autocomplete.getPlacePredictions(
      {
        input: search,
        componentRestrictions: { country: 'br' },
        types: ['geocode'],
      },
      (addresses) => {
        this.addresses$.next(addresses);
      },
    );
  }

  clearAddressList() {
    this.addresses$.next([]);
  }

  async getPlaceDetails(placeId) {
    const placeResult = await this._getDetails(placeId);

    return { ...placeResult, addressForm: this.getAddressObject(placeResult) };
  }

  private _getDetails(placeId) {
    const request = {
      placeId: placeId,
      fields: ['address_components', 'formatted_address', 'geometry', 'name'],
    };

    return new Promise<any>((resolve, reject) => {
      this.googlePlaces.getDetails(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(place);
          return;
        }

        reject(new Error(`Status ${status}: Endereço invalido`));
      });
    });
  }

  getAddressObject(address): any {
    const component = address.address_components;
    const data = {
      address: this._findValue(component, 'route'),
      number: this._findValue(component, 'street_number'),
      neighborhood: this._findValue(component, 'sublocality_level_1'),
      city: this._findValue(component, 'administrative_area_level_2'),
      state: this._findValue(component, 'administrative_area_level_1'),
      country: this._findValue(component, 'country'),
      zip_code: this._findValue(component, 'postal_code'),
      lat: `${address.geometry.location.lat()}`,
      lng: `${address.geometry.location.lng()}`,
      full_address: address.formatted_address,
    };

    if ((data.city as string).toLowerCase() === 'brasília') {
      return this.parseBrasiliaObject(data);
    }

    return data;
  }

  private parseBrasiliaObject(address: any) {
    return {
      ...address,
      number: 'S/N',
      address: 'NI',
      neighborhood: '',
    };
  }

  private _findValue(addressComponents: Array<any>, key: string) {
    if (!addressComponents) {
      return null;
    }

    return addressComponents.find((address) => address.types[0] === key)
      ?.long_name;
  }
}
