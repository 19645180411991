import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { FDI_Category } from '@fabapp-delivery/models/product/product.model';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/http/http.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends HttpService<any> {
  protected url = `${environment.shop.url}/app/products`;

  public getCategorizedProducts(storeId?: number): Observable<FDI_Category[]> {
    return this.$http.get<FDI_Category[]>(this.url, {
      headers: {
        'store-id': storeId.toString(),
      },
    });
  }

  public getOneProduct(id: string) {
    return this.request('GET', `/${id}`);
  }
}
