export function getRouteNameBySuperClass(superClass: string): string {
  switch (superClass) {
    case 'm-blank-page':
      return '/blank/';
    case 'm-album':
      return '/album/';
    case 'folder':
      return '/group/';
    case 'lists':
      return '/list/';
    case 'pricelist':
      return '/catalog/';
    case 'm-catalog':
    case 'fabapp-commerce':
      return '/fabapp-commerce/';
    case 'twitter_hashtag':
    case 'twitter':
      return '/twitter/';
    case 'uframe':
    case 'm-tawkto':
    case 'iframe':
    case 'gloria-food':
      return '/web/';
    case 'videoupload':
      return '/videos/';
    case 'rss':
      return '/rss/';
    case 'podcast':
      return '/podcast/';
    case 'mercado_livre':
      return '/mercado-livre/';
    case 'wall':
      return 'mural';
    case 'youtube':
      return '/youtube/';
    case 'contact':
    case 'singleitem':
      return '/contact/';
    case 'flickr':
      return '/flickr/';
    case 'mrss':
      return '/mrss/';
    case 'audioupload':
      return '/audio-gallery/';
    case 'google calendar':
      return '/google-calendar/';
    case 'm-forms':
      return '/forms/';
    case 'm-rewards':
      return '/rewards/';
    case 'fabapp-delivery':
      return '/fabapp-delivery/';
    case 'fabapp-scheduling':
      return '/scheduling/';
    default:
      // TODO: pagina de atualizacao do app
      return null;
  }
}
