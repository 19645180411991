import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { GroupLayoutDef, Page } from '@core/models';

@Component({
  selector: 'fabapp-group-grid-layout',
  templateUrl: './group-grid-layout.component.html',
  styleUrls: ['./group-grid-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupGridLayoutComponent implements OnInit, OnChanges {
  @Input() group: Page[];
  @Input() layout: GroupLayoutDef;

  @Output() navigate: EventEmitter<any> = new EventEmitter();
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.layout?.currentValue) {
      this.layout = changes.layout.currentValue;
    }

    if (changes?.group?.currentValue) {
      this.group = changes.group.currentValue;
    }

    this.cdr.detectChanges();
  }

  trackByGroupIndex(index: number, item: Page): number {
    return item?.id ? item.id : index;
  }

  navigateToMoblet(moblet: Page): void {
    this.navigate.emit(moblet);
  }
}
