import { Injectable } from '@angular/core';
import { Tax } from '@fabapp-delivery/models/store/tax.model';
import { StoreService } from '@fabapp-delivery/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LoadTaxes } from './tax.action';

export interface TaxModelState {
  tax?: Tax;
  deliveryEnabled: boolean;
}

@State<TaxModelState>({
  name: 'tax',
  defaults: {
    deliveryEnabled: true,
  },
})
@Injectable()
export class TaxState {
  constructor(private storeService: StoreService) {}

  @Selector()
  static getTax(state: TaxModelState): Tax {
    return state.tax;
  }

  @Selector()
  static getDeliveryEnabled(state: TaxModelState) {
    return state.deliveryEnabled;
  }

  @Action(LoadTaxes)
  async LoadCategorizedProducts(
    ctx: StateContext<TaxModelState>,
    { addressId }: LoadTaxes,
  ) {
    if (!addressId) {
      ctx.patchState({ deliveryEnabled: true, tax: null });
      return;
    }

    try {
      const tax = await this.storeService.taxes(addressId).toPromise();
      ctx.patchState({ deliveryEnabled: true, tax });
    } catch (error) {
      ctx.patchState({ deliveryEnabled: false, tax: { error: true } });
    }
  }
}
