<ion-item-divider
  *transloco="let t; read: 'fabappDelivery.complementGroup'"
  [sticky]="sticky"
  class="additionalitem-container"
>
  <ion-label class="additionalitem-header">
    <h2 class="-title" *ngIf="title">{{ title }}</h2>
    <h2 class="-subtitle" *ngIf="subtitle">{{ subtitle }}</h2>
    <span class="-description" *ngIf="maxValue">
      <ng-container *ngIf="maxValue === 1">
        {{ t('chooseOne') }}
      </ng-container>
      <ng-container *ngIf="maxValue > 1 && !required">
        {{ t('chooseUp', { value: maxValue }) }}
      </ng-container>
      <ng-container *ngIf="maxValue > 1 && required">
        {{ t('chooseFrom', { maxValue: maxValue, minValue: minValue }) }}
      </ng-container>
    </span>
  </ion-label>
  <ng-container *ngIf="showRequiredLabel">
    <ion-chip *transloco="let t" mode="md" slot="end" class="requiredlabel">
      {{ t('fabappDelivery.required') }}
    </ion-chip>
  </ng-container>
</ion-item-divider>
