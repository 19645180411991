import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DeliveryMethod } from '@core/enums';
import { Address } from '@core/models/address/address.model';
import { AddressState } from '@core/state/address/address.state';
import { CoreState } from '@core/state/core/core.state';
import { MobletsState } from '@core/state/moblets';
import { FDI_Store, FDI_StoreAddress } from '@fabapp-delivery/models/store';
import { DeliveryCartActions } from '@fabapp-delivery/state/cart/cart.actions';
import { TaxState } from '@fabapp-delivery/state/taxes/tax.state';
import { ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AddressComponent } from 'src/app/pages/settings/address/address.component';

@Component({
  selector: 'fabapp-cart-delivery-type',
  templateUrl: './cart-delivery-type.component.html',
  styleUrls: ['./cart-delivery-type.component.scss'],
})
export class CartDeliveryTypeComponent implements OnInit, OnDestroy {
  @Select(AddressState.getFavoriteAddress)
  favoriteAddress$: Observable<Address>;
  public storeAddress: FDI_StoreAddress;
  private _mobletSubscription: Subscription;

  @Input() deliveryMethodSelected: DeliveryMethod;
  @Output() deliveryMethodChange = new EventEmitter<DeliveryMethod>();
  @Output() openAddressEmitter = new EventEmitter<any>();
  public deliveryMethodEnum = DeliveryMethod;

  @Select(TaxState.getDeliveryEnabled) deliverable$: Observable<boolean>;
  public store: FDI_Store;
  constructor(
    private store$: Store,
    private modalController: ModalController,
  ) {}

  ngOnInit() {
    this.setMobletSubscription();
  }

  ngOnDestroy() {
    this._mobletSubscription.unsubscribe();
  }

  public setMobletSubscription(): void {
    const storeId = this.store$.selectSnapshot(CoreState.currentStoreId);

    this._mobletSubscription = this.store$
      .select(MobletsState.getMoblet(storeId))
      .subscribe((store: FDI_Store) => {
        this.storeAddress = store?.address;
        this.store = store;
      });
  }

  async openAddress() {
    this.openAddressEmitter.emit();
  }

  public changed(method: DeliveryMethod) {
    this.store$.dispatch([
      new DeliveryCartActions.SetDeliveryMethod(method),
      new DeliveryCartActions.GetFees(),
    ]);
  }
}
