<form [formGroup]="form">
  <ion-grid>
    <ion-row>
      <ion-col class="ion-padding-end" size="9">
        <ion-item>
          <ion-label position="floating"
            >Nome como aparece no cartão*</ion-label
          >
          <ion-input
            [maxlength]="fieldsMaxLength[FIELDS.CARD_HOLDER_NAME]"
            [formControlName]="FIELDS.CARD_HOLDER_NAME"
          >
          </ion-input>
        </ion-item>
      </ion-col>
      <ion-col class="" size="3">
        <ion-item>
          <ion-label position="floating">Válido até*</ion-label>
          <ion-input
            id="cc-exp-date"
            [formControlName]="FIELDS.CARD_EXPIRATION_DATE"
            type="tel"
            autocomplete="cc-exp"
            ccExp
            [maxlength]="fieldsMaxLength[FIELDS.CARD_EXPIRATION_DATE]"
          >
          </ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-padding-end" size="9">
        <ion-item>
          <ion-label position="floating">Número do cartão*</ion-label>
          <ion-input
            id="cc-number"
            [formControlName]="FIELDS.CARD_NUMBER"
            type="tel"
            autocomplete="cc-number"
            ccNumber
          >
          </ion-input>
          <ion-icon class="cardicon" slot="end" name="card-outline"></ion-icon>
        </ion-item>
      </ion-col>
      <ion-col class="" size="3">
        <ion-item>
          <ion-label position="floating">CVV*</ion-label>
          <ion-input
            [maxlength]="fieldsMaxLength[FIELDS.CARD_CVV]"
            id="cc-cvc"
            [formControlName]="FIELDS.CARD_CVV"
            type="tel"
            autocomplete="off"
            ccCVC
          >
          </ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-padding-end" size="7">
        <ion-item>
          <ion-label position="floating">CPF do titular do cartão*</ion-label>
          <ion-input
            [formControlName]="FIELDS.DOCUMENT_NUMBER"
            [brmasker]="{ person: true }"
            [maxlength]="fieldsMaxLength[FIELDS.DOCUMENT_NUMBER]"
          >
          </ion-input>
        </ion-item>
      </ion-col>
      <ion-col class="" size="5">
        <ion-item>
          <ion-label position="floating">Apelido cartão</ion-label>
          <ion-input
            [maxlength]="fieldsMaxLength[FIELDS.NICKNAME]"
            [formControlName]="FIELDS.NICKNAME"
          >
          </ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
