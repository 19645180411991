import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AppDef } from '@core/models';
import { PwaService } from '@core/services/pwa.service';
import { AppDefState } from '@core/state/appdef';
import { Platform } from '@ionic/angular';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

const TRANSLATION_SCOPE: string = 'pwa-install';

@Component({
  selector: 'fabapp-pwa-install-banner',
  templateUrl: './pwa-install-banner.component.html',
  styleUrls: ['./pwa-install-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: TRANSLATION_SCOPE, alias: 'pwaInstall' },
    },
  ],
})
export class PwaInstallBannerComponent {
  public hide = false;

  @Select(AppDefState.getFromAppDef(['info']))
  public partialAppDef$: Observable<Partial<AppDef>>;
  constructor(
    public readonly pwaService: PwaService,
    private readonly platform: Platform,
    private readonly cdr: ChangeDetectorRef,
  ) {
    if (this.platform.is('capacitor')) {
      this.hide = true;
      this.cdr.markForCheck();
    }
  }

  hideWrapper(): void {
    this.hide = true;
    this.cdr.markForCheck();
  }
}
