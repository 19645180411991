import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GroupLayout, Page } from '@core/models';
import { AppDefState } from '@core/state/appdef';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { AppDefService } from './app-def.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MobletService extends HttpService<any> {
  public mobletsStream: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(
    $http: HttpClient,
    public appDefService: AppDefService,
    public store: Store,
  ) {
    super($http);
  }

  /**
   * Faz o carregamento do conteudo do moblet atraves da api de moblets
   *
   * @param id - Instance ID
   * @param page: Page pagination
   * @param version: VersionAPI
   * @param extraParams - Extra data params
   */

  loadMoblet(
    id: number,
    page: number = 1,
    version: number = 3,
    extraParams?: object,
    fabAppVersion = 3,
  ): Observable<any> {
    let url: string = `${environment.mobletApi.url}/${id}.json?page=${page}&per_page=25&version=${version}&fabapp_version=${fabAppVersion}`;

    if (extraParams) {
      // tslint:disable-next-line: typedef
      Object.entries(extraParams).forEach(([key, value]) => {
        url += `&${key}=${value}`;
      });
    }
    return this.request('get', url).pipe(
      map((moblet: any) => {
        if (moblet && !moblet.items) {
          moblet['items'] = [];
        } else if (!moblet) {
          return { items: [] };
        }
        return moblet;
      }),
    );
  }

  /**
   * Faz o carregamento das paginas do grupo de abas e do layout
   *
   * @param id - Instance ID
   */

  async loadGroup(id: number): Promise<[Page, GroupLayout]> {
    try {
      const groupPage: Page = this.store.selectSnapshot(
        AppDefState.getPage(id),
      );
      const layoutInfo: GroupLayout = await this.getNewGroupLayout(
        groupPage.page_id,
      );
      return [groupPage, layoutInfo];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  getNewGroupLayout(pageId: number): Promise<GroupLayout> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.request(
      'GET',
      `${environment.aphroditeApi.url}/app/layouts/${pageId}`,
      { headers },
    ).toPromise() as Promise<GroupLayout>;
  }
}
