export interface ErrorApiReturns {
  invalid_credentials: string;
  user_suspended: string;
  user_closed: string;
  provider_not_configured: string;
}

export enum ErrorApiEnum {
  INVALID_CREDENTIALS = 'invalid_credentials',
  USER_SUSPENDED = 'user_suspended',
  USER_CLOSED = 'user_closed',
  PROVIDER_NOT_CONFIGURED = 'provider_not_configured',
}
