import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Address } from '@core/models/address/address.model';
import {
  ChangeFavoriteAddress,
  DeleteAddress,

  LoadAddresses,
  RegisterAddress,
  SetEditableAddress,
  UpdateAddress
} from '@core/state/address/address.action';
import { ThemingColor } from '@core/types';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslocoService, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { AddressCreateAndEditComponent } from './components/address-create-and-edit/address-create-and-edit.component';

const scope: string = 'settings';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: scope }],
})
export class AddressComponent implements OnInit {
  private scopeAndCurrentLang: string;
  modalAddressCreateAndEdit: HTMLIonModalElement;

  constructor(
    private readonly store: Store,
    private readonly translocoService: TranslocoService,
    private readonly toast: ToastController,
    public readonly modalController: ModalController,
    private actions$: Actions,
  ) {
    this.scopeAndCurrentLang = `${scope}/${this.translocoService.getActiveLang()}`;
  }

  async ngOnInit(): Promise<void> {
    await this.store.dispatch(new LoadAddresses()).toPromise();
  }

  goToAddress({ id }: Address): void {
    this.openCreateAndEdit(id, true);
  }

  async removeAddress(address: Address): Promise<any> {
    try {
      if (address.favorite) {
        return this.showMessageForCannotDeleteFavorite();
      }

      await this.store.dispatch(new DeleteAddress(address)).toPromise();

      const addressRemovedSuccessfully: string = this.translocoService.translate(
        'addressPage.addressRemovedSuccessfully',
        {},
        this.scopeAndCurrentLang,
      );

      this.feedbackToaster(addressRemovedSuccessfully, 'success');
    } catch (error) {
      const addressCannotBeRemoved: string = this.translocoService.translate(
        'addressPage.addressCannotBeRemoved.',
        {},
        this.scopeAndCurrentLang,
      );
      this.feedbackToaster(addressCannotBeRemoved, 'success');
    }
  }

  public setFavoriteAddress(address: Address) {
    this.store.dispatch(new ChangeFavoriteAddress(address));
    this.closeModal();
    this.feedbackToaster('Endereço alterado!', 'success');
  }

  private showMessageForCannotDeleteFavorite() {
    const cannotDeleteFavoriteAddress: string = this.translocoService.translate(
      'addressPage.cannotDeleteFavoriteAddress',
      {},
      this.scopeAndCurrentLang,
    );
    return this.feedbackToaster(cannotDeleteFavoriteAddress, 'medium');
  }

  private async feedbackToaster(
    message: string,
    theme: ThemingColor = 'danger',
  ): Promise<void> {
    const toast: HTMLIonToastElement = await this.toast.create({
      message,
      color: theme,
      position: 'top',
      duration: 1000,
    });
    toast.present();
  }

  async openCreateAndEdit(id: string = null, editable: boolean = false) {

    await this.setListenerAddress(editable, id);

    this.modalAddressCreateAndEdit = await this.modalController.create({
      component: AddressCreateAndEditComponent,
      componentProps: {
        itemId: id,
      },
    });

    await this.modalAddressCreateAndEdit.present();
  }

  private async setListenerAddress(editable: boolean, id: string) {
    if (editable) {
      await this.store.dispatch(new SetEditableAddress(true, id)).toPromise();

      this.actions$
        .pipe(ofActionDispatched(UpdateAddress))
        .subscribe(async () => {
          await this.modalAddressCreateAndEdit.dismiss();
        });
    } else {
      this.actions$
        .pipe(ofActionDispatched(RegisterAddress))
        .subscribe(async () => {
          await this.modalAddressCreateAndEdit.dismiss();
        });
    }
  }

  async closeModal() {
    await this.modalController.dismiss();
  }
}
