import { Component, Input, OnInit } from '@angular/core';
import { DeliveryMethod } from '@core/enums';
import { StatusDelivery } from '@core/enums/status-delivery.enum';
import { BaseMoblet } from '@core/models/moblets';
import { DeliveryInfoOrder } from '@core/models/moblets/fabapp-delivery/delivery-info-order.model';
import { environment } from '@environments/environment';
import { FabappDeliveryService } from '@fabapp-delivery/fabapp-delivery.service';
import { FDI_Store } from '@fabapp-delivery/models/store';
import { OrdersService } from '@fabapp-delivery/services';
import { SocketDeliveryService } from '@fabapp-delivery/services/socket-delivery.service';
import { isPlatform, ToastController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-order-status-progress',
  templateUrl: './order-status-progress.component.html',
  styleUrls: ['./order-status-progress.component.scss'],
  providers: [FabappDeliveryService],
})
export class OrderStatusProgressComponent implements OnInit {
  @Input() storeInfo: BaseMoblet<FDI_Store>;
  @Input() dataOrder: DeliveryInfoOrder;

  statusDeliveryEnum = StatusDelivery;
  currentStatus: number = 1;
  socketDelivery: any;
  appId: string;
  storeId: number;
  orderId: string;
  currentIconStage: string;
  labelCurrentStage: string;
  subscribeStoreInfo: Subscription;
  hourCurrentStage: string;

  public deliveryMethod = DeliveryMethod;
  constructor(
    private toast: ToastController,
    private ordersService: OrdersService,
    public fabappDeliveryService: FabappDeliveryService,
  ) {}

  ngOnInit() {
    this.initSocket();
  }

  private initSocket() {
    this.appId = this.dataOrder.appId;
    this.storeId = this.dataOrder.storeId;
    this.orderId = this.dataOrder.id;

    if (this.socketDelivery) {
      this.socketDelivery.disconnect();
      this.socketDelivery = null;
    }

    this.socketDelivery = new SocketDeliveryService(
      this.appId,
      this.storeId.toString(),
      this.orderId,
    );
    this.currentStatusEvent(this.dataOrder.status.id);
    this.socketDelivery.on(
      `${this.appId}:${this.storeId}:${this.orderId}:status`,
      (data) => {
        this.dataOrder.treadmill.push({
          date: data.date,
          orderId: this.orderId,
          statusId: data.statusId,
        });
        this.currentStatusEvent(data.statusId);
      },
    );
  }

  ngOnDestroy() {
    this.socketDelivery.disconnect();
    this.socketDelivery = null;
  }

  private async feedbackToaster(message): Promise<void> {
    const toast: HTMLIonToastElement = await this.toast.create({
      message: message,
      position: 'top',
      duration: 3000,
      buttons: [
        {
          text: 'Ok',
        },
      ],
    });
    toast.present();
  }

  getHour(enumHour) {
    return this.dataOrder.treadmill.filter(
      (stage) => stage.statusId == enumHour,
    )[0]?.date;
  }

  currentStatusEvent(status) {
    const hour1Step = this.getHour(this.statusDeliveryEnum.PENDING);
    const hour2Step = this.getHour(this.statusDeliveryEnum.PREPARING);
    const hour3Step = this.getHour(this.statusDeliveryEnum.DELIVERING);
    // quando retirada colocar Pronto para retirada

    const hour4Step = this.getHour(this.statusDeliveryEnum.DELIVERED);
    const hour5Step = this.getHour(this.statusDeliveryEnum.CANCELED);

    const hour6Step = this.getHour(this.statusDeliveryEnum.STAND_BY);

    switch (status) {
      case this.statusDeliveryEnum.PENDING:
        this.currentStatus = this.statusDeliveryEnum.PENDING;
        this.labelCurrentStage = 'Aguardando aprovação';
        this.currentIconStage = 'order';
        this.hourCurrentStage = hour1Step == undefined ? '' : hour1Step;

        break;
      case this.statusDeliveryEnum.PREPARING:
        this.currentStatus = this.statusDeliveryEnum.PREPARING;
        this.labelCurrentStage = 'Em preparo';
        this.currentIconStage = 'rotate_right_black';
        this.hourCurrentStage = hour2Step == undefined ? '' : hour2Step;

        break;
      case this.statusDeliveryEnum.DELIVERING:
        this.currentStatus = this.statusDeliveryEnum.DELIVERING;
        this.labelCurrentStage =
          this.dataOrder.deliveryMethodId == DeliveryMethod.DELIVERY
            ? 'Saiu para entrega'
            : 'Pronto para retirada';
        this.currentIconStage = 'delivery-motor';
        this.hourCurrentStage = hour3Step == undefined ? '' : hour3Step;
        break;

      case this.statusDeliveryEnum.DELIVERED:
        this.currentStatus = this.statusDeliveryEnum.DELIVERED;
        this.labelCurrentStage = 'Entregue';
        this.currentIconStage = 'check_circle-24px';
        this.hourCurrentStage = hour4Step == undefined ? '' : hour4Step;
        break;

      case this.statusDeliveryEnum.STAND_BY:
        this.currentStatus = this.statusDeliveryEnum.STAND_BY;
        this.labelCurrentStage = 'Aguardando retirada motoboy';
        this.currentIconStage = 'stand-by';
        this.hourCurrentStage = hour6Step == undefined ? '' : hour6Step;
        break;
      case this.statusDeliveryEnum.CANCELED:
        this.currentStatus = this.statusDeliveryEnum.CANCELED;
        this.labelCurrentStage = 'Pedido cancelado';
        this.currentIconStage = 'cancel-24px';
        this.hourCurrentStage = hour5Step == undefined ? '' : hour5Step;
        break;

      default:
        break;
    }
  }

  async confirmOrder() {
    try {
      await this.ordersService.receivedOrder(this.orderId).toPromise();
      this.feedbackToaster('Pedido confirmado');
      this.currentStatusEvent(this.statusDeliveryEnum.DELIVERED);
    } catch (error) {
      console.error(error);
    }
  }
}
