import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComplementItem } from '@fabapp-delivery/models/complement';

@Component({
  selector: 'fabapp-complement-selection-radio',
  templateUrl: './complement-selection-radio.component.html',
  styleUrls: ['./complement-selection-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComplementSelectionRadioComponent),
      multi: true,
    },
  ],
})
export class ComplementSelectionRadioComponent
  implements OnInit, ControlValueAccessor
{
  private _value: any;
  @Input()
  set value(item: any) {
    this._value = item;
  }

  get value() {
    return this._value;
  }

  public disabled: boolean = false;
  public _onChange: (_: any) => void = () => {};
  public _onTouched: any = () => {};

  @Input() public label: string;
  @Input() public description: string;
  @Input() public aggregateValue: number;
  @Input('currencyId')
  public set setCurrencyId(value: string) {
    this.currencyId = value;
  }
  currencyId: string;
  constructor() {}

  ngOnInit() {}

  writeValue(value: any): void {
    this._value = value;
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
