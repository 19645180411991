<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button class="button-back" (click)="back()">
        <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Promoções disponíveis</ion-title>
  </ion-toolbar>
</ion-header>

<ion-progress-bar type="indeterminate" *ngIf="isSubmitting"></ion-progress-bar>
<ion-content class="ion-padding">
  <fabapp-reward-card
    [large]="true"
    [progress]="progress$|async"
    [reward]="reward$|async"
    [punctuation]="punctuation$|async"
  ></fabapp-reward-card>
  <ng-container *ngIf="coupons$ | async as coupons; else loader">
    <fabapp-coupon-list
      *ngIf="coupons.length > 0; else emptyState"
      [cartTotal]="fees$ | async"
      [coupons]="coupons"
      [couponSelected]="couponSelected$|async"
      [enableButton]="inCart"
      (applyCoupon)="applyCoupon($event)"
      (removeCoupon)="removeCoupon()"
    >
    </fabapp-coupon-list>
  </ng-container>
</ion-content>

<ion-footer class="ion-padding ion-no-border">
  <ion-button
    (click)="insertCoupon()"
    fill="outline"
    [style.--color]="'var(--primarycolor-or-black)'"
    expand="block"
  >
    Inserir código do cupom
  </ion-button>
</ion-footer>

<!-- loader -->
<ng-template #loader>
  <div class="spinner-wrapper">
    <ion-spinner></ion-spinner>
  </div>
</ng-template>
<!-- /loader -->

<ng-template #emptyState>
  <div class="empty-state" *ngIf="!reward">
    <img src="assets/images/fabapp-delivery/empty-state-coupon.svg" />
    <p><strong>Você ainda não possui nenhuma promoção disponível</strong></p>
    <p>
      Se você possui um código promocional, clique em “inserir código
      promocional” para aplicar seu cupom.
    </p>
  </div>
</ng-template>
