<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/order-history"></ion-back-button>
    </ion-buttons>
    <ion-title>Pedido #{{ orderNumber}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="section" *ngIf="dataOrder && completeRequestOrder; else loader">
    <app-order-status-progress
      [storeInfo]="storeInfo"
      [dataOrder]="dataOrder"
    ></app-order-status-progress>
    <app-order-summary
      [storeInfo]="storeInfo"
      [dataOrder]="dataOrder"
    ></app-order-summary>

    <div class="rating" [formGroup]="form" *ngIf="hasClassification">
      <app-rating-feedback
        [initialRatingInStars]="dataOrder?.rating?.rating"
        [title]="dataOrder?.rating?.rating ? 'Pedido avaliado' : 'Avalie seu pedido'"
        (rate)="changeRating($event)"
        [disabled]="dataOrder?.rating?.rating"
        [haveFeedback]="true"
        (feedbackOrderOptionsUpdate)="feedbackOrderOptionsUpdate($event)"
        (updateComment)="updateCommentOrder($event)"
      ></app-rating-feedback>

      <div class="feedback-delivery" *ngIf="!dataOrder?.rating?.rating">
        <app-evaluate-delivery
          (updateComment)="updateCommentDelivery($event)"
          (feedbackDeliveryOptionsUpdate)="feedbackDeliveryOptionsUpdate($event)"
          (updateDeliverySatisfaction)="updateDeliverySatisfaction($event)"
        ></app-evaluate-delivery>
      </div>

      <div class="button-submit" *ngIf="!dataOrder?.rating?.rating">
        <ion-button class="button" (click)="submitRating()" expand="block"
          >Avaliar</ion-button
        >
      </div>
    </div>
  </div>
</ion-content>

<ng-template #loader>
  <div class="spinner-wrapper">
    <ion-spinner></ion-spinner>
  </div>
</ng-template>
