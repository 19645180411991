import { Injectable } from '@angular/core';
import { CartModal } from '@fabapp-delivery/components/cart-modal/cart.modal';
import { FabappDeliveryItemComponent } from '@fabapp-delivery/components/fabapp-delivery-item/fabapp-delivery-item.component';
import { ModalController } from '@ionic/angular';

@Injectable()
export class FabappDeliveryNavigateService {
  private _url: string;
  constructor(private modalCtrl: ModalController) {}

  async goToProductDetail(
    productId: string,
    itCameFromResearch = false,
  ): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: FabappDeliveryItemComponent,
      componentProps: {
        productIsInCart: false,
        productId,
      },
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();

    if (data?.openCart) {
      this.openCart(itCameFromResearch);
    }
  }

  private async openCart(itCameFromResearch = false) {
    const modal = await this.modalCtrl.create({
      component: CartModal,
      componentProps: {
        itCameFromResearch,
      },
    });

    await modal.present();
  }
}
