<ion-header class="ion-no-border">
  <fabapp-header [isModal]="isModal" color="transparent"> </fabapp-header>
</ion-header>

<ng-container *transloco="let t">
  <ion-content class="ion-padding-horizontal">
    <div class="vertical-center">
      <div class="content">
        <div class="head ion-text-center">
          <h1 class="ion-padding-top featured">{{t('codeRequest.title')}}</h1>
          <p>{{t('codeRequest.'+typeReset+'.message')}}</p>
        </div>
        <form [formGroup]="form">
          <ion-list class="ion-no-padding" lines="none">
            <ion-item [class.-stayfocused]="deliveryEnabled">
              <ion-label position="floating"
                >{{t('codeRequest.'+typeReset+'.label')}}</ion-label
              >
              <fabapp-phone-email-input
                type="email"
                formControlName="username"
                outline
                [label]="t('codeRequest.'+typeReset+'.label')"
                [isEmail]="isEmail"
                [brmasker]="mask"
                (input)="onInputUsername($event.target.value)"
                (setMask)="setMask($event)"
                (getCallingCode)="getCallingCode($event)"
              >
              </fabapp-phone-email-input>
            </ion-item>
            <control-error
              [label]="t('codeRequest.'+typeReset+'.label')"
              [control]="username"
            ></control-error>
          </ion-list>
        </form>
      </div>
    </div>
  </ion-content>

  <ion-footer class="ion-no-border">
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col col-12>
            <ion-button
              [disabled]="isSubmitting"
              expand="block"
              color="primary"
              [validate]="form"
              (click)="resetPassword()"
            >
              {{t('codeRequest.buttons.continue')}}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-footer>
</ng-container>
