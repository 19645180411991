<ion-header class="ion-no-border">
  <fabapp-header [isModal]="isModal" color="transparent"> </fabapp-header>
</ion-header>

<ion-content class="ion-padding-horizontal">
  <div class="vertical-center">
    <div class="content">
      <div class="head ion-text-center">
        <h1 class="ion-padding-top featured">
          {{'loginByToken.title' | transloco}}
        </h1>
        <p
          [innerHTML]="'loginByToken.'+resetType + '.message' | transloco: {value: phoneNumberToShow} "
        ></p>
      </div>

      <form [formGroup]="form">
        <ion-list class="ion-no-padding" lines="none">
          <ion-item>
            <ion-input
              outline
              class="ion-text-uppercase"
              formControlName="code"
              type="tel"
              [maxlength]="6"
              placeholder="••••••"
            ></ion-input>
          </ion-item>
        </ion-list>
      </form>
      <div class="ion-text-center resend-container">
        <a (click)="sendToken()">
          {{ ((timeToShow) ? 'loginByToken.buttons.resendTime' :
          'loginByToken.buttons.resend') | transloco: {time: timeToShow} }}
        </a>
      </div>
    </div>
    <ion-button
      [disabled]="isSubmitting"
      expand="block"
      color="primary"
      [validate]="form"
      (click)="confimCode()"
      class="confirm-btn"
    >
      {{'loginByToken.buttons.verify' | transloco}}
    </ion-button>
  </div>
</ion-content>
