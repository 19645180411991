import {
  ChangeDetectorRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { EmptyStateComponent } from 'src/app/components/empty-state/empty-state.component';
import { LoaderComponent } from 'src/app/components/empty-state/loader/loader.component';

// tslint:disable-next-line: directive-selector
@Directive({ selector: '[emptyState]' })
export class EmptyStateDirective implements OnInit, OnChanges {
  private timeout;
  private isFirstTime = true;
  @Input('emptyState') observable: any;

  loaderView: ComponentRef<any>;
  emptyView: ComponentRef<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private resolver: ComponentFactoryResolver,
  ) {}

  ngOnInit(): void {
    this.run();
  }

  ngOnChanges(): void {
    this.run();
  }

  private stopTimer(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  private runTimeout(): void {
    if (!this.timeout) {
      this.timeout = setTimeout(() => {
        this.createEmptyState();
      }, 10000);
    }
  }

  private run(): void {
    if (this.observable?.loading) {
      return this.createLoadState();
    }

    if (
      !this.observable ||
      ('items' in this.observable && this.observable?.items?.length < 1) ||
      ('data' in this.observable && this.observable?.data?.length < 1) ||
      'error' in this.observable
    ) {
      this.createEmptyState();
      return this.stopTimer();
    }

    return this.createDataState();
  }

  private createLoadState(): void {
    this.viewContainer.clear();
    const factory: ComponentFactory<LoaderComponent> = this.resolver.resolveComponentFactory(
      LoaderComponent,
    );
    this.loaderView = this.viewContainer.createComponent(factory);
    this.runTimeout();
  }

  private createDataState(): void {
    this.loaderView?.destroy();
    this.emptyView?.destroy();

    if (this.isFirstTime) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.isFirstTime = false;
    }

    this.stopTimer();
    this.cdr.detectChanges();
  }

  private createEmptyState(): void {
    this.viewContainer.clear();
    const emptyStateComponent: ComponentFactory<EmptyStateComponent> = this.resolver.resolveComponentFactory(
      EmptyStateComponent,
    );
    this.emptyView = this.viewContainer.createComponent(emptyStateComponent);
    this.cdr.detectChanges();
  }
}
