import { GoogleLoginAuth, User } from '@core/models';

export class RegularSignUp {
  static readonly type = '[auth] Sign up with e-mail or phone';

  constructor(public user: any) {}
}

export class UpdateUser {
  static readonly type = '[auth] update user';

  constructor(public user: Partial<User>) {}
}

export class UploadAvatar {
  static readonly type = '[auth] upload avatar';

  constructor(public avatar: any) {}
}

export class DeleteAvatar {
  static readonly type = '[auth] delete avatar';

  constructor() {}
}

export class DeleteUser {
  static readonly type = '[auth] delete user';
}

export class Login {
  static readonly type = '[Auth] Login';
  constructor(
    public payload: { email: string; password?: string; code?: string },
  ) {}
}

export class AppleLogin {
  static readonly type = '[Auth] Login with Apple';
  constructor(public accessToken: string) {}
}

export class AppleWebLogin {
  static readonly type = '[Auth] Login with Apple JS';
  constructor(public refreshToken: string) {}
}

export class FacebookLogin {
  static readonly type = '[Auth] Facebook Login';
  constructor(public payload: { token: string }) {}
}

export class RefreshToken {
  static readonly type = '[Auth] Refresh Token';
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class GoogleLogin {
  static readonly type = '[Auth] Google Login';
  /**
   * @description Dispatch Google Login, for create account in Current App or Login
   * @param  {string} token
   */
  constructor(public payload: GoogleLoginAuth) {}
}

export class LoadRestrictedPages {
  static readonly type = '[app] Load Restricted Pages';
  constructor(public pages?: number[]) {}
}

export class UpdateRestrictedPagesWhenLogged {
  static readonly type = '[app] Update Restricted Pages When Logged';
}

export class RemoveRestrictedPages {
  // tem o type igual ao do logout para retirar as páginas salvar quando deslogar
  static readonly type = '[Auth] Logout';
}

export class ReloadUser {
  static readonly type = '[Auth] Request and reload current user';
}

export class SetAuthToken {
  static readonly type = '[Auth] Set is Refreshing token';
  constructor(public payload: any) {}
}

export class SetPhoneToVerifyFirstAccess {
  static readonly type = '[Auth] Set Phone to verify First Access';
  constructor(public phoneNumber: string) {}
}