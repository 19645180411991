import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IonTextarea, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-note-modal',
  templateUrl: './note-modal.component.html',
  styleUrls: ['./note-modal.component.scss'],
})
export class NoteModalComponent implements OnInit, AfterViewInit {
  @ViewChild('noteArea') noteArea: IonTextarea;
  @Input() noteValue: string;

  constructor(public modalCtrl: ModalController) {}

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.noteArea.setFocus();
    }, 1000);
  }

  save(): void {
    this.modalCtrl.dismiss(this.noteValue);
  }
}
