import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CountryDDI } from '@core/models';
import { Address } from '@core/models/address/address.model';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HeraService {
  constructor(private httpClient: HttpClient, private store: Store) {}

  private headers(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  getCountriesInfo(): Observable<CountryDDI[]> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/settings/countries?fields[]=name&fields[]=flag&fields[]=callingCodes&fields[]=alpha2Code`;

    return this.httpClient.get<CountryDDI[]>(url, { headers: this.headers() });
  }

  regularSignUp(body: any): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/users`;

    return this.httpClient.post<any>(url, body, { headers: this.headers() });
  }

  updateUser(body: any): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/users/me`;

    return this.httpClient.put<any>(url, body, { headers: this.headers() });
  }

  uploadAvatar(avatar: any): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/users/me/avatar`;

    const body: FormData = new FormData();
    body.append('avatar', avatar);

    return this.httpClient.post<any>(url, body, {
      withCredentials: true,
    });
  }

  deleteAvatar(): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/users/me/avatar`;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.httpClient.delete<any>(url, { headers });
  }

  deleteUser(): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/users/me`;

    return this.httpClient.delete<any>(url, { headers: this.headers() });
  }

  resetPasswordCodeRequest(username: string): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/auth/forgot`;
    return this.httpClient.post<any>(
      url,
      { email: username },
      { headers: this.headers() },
    );
  }

  resetPasswordConfirmationCode(email: string, token: string): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/auth/forgot/validate`;
    const body = {
      email,
      token,
    };

    return this.httpClient.post<any>(url, body, { headers: this.headers() });
  }

  resetPassword(
    email: string,
    token: string,
    password: string,
    passwordConfirmation: string,
  ): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/v3/auth/reset`;
    const body = {
      email,
      token,
      password,
      password_confirmation: passwordConfirmation,
    };

    return this.httpClient.post<any>(url, body, { headers: this.headers() });
  }

  deviceRegister(
    token: string,
    platform: string,
    userId: string,
  ): Observable<any> {
    const url: string = userId
      ? `${environment.hera.url}/app/users/me/devices`
      : `${environment.hera.url}/app/devices`;

    const body: any = {
      token,
      platform,
      user_agent: navigator.userAgent,
      version: 3,
    };

    return this.httpClient.post<any>(url, body, { headers: this.headers() });
  }

  /**
   * @description
   * carrega um array de page_id's das paginas que o usuário tem acesso
   */
  loadRestrictedPages(): Promise<number[]> {
    const url: string = `${environment.hera.url}/app/users/me/pages`;
    return this.httpClient
      .get<number[]>(url, { headers: this.headers() })
      .toPromise();
  }

  public registerAddress(
    address: Address,
    userId: string,
  ): Observable<Address> {
    const url: string = `${environment.hera.url}/app/users/${userId}/addresses`;
    return this.httpClient.post(url, address) as Observable<Address>;
  }

  listAddresses(userId: string): Observable<Address[]> {
    const url: string = `${environment.hera.url}/app/users/${userId}/addresses`;
    return this.httpClient.get(url) as Observable<Address[]>;
  }

  updateAddress(userId: string, address: Address) {
    const url: string = `${environment.hera.url}/app/users/${userId}/addresses/${address.id}`;
    return this.httpClient.put(url, address) as Observable<Address>;
  }

  public deleteAddress(userId: string, addressId: string): Observable<any> {
    const url: string = `${environment.hera.url}/app/users/${userId}/addresses/${addressId}`;
    return this.httpClient.delete(url) as Observable<any>;
  }

  getUserForm(lang: string = 'pt-BR'): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/users/field-settings?lang=${lang}&mode=visualization&v=3`;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'app-id': `${window['appId']}`,
    });
    return this.httpClient.get(url, { headers });
  }

  async queryCEP(cep: string): Promise<any> {
    const headers = new Headers();
    headers.append('Accept', 'application/json');

    const url: string = `${environment.viacep.url}/${cep}/json/`;

    try {
      const res: Response = await fetch(url, {
        method: 'GET',
        headers,
      });

      if (res.ok) {
        const result = await res.json();
        return result;
      }
    } catch (error) {
      throw error;
    }
  }

  sendTokenToPhoneNumber(phoneNumber: string, ddi: string): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/auth/code`;
    return this.httpClient.post<any>(
      url,
      { phoneNumber, ddi },
      { headers: this.headers() },
    );
  }

  getNotifications(page = 1): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/users/me/notifications?page=${page}`;
    return this.httpClient.get<any>(url, { headers: this.headers() });
  }
}
