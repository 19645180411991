<ion-header class="ion-no-border" mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="close()">
        <ion-icon class="close-icon" slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title
      >{{ currentTime | date:'dd LLL yyyy - HH:mm' | uppercase }}</ion-title
    >
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="pix-ready" *ngIf="countdownSeconds >= 0; else pixExpired">
    <img
      class="pix-ready-image"
      src="/assets/images/fabapp-delivery/pix-ready.svg"
      alt=""
    />
    <h1>Seu pedido está quase pronto!</h1>
    <h2>
      Leia o Qr Code abaixo ou copie o PIX Copia e Cola, para pagar em qualquer
      aplicativo habilitado:
    </h2>
    <div class="qrcode">
      <img
        [src]="'https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=' + order.lastTransaction.pixQrCode +  '&choe=UTF-8'"
        alt=""
      />
    </div>
    <span class="qr-tooltip"> PIX copia e cola: </span>
    <div
      class="pix-code-box"
      (click)="copyToClipboard(order.lastTransaction.pixQrCode)"
    >
      <div class="pix-code">
        <span>{{ order.lastTransaction.pixQrCode }}</span>
      </div>
      <div class="copy-button">
        <ion-icon name="link-outline"></ion-icon>
      </div>
    </div>
    <div class="expiring-counter" (click)="expired = true">
      <span>O código irá expirar em:</span>
      <span>{{ countdownSeconds * 1000 | date:'mm:ss':'UTC'}}</span>
    </div>
  </div>
  <ng-template #pixExpired>
    <div class="pix-expired">
      <div class="pix-expired-help">
        <img src="/assets/images/fabapp-delivery/pix-expired.svg" alt="" />
        <span>Seu código expirou</span>
        <p>
          Mas não se preocupe! Você pode gerar um novo pedido clicando no botão
          abaixo
        </p>
      </div>
      <ion-button
        class="duplicate-order-button"
        color="primary"
        (click)="replicateOrder()"
      >
        Gerar novo pedido
      </ion-button>
    </div>
  </ng-template>
</ion-content>
