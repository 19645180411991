<ion-header>
  <ion-toolbar>
    <ion-buttons class="button-close" (click)="closeModal()" slot="start">
      <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
    </ion-buttons>
    <ion-title>Inserir código promocional</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [scrollEvents]="true">
  <form [formGroup]="form">
    <div class="item-input">
      <ion-item class="item" lines="none">
        <ion-input
          outline
          placeholder="Inserir código de cupom"
          formControlName="code"
        >
        </ion-input>
      </ion-item>
      <p class="hint-message">Apenas letras e números</p>
      <control-error
        [label]="'Código do cupom'"
        [control]="form.get('code')"
      ></control-error>
    </div>
  </form>

  <div class="button-coupon">
    <ion-button
      (click)="applyCoupon()"
      fill="outline"
      [style.--color]="'var(--primarycolor-or-black)'"
      expand="block"
    >
      Aplicar cupom
    </ion-button>
  </div>
</ion-content>
