import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GoogleMapsAutocompleteService } from '@core/services/google-maps.service';
import { IonInput, TextValueAccessor } from '@ionic/angular';

declare let google: any;

@Component({
  selector: 'fabapp-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressAutocompleteGoogleComponent),
      multi: true,
    },
  ],
})
export class AddressAutocompleteGoogleComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('addressInput') addressInput: IonInput;
  @Input() placeholder: string;
  value: any;
  onChange: any = () => {};
  onTouched: any = () => {};
  disabled = false;
  address = new FormControl('');

  @Output() setError: EventEmitter<{
    [key: string]: boolean;
  }> = new EventEmitter();

  @Input() gMaps: string;
  constructor(
    public readonly googleMapsAutocompleteService: GoogleMapsAutocompleteService,
  ) {}

  ngOnInit(): void {}

  async ngAfterViewInit() {
    const input = await this.addressInput.getInputElement();
    this.googleMapsAutocompleteService.init(input, this.gMaps);
  }

  writeValue(value: any): void {
    this.value = value;
    this.address.setValue(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
