import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FDI_CartFees } from '@fabapp-delivery/models/cart/fees.model';
import { Coupon } from '@fabapp-delivery/models/promotions/coupon/coupon.model';

@Component({
  selector: 'fabapp-coupon-list',
  templateUrl: './coupon-list.component.html',
  styleUrls: ['./coupon-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouponListComponent implements OnInit {
  @Input('enableButton')
  set setEnableButton(value: boolean) {
    this.enableButton = value;
  }
  public enableButton = false;

  @Output() applyCoupon: EventEmitter<Coupon> = new EventEmitter();
  @Output() removeCoupon: EventEmitter<Coupon> = new EventEmitter();

  @Input('coupons')
  set setCoupons(value: Array<Coupon>) {
    this.coupons = value;
    this.cdr.markForCheck();
  }
  public coupons: Array<Coupon>;

  @Input('cartTotal')
  set setCartTotal(value: FDI_CartFees) {
    if (!value) {
      this.cartTotal = null;
      return;
    }

    this.cartTotal = value.subtotal;
    this.cdr.markForCheck();
  }
  public cartTotal: number;

  @Input('couponSelected')
  set setCouponSelected(value: Partial<Coupon>) {
    this.couponSelected = value;
    this.cdr.markForCheck();
  }
  public couponSelected: Partial<Coupon>;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  public apply(coupon: Coupon) {
    this.applyCoupon.emit(coupon);
  }

  public remove() {
    this.removeCoupon.emit();
  }

  trackByIndex(index: number, item: Coupon) {
    return item.id ? item.id : index;
  }
}
