import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ListExibitionType } from '../../enums';
import { List, ListDef } from '../../models';

@Component({
  selector: 'app-wrapper-lists',
  templateUrl: './wrapper-lists.component.html',
  styleUrls: ['./wrapper-lists.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WrapperListsComponent implements OnInit, OnChanges {
  /**
   * Lista de itens
   */
  @Input() items: List[];

  /**
   * Definição da lista
   */
  @Input() listDef: ListDef;

  @Output() navigate: EventEmitter<any> = new EventEmitter();

  exibitionType = ListExibitionType;

  /**
   * propriedade que valida se apresenta o empty state
   */
  @Input() empty: boolean = false;

  /**
   * propriedade mostra loader
   */
  @Input() loading: boolean;
  constructor(private cd: ChangeDetectorRef, private el: ElementRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.items?.currentValue) {
      this.items = changes.items.currentValue;
    }

    if (changes?.listDef?.currentValue) {
      this.listDef = changes.listDef.currentValue;
    }

    if (changes?.loading?.currentValue) {
      this.loading = changes.loading.currentValue;
    }

    if (changes?.empty?.currentValue) {
      this.empty = changes.empty.currentValue;
    }

    this.cd.markForCheck();
  }
}
