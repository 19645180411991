import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable()
export class AppIdInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const appId: string = window['appId'].toString();

    // Viacep não funciona com propriedades (que ele não usa) no header
    if (appId) {
      request = request.clone({
        setHeaders: {
          'app-id': appId,
        },
      });
    }

    return next.handle(request);
  }
}
