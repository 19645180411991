import { Injectable } from '@angular/core';
import { AppLayout, CountryDDI, DynamicFormField, Page } from '@core/models';
import { HeraService } from '@core/services/hera.service';
import { FDI_Store } from '@fabapp-delivery/models/store';
import { TranslocoService } from '@ngneat/transloco';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { getRouteNameBySuperClass } from '@utils';
import { Form } from 'src/app/moblets/forms/models';
import { AppDefState } from '../appdef';
import {
  LoadCountriesDDI,
  LoadSignupForm,
  SetFormMoblet,
  Menu,
  SetCurrentStore,
  SetIsFabappDeliveryEnabled,
  SetHomeRoute,
  SetSignupForm,
} from './core.actions';

export interface CoreStateModel {
  homeRoute: (string | number)[];
  form: DynamicFormField[];
  countries: CountryDDI[];
  formMoblet: Form;
  menuEnabled: boolean;
  currentStore: FDI_Store;
  currentStoreId: number;
  isFabappDeliveryEnabled: boolean;
}

@State<CoreStateModel>({
  name: 'core',
  defaults: {
    homeRoute: ['/'],
    form: null,
    countries: [],
    formMoblet: null,
    menuEnabled: null,
    currentStore: null,
    currentStoreId: null,
    isFabappDeliveryEnabled: false,
  },
})
@Injectable()
export class CoreState {
  constructor(
    private store: Store,
    private heraService: HeraService,
    private translocoService: TranslocoService,
  ) {}

  @Selector()
  static getHomeRoute(state: CoreStateModel): (string | number)[] {
    return state.homeRoute;
  }

  @Selector()
  static isFabappDeliveryEnabled(state: CoreStateModel): boolean {
    return state.isFabappDeliveryEnabled;
  }

  @Selector()
  static menuEnabled(state: CoreStateModel): boolean {
    return state.menuEnabled;
  }

  @Selector()
  static currentStoreId(state: CoreStateModel): number {
    return state.currentStoreId;
  }

  @Selector()
  static currentStore(state: CoreStateModel): FDI_Store {
    return state.currentStore;
  }

  @Selector()
  static getCountriesList(state: CoreStateModel): CountryDDI[] {
    return state.countries;
  }

  @Selector()
  static getDynamicForm(state: CoreStateModel): DynamicFormField[] {
    return state.form;
  }

  @Selector()
  /**
   * @description
   * retorna o form para o moblet form
   */
  static getFormMoblet(state: CoreStateModel): Form {
    return state.formMoblet;
  }

  @Action(SetSignupForm)
  updateSignupForm(
    ctx: StateContext<CoreStateModel>,
    { form }: SetSignupForm,
  ): void {
    ctx.patchState({
      form,
    });
  }

  @Action(Menu.Enable)
  enableMenu(ctx: StateContext<CoreStateModel>): void {
    ctx.patchState({
      menuEnabled: true,
    });
  }

  @Action(Menu.Disable)
  disableMenu(ctx: StateContext<CoreStateModel>): void {
    ctx.patchState({
      menuEnabled: false,
    });
  }

  @Action(SetIsFabappDeliveryEnabled)
  enableFabappDelivery(
    ctx: StateContext<CoreStateModel>,
    { isFabappDeliveryEnabled }: SetIsFabappDeliveryEnabled,
  ) {
    ctx.patchState({ isFabappDeliveryEnabled });
  }

  @Action(SetFormMoblet)
  updateFormMoblet(
    ctx: StateContext<CoreStateModel>,
    { formMoblet }: SetFormMoblet,
  ): void {
    ctx.patchState({
      formMoblet,
    });
  }

  @Action(SetCurrentStore)
  setCurrentStore(
    ctx: StateContext<CoreStateModel>,
    { store, storeId }: SetCurrentStore,
  ) {
    return ctx.patchState({
      currentStore: store,
      currentStoreId: storeId || store.id,
    });
  }

  @Action(SetHomeRoute)
  setHomeRoute(ctx: StateContext<CoreStateModel>): any {
    const state: CoreStateModel = ctx.getState();
    const layout: AppLayout = this.store.selectSnapshot(AppDefState.getLayout);
    const pages: Page[] = this.store.selectSnapshot(AppDefState.getPages);

    if (layout.layout_type !== 'page_home') {
      ctx.dispatch(new Menu.Enable());

      return ctx.patchState({
        homeRoute: ['/'],
      });
    }

    const instanceId: number = pages[0].moblets[0].instance.id;
    const superClass: string = this.store.selectSnapshot(
      AppDefState.getPage(instanceId),
    ).moblet_name;

    const isDelivery = superClass === 'fabapp-delivery';

    ctx.dispatch(isDelivery ? new Menu.Disable() : new Menu.Enable());
    ctx.dispatch(new SetIsFabappDeliveryEnabled(isDelivery));

    const routeName: string = getRouteNameBySuperClass(superClass);

    return ctx.patchState({
      homeRoute: [routeName, instanceId],
      currentStoreId: isDelivery ? instanceId : null,
    });
  }

  @Action(LoadSignupForm)
  async loadSignupForm(ctx: StateContext<CoreStateModel>): Promise<any> {
    const userForm: DynamicFormField[] = await this.heraService
      .getUserForm(this.translocoService.getActiveLang())
      .toPromise();

    ctx.patchState({
      form: userForm,
    });
  }

  @Action(LoadCountriesDDI)
  async loadCountriesDDI(ctx: StateContext<CoreStateModel>): Promise<any> {
    const state: CoreStateModel = ctx.getState();
    const countries: CountryDDI[] = await this.heraService
      .getCountriesInfo()
      .toPromise();

    ctx.patchState({
      countries,
    });
  }
}
