import { Injectable } from '@angular/core';
import { User } from '@core/models';
import {
  Action,
  Actions,
  createSelector,
  NgxsOnInit,
  ofActionDispatched,
  Selector,
  State,
  StateContext,
  Store
} from '@ngxs/store';
import { Address } from '../../models/address/address.model';
import { HeraService } from '../../services/hera.service';
import { AuthState, Logout } from '../auth';
import {
  ChangeFavoriteAddress,
  ClearAddress,
  DeleteAddress,




  DeleteCurrentAddress, LoadAddresses,
  RegisterAddress,

  SetCurrentAddress,

  SetEditableAddress, UpdateAddress
} from './address.action';

export interface AddressStateModel {
  addresses: Address[];
  favoriteAddress: Address;
  currentAddress: Address;
  editable: boolean;
  currentIdEditable: string;
}

@State<AddressStateModel>({
  name: 'address',
  defaults: {
    addresses: null,
    favoriteAddress: null,
    currentAddress: null,
    editable: false,
    currentIdEditable: null,
  },
})
@Injectable()
export class AddressState implements NgxsOnInit {
  constructor(
    private readonly heraService: HeraService,
    private readonly store: Store,
    private actions$: Actions,
  ) {}

  ngxsOnInit(ctx: StateContext<AddressStateModel>) {
    this.actions$
      .pipe(ofActionDispatched(Logout))
      .subscribe(() =>
        ctx.patchState({ addresses: null, favoriteAddress: null }),
      );
  }

  @Selector()
  static hasAddress(state: AddressStateModel): boolean {
    return state.addresses?.length > 0;
  }

  @Selector()
  static getAddresses(state: AddressStateModel): Address[] {
    return state.addresses;
  }

  @Selector()
  static getFavoriteAddress(state: AddressStateModel): Address {
    return state.favoriteAddress;
  }

  @Selector()
  static getCurrentAddress(state: AddressStateModel): Address {
    return state.currentAddress;
  }

  @Selector()
  static getEditableAddress(state: AddressStateModel): boolean {
    return state.editable;
  }

  @Selector()
  static getCurrentIdEditable(state: AddressStateModel): string {
    return state.currentIdEditable;
  }

  static getAddress(id: string) {
    return createSelector([AddressState], (state: AddressStateModel) =>
      state.addresses.find((address) => address.id === id),
    );
  }

  @Action(UpdateAddress)
  async update(
    ctx: StateContext<AddressStateModel>,
    { address }: UpdateAddress,
  ) {
    const user: User = this.store.selectSnapshot(AuthState.getUser);
    let addresses: Address[] = this.store.selectSnapshot(
      AddressState.getAddresses,
    );

    try {
      const updatedAddress: Address = await this.heraService
        .updateAddress(user.id, address)
        .toPromise();

      addresses = addresses.map((addressItem) =>
        addressItem.id === updatedAddress.id ? updatedAddress : addressItem,
      );

      ctx.patchState({ addresses });

      if (updatedAddress.favorite) {
        ctx.patchState({ favoriteAddress: updatedAddress });
      }
    } catch (error) {
      throw error;
    }
  }

  @Action(ClearAddress)
  clearAddress(ctx: StateContext<AddressStateModel>): void {
    ctx.patchState({
      addresses: null,
      favoriteAddress: null,
    });
  }

  @Action(DeleteAddress)
  async delete(
    ctx: StateContext<AddressStateModel>,
    { address }: DeleteAddress,
  ) {
    const user: User = this.store.selectSnapshot(AuthState.getUser);
    let addresses: Address[] = this.store.selectSnapshot(
      AddressState.getAddresses,
    );

    try {
      await this.heraService.deleteAddress(user.id, address.id).toPromise();

      addresses = addresses.filter(
        (addressItem) => addressItem.id !== address.id,
      );

      ctx.patchState({ addresses });

      if (address.favorite) {
        ctx.patchState({ favoriteAddress: address });
      }
    } catch (error) {
      throw error;
    }
  }

  @Action(RegisterAddress)
  async register(
    ctx: StateContext<AddressStateModel>,
    { address }: RegisterAddress,
  ): Promise<void> {
    const user: User = this.store.selectSnapshot(AuthState.getUser);
    try {
      let addresses: Address[] = ctx.getState().addresses || [];
      // TODO: MELHORAR IMPLEMENTAÇÃO
      // modifica a flag `favorite` para `false` para todos os endereços
      addresses = addresses.map((ad) => {
        return {
          ...ad,
          favorite: false,
        };
      });

      // cria um novo endereço como favorito
      address = { ...address, favorite: true };
      const createdAddress: Address = await this.heraService
        .registerAddress(address, user.id)
        .toPromise();

      // seta endereço criado como favorito
      ctx.patchState({ favoriteAddress: createdAddress });

      ctx.patchState({ addresses: [...addresses, createdAddress] });
    } catch (error) {
      throw error;
    }
  }

  @Action(ChangeFavoriteAddress)
  async changeFavoriteAddress(
    ctx: StateContext<AddressStateModel>,
    { newFavoriteAddress }: ChangeFavoriteAddress,
  ): Promise<void> {
    const user: User = this.store.selectSnapshot(AuthState.getUser);

    try {
      // TODO: MELHORAR IMPLEMENTAÇÃO

      // modifica a flag para `false` de endereços com id diferente do selecionado
      const addresses: Address[] = ctx
        .getState()
        .addresses.map((ad: Address) => {
          return {
            ...ad,
            favorite: ad.id === newFavoriteAddress.id,
          };
        });

      // endereço selecionado vira o endereço favorito
      newFavoriteAddress = {
        ...newFavoriteAddress,
        favorite: true,
      };

      ctx.patchState({ addresses });
      ctx.patchState({ favoriteAddress: newFavoriteAddress });

      // efetua a requisição que altera o endereço favorito
      await this.heraService
        .updateAddress(user.id, newFavoriteAddress)
        .toPromise();
    } catch (error) {
      throw error;
    }
  }

  @Action(LoadAddresses)
  async loadAddresses(ctx: StateContext<AddressStateModel>): Promise<void> {
    const user: User = this.store.selectSnapshot(AuthState.getUser);

    if (!user) {
      console.warn('Fabapp: User not logged');
      return;
    }

    const addresses: Address[] = await this.heraService
      .listAddresses(user.id)
      .toPromise();

    const favoriteAddressFound: Address = addresses.find(
      ({ favorite }: Address) => favorite,
    );

    this._updateFavoriteAddress(ctx, favoriteAddressFound);
    ctx.patchState({ addresses });
  }

  private _updateFavoriteAddress(
    ctx: StateContext<AddressStateModel>,
    favoriteAddressFound: Address,
  ) {
    const currentFavorite = ctx.getState()?.favoriteAddress?.id;
    const dontNeedUpdate = currentFavorite === favoriteAddressFound?.id;

    if (dontNeedUpdate) {
      return;
    }

    ctx.patchState({ favoriteAddress: favoriteAddressFound });
  }

  @Action(SetCurrentAddress)
  async setCurrentAddress(
    ctx: StateContext<AddressStateModel>,
    { address }: SetCurrentAddress,
  ) {
    ctx.patchState({ currentAddress: address });
  }

  @Action(DeleteCurrentAddress)
  async deleteCurrentAddress(ctx: StateContext<AddressStateModel>) {
    ctx.patchState({
      currentAddress: null,
      editable: false,
      currentIdEditable: null,
    });
  }

  @Action(SetEditableAddress)
  async setEditableAddress(
    ctx: StateContext<AddressStateModel>,
    { editable, id }: SetEditableAddress,
  ) {
    ctx.patchState({ editable, currentIdEditable: id });
  }
}
