<ion-header class="ion-no-border">
  <fabapp-header
    [isModal]="isModal"
    color="transparent"
    *ngIf="partialAppDef$ | async as partialAppDef"
    [class.-hide]="partialAppDef?.info?.login_required && !isPreview"
  >
  </fabapp-header>
</ion-header>

<ng-container *transloco="let t;">
  <ion-content class="ion-padding-horizontal">
    <div
      class="ion-text-center centered"
      *ngIf="partialAppDef$ | async as partialAppDef"
    >
      <div class="inner-wrapper" *ngIf="!deliveryEnabled; else deliveryHeading">
        <ion-thumbnail slot="center" *ngIf="partialAppDef.info?.login_image">
          <img-loader
            class="login_image"
            [src]="partialAppDef.info.login_image"
          >
          </img-loader>
        </ion-thumbnail>
        <h1 class="headline">
          {{ partialAppDef.info?.login_heading || t.title }}
        </h1>
        <p class="info ion-no-margin">
          {{ partialAppDef.info?.login_description || t.welcome }}
        </p>
      </div>

      <ng-template #deliveryHeading>
        <div class="inner-wrapper" *ngIf="currentStore">
          <ion-thumbnail slot="center" *ngIf="currentStore?.logo?.url">
            <img class="login_image" [src]="currentStore?.logo?.url" />
          </ion-thumbnail>
          <h1 class="headline" *ngIf="currentStore?.name">
            {{ currentStore.name }}
          </h1>
        </div>
      </ng-template>

      <div class="wrapper-email">
        <ng-container
          *ngIf="partialAppDef.info?.login_apple_active || partialAppDef.info?.login_facebook_active || partialAppDef.info?.login_google_active"
        >
          <ion-buttons mode="md" class="social-container">
            <ion-button
              class="social-buttons"
              *ngIf="partialAppDef.info?.login_apple_active && !isAndroid()"
              (click)="appleLogin()"
            >
              <ion-icon
                slot="icon-only"
                src="/assets/icons/logo-apple.svg"
                color="dark"
              ></ion-icon>
            </ion-button>
            <ion-button
              class="social-buttons"
              *ngIf="partialAppDef.info?.login_facebook_active"
              (click)="facebookLogin()"
              class="social-buttons"
            >
              <ion-icon
                slot="icon-only"
                src="/assets/icons/logo-facebook.svg"
              ></ion-icon>
            </ion-button>
            <ion-button
              class="social-buttons"
              *ngIf="partialAppDef.info?.login_google_active"
              (click)="googleLogin()"
              class="social-buttons"
            >
              <ion-icon
                slot="icon-only"
                src="/assets/icons/logo-google.svg"
              ></ion-icon>
            </ion-button>
          </ion-buttons>
          <div class="separator">{{t('login.orSocial')}}</div>
        </ng-container>

        <form [formGroup]="form">
          <ion-list class="ion-no-padding" lines="none">
            <ion-item [class.-stayfocused]="deliveryEnabled">
              <ion-label position="floating"
                >{{t(userNameTranslationKey)}}</ion-label
              >
              <fabapp-phone-email-input
                type="email"
                formControlName="email"
                outline
                [label]="t(userNameTranslationKey)"
                [isEmail]="isEmail"
                [brmasker]="mask"
                (input)="onInputUsername($event.target.value)"
                (setMask)="setMask($event)"
                (getCallingCode)="getCallingCode($event)"
              >
              </fabapp-phone-email-input>
            </ion-item>
            <control-error
              [label]="t(userNameTranslationKey)"
              [control]="email"
            ></control-error>

            <ion-item
              [@slideDown]="showPassword"
              [class.no-margin]="!showPassword"
            >
              <ion-label position="floating"
                >{{t('login.fields.password.label')}}</ion-label
              >
              <ion-input
                #passwordField
                outline
                formControlName="password"
                [type]="passwordType"
              ></ion-input>
              <ion-icon
                slot="end"
                class="ion-align-self-center"
                [name]="passwordType == 'password' ? 'eye' : 'eye-off'"
                (click)="togglePasswordMode()"
              >
              </ion-icon>
            </ion-item>
            <control-error
              [class.ion-hide]="email.invalid"
              [label]="t('login.fields.password.label')"
              [control]="password"
            >
            </control-error>
          </ion-list>
        </form>

        <div class="ion-text-start ion-padding-vertical">
          <a class="link" (click)="recoveryCode()">
            {{t('login.buttons.forgotPassword')}}
          </a>
        </div>
        <ion-button
          *ngIf="!checkEmailExist"
          [disabled]="isSubmitting"
          expand="block"
          class="standard-btn -login"
          color="primary"
          (click)="checkEmailOrPassword()"
        >
          Avançar
        </ion-button>
        <ion-button
          *ngIf="checkEmailExist"
          [disabled]="isSubmitting"
          expand="block"
          class="standard-btn -login"
          color="primary"
          [validate]="form"
          (click)="login()"
        >
          {{t('login.buttons.login')}}
        </ion-button>
        <ion-button
          (click)="loginByToken()"
          [disabled]="!showPassword || isEmail"
          fill="outline"
          expand="block"
          class="loginbytoken"
          size="small"
        >
          {{ t('login.buttons.loginByToken') }} SMS
        </ion-button>
        <a
          class="link -new-account"
          (click)="goToNewAccount()"
          [innerHTML]="t('login.buttons.newAccount') | safe: 'html'"
        >
        </a>
      </div>
    </div>
  </ion-content>
</ng-container>
