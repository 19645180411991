import { isPlatform } from '@ionic/angular';
import { getSupportedLang } from './get-supported-language';

const lang: string = getSupportedLang();

export const getTextOFBackButton: () => string = (): string => {
  if (!isPlatform('ios')) {
    return '';
  }

  const backButtonTextTranslations = {
    'pt-BR': 'Voltar',
    'en-US': 'Back',
    'es-ES': 'Volver',
  };

  return backButtonTextTranslations[lang || 'pt-BR'];
};

export const getLangUtils: () => string = (): string => {
  return lang || 'pt-BR';
};
