import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  selector: 'fabapp-card-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardItemComponent implements OnInit {
  private _item: any;

  @Input()
  public set item(value: any) {
    // TODO: falar com o Jean para enviar do backend a data em ISOString
    let [date, hour] = value.date.split('-');
    // date -> dd/MM/yyyy - hh:MM:ss
    // formatação para yyyy-MM-dd hh:MM:ss
    date = date
      .replace(/\//g, '-')
      .trim()
      .split('-')
      .reverse()
      .join('-');

    date = `${date}${hour}`;
    this._item = { ...value, date };
  }

  public get item(): any {
    return this._item;
  }

  @Output() clicked: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
}
