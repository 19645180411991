import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DeliveryMethod } from '@core/enums';
import { StatusDelivery } from '@core/enums/status-delivery.enum';
import { Page } from '@core/models';
import { BaseMoblet } from '@core/models/moblets';
import { Order } from '@core/models/moblets/fabapp-delivery/delivery-order.model';
import { PaginateOrder } from '@core/models/moblets/fabapp-delivery/delivery-paginate-order.model';
import { NavigationService } from '@core/services';
import { SetCurrentStore } from '@core/state/core/core.actions';
import { CoreState } from '@core/state/core/core.state';
import { FDI_Store } from '@fabapp-delivery/models/store';
import { OrdersService } from '@fabapp-delivery/services';
import { IonInfiniteScroll } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FabappDeliveryService } from '../../fabapp-delivery.service';
@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.page.html',
  styleUrls: ['./order-history.page.scss'],
  providers: [FabappDeliveryService],
})
export class OrderHistoryPage implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @Select(CoreState.isFabappDeliveryEnabled)
  isFabappDelivery$: Observable<boolean>;

  public page$: Observable<Page>;
  public store$: Observable<BaseMoblet<FDI_Store>>;

  storeId: number;
  StatusDelivery = StatusDelivery;
  paginateOrder: PaginateOrder;

  public deliveryMethodEnum = DeliveryMethod;
  constructor(
    public router: Router,
    public navigationService: NavigationService,
    public store: Store,
    private ordersService: OrdersService,
  ) {}

  async ngOnInit() {
    this._load();
  }

  private async _load(): Promise<void> {
    try {
      this.paginateOrder = await this.ordersService.getProducts().toPromise();
      this.formatAmountFinal();
    } catch (error) {
      throw error;
    }
  }

  public async reload(event: any): Promise<void> {
    try {
      await this._load();
    } finally {
      event.target.complete();
    }
  }

  private formatAmountFinal() {
    const data = this.paginateOrder.data.map((order) => {
      order.amountFinal = (order.amountFinal / 100).toString();
      return order;
    });

    this.paginateOrder.data = data;
  }

  async navigateScreen(route, item) {
    let typeQuery = {
      queryParams: { orderid: item.id, orderNumber: item.orderNumber },
    };

    await this.store
      .dispatch(new SetCurrentStore(null, item.storeId))
      .toPromise();

    await this.router.navigate(
      [`/order-history/${item.storeId}/${route}`],
      typeQuery,
    );
  }

  loadData(event) {
    setTimeout(async () => {
      this.paginateOrder.current_page = this.paginateOrder.current_page + 1;

      if (this.paginateOrder.data.length == this.paginateOrder.total) {
        event.target.disabled = true;
        event.target.complete();
        return;
      }

      const dataRequest = await this.ordersService
        .getProducts(this.paginateOrder.current_page)
        .toPromise();

      dataRequest.data.map((data) => {
        data.amountFinal = (data.amountFinal / 100).toString();
        this.paginateOrder.data.push(data);
      });
      event.target.complete();
    }, 500);
  }

  toggleInfiniteScroll() {
    this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
  }

  trackById(index: number, order: Order) {
    return order?.id ? order?.id : index;
  }
}
