<div class="document-form">
  <span class="title"
    >Para funcionamento do antifraude é necessário, o seu E-mail</span
  >

  <ion-input
    [formControl]="emailField"
    placeholder="Email"
    [style.fontSize.px]="emailField.value.length > 0 ? 14 : 30"
  ></ion-input>

  <div *ngIf="emailField.invalid && emailField.touched" class="control-error">
    <span>E-mail inválido</span>
  </div>
</div>

<ion-button
  class="btn-full"
  (click)="validateDocument()"
  [disabled]="emailField.invalid || isSubmitting"
  >Fazer pedido</ion-button
>
