<ng-container *transloco="let t">
  <ion-item
    class="pwa-install-banner"
    *ngIf="pwaService.showPopup() | async as canShow"
    [class.-show]="canShow && !hide"
  >
    <ion-buttons>
      <ion-button class="cancel" (click)="hideWrapper()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label *ngIf="partialAppDef$ | async as partialAppDef">
      <h3 class="title">{{ partialAppDef.info.name }}</h3>
      <span class="content">{{ t('pwaInstall.text') }}</span>
    </ion-label>
    <ion-button
      (click)="pwaService.addToHomeScreen()"
      class="install"
      color="light"
      shape="round"
      >{{ t('pwaInstall.install') }}</ion-button
    >
  </ion-item>
</ng-container>
