import { Injectable } from '@angular/core';
import {
  AppDef,
  AppInfo,
  DynamicFormField,
  GroupLayoutDef,
  Page,
} from '@core/models';
import { LoadAppDef } from '@core/state/appdef';
import {
  CommunicateAccessOptionsChange,
  CommunicateAppInfoChange,
  CommunicateColorChange,
  CommunicateHeaderLogoChange,
  CommunicateHeaderPatternChange,
  CommunicateHomePageChanged,
  CommunicateInternalNavigation,
  CommunicateLayoutChange,
  CommunicateNavigateBack,
  CommunicateNavigateToMoblet,
  CommunicateNavigationToLogin,
  CommunicateNavigationToSignup,
  CommunicateNewMobletAdded,
  CommunicatePagesChange,
  CommunicateReloadMoblet,
  CommunicateSearchChange,
  CommunicateSplashScreenChange,
  CommunicateUpdatePageData,
  CommunicateAuthUser,
  CommunicateUpdateOnFormMoblet,
  CommunicateUpdateOnGroupPageLayout,
  CommunicateUpdateOnSignupForm,
} from '@core/state/communicator';
import { Store } from '@ngxs/store';
import { Form } from 'src/app/moblets/forms/models';
import {
  EventsOfCommunicator,
  OpenMobletCommunicator,
} from './models/events-of-communicator.model';
import { HomeLayout } from '@core/types';

interface EventFromPortal {
  event: string;
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class CommunicatorService {
  /**
   * @description
   * armazena os actions do store de comunicação
   * consultar -> events-of-communicator.model
   */
  private eventsOfCommunicator: EventsOfCommunicator = {
    headerImage: ({ headerImage }: AppInfo) =>
      new CommunicateAppInfoChange({ headerImage }), // TODO: REMOVER MÉTODO headerImage
    headerPattern: ({ header_pattern }: AppDef) =>
      new CommunicateHeaderPatternChange(header_pattern),
    headerLogo: ({ logo }: Partial<AppDef>) =>
      new CommunicateHeaderLogoChange(logo),
    colors: ({ colors }: AppDef) => new CommunicateColorChange(colors),
    background: ({ background }: AppInfo) =>
      new CommunicateAppInfoChange({ background }),
    search: ({ search }: AppDef) => new CommunicateSearchChange(search),
    newMoblet: (data: { moblet: Page }) =>
      new CommunicateNewMobletAdded(data.moblet),
    layout: ({ layout }: AppDef) => new CommunicateLayoutChange(layout),
    pop: () => new CommunicateNavigateBack('back'),
    popRoot: () => new CommunicateNavigateBack('root'),
    reloadAppDef: () => new LoadAppDef(),
    pagesUpdated: ({ pages }: AppDef) => new CommunicatePagesChange(pages),
    accessOptions: (data: { accessOptions: Partial<AppInfo> }) =>
      new CommunicateAccessOptionsChange(data.accessOptions),
    splash: (data: { splash: string }) =>
      new CommunicateSplashScreenChange(data.splash),
    advertising: (data: { advertising: Partial<AppInfo> }) =>
      new CommunicateAppInfoChange(data.advertising),
    openSignUpPage: () => new CommunicateNavigationToSignup(),
    internalNavigation: (data: {
      internalNavigation: string;
      queryParams?: Record<string, string>;
    }) =>
      new CommunicateInternalNavigation(
        data.internalNavigation,
        data.queryParams,
      ),
    openLoginPage: () => new CommunicateNavigationToLogin(),
    mobletGroup: (data: OpenMobletCommunicator) =>
      new CommunicateNavigateToMoblet(data),
    openMoblet: (data: { mobletId: number; superClass: string }) =>
      new CommunicateNavigateToMoblet(data),
    openMobletItem: (data: OpenMobletCommunicator) =>
      new CommunicateNavigateToMoblet(data),
    form: (data: { form: DynamicFormField[] }) =>
      new CommunicateUpdateOnSignupForm(data.form),
    formMoblet: (data: { formMoblet: Form }) =>
      new CommunicateUpdateOnFormMoblet(data.formMoblet),
    pageGroupLayout: (data: {
      instanceId: number;
      pageLayout: GroupLayoutDef;
    }) =>
      new CommunicateUpdateOnGroupPageLayout(data.instanceId, data.pageLayout),
    // group: (data: { group: Page }) =>
    //   new CommunicateNavigateToMoblet({ mobletId: data.group.instance.id }) // TODO: Remover pois o grupo de abas não utiliza mais no editor,
    reloadMoblet: (data: { mobletId: number }) =>
      new CommunicateReloadMoblet(data.mobletId),
    setHomePage: (data: { layout_type: HomeLayout }) =>
      new CommunicateHomePageChanged(data.layout_type),
    updatePage: (data: { page: Page }) =>
      new CommunicateUpdatePageData(data.page),
    authUser: (data: { email: string; password: string }) =>
      new CommunicateAuthUser(data),
  };

  constructor(private store: Store) {}

  /**
   * @description
   * data: dados passados pelo evento postMessage,
   * event: nome do event que será chamado
   * @returns void
   */
  public eventCall({ data, event }: EventFromPortal): void {
    if (!event) {
      return;
    }
    if (!this.eventsOfCommunicator[event]) {
      console.log('Unkown event: ', event);
      return;
    }

    this.store.dispatch(this.eventsOfCommunicator[event](data));
  }
}
