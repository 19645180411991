import { HttpClient } from '@angular/common/http';
import { Request } from '@core/models';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpService<T> {
  private _lastRequest: Request;
  protected url = '';

  protected filters = {};
  protected includes: string[] = [];

  constructor(protected $http: HttpClient) {}

  public include(value: string) {
    this.includes.push(value);
    return this;
  }

  public includeArray(values: string[]) {
    this.includes = this.includes.concat(values);
    return this;
  }

  public getIncludes(): Object {
    return this.includes;
  }

  public post(body: object): Observable<T> {
    return this.send('post', this.url, body);
  }

  public get(id: string): Observable<T> {
    return this.send('get', `${this.url}/${id}`);
  }

  public update(body: object, id?: string): Observable<T> {
    return this.send('put', `${this.url}${id ? '/' + id : ''}`, body);
  }

  public delete(id: string): Observable<any> {
    return this.send('delete', `${this.url}/${id}`);
  }

  public request(
    method: string,
    complementUrl: string,
    body?: object,
  ): Observable<any> {
    const endpoint: string = this.url + complementUrl;
    return this.send(method, endpoint, body);
  }

  protected send(method: string, url: string, body?: object): Observable<T> {
    const queryString = this.getQueryString();

    if (queryString !== '') {
      url = url + '?' + queryString;
    }

    method = method.toLowerCase();
    this.lastRequest = { method, url, body };

    return this.$http[method](url, body);
  }

  get lastRequest(): Request {
    return this._lastRequest;
  }

  set lastRequest(request: Request) {
    this._lastRequest = request;
  }

  protected getQueryString(): string {
    let queryString = '';
    for (const i in this.filters) {
      if (this.filters.hasOwnProperty(i)) {
        queryString += i + '=' + this.filters[i] + '&';
      }
    }

    for (const i in this.includes) {
      if (this.includes.hasOwnProperty(i)) {
        queryString += `includes[${i}]=${this.includes[i]}&`;
      }
    }

    queryString = queryString.substring(0, queryString.length - 1);
    this.filters = {};
    return queryString;
  }

  public addFilter(key: string, value: any) {
    this.filters[key] = value;
    return this;
  }
}
