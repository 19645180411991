<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons [slot]="!isModal ? 'start' : 'end'">
      <ion-back-button *ngIf="!isModal" defaultHref="home"></ion-back-button>
      <ion-button
        *ngIf="isModal"
        fill="clear"
        (click)="modalController.dismiss()"
      >
        <ion-icon slot="icon-only" name="close" color="headercolor"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-progress-bar *ngIf="isSubmitting" type="indeterminate"></ion-progress-bar>

<ion-content class="ion-padding">
  <fabapp-user-form
    *ngIf="!deliveryEnabled; else delivery"
    (submitForm)="formSubmit($event)"
    [isSubmitting]="isSubmitting"
  >
  </fabapp-user-form>
  <!-- delivery form -->
  <ng-template #delivery>
    <fabapp-delivery-user-form
      [isSubmitting]="isSubmitting"
      (submitForm)="formSubmit($event)"
    ></fabapp-delivery-user-form>
  </ng-template>
  <!-- /delivery form -->
</ion-content>
