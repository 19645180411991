import { FDPaymentMethod } from 'src/app/pages/settings/payment-methods/models/payment-method.model';
import { ComplementFromItemState } from '../item';
import { FDI_ComplementItem, FDI_Product } from '../product/product.model';
import { Coupon } from '../promotions/coupon/coupon.model';
import { PaymentMethod } from '../store';

export interface FDI_Cart {
  id: string;
  appId: number;
  storeId: number;
  statusId: number;
  userId: string;
  createdAt: string;
  updatedAt: string;
  status: { id: number; name: string };
  items: FDI_CartItem[];
  couponId?: string;
  coupon?: Partial<Coupon>;
}

export interface FDI_OrderObject {
  cartId: string;
  addressId?: string;
  paymentMethodId: number;
  deliveryMethodId: number;
  amountFinal: number;
  cashback?: number;
}
export class FDI_CartItem {
  constructor(
    init?: Partial<FDI_CartItem>,
    complements?: FDI_CartItemComplements,
  ) {
    const parsed: Partial<FDI_CartItem> = {
      ...init,
      complements,
    };

    Object.assign(this, parsed);
  }

  id?: string;
  productId: string;
  quantity?: number;
  observation?: string;
  cartId?: string;
  product?: FDI_Product;
  complements: FDI_CartItemComplements;
}

export type FDI_CartItemComplements =
  | {
      [key: string]: Array<ComplementFromItemState>;
    }
  | Array<ComplementFromItemState>;

export interface FDI_ProductImage {
  url: string;
  productId: string;
}

export interface FDI_Complement {
  id: string;
  quantity: number;
  complementItemId: string;
  cartItemId: string;
  complementItem: FDI_ComplementItem;
}

export interface FDI_PaymentMethodWithCard {
  method: PaymentMethod;
  card: FDPaymentMethod;
  cashback?: number;
  noCashBack?: boolean;
}
