import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { CropperComponent } from '../angular-cropper/cropper/cropper.component';

@Component({
  selector: 'app-cropper-image',
  templateUrl: './cropper-image.component.html',
  styleUrls: ['./cropper-image.component.scss'],
})
export class CropperImageComponent implements OnInit {
  @ViewChild('angularCropper') public angularCropper: CropperComponent;
  cropperOptions: any;
  croppedImage: string = null;
  @Input() image: string = null;

  scaleValX = 1;
  scaleValY = 1;

  public profilePhoto$: Observable<string>;
  constructor(
    private modalCtrl: ModalController,
    private translocoService: TranslocoService,
  ) {
    this.cropperOptions = {
      dragMode: 'move',
      aspectRatio: 1,
      autoCrop: true,
      movable: true,
      zoomable: true,
      scalable: true,
      autoCropArea: 0.8,
      viewMode: 0,
    };
  }

  ngOnInit(): void {
    this.getTranslation();
  }

  getTranslation(): void {
    const scope: string = `settings/${this.translocoService.getActiveLang()}`;
    this.profilePhoto$ = this.translocoService.selectTranslate(
      'avatar.profilePhoto',
      {},
      scope,
    );
  }

  reset(): void {
    this.angularCropper.cropper.reset();
  }

  clear(): void {
    this.angularCropper.cropper.clear();
  }

  rotate(): void {
    this.angularCropper.cropper.rotate(90);
  }

  zoom(zoomIn: boolean): void {
    let factor = zoomIn ? 0.1 : -0.1;
    this.angularCropper.cropper.zoom(factor);
  }

  scaleX(): void {
    this.scaleValX = this.scaleValX * -1;
    this.angularCropper.cropper.scaleX(this.scaleValX);
  }

  scaleY(): void {
    this.scaleValY = this.scaleValY * -1;
    this.angularCropper.cropper.scaleY(this.scaleValY);
  }

  close(): void {
    this.angularCropper.cropper.reset();
    this.modalCtrl.dismiss();
  }

  save(): void {
    const croppedImgB64String: string = this.angularCropper.cropper
      .getCroppedCanvas()
      .toDataURL('image/jpeg', 100 / 100);
    this.croppedImage = croppedImgB64String;
    this.modalCtrl.dismiss({
      image: croppedImgB64String,
    });
  }
}
