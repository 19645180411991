<ion-list lines="none">
  <ng-container *ngFor="let page of group; trackBy: trackByGroupIndex">
    <ion-item
      [class.ion-hide]="!(page.visible | convertToBoolean)"
      button
      (click)="navigateToMoblet(page)"
      class="group-item"
      [detail]="!page.background"
      [ngClass]="layout?.listLayoutType"
      [class.-with-bg]="page.background"
      [appShadowCss]="page.background"
      shadowCustomCss=".item-native { position: absolute !important; left: 0; right: 0; bottom: 0; top: 0; background-size: cover!important;}"
      [style.--background]="
        page.background
          ? 'url(' + page.background + ') no-repeat center center / cover'
          : null
      "
    >
      <!-- icone -->
      <ion-thumbnail class="icon" *ngIf="layout?.showPageListIcon">
        <ion-img [src]="page.icon"> </ion-img>
      </ion-thumbnail>
      <!-- /icone -->
      <!-- content -->
      <ion-label class="inner-wrapper">
        <span *ngIf="layout?.showPageListName" class="page-name">
          {{ page.name }}
        </span>
      </ion-label>
      <!-- content -->
    </ion-item>
  </ng-container>
</ion-list>
