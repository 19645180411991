import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppDef } from '@core/models';
import { NavigationService } from '@core/services/navigation.service';
import { AppDefState } from '@core/state/appdef';
import { CoreState } from '@core/state/core/core.state';
import { ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SearchPageComponent } from '../search/search-page.component';

@Component({
  selector: 'fabapp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() color: 'transparent' | 'headercolor' | '';
  @Input() defaultHref = this.navigationService.getHomeRoute();

  shouldGoBackToHome = false;
  @Input() isModal = false;
  @Input() forceHideBackButton = false;

  @Select(CoreState.menuEnabled)
  menuEnabled$: Observable<boolean>;
  @Select(
    AppDefState.getFromAppDef([
      'info',
      'colors',
      'layout',
      'logo',
      'search',
      'header_pattern',
    ]),
  )
  partialAppDef$: Observable<Partial<AppDef>>;
  openSearch: EventEmitter<boolean> = new EventEmitter();
  searchPlaceholder: Observable<string>;

  constructor(
    public router: Router,
    public navigationService: NavigationService,
    private translocoService: TranslocoService,
    public modalCtrl: ModalController,
  ) {
    this.searchPlaceholder = this.translocoService.selectTranslate(
      'searchPlaceholder',
      {},
      `search/${this.translocoService.getActiveLang()}`,
    );
  }

  async ngOnInit(): Promise<any> {
    this.color = !this.color ? 'headercolor' : this.color;
    this.color = this.color === 'transparent' ? '' : this.color;

    this.shouldGoBackToHome = !this.router.navigated;
  }

  async openSearchModal(animated: boolean = true): Promise<void> {
    const modal: HTMLIonModalElement = await this.modalCtrl.create({
      animated,
      component: SearchPageComponent,
    });

    return await modal.present();
  }
}
