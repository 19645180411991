<ng-container *ngIf="adCarouselCampaign$ | async as campaign">
  <ion-slides
    [options]="{ autoplay: { delay: campaign?.exhibitionTime } }"
    *ngIf="campaign"
    [class.swiper-no-swiping]="campaign?.ads?.length === 1"
    [pager]="campaign?.ads?.length > 1"
    #slider
    (ionSlideTouchStart)="slideChanged(slider)"
    (ionSlideTouchEnd)="enableAutoPlay(slider)"
    (ionSlidesDidLoad)="slidesDidLoad(slider)"
    (ionSlideWillChange)="triggerVisualization(slider)"
  >
    <ion-slide *ngFor="let item of campaign?.ads; trackBy: trackByIndex">
      <div
        class="image"
        [ngStyle]="{
          'aspect-ratio':
            carouselDefaultSizes.width / campaign?.carouselAdsHeight ||
            carouselDefaultSizes.height
        }"
        [style.backgroundImage]="
          item?.imagePath && 'url(' + item.imagePath + ')'
        "
        [style.backgroundColor]="item?.bgColor"
        (click)="carouselNavigation(item)"
      >
        <span class="caption" *ngIf="item.title">{{ item?.title }}</span>
      </div>
    </ion-slide>
  </ion-slides>
</ng-container>
