import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import '@codetrix-studio/capacitor-google-auth';
import { AppInfo, ErrorApiEnum, SocialUser } from '@core/models';
import { NavigationService } from '@core/services/navigation.service';
import { AppDefState } from '@core/state/appdef';
import { GoogleLogin } from '@core/state/auth';
import { LoadingController, Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';

const { GoogleAuth } = Plugins;

@Injectable()
export class GoogleSignInService {
  private appInfo: AppInfo;

  constructor(
    private store: Store,
    private navigationService: NavigationService,
    private loadingController: LoadingController,
    private platform: Platform,
  ) {
    this.initialize();
  }

  private initialize(): void {
    this.appInfo = this.store.selectSnapshot(AppDefState.getInfo);

    if (!this.appInfo?.login_google_active || !this.appInfo?.google_client_id) {
      return;
    }

    const metatagGoogle: HTMLElement = document.querySelector(
      '#google-clientid',
    );
    metatagGoogle.setAttribute('name', 'google-signin-client_id');
    metatagGoogle.setAttribute('content', this.appInfo.google_client_id);
    document.head.appendChild(metatagGoogle);
    this.forceLoadGoogleAuth();
  }

  /**
   * @description Method "Factory" for create Google login
   *
   * @method
   * @public
   * @returns Promise
   * @memberof GoogleSignInService
   *
   */
  public async signIn(): Promise<void> {
    try {
      if (!this.appInfo?.login_google_active) {
        this.errorForProviderNotConfigured();
      }

      await this.signInWithGoogleAccount();
    } catch (error) {
      if (error?.error !== 'popup_closed_by_user' && error?.error) {
        throw error;
      }
    }
  }

  /**
   * @description
   * método força o carregamento do plugin
   * para que possa iniciar uma instancia usando
   * google-signin-client_id da index.html
   * @returns void
   */
  private forceLoadGoogleAuth(): void {
    if (!this.platform.is('capacitor')) {
      // HACK: para forçar inicialização do plugin
      GoogleAuth.gapiLoaded = new Promise((resolve: any) => {
        (window as any).gapiResolve = resolve;
        GoogleAuth.initialize();
      });
    }
  }

  /**
   * @private
   * @description Dispactch Google Login
   * @returns Promise
   * @memberof GoogleSignInService
   */
  private async signInWithGoogleAccount(): Promise<void> {
    let loading: HTMLIonLoadingElement;
    try {
      const token: string = await this.checkGoogleAccount();
      if (!token) {
        console.log('token is null');
        return null;
      }
      // present loading
      loading = await this.presentLoading();
      await this.store.dispatch(new GoogleLogin({ token })).toPromise();
    } catch (error) {
      throw error;
    } finally {
      if (loading) {
        loading.dismiss();
      }
    }
  }

  /**
   *
   * @description using plugin: https://github.com/CodetrixStudio/CapacitorGoogleAuth
   * configs: capacitor.config.json
   * scopes: [
   *  "profile", // public info about user
   *  "email" // access to email
   * ]
   *
   * @method
   * @private
   * @returns Promise
   * @memberof GoogleSignInService
   */
  private async checkGoogleAccount(): Promise<string> {
    try {
      // logout before signIn
      await GoogleAuth.signOut();
      const user: SocialUser = await GoogleAuth.signIn();

      if (!user) {
        return null;
      }

      if (user?.authentication?.accessToken) {
        return user.authentication.accessToken;
      }

      return user.serverAuthCode;
    } catch (error) {
      // caso tenha uma erro generico que a API de loggin do google retorna
      // lança um error generico
      if (typeof error === 'object' && Object.keys(error).length === 0) {
        throw new HttpErrorResponse({
          error: { error: 'genericError' },
        });
      }

      throw error;
    }
  }

  private errorForProviderNotConfigured(): never {
    throw new HttpErrorResponse({
      error: { error: ErrorApiEnum.PROVIDER_NOT_CONFIGURED },
    });
  }

  private async presentLoading(): Promise<HTMLIonLoadingElement> {
    const loading: HTMLIonLoadingElement = await this.loadingController.create({
      message: 'Loading...',
    });
    await loading.present();

    return loading;
  }
}
