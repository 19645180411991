import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { isPlatform } from '@ionic/angular';

@Pipe({
  name: 'sanitizeHtml',
})
export class SanitizeHTMLPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  isSafari() {
    var match = ((navigator && navigator.userAgent) || '')
      .toLowerCase()
      .match(/version\/(\d+).+?safari/);
    return match !== null;
  }

  transform(html: string): SafeHtml {
    if (!html) {
      return html;
    }

    /**
     * @description
     *  html.replace(/href="#"/g, '').replace(/data-moblet="#"/g, '')
     * remove '#' para não haver um reload no app ao clicar em elementos html
     * que contenham esses atributos
     */
    let newHtml: string;
    newHtml = html.replace(/data-moblet="#"/g, '').replace(/<meta[^>]*>/g, '');

    if (isPlatform('ios') && (this.isSafari() || !isPlatform('mobileweb'))) {
      newHtml = newHtml
        .replace(/href="#"/g, 'href="javascript:void(0)"')
        .replace(/href=""/g, 'href="javascript:void(0)"');
    } else {
      // remove `href="javascript:void(0)` no android para ser não aberto uma pagina em branco
      // quando houver um `onclick="window.open(...)"`
      newHtml = newHtml
        .replace(/href="javascript:void\(0\)"/g, '')
        .replace(/href="#"/g, '')
        .replace(/href=""/g, '');
    }

    return this.sanitizer.bypassSecurityTrustHtml(newHtml);
  }
}
