import { Injectable } from '@angular/core';
import { DeliveryMethod } from '@core/enums';
import { Address } from '@core/models/address/address.model';
import { LoadAddresses } from '@core/state/address/address.action';
import { AddressState } from '@core/state/address/address.state';
import {
  FDI_Cart,
  FDI_PaymentMethodWithCard,
} from '@fabapp-delivery/models/cart/cart.model';
import { FDI_CartFees } from '@fabapp-delivery/models/cart/fees.model';
import { PaymentMethod } from '@fabapp-delivery/models/store';
import { CartService } from '@fabapp-delivery/services';
import {
  Action,
  Actions,
  NgxsOnInit,
  ofAction,
  ofActionDispatched,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { FDPaymentMethod } from '../../../../pages/settings/payment-methods/models/payment-method.model';
import { PaymentMethod as PaymentMethodEnum } from '@core/enums';
import {
  ClearSelectedCoupon,
  SetSelectedCoupon,
  ShowConfirmationToRemoveCoupon,
} from '../promotions/promotions.action';
import { DeliveryCartActions } from './cart.actions';
import { CancelLoadStore } from '@core/state/moblets';

export interface DeliveryCartStateModel {
  cart: FDI_Cart;
  fees: FDI_CartFees;
  deliveryMethod: DeliveryMethod;
  defaultDeliveryMethod: DeliveryMethod;
  selectedPaymentMethod: FDI_PaymentMethodWithCard;
}

const DEFAULTS: DeliveryCartStateModel = {
  cart: {
    id: null,
    appId: null,
    storeId: null,
    statusId: null,
    userId: null,
    createdAt: null,
    updatedAt: null,
    status: null,
    items: [],
  },
  fees: null,
  deliveryMethod: DeliveryMethod.DELIVERY,
  defaultDeliveryMethod: null,
  selectedPaymentMethod: {
    method: null,
    card: null,
    cashback: null,
    noCashBack: null,
  },
};

@State<DeliveryCartStateModel>({
  name: 'fabappDeliveryCart',
  defaults: DEFAULTS,
})
@Injectable()
export class CartState implements NgxsOnInit {
  constructor(
    private cartService: CartService,
    private store: Store,
    private actions$: Actions,
  ) {}

  ngxsOnInit(ctx?: StateContext<any>) {
    this.actions$
      .pipe(ofActionDispatched(CancelLoadStore))
      .subscribe(() => ctx.dispatch(new DeliveryCartActions.Reset()));
  }

  @Selector()
  static getDeliveryMethod(state: DeliveryCartStateModel): DeliveryMethod {
    return state.deliveryMethod;
  }

  @Selector()
  static getCart(state: DeliveryCartStateModel): FDI_Cart {
    return state.cart;
  }

  @Selector()
  static getFees(state: DeliveryCartStateModel): FDI_CartFees {
    return state.fees;
  }

  @Selector()
  static getCartId(state: DeliveryCartStateModel): string {
    return state.cart.id;
  }

  @Selector()
  static getSelectedPaymentMethod(
    state: DeliveryCartStateModel,
  ): FDI_PaymentMethodWithCard {
    return state.selectedPaymentMethod;
  }

  @Action(DeliveryCartActions.Load)
  loadDeliveryCart(ctx: StateContext<any>) {
    return this.cartService.getCart().pipe(
      tap((cartFromApi: FDI_Cart) => {
        ctx.patchState({
          cart: cartFromApi,
        });
      }),
      mergeMap((cartFromApi) =>
        ctx.dispatch([
          new DeliveryCartActions.GetFees(),
          new SetSelectedCoupon(cartFromApi?.coupon),
        ]),
      ),
      takeUntil(this.actions$.pipe(ofAction(CancelLoadStore))),
    );
  }

  @Action(DeliveryCartActions.Reset)
  async resetCart(ctx: StateContext<any>): Promise<any> {
    const { defaultDeliveryMethod }: DeliveryCartStateModel = ctx.getState();
    ctx.patchState({
      ...DEFAULTS,
      defaultDeliveryMethod,
      deliveryMethod: defaultDeliveryMethod,
    });
  }

  @Action(DeliveryCartActions.Clear)
  async clearDeliveryCart(ctx: StateContext<any>): Promise<any> {
    const { cart }: DeliveryCartStateModel = ctx.getState();
    await this.cartService.clearCart(cart.id).toPromise();

    ctx.patchState({
      cart: { ...cart, items: [] },
    });

    ctx.dispatch([
      new ClearSelectedCoupon(),
      new DeliveryCartActions.Load(),
      new DeliveryCartActions.ClearFees(),
    ]);
  }

  @Action(DeliveryCartActions.ClearFees)
  async clearFees(ctx: StateContext<any>): Promise<any> {
    ctx.patchState({
      fees: {
        discount: 0,
        shippingTax: 0,
        subtotal: 0,
        total: 0,
      },
    });
  }

  @Action(DeliveryCartActions.GetFees)
  async getFees(ctx: StateContext<DeliveryCartStateModel>): Promise<any> {
    const { cart, deliveryMethod }: DeliveryCartStateModel = ctx.getState();
    let address: Address = null;

    if (deliveryMethod == DeliveryMethod.DELIVERY) {
      address = await this._getAddress(ctx);
    }

    try {
      const feesFromApi: any = await this.cartService
        .getFees(cart.id, address)
        .toPromise();

      if (feesFromApi.hasFreeShipping) {
        feesFromApi.shippingTax = 0;
        feesFromApi.total = feesFromApi.subtotal;
      }

      ctx.patchState({
        fees: feesFromApi,
      });
    } catch (httpErrorResponse) {
      await this._treatInvalidCoupon(httpErrorResponse, ctx);

      ctx.patchState({
        fees: {
          error: true,
          total: 0,
          subtotal: 0,
          shippingTax: 0,
        },
      });
    }
  }

  private async _getAddress(ctx: StateContext<DeliveryCartStateModel>) {
    let address: Address = this.store.selectSnapshot(
      AddressState.getFavoriteAddress,
    );

    if (!address) {
      await ctx.dispatch(new LoadAddresses()).toPromise();
      address = this.store.selectSnapshot(AddressState.getFavoriteAddress);
    }
    return address;
  }

  private async _treatInvalidCoupon(
    httpErrorResponse: any,
    ctx: StateContext<DeliveryCartStateModel>,
  ) {
    const errorMessage = httpErrorResponse?.error?.error as string;
    if (errorMessage?.toLowerCase() === 'cupom inválido') {
      await ctx.dispatch(new ShowConfirmationToRemoveCoupon()).toPromise();
    }
  }

  @Action(DeliveryCartActions.Item.Add)
  addItem(ctx: StateContext<any>, { item }: DeliveryCartActions.Item.Add) {
    const { cart }: DeliveryCartStateModel = ctx.getState();
    return this.cartService.addItem(cart.id, item).pipe(
      tap((cart) => ctx.patchState({ cart })),
      mergeMap(() => ctx.dispatch(new DeliveryCartActions.GetFees())),
    );
  }

  @Action(DeliveryCartActions.Item.Increment)
  async incrementItem(
    ctx: StateContext<any>,
    { item }: DeliveryCartActions.Item.Increment,
  ): Promise<any> {
    const { cart }: DeliveryCartStateModel = ctx.getState();
    const cartFromApi: FDI_Cart = await this.cartService
      .incrementItem(cart.id, item.id)
      .toPromise();

    ctx.dispatch(new DeliveryCartActions.GetFees());

    ctx.patchState({
      cart: cartFromApi,
    });
  }

  @Action(DeliveryCartActions.Item.Decrement)
  async decrementItem(
    ctx: StateContext<any>,
    { item }: DeliveryCartActions.Item.Decrement,
  ): Promise<any> {
    const { cart }: DeliveryCartStateModel = ctx.getState();
    const cartFromApi: FDI_Cart = await this.cartService
      .decrementItem(cart.id, item.id)
      .toPromise();

    ctx.dispatch(new DeliveryCartActions.GetFees());

    ctx.patchState({
      cart: cartFromApi,
    });
  }

  @Action(DeliveryCartActions.Item.Remove)
  async removeItem(
    ctx: StateContext<any>,
    { item }: DeliveryCartActions.Item.Remove,
  ): Promise<any> {
    const { cart }: DeliveryCartStateModel = ctx.getState();
    const cartFromApi: FDI_Cart = await this.cartService
      .removeItem(cart.id, item.id)
      .toPromise();

    ctx.dispatch(new DeliveryCartActions.GetFees());

    ctx.patchState({
      cart: cartFromApi,
    });
  }

  @Action(DeliveryCartActions.SetDeliveryMethod)
  setDeliveryMethod(
    ctx: StateContext<DeliveryCartStateModel>,
    { deliveryMethod }: DeliveryCartActions.SetDeliveryMethod,
  ) {
    if (!deliveryMethod) {
      deliveryMethod = ctx.getState()?.defaultDeliveryMethod;
    }

    ctx.patchState({ deliveryMethod });
  }

  @Action(DeliveryCartActions.SetDefaultDeliveryMethod)
  setDefaultDeliveryMethod(
    ctx: StateContext<DeliveryCartStateModel>,
    { deliveryMethod }: DeliveryCartActions.SetDeliveryMethod,
  ) {
    ctx.patchState({ deliveryMethod, defaultDeliveryMethod: deliveryMethod });
  }

  @Action(DeliveryCartActions.SetPaymentMethod)
  setPaymentMethod(
    ctx: StateContext<DeliveryCartStateModel>,
    {
      method,
      card,
      cashback,
      noCashBack,
    }: DeliveryCartActions.SetPaymentMethod,
  ) {
    ctx.patchState({
      selectedPaymentMethod: {
        method,
        card,
        cashback:
          method.id === PaymentMethodEnum.MONEY && !noCashBack
            ? cashback
            : null,
        noCashBack,
      },
    });
  }

  @Action(DeliveryCartActions.ClearPaymentMethod)
  clearPaymentMethod(ctx: StateContext<DeliveryCartStateModel>) {
    ctx.patchState({
      selectedPaymentMethod: {
        method: null,
        card: null,
        cashback: null,
        noCashBack: null,
      },
    });
  }
}
