<div class="delivery-type">
  <ion-segment
    mode="md"
    color="primary"
    [style.--color]="'var(--primarycolor-or-black)'"
    class="segment"
    [(ngModel)]="deliveryMethodSelected"
    (ionChange)="changed($event.detail.value)"
  >
    <ion-segment-button
      *ngIf="store?.hasDelivery"
      [value]="deliveryMethodEnum.DELIVERY"
    >
      <ion-label>Entrega</ion-label>
    </ion-segment-button>
    <ion-segment-button
      [value]="deliveryMethodEnum.TAKE_AWAY"
      *ngIf="storeAddress && store?.hasOnSite"
    >
      <ion-label>Retirada</ion-label>
    </ion-segment-button>
  </ion-segment>
  <hr />
  <ng-container
    *ngIf="
      deliveryMethodSelected == deliveryMethodEnum.DELIVERY &&
      store?.hasDelivery
    "
  >
    <div
      class="delivery-option-box ion-activatable"
      *ngIf="favoriteAddress$ | async as favoriteAddress; else addAddress"
      (click)="openAddress()"
    >
      <img class="delivery-option-box-icon" src="/assets/entrega-icon.svg" />
      <div class="delivery-option-box-address">
        <h4 *ngIf="deliverable$ | async; else undeliverable">Entrega em</h4>
        <ng-template #undeliverable>
          <h4 class="undeliverable">Não entregamos em</h4>
        </ng-template>
        <h3>
          {{ favoriteAddress.address }}, {{ favoriteAddress.number }}
          {{ favoriteAddress.complement }}
        </h3>
        <p>{{ favoriteAddress.city }} - {{ favoriteAddress.state }}</p>
      </div>
      <ion-ripple-effect></ion-ripple-effect>
    </div>
  </ng-container>
  <ng-template #addAddress>
    <ion-button
      class="add-address-button"
      fill="transparent"
      [style.--color]="'var(--primarycolor-or-black)'"
      (click)="openAddress()"
    >
      <span slot="start">Adicionar endereço</span>
    </ion-button>
  </ng-template>
  <div
    class="delivery-option-box ion-activatable"
    *ngIf="
      deliveryMethodSelected == deliveryMethodEnum.TAKE_AWAY &&
      storeAddress &&
      store?.hasOnSite
    "
  >
    <img class="delivery-option-box-icon" src="/assets/retirada-icon.svg" />
    <div class="delivery-option-box-address">
      <h4>Retirada em</h4>
      <h3>
        {{ storeAddress.address }}, {{ storeAddress.number }}
        {{ storeAddress.complement }}
      </h3>
      <p>{{ storeAddress.city }} - {{ storeAddress.state }}</p>
    </div>
    <ion-ripple-effect></ion-ripple-effect>
  </div>
</div>
