import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[outline]',
})
export class OutlineDirective {
  clickListeners: (() => void)[] = [];
  elementRef: ElementRef;
  domElement: any;

  constructor(el: ElementRef) {
    this.elementRef = el;
    this.domElement = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.domElement.closest('ion-item').classList.add('outline');
  }
}
