import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FabiPaymentMethodButtonType } from './types/fabi-payment-method-button-type';
import { FabiPaymentMethodIconType } from './types/fabi-payment-method-icon-type';

@Component({
  selector: 'fabi-payment-method-item',
  templateUrl: './payment-method-item.component.html',
  styleUrls: ['./payment-method-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  host: { class: 'ion-activatable ripple-parent' },
})
export class PaymentMethodItemComponent implements OnInit {
  public readonly brands = {
    mastercard: '/assets/images/fabapp-delivery/brands/mastercard.svg',
    visa: '/assets/images/fabapp-delivery/brands/visa.svg',
    elo: '/assets/images/fabapp-delivery/brands/elo.svg',
    hipercard: '/assets/images/fabapp-delivery/brands/hipercard.svg',
    amex: '/assets/images/fabapp-delivery/brands/amex.svg',
    diners: '/assets/images/fabapp-delivery/brands/diners.svg',
  };

  brandIcon: string;
  @Input('brandIcon')
  set setBrandIcon(brand: string) {
    this.brandIcon = brand?.includes('http') ? brand : this.brands[brand] || '';
    this.cdr.detectChanges();
  }

  _buttonType: FabiPaymentMethodButtonType = 'button';
  @Input('button-type')
  set buttonType(type: FabiPaymentMethodButtonType) {
    this._buttonType = type;
    this.renderer.addClass(this.elementRef.nativeElement, type);
  }

  get buttonType(): FabiPaymentMethodButtonType {
    return this._buttonType;
  }

  _selected: boolean = false;
  @Input()
  set selected(isSelected: boolean) {
    this._selected = isSelected;

    if (isSelected) {
      this.renderer.addClass(this.elementRef.nativeElement, 'selected');
    }
  }

  code: string;
  @Input('code')
  set setCode(code: string) {
    this.code = code;
    this.cdr.detectChanges();
  }

  @Input() title: string;
  @Input() icon: FabiPaymentMethodIconType = 'ellipsis-vertical';
  @Input() titleSize: 'small' | 'normal' = 'normal';
  @Input() iconSize: 'small' | 'normal' = 'normal';
  @Input() censorCode: boolean = true;

  @Output() onAction = new EventEmitter();
  @Output() onClick = new EventEmitter();

  public hasClick: boolean;
  public hasAction: boolean;

  @HostListener('click')
  clickListener() {
    if (this.hasClick) {
      this.onClick.emit();
    }
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.hasAction = this.onAction.observers.length > 0;
    this.hasClick = this.onClick.observers.length > 0;
  }
}
