<div *ngIf="currentLocateUser">
  <google-map
    [height]="height"
    width="100%"
    [zoom]="zoomViewGoogleMaps"
    [center]="center"
    [options]="optionsControlGoogleMaps"
    (zoomChanged)="zoomMap()"
    (mapDragend)="draggableMap()"
    #map
  >
  </google-map>
</div>

<div class="input-address">
  <div class="static-solid">
    <span>{{ currentStreet }}</span>

    <button class="button-back" (click)="closeModal()">
      <ion-icon class="icon" name="chevron-back-outline"></ion-icon>
    </button>
  </div>
</div>

<div class="button-locale" *ngIf="gMapsKey">
  <ion-button
    class="button"
    fill="outline"
    expand="block"
    (click)="currentLocationUser()"
  >
    <img src="assets/icons/localeMaps.svg" />
  </ion-button>
</div>

<div class="button-save">
  <ion-button
    class="button"
    fill="outline"
    [style.--color]="'var(--primarycolor-or-black)'"
    expand="block"
    (click)="confirm()"
    >Confirmar</ion-button
  >
</div>

<div id="marker" *ngIf="gMapsKey"></div>

<div class="gmapskey-not-found" *ngIf="!gMapsKey">
  <div class="inner-wrapper">
    <img src="/assets/images/fabapp-delivery/gmaps-key-not-found.svg" alt="" />
    <div class="title">API do Google não configurada</div>
  </div>
</div>
