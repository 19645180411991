import { Component, Input, OnInit } from '@angular/core';
import { DeliveryStoreProductImage } from '@core/models/moblets/fabapp-delivery/delivery-store-product-image.model';
import { ModalController } from '@ionic/angular';
import { ImageDetailComponent } from 'src/app/moblets/list/components';

@Component({
  selector: 'fabapp-product-photo-carousel',
  templateUrl: './product-photo-carousel.component.html',
  styleUrls: ['./product-photo-carousel.component.scss'],
})
export class ProductPhotoCarouselComponent implements OnInit {
  @Input() set productImages(images: Array<DeliveryStoreProductImage>) {
    this.images = images.map(({ url }) => url);
  }
  @Input() productVideo: string;

  public images: string[];

  constructor(public modalController: ModalController) {}

  ngOnInit() {}

  async openImage(item: string, index?: number): Promise<void> {
    if (!item) {
      return;
    }

    const modal: HTMLIonModalElement = await this.modalController.create({
      component: ImageDetailComponent,
      componentProps: {
        item,
        index,
        images: this.images,
      },
    });

    modal.present();
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }
}
