import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@core/directives/directives.module';
import { IonicModule } from '@ionic/angular';
import { PwaInstallBannerModule } from '../pwa-install-banner/pwa-install-banner.module';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
    PwaInstallBannerModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
