<ion-grid>
  <ion-row>
    <ion-col
      sizeXs="12"
      sizeMd="6"
      sizeLg="6"
      sizeXl="4"
      *ngFor="let item of items; trackBy: trackById"
    >
      <ion-card class="card-container" (click)="handle(item)">
        <ion-card-header class="card-header">
          <div class="card-wrapper" *ngIf="(item?.listItemImages)[0]?.imageUrl">
            <div
              class="cardthumb"
              [style.backgroundImage]="
                'url(' + (item?.listItemImages)[0]?.imageUrl + ')'
              "
            ></div>
          </div>
          <div
            class="inner-wrapper"
            [class.-without-image]="!(item?.listItemImages)[0]?.imageUrl"
          >
            <ion-card-title class="headline">{{ item.title }}</ion-card-title>
            <app-rating-of-item
              *ngIf="listDef?.ratings"
              (rate)="handle(item, true)"
              [average]="item?.ratingsavg"
              [ratingsTotal]="item?.ratingstotal"
              [withSeparator]="item?.distance"
            ></app-rating-of-item>
            <span *ngIf="item.distance" class="distance">{{
              item.distance | meterToKilometer
            }}</span>
            <ion-card-subtitle class="subheadline">{{
              item.resume
            }}</ion-card-subtitle>
          </div>
        </ion-card-header>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>
