import { Pipe, PipeTransform } from '@angular/core';
import { isHTML } from '@utils';

@Pipe({
  name: 'truncate',
  pure: true,
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, args: any[]): string {
    if ((args[0] || isHTML(value)) && args[0] === 'infinite') {
      return value;
    }

    const limit: number = args.length > 0 ? args[0] : 20;
    const trail: string = args.length > 1 ? args[1] : '...';
    return value?.length > limit ? value.substring(0, limit) + trail : value;
  }
}
