import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { TranslocoService } from '@ngneat/transloco';

const { Toast, Network } = Plugins;

type fedbackToast = 'long' | 'short';

export enum AudioEvents {
  PLAYING = 'playing',
  PAUSE = 'pause',
  TIMEUPDATE = 'timeupdate',
  LOADSTART = 'loadstart',
  LOADEDDATA = 'loadeddata',
  SEEDKED = 'seeked',
  SEEKING = 'seeking',
}

interface Translations {
  withoutConnection: string;
  cannotLoad: string;
  loading: string;
}

@Injectable()
export class AudioService {
  private _duration: any;

  private _player: HTMLAudioElement;

  private translations: Translations;
  private scope: string;
  constructor(public translocoService: TranslocoService) {
    this.scope = `audio-player/${translocoService.getActiveLang()}`;
    this.getTranslation();
  }

  async treatNetworkStatus(): Promise<void> {
    const { connected } = await Network.getStatus();
    if (!connected) {
      this.feedbackToast(this.translations.withoutConnection);
    }
  }

  /**
   * PLAYER
   */
  public get player(): HTMLAudioElement {
    return this._player;
  }

  public set player(value: HTMLAudioElement) {
    if (!value) {
      this.feedbackToast(this.translations.cannotLoad);
      return;
    }
    this._player = value;
  } // end player

  /**
   * duration
   */
  public get duration(): any {
    return this._duration;
  }

  public set duration(value: any) {
    this._duration = value;
  } // end duration

  public play(): void {
    this.treatNetworkStatus();
    this._player?.play();
  }

  public stop(): void {
    this._player?.pause();
  }

  seek(newValue: number): void {
    if (Math.floor(this._player.currentTime) === Math.floor(newValue)) {
      return;
    }

    this._player.currentTime = newValue;
  }

  public get durationFormatted(): string {
    return this.formatSeconds(this.player.duration);
  }

  public get currenTimeFormatted(): string {
    return this.formatSeconds(this._player.currentTime);
  }

  private formatSeconds(seconds: any): string {
    const date: Date = new Date(1970, 0, 1);
    date.setSeconds(seconds);
    if (this.duration) {
      if (this.duration.toString().length > 5) {
        return date.toTimeString().replace(/.*(\d{1}:\d{2}:\d{2}).*/, '$1');
      }
      return date.toTimeString().replace(/.*(\d{2}:\d{2}).*/, '$1');
    }

    return date.toTimeString().replace(/.*(\d{1}:\d{2}:\d{2}).*/, '$1');
  }

  public async getTranslation(): Promise<void> {
    this.translations = (await this.translocoService
      .selectTranslation(this.scope)
      .toPromise()) as Translations;
  }

  private async translate(key: string): Promise<string> {
    return (await this.translocoService
      .selectTranslate(key, {}, this.scope)
      .toPromise()) as string;
  }

  private async feedbackToast(
    text: string,
    duration: fedbackToast = 'long',
  ): Promise<void> {
    await Toast.show({
      text,
      duration,
      position: 'bottom',
    });
  }
}
