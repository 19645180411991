import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthState } from '@core/state/auth';
import { Store } from '@ngxs/store';
import { AuthGuardService } from '../auth-guard.service';

@Injectable({
  providedIn: 'root',
})
export class FDAuthGuardGuard implements CanActivate {
  constructor(
    private store: Store,
    private authGuardService: AuthGuardService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    if (!this.isAuthenticated) {
      this.authGuardService.goToLogin(state.url);
      return false;
    }

    return true;
  }

  private get isAuthenticated(): boolean {
    return this.store.selectSnapshot(AuthState.isAuthenticated);
  }
}
