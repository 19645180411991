<ion-header class="ion-no-border" [class.hidden]="!showOverlay">
  <ion-toolbar color="dark">
    <ion-buttons mode="md" slot="end">
      <ion-button (click)="modalController.dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="image-overlay">
  <!-- <pinch-zoom overflow="visible" disableZoomControl="disable">
    <img-loader (click)="toggleOverlay()" [src]="item"></img-loader>
  </pinch-zoom> -->

  <ion-slides
    *ngIf="showSlides && images"
    zoom="true"
    [options]="{ zoom: true, initialSlide: index }"
    [pager]="images.length > 1"
    #slider
  >
    <ion-slide
      class="swiper-zoom-container"
      *ngFor="let image of images; index as i; trackBy: trackByIndex"
    >
      <img [src]="image" />
    </ion-slide>
  </ion-slides>
</div>
