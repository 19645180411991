<ion-item button [detail]="true" lines="none" (click)="open()">
  <ng-container *ngIf="couponSelected; else withoutCoupon">
    <ion-icon slot="start" class="icon couponicon -coupon"></ion-icon>
    <ion-label class="ion-margin-vertical">
      <h3>
        <strong>{{ couponSelected.code }}</strong>
      </h3>
      <p>Cupom aplicado</p>
    </ion-label>
  </ng-container>
</ion-item>

<ng-template #withoutCoupon>
  <ion-icon slot="start" class="icon couponicon -coupon"></ion-icon>
  <ion-label class="ion-margin-vertical">
    <h3><strong>Cupons</strong></h3>
    <p>
      Você tem {{ promotionsCount }}
      {{ promotionsCount > 1 ? 'cupons' : 'cupom' }}
    </p>
  </ion-label>
</ng-template>
