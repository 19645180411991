export function blobToBase64(
  blob: Blob,
  callback: (blob: string | ArrayBuffer) => void,
): void {
  const reader: FileReader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = () => {
    callback(reader.result);
  };
}
