import { Pipe, PipeTransform } from '@angular/core';
import { convertToBoolean } from '@utils';

@Pipe({
  name: 'convertToBoolean',
})
export class ConvertToBooleanPipe implements PipeTransform {
  transform(value: any): boolean {
    return convertToBoolean(value);
  }
}
