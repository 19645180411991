import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Page, GroupLayoutDef } from '@core/models';
import { GroupLayoutType } from '../../enums/group-layout-type.enum';

interface GroupLayoutGrid {
  entries: Page[];
  layoutType: string;
  layout?: GroupLayoutDef;
}

@Component({
  selector: 'fabapp-group-mixed-layout',
  templateUrl: './group-mixed-layout.component.html',
  styleUrls: ['./group-mixed-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupMixedLayoutComponent implements OnInit, OnChanges {
  @Input() group: Page[];
  @Input() layout: GroupLayoutDef;

  @Output() navigate: EventEmitter<any> = new EventEmitter();

  items: GroupLayoutGrid[] = [];
  layoutType = GroupLayoutType;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.layout?.currentValue) {
      this.layout = changes.layout.currentValue;
    }

    if (changes?.group?.currentValue) {
      this.group = changes.group.currentValue;
    }

    this.cdr.markForCheck();
    this.parsePages();
  }

  /**
   * método responsavel por tratar os para montar o layout misto
   * @returns void
   */
  private parsePages(): void {
    this.items = [];

    if (this.group.length === 0 || !this.layout?.mixedLayoutType) {
      return;
    }

    const typesOfLayouts: string[] = this.layout?.mixedLayoutType.split('_');
    const pagesOfGroup: Page[] = [...this.group];

    for (const i in typesOfLayouts) {
      if (Object.hasOwnProperty.call(typesOfLayouts, i)) {
        const type: string = typesOfLayouts[i];
        const length: number = typesOfLayouts.length - 1;
        const isList: boolean = type.startsWith('list');
        const isGrid: boolean = type.startsWith('grid');
        const canGetFirstPage: boolean = isList && +i < length;

        if (canGetFirstPage) {
          this.items.push({
            layoutType: GroupLayoutType.LIST,
            entries: [pagesOfGroup.shift()],
          });
          continue;
        }

        if (isList) {
          this.items.push({
            layoutType: GroupLayoutType.LIST,
            entries: pagesOfGroup,
          });

          continue;
        }

        if (isGrid && +i < length) {
          const gridColumns: number = +type.split('-')[1];

          const gridEntries: Page[] = this.parseGrid(pagesOfGroup, gridColumns);

          this.items.push({
            layoutType: GroupLayoutType.GRID,
            layout: { ...this.layout, gridColumns },
            entries: gridEntries,
          });

          continue;
        }

        if (isGrid) {
          const gridColumns: number = +type.split('-')[1];

          this.items.push({
            layoutType: GroupLayoutType.GRID,
            layout: { ...this.layout, gridColumns },
            entries: pagesOfGroup,
          });

          continue;
        }

        this.cdr.markForCheck();
      }
    }
  }

  parseGrid(entries: Page[], gridColumns: number): Page[] {
    const gridEntries: Page[] = [];
    for (let page: number = 0; page < gridColumns; page++) {
      if (entries?.length === 0) {
        break;
      }

      gridEntries.push(entries.shift());
    }

    return gridEntries;
  }
}
