<form *ngIf="!loading" [formGroup]="form">
  <ng-container *transloco="let t">
    <ion-list lines="none">
      <ion-list-header
        *ngIf="!user"
        class="signup-header ion-text-center"
        lines="none"
      >
        <h1>
          {{ t('userForm.newAccount') }}
        </h1>
        <h2>{{ t('userForm.subtitle') }}</h2>
      </ion-list-header>
      <ng-container *ngFor="let field of dynamicForm$ | async">
        <ng-container
          *ngIf="user || isPreview || (!user && !field.readonly)"
          [ngSwitch]="field.type"
        >
          <ion-item *ngSwitchCase="'checkbox'">
            <ion-label position="floating">{{
              field.label + (field.rules.required ? ' *' : '')
            }}</ion-label>
            <ion-select
              [cancelText]="t('userForm.fields.labelCancel')"
              [okText]="t('userForm.fields.labelDone')"
              outline
              (ionChange)="selectValue($event, field)"
              multiple="true"
              [formControlName]="field.name"
            >
              <div *ngFor="let option of field.options">
                <ion-select-option [value]="option.id">{{
                  option.label
                }}</ion-select-option>
              </div>
            </ion-select>
          </ion-item>

          <ion-item *ngSwitchCase="'tel'">
            <ion-label position="stacked">{{
              field.label + (field.rules.required ? ' *' : '')
            }}</ion-label>
            <fabapp-phone-email-input
              outline
              [callingCode]="phoneHelper.ddi"
              (setMask)="setMask($event)"
              (getCallingCode)="getCallingCode($event)"
              [brmasker]="{ mask: phoneHelper.mask, type: 'tel' }"
              [formControlName]="field.name"
              type="tel"
            ></fabapp-phone-email-input>
          </ion-item>

          <ion-item *ngSwitchCase="'date'">
            <ion-label position="floating">{{
              field.label + (field.rules.required ? ' *' : '')
            }}</ion-label>
            <ion-datetime
              [cancelText]="t('userForm.fields.labelCancel')"
              [doneText]="t('userForm.fields.labelDone')"
              outline
              [formControlName]="field.name"
              displayFormat="DD-MM-YYYY"
              (ionChange)="dateChange(field.name, $event)"
            >
            </ion-datetime>
          </ion-item>

          <ion-item *ngSwitchCase="'email'">
            <ion-label position="floating">{{
              field.label + (field.rules.required ? ' *' : '')
            }}</ion-label>
            <ion-input
              outline
              [formControlName]="field.name"
              type="email"
              [required]="field.rules.required"
            >
            </ion-input>
          </ion-item>

          <ion-item *ngSwitchCase="'number'">
            <ion-label position="floating">{{
              field.label + (field.rules.required ? ' *' : '')
            }}</ion-label>
            <ion-input
              outline
              [formControlName]="field.name"
              [required]="field.rules.required"
              [brmasker]="{ type: 'num' }"
              [maxlength]="field?.rules?.maxLength"
            ></ion-input>
          </ion-item>

          <ng-container *ngSwitchCase="'password'">
            <ion-item *ngIf="!user">
              <ion-label position="floating">{{
                field.label + (field.rules.required ? ' *' : '')
              }}</ion-label>
              <ion-input
                outline
                [formControlName]="field.name"
                type="password"
                [required]="field.rules.required"
              >
              </ion-input>
            </ion-item>
          </ng-container>

          <ion-item *ngSwitchCase="'radio'" class="field select-field">
            <ion-label position="floating">{{
              field.label + (field.rules.required ? ' *' : '')
            }}</ion-label>
            <ion-select
              [cancelText]="t('userForm.fields.labelCancel')"
              [okText]="t('userForm.fields.labelDone')"
              [formControlName]="field.name"
              outline
            >
              <div *ngFor="let option of field.options">
                <ion-select-option [value]="option.id">{{
                  option.label
                }}</ion-select-option>
              </div>
            </ion-select>
          </ion-item>

          <ion-item *ngSwitchCase="'select'">
            <ion-label position="floating">{{
              field.label + (field.rules.required ? ' *' : '')
            }}</ion-label>
            <ion-select
              [cancelText]="t('userForm.fields.labelCancel')"
              [okText]="t('userForm.fields.labelDone')"
              interface="popover"
              [formControlName]="field.name"
              outline
            >
              <div *ngFor="let option of field.options">
                <ion-select-option [value]="option.id">{{
                  option.label
                }}</ion-select-option>
              </div>
            </ion-select>
          </ion-item>

          <ion-item *ngSwitchCase="'text'">
            <ion-label position="floating">{{
              field.label + (field.rules.required ? ' *' : '')
            }}</ion-label>
            <ion-input
              outline
              [formControlName]="field.name"
              [required]="field.rules.required"
              [maxlength]="field?.rules?.maxLength"
              [brmasker]="
                field.rules?.cpf ? { person: true } : { type: 'text' }
              "
              [type]="field.rules.cpf ? 'tel' : 'text'"
            >
            </ion-input>
          </ion-item>

          <ion-item *ngSwitchCase="'textarea'">
            <ion-label position="floating">{{
              field.label + (field.rules.required ? ' *' : '')
            }}</ion-label>
            <ion-textarea
              outline
              [formControlName]="field.name"
              [required]="field.rules.required"
              [maxlength]="field?.rules?.maxLength"
              [minlength]="field?.rules?.minLength"
            >
            </ion-textarea>
          </ion-item>

          <ion-item *ngSwitchDefault>
            <ion-label position="floating"
              >{{ field.label + (field.rules.required ? ' *' : '') }} mas n tem
              input</ion-label
            >
            <ion-input outline [formControlName]="field.name"></ion-input>
          </ion-item>

          <control-error
            *ngIf="!user || (user && field.name !== 'password')"
            [label]="field.label"
            [control]="form.get(field.name)"
          ></control-error>
        </ng-container>
      </ng-container>
      <a *ngIf="!user" class="terms-link" (click)="showUseTerms()">{{
        t('userForm.termsLink')
      }}</a>
      <ion-item *ngIf="!user" lines="none" class="ion-no-padding">
        <ion-label class="ion-text-wrap terms">
          {{ t('userForm.terms.part1') }} {{ t('userForm.terms.part2') }}
        </ion-label>
        <ion-checkbox
          class="ion-margin-end"
          slot="start"
          formControlName="terms"
        ></ion-checkbox>
      </ion-item>
    </ion-list>
    <ion-button
      class="btnsignup"
      [disabled]="isSubmitting"
      expand="block"
      type="submit"
      [validate]="form"
      (click)="formSubmit()"
    >
      {{ user ? t('userForm.save') : t('userForm.signup') }}
    </ion-button>
  </ng-container>
</form>

<!-- loader -->
<div class="spinner-wrapper" style="height: 100%" *ngIf="loading">
  <ion-spinner></ion-spinner>
</div>
<!-- end of loader -->
