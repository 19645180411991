import { Injectable, OnDestroy } from '@angular/core';
import { TemplateParser } from '@core/services';
import { MobletService } from '@core/services/moblet.service';
import {
  LoadMoblet,
  LoadMobletItem,
  MobletsState,
  RefreshMoblet,
} from '@core/state/moblets';
import { Back } from '@core/state/navigation/navigation.actions';
import { ToastController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { isEqual } from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export abstract class BaseMobletService<T> implements OnDestroy {
  protected _mobletId: number;
  protected _itemId: number | string;
  protected _moblet: BehaviorSubject<T> = new BehaviorSubject<T>(null);
  protected _item: BehaviorSubject<T> = new BehaviorSubject<T>(null);
  protected _mobletSubscription: Subscription;
  protected _mobletItemSubscription: Subscription;
  protected newMobletValue: T;
  protected page = 1;
  protected version = 3;
  protected extraParams = {};
  protected refreshing = false;
  protected infiniteScrollRef;
  public templateParser: TemplateParser;
  protected fabAppVersion = 3;

  constructor(
    protected mobletService: MobletService,
    protected store: Store,
    protected toastController: ToastController,
  ) {
    this.templateParser = new TemplateParser(store);
  }

  ngOnDestroy(): void {
    if (this._mobletSubscription) {
      this._mobletSubscription.unsubscribe();
    }
    if (this._mobletItemSubscription) {
      this._mobletItemSubscription.unsubscribe();
    }
  }

  get moblet(): Observable<any> {
    return this._moblet.asObservable().pipe(filter((data: T) => !!data));
  }

  get item(): Observable<any> {
    return this._item.asObservable().pipe(filter((data: T) => !!data));
  }

  async infiniteScroll(scrollEvent: any): Promise<any> {
    this.page += 1;
    this.infiniteScrollRef = scrollEvent;

    const currentMoblet: any = this._moblet.value;

    if (currentMoblet.isLastPage) {
      scrollEvent.target.disabled = true;
      return scrollEvent.target.complete();
    }

    await this.loadMoblet(this._mobletId);
    const newMoblet: any = this._moblet.value;

    if (
      currentMoblet.items.length === newMoblet.items.length ||
      newMoblet.isLastPage
    ) {
      scrollEvent.target.disabled = true;
    }

    scrollEvent.target.complete();
  }

  async doRefresh(event: any): Promise<void> {
    this.page = 1;
    this.refreshing = true;
    await this.store
      .dispatch(
        new RefreshMoblet(
          this._mobletId,
          this.page,
          this.version,
          this.extraParams,
          this.fabAppVersion,
        ),
      )
      .toPromise();
    event.target.complete();

    if (this.infiniteScrollRef) {
      this.infiniteScrollRef.target.disabled = false;
    }
  }

  async loadMoblet(id: number): Promise<void> {
    this._mobletId = id;

    await this.store
      .dispatch(
        new LoadMoblet(
          id,
          this.page,
          this.version,
          this.extraParams,
          this.fabAppVersion,
        ),
      )
      .toPromise();

    if (!this._mobletSubscription) {
      this.setMobletSubscription();
    }
  }

  async loadMobletItem(id: number, itemId: number | string): Promise<void> {
    this._mobletId = id;
    this._itemId = itemId;
    await this.store
      .dispatch(new LoadMobletItem(this._mobletId, this._itemId?.toString()))
      .toPromise();

    if (!this._mobletItemSubscription) {
      this.setMobletItemSubscription();
    }
  }

  protected setMobletItemSubscription(): void {
    this._mobletItemSubscription = this.store
      .select(
        MobletsState.getMobletItem(this._mobletId, this._itemId?.toString()),
      )
      .subscribe((mobletItem: T) => {
        if (!mobletItem) {
          return this.back();
        }
        this._item.next(mobletItem);
      });
  }

  protected setMobletSubscription(): void {
    this._mobletSubscription = this.store
      .select(MobletsState.getMoblet(this._mobletId))
      .subscribe((moblet: T) => {
        const currentMoblet: T = this._moblet.value;

        if (!currentMoblet || this.page > 1 || this.refreshing) {
          this._moblet.next(moblet);
          this.refreshing = false;
          return;
        }

        if (!isEqual(currentMoblet, moblet) && !window['preview']) {
          this.newMobletValue = moblet;
          return this.showUpdateToast();
        } else {
          this._moblet.next(moblet);
          this.newMobletValue = null;
        }
      });
  }

  protected async showUpdateToast(): Promise<void> {
    const toast: HTMLIonToastElement = await this.toastController.create({
      message: 'Novo conteúdo encontrado',
      buttons: [
        {
          text: 'Atualizar',
          handler: () => {
            this._moblet.next(this.newMobletValue);
            this.newMobletValue = null;
          },
        },
      ],
    });

    toast.present();
  }

  /**
   * retorna lista de items sem o item dentro de cada indice
   */
  protected flatMoblet(items: any[]): any[] {
    return items
      .map((item: any) => item['item'])
      .filter((item: any) => item.id);
  }

  /**
   * retorna para a listagem do moblet
   */
  async back(): Promise<any> {
    await this.store.dispatch(new Back(this._mobletId)).toPromise();
  }
}
