import {
  ChangeDetectorRef,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { NavigationEnd, RouteConfigLoadEnd, Router } from '@angular/router';
import { CoreState } from '@core/state/core/core.state';
import { Store } from '@ngxs/store';
import { combineLatest, Subscription } from 'rxjs';

@Directive({ selector: '[isHomePage]' })
export class IsHomePageDirective {
  private show: string;
  private sub: Subscription;

  @Input() set isHomePage(show: 'back' | 'menu' | 'none') {
    this.show = show;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store,
    public router: Router,
    private cdr: ChangeDetectorRef,
  ) {
    if (this.show !== 'none') {
      this.sub = combineLatest([
        this.store.select(CoreState.getHomeRoute),
        this.router.events,
      ]).subscribe((value: any) => {
        const homeRoute: string = value[0].join('');
        const routeEvent: Event = value[1];

        // data-root
        if (history.state.isRoot) {
          this.run(true);
          this.sub.unsubscribe();
        }

        if (routeEvent instanceof NavigationEnd) {
          const url: string = routeEvent.urlAfterRedirects.includes('/home')
            ? '/'
            : routeEvent.url;
          this.run(url === homeRoute);
          this.sub.unsubscribe();
        }

        if (routeEvent instanceof RouteConfigLoadEnd) {
          this.run(true);
          this.sub.unsubscribe();
        }
      });
    }
  }

  run(isHome: boolean): void {
    if (!isHome && this.show === 'back') {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.show === 'none' || (this.show === 'menu' && isHome)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.show === 'back' && isHome) {
      this.viewContainer.clear();
    }
    this.cdr.detectChanges();
  }
}
