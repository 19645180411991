import { Address } from '../../models/address/address.model';

export class LoadAddresses {
  static readonly type = '[address] Load Addresses';
}

export class RegisterAddress {
  static readonly type = '[address] Register Address';
  constructor(public address: Address) {}
}

export class UpdateAddress {
  static readonly type = '[address] Update Address';
  constructor(public address: Address) {}
}

export class ClearAddress {
  static readonly type = '[address] Clear Address';
  constructor() {}
}

export class DeleteAddress {
  static readonly type = '[address] Delete Address';
  constructor(public address: Address) {}
}

export class ChangeFavoriteAddress {
  static readonly type = '[address] Change Favorite Address';
  constructor(public newFavoriteAddress: Address) {}
}

export class SetCurrentAddress {
  static readonly type = '[address] Set Current Address';
  constructor(public address: Address) {}
}

export class DeleteCurrentAddress {
  static readonly type = '[address] Delete Current Address';
}

export class SetEditableAddress {
  static readonly type = '[address] Set Editable Address';
  constructor(public editable: boolean, public id: string) {}
}

export class CloseAllModals { 
  static readonly type = '[address] Close all address modal';
  constructor(public editable: boolean, public id: string) {}
}