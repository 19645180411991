<div class="main">
  <div class="header">
    <button class="button-close" (click)="closeModal()">
      <ion-icon class="icon" name="close-outline"></ion-icon>
    </button>
    <span>{{ currentDate }}</span>
  </div>

  <div class="logo-pix">
    <img class="image" src="assets/icons/ilustrate_pix.svg" />
  </div>
  <div class="labels">
    <h1 class="title">Seu pedido está quase pronto!</h1>
    <p class="subtitle">
      Copie a chave para pagar via PIX no seu aplicativo bancário. Após realizar
      o pagamento do pedido, confirme que o mesmo foi pago no botão a seguir.
    </p>
  </div>

  <div class="input-pix">
    <div class="input">
      <span class="pix-key" *ngIf="pixKey">{{ pixKey }}</span>
      <div class="button-pix">
        <button class="button" (click)="copyToClipboard(pixKey)">
          <ion-icon class="icon" color="primary" name="link-outline"></ion-icon>
        </button>
      </div>
    </div>
  </div>

  <div>
    <p class="description">
      <strong>Atenção!</strong> O lojista irá confirmar o pagamento. Aguarde a
      confirmação do pedido.
    </p>
  </div>

  <div class="button-confirm">
    <ion-button class="btn-full" (click)="closeModal()"
      >Confirmar pagamento</ion-button
    >
  </div>
</div>
