import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
  BootstrapModuleFn as Bootstrap,
  hmr,
  WebpackModule,
} from '@ngxs/hmr-plugin';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import '@angular/compiler';

if (environment.production) {
  enableProdMode();
}

const bootstrap: Bootstrap = () =>
  platformBrowserDynamic().bootstrapModule(AppModule);

defineCustomElements(window);

if (environment.hmr) {
  hmr(module, bootstrap).catch((err: any) => console.error(err));
} else {
  bootstrap().catch((err: any) => console.log(err));
}
