import { Injectable } from '@angular/core';
import { Catalog } from '@core/models/moblets';
import { MobletService } from '@core/services';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import {
  LoadCatalogAndConcat,
  RefreshCatalog,
  ResetCatalog,
} from './catalog.action';

@State({
  name: 'mobletsCatalog',
})
@Injectable()
export class CatalogsState {
  constructor(private mobletService: MobletService) {}

  static getCatalog(categoryId: string | number = 0): (state: any) => Catalog {
    return createSelector([CatalogsState], (state: any) => state[categoryId]);
  }

  @Action(LoadCatalogAndConcat)
  async loadCatalog(
    ctx: StateContext<any>,
    {
      id,
      categoryId,
      page,
      version,
      extraParams,
      fabAppVersion,
    }: LoadCatalogAndConcat,
  ): Promise<any> {
    extraParams = { categoryId };
    fabAppVersion = 3;

    const state: any = ctx.getState();
    let moblet: Catalog = state[categoryId];

    const mobletFromApi: Catalog = await this.mobletService
      .loadMoblet(id, page, version, extraParams, fabAppVersion)
      .toPromise();

    if (moblet) {
      let items = [...moblet.items, ...mobletFromApi.items];

      if (
        items.length === mobletFromApi.total ||
        moblet.items.length === items.length
      ) {
        mobletFromApi.isLastPage = true;
      }

      moblet = {
        ...moblet,
        ...mobletFromApi,
        items,
      };
    }

    ctx.patchState({ [categoryId]: moblet });
  }

  @Action(RefreshCatalog)
  async refreshCatalog(
    ctx: StateContext<any>,
    { id, categoryId, page, version, fabAppVersion }: RefreshCatalog,
  ): Promise<void> {
    const extraParams = { categoryId };
    fabAppVersion = 3;
    const state: any = ctx.getState();

    const moblet: any = await this.mobletService
      .loadMoblet(id, page, version, extraParams, fabAppVersion)
      .toPromise();

    ctx.setState({
      ...state,
      [categoryId]: moblet,
    });
  }

  @Action(ResetCatalog)
  async resetCatalog(ctx: StateContext<any>): Promise<void> {
    ctx.setState({});
  }
}
