<ion-header>
  <fabapp-header *ngIf="page$ | async as page">
    <ion-title>{{ page.name }}</ion-title>
    <ion-buttons *ngIf="partialAppDef$ | async as partialAppDef" slot="end">
      <ion-button
        *ngIf="page.search === '1'"
        [style.--color]="'var(--ion-color-headercolor-contrast)'"
        (click)="openSearchModal()"
      >
        <ion-icon slot="icon-only" name="search"></ion-icon>
      </ion-button>
    </ion-buttons>
  </fabapp-header>
</ion-header>

<ion-content *ngIf="(moblet$ | obsLoading | async) as moblet">
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <!-- 
    wrapper: contém o estilo do backround porque se colocar no ion-content prejudica a animação da pagina no IOS 
  -->

  <fabapp-ad-carousel
    *ngIf="layoutHome?.layout_type == homeLayoutEnum.PAGE_HOME && isHome "
  >
  </fabapp-ad-carousel>

  <div *emptyState="moblet" class="wrapper">
    <div class="content-wrapper" *ngIf="moblet?.items?.length > 0">
      <fabapp-group-grid-layout
        [class.ion-hide]="layout?.layoutType !== layoutType.GRID"
        (navigate)="navigate($event)"
        [group]="moblet.items"
        [layout]="layout"
      ></fabapp-group-grid-layout>

      <fabapp-group-list-layout
        [class.ion-hide]="layout?.layoutType !== layoutType.LIST"
        (navigate)="navigate($event)"
        [group]="moblet.items"
        [layout]="layout"
      ></fabapp-group-list-layout>

      <fabapp-group-mixed-layout
        [class.ion-hide]="layout?.layoutType !== layoutType.MIXED"
        (navigate)="navigate($event)"
        [group]="moblet.items"
        [layout]="layout"
      ></fabapp-group-mixed-layout>
    </div>
  </div>
</ion-content>
<fabapp-banner-footer></fabapp-banner-footer>
