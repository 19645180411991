export function findObjectKeyRecursive(object: object, keys: any[]): any {
  const item: any = object[keys[0]];
  const newKeys: any[] = keys.filter((key: string) => keys[0] !== key);

  if (typeof item === 'object') {
    return findObjectKeyRecursive(item, newKeys);
  }

  return item;
}
