import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BannerFooterComponent } from './banner-footer.component';

@NgModule({
  declarations: [BannerFooterComponent],
  imports: [CommonModule, IonicModule],
  exports: [BannerFooterComponent],
})
export class BannerFooterModule {}
