import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CoreState } from '@core/state/core/core.state';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

/*
 *
 * Esse guard é uma gambiarrazinha só pra não piscar a tela
 * e também não ter que alterar o funcionamento do ion-tabs
 * para o fabapp-delivery
 *
 */
@Injectable({
  providedIn: 'root',
})
export class WrongHomeGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    const homeRoute: (string | number)[] = this.store.selectSnapshot(
      CoreState.getHomeRoute,
    );

    if (homeRoute.length > 1) {
      this.router.navigate(homeRoute);
      return false;
    }

    return true;
  }
}
