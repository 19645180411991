<!-- lista de endereços -->
<ng-container *ngIf="addresses$ | async as addresses; else loader">
  <ng-container *ngIf="addresses.length > 0; else empty">
    <ion-list>
      <ion-item
        (click)="changeFavoriteAddress(item)"
        button
        [detail]="false"
        class="address-item"
        *ngFor="let item of addresses; trackBy: trackById"
        [class.-activated]="item.favorite"
      >
        <ion-avatar slot="start">
          <img src="/assets/entrega-icon.svg" />
        </ion-avatar>
        <ion-label>
          <h3 class="street" *ngIf="item.number !== withoutNumber">
            {{ item.address }}, {{ item.number }} {{ item?.complement }}
          </h3>
          <h3 class="street" *ngIf="item.number === withoutNumber">
            {{ item.full_address }}
          </h3>
          <h4 class="country">{{ item.city }} - {{ item.state }}</h4>
        </ion-label>
        <span class="icon-wrapper" slot="end">
          <ion-icon
            *ngIf="item.favorite"
            name="checkmark-circle"
            class="-success"
          ></ion-icon>
        </span>
        <ion-button
          (click)="addressAction(item); $event.stopPropagation()"
          fill="clear"
          color="dark"
          slot="end"
        >
          <ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
        </ion-button>
      </ion-item>
    </ion-list>
  </ng-container>
  <ng-template #empty>
    <app-empty-state></app-empty-state>
  </ng-template>
</ng-container>

<ng-template #loader>
  <span
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    "
  >
    <ion-spinner></ion-spinner>
  </span>
</ng-template>
