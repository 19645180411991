import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TagRatingService } from '@fabapp-delivery/services/tag-rating.service';

interface feedbackOptionInterface {
  label: string;
  code: number;
}
@Component({
  selector: 'app-evaluate-delivery',
  templateUrl: './evaluate-delivery.component.html',
  styleUrls: ['./evaluate-delivery.component.scss'],
})
export class EvaluateDeliveryComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() styleType: string = 'basic';
  @Input() disabled: boolean = false;
  @Output() rate: EventEmitter<any> = new EventEmitter();
  @Input() initialRatingInStars: number = 0;
  @Input() feedbackOptions: feedbackOptionInterface[];
  @Input() haveFeedback: boolean = false;
  @Output() feedbackDeliveryOptionsUpdate: EventEmitter<any> =
    new EventEmitter();
  @Input() comment: string = '';

  @Output() updateComment: EventEmitter<any> = new EventEmitter();
  @Output() updateDeliverySatisfaction: EventEmitter<any> = new EventEmitter();
  deliverySatisfaction: boolean = null;

  tagsDelivery: any;
  feedbackOrderOptions = [];

  constructor(
    private tagRatingService: TagRatingService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.getTagsRating();
  }

  async getTagsRating() {
    this.tagsDelivery = await this.tagRatingService
      .getTagDelivery()
      .toPromise();

    this.cd.detectChanges();
    this.cd.markForCheck();
  }

  alterDeliverySatisfaction(option) {
    this.deliverySatisfaction = option;
    this.updateDeliverySatisfaction.emit(option);
  }

  selectOptionFeedbackOrder(id, index) {
    if (this.feedbackOrderOptions.find((tag) => tag == id)) {
      this.feedbackOrderOptions = this.feedbackOrderOptions.filter(
        (tags) => tags != id,
      );

      this.tagsDelivery[index]['selected'] = false;

      this.feedbackDeliveryOptionsUpdate.emit(this.feedbackOrderOptions);
      return;
    }

    this.feedbackOrderOptions.push(id);
    this.tagsDelivery[index]['selected'] = true;
    this.feedbackDeliveryOptionsUpdate.emit(this.feedbackOrderOptions);
  }

  inputChanged(event: any): void {
    const comment: string = event.detail.value;
    this.comment = comment;
    this.updateComment.emit(comment);
  }
}
