import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { FDI_OrderObject } from '@fabapp-delivery/models/cart/cart.model';
import { HttpService } from '../../../core/http/http.service';

@Injectable({
  providedIn: 'root',
})
export class RatingService extends HttpService<any> {
  protected url = `${environment.shop.url}/app/ratings`;

  registerRating(body: object) {
    return this.request('POST', '', body);
  }

  getTagDelivery(body: object) {
    return this.request('POST', '', body);
  }

  getRatings(currentPage = 1) {
    return this.request('GET', `/?page=${currentPage}`);
  }
}
