import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-image-detail',
  templateUrl: './image-detail.component.html',
  styleUrls: ['./image-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageDetailComponent implements OnInit {
  @ViewChild('slider') slider: IonSlides;

  public showOverlay = true;
  showSlides = false;
  @Input() set item(images: any) {
    if (!(images instanceof Array)) {
      this.images = [images];
    }
  }
  @Input() index: number;
  @Input() images: string[];

  constructor(
    public modalController: ModalController,
    public cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.showSlides = true;
      this.cdr.detectChanges();
    }, 100);
  }

  toggleOverlay(): void {
    this.showOverlay = !this.showOverlay;
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }
}
