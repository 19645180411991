import { Page } from '@core/models';

export function flatten(pages: Page[]): any[] {
  const flat: Page[] = [];

  pages.forEach((page: Page) => {
    flat.push(page);
    if (Array.isArray(page.entries) && page.entries.length > 0) {
      flat.push(...flatten(page.entries));
    }
  });

  return flat;
}
