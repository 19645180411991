import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'fabapp-clock-icon',
  templateUrl: 'clock-icon.component.html',
  styleUrls: ['clock-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClockIconComponent implements OnInit {
  @Input() color: string;
  constructor() {}

  ngOnInit(): void {
    document.documentElement.style.setProperty(
      '--clock-icon-color',
      this.color,
    );
  }
}
