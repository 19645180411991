import {
  HttpErrorResponse,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { User } from '@core/models';
import { AuthState, Logout, SetAuthToken } from '@core/state/auth';
import { ReturnToHomePage } from '@core/state/navigation/navigation.actions';
import { Store } from '@ngxs/store';
import { BehaviorSubject, throwError } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  finalize,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AuthService } from 'src/app/pages/auth/services/auth.service';

export class RefreshTokenService {
  delay: number = 600;
  isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null,
  );
  constructor(public store: Store, private authService: AuthService) {}

  handleResponseError(
    error: HttpErrorResponse,
    req: HttpRequest<any>,
    next: HttpHandler,
  ) {
    const user: User = this.store.selectSnapshot(AuthState.getUser);
    if (error.status !== 401 || !user || req.url.includes('auth/refresh')) {
      return throwError(error);
    }

    if (this.isRefreshing) {
      return this._resolveAfterRefresh(next, req);
    }

    this.isRefreshing = true;
    this.refreshTokenSubject.next(null);
    return this._doRefresh(next, req, error);
  }

  private _resolveAfterRefresh(next: HttpHandler, req: HttpRequest<any>) {
    return this.refreshTokenSubject.pipe(
      filter((result) => result !== null),
      switchMap(() => next.handle(this._addAuthenticationToken(req))),
    );
  }

  private _doRefresh(
    next: HttpHandler,
    req: HttpRequest<any>,
    error: HttpErrorResponse,
  ) {
    const refreshToken = this.store.selectSnapshot(AuthState.refreshToken);

    return this.authService
      .refreshToken({
        refresh_token: refreshToken,
      })
      .pipe(
        tap((payload) => this.store.dispatch(new SetAuthToken(payload))),
        delay(this.delay),
        switchMap((success) => this._dispatchRequests(success, next, req)),
        finalize(() => (this.isRefreshing = false)),
        catchError(() => {
          this.store.dispatch([new Logout(), new ReturnToHomePage()]);
          return throwError(error);
        }),
      );
  }

  private _dispatchRequests(
    success: any,
    next: HttpHandler,
    req: HttpRequest<any>,
  ) {
    this.refreshTokenSubject.next(!!success);
    return next.handle(this._addAuthenticationToken(req));
  }

  private _addAuthenticationToken(req: HttpRequest<any>): HttpRequest<any> {
    const token = this.store.selectSnapshot(AuthState.accessToken);
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
