<ng-container *ngIf="!isLoading">
  <div
    class="image-wrapper"
    *ngIf="pinSelected?.listItemImages?.length > 0"
    (click)="seeDetail()"
  >
    <div
      [ngStyle]="{
        'background-image':
          'url(' + pinSelected?.listItemImages[0].imageUrl + ')'
      }"
      class="image"
    ></div>
  </div>
  <!-- content area -->
  <span class="title">
    <span>{{ pinSelected?.title }}</span>
    <app-rating-of-item
      *ngIf="listDef?.ratings"
      (rate)="seeDetail(true)"
      [average]="pinSelected?.ratingsavg"
      [ratingsTotal]="pinSelected?.ratingstotal"
      [withSeparator]="pinSelected?.distance"
    ></app-rating-of-item>
  </span>
  <span class="resume">{{ pinSelected?.resume }}</span>
  <!-- end of content area -->
</ng-container>
<ng-container *ngIf="isLoading">
  <div class="loading-wrapper">
    <ion-spinner></ion-spinner>
  </div>
</ng-container>
