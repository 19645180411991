import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { NavigationParameters, Page } from '@core/models';
import { AppDefState } from '@core/state/appdef';
import { CoreState } from '@core/state/core/core.state';
import { MobletsState } from '@core/state/moblets';
import { NativeLog } from '@core/state/react-native/react-native.actions';
import { NavController, Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { getRouteNameBySuperClass } from '@utils';
import { AnalyticsService } from './analytics.service';
import { InAppBrowserService } from './in-app-browser.service';
import { MobletService } from './moblet.service';
const { Toast } = Plugins;

type RouteArray = (number | string)[];

@Injectable({ providedIn: 'root' })
export class NavigationService {
  _url: string;
  _lastNavigation = { instanceId: 0, itemId: 0 };
  constructor(
    private navCtrl: NavController,
    private store: Store,
    private platform: Platform,
    protected mobletService: MobletService,
    private inAppBrowser: InAppBrowserService,
    private analyticsService: AnalyticsService,
  ) {}

  navigate({
    instanceId,
    itemId,
    params = [],
    queryParams,
  }: NavigationParameters): void {
    try {
      this.preventDblClick({ instanceId, itemId });

      const routeArray: RouteArray = this.generateRouteArray({
        instanceId,
        itemId,
        params,
      });

      this.registerPageAccess({ instanceId, itemId });
      this.store.dispatch(new NativeLog(routeArray));

      if (
        this.inAppBrowser.mustOpenBrowser ||
        this.inAppBrowser.isTawkToInWeb
      ) {
        this.inAppBrowser.getMobletInfo(instanceId);
        return;
      }

      this.navCtrl.navigateForward(routeArray, {
        queryParams,
      });
    } catch (err) {
      console.error('navigation error', err);
    }
  }

  preventDblClick(nav: { instanceId; itemId }): void {
    if (
      this._lastNavigation.instanceId === nav.instanceId &&
      this._lastNavigation.itemId === nav.itemId
    ) {
      throw new Error('Double navigation identify');
    }
    this._lastNavigation = nav;
    const timeout: NodeJS.Timeout = setTimeout(() => {
      this._lastNavigation = { instanceId: 0, itemId: 0 };
      clearTimeout(timeout);
    }, 1200);
  }

  back({ instanceId, itemId }: NavigationParameters): void {
    try {
      const routeArray: RouteArray = this.generateRouteArray({
        instanceId,
        itemId,
      });

      this.navCtrl.navigateBack(routeArray);
    } catch (err) {
      console.error('navigation back error', err);
    }
  }

  generateRouteArray({
    instanceId,
    itemId,
    params,
  }: NavigationParameters): (number | string)[] {
    const superClass: string = this.getPageSuperClass(instanceId);
    this.inAppBrowser.superClass = superClass;

    if (!superClass) {
      console.log(instanceId, superClass);
      throw new Error('no superclass');
    }

    const routeName: string = getRouteNameBySuperClass(superClass);

    if (!routeName) {
      Toast.show({
        text:
          superClass === 'lists'
            ? 'Essa lista precisa ser migrada'
            : `Moblet ${superClass} em construção`,
        duration: 'long',
        position: 'bottom',
      });
      throw new Error('moblet unavailable');
    }

    const routeArray: (string | number)[] = [routeName, instanceId];

    if (itemId) {
      routeArray.push(itemId);
    }

    if (params) {
      routeArray.push(...params);
    }

    return routeArray;
  }

  returnToHomePage(): void {
    const homeRoute: any[] = this.store.selectSnapshot(CoreState.getHomeRoute);
    this.navCtrl.navigateRoot(homeRoute);
  }

  getHomeRoute(): (string | number)[] {
    return this.store.selectSnapshot(CoreState.getHomeRoute);
  }

  handleLinkClick(event: any): void | Window {
    // data-moblet
    if (event.target.dataset.moblet) {
      const [instanceId, itemId, ...params] = event.target.dataset.moblet.split(
        '/',
      );
      if ('preventDefault' in event) {
        event.preventDefault();
      }

      return this.navigate({ instanceId, itemId, params });
    }

    // data-root
    if (event.target.dataset.root) {
      const [instanceId, itemId, ...params] = event.target.dataset.root.split(
        '/',
      );
      if ('preventDefault' in event) {
        event.preventDefault();
      }

      return this.navigateRoot({ instanceId, itemId, params });
    }

    if (event.target && event.target.href) {
      const target: string = this.platform.is('capacitor')
        ? '_system'
        : '_blank';
      if ('preventDefault' in event) {
        event.preventDefault();
      }

      if (event.target.href.indexOf(window.location.host) === -1) {
        return InAppBrowserService.windowOpen(
          event.target.href,
          target,
          'location=yes',
        );
      }
    }
  }

  navigateRoot({ instanceId, itemId, params }: NavigationParameters): void {
    try {
      this.preventDblClick({ instanceId, itemId });

      const routeArray: RouteArray = this.generateRouteArray({
        instanceId,
        itemId,
        params,
      });

      this.registerPageAccess({ instanceId, itemId });

      this.navCtrl.navigateRoot(routeArray, { state: { isRoot: true } });
    } catch (err) {
      console.error('navigation error', err);
    }
  }

  /**
   * @description
   * método utlizado pela Loft Portarias
   * @returns void
   */
  internalNavigation(path: string, queryParams?: Record<string, string>): void {
    if (!path) {
      return;
    }

    console.log(path);

    const [instanceId, itemId] = path.split('/');
    return this.navigate({
      instanceId: +instanceId,
      itemId,
      queryParams,
    });
  }

  getPageSuperClass(instanceId: number): string {
    const page: Page = this.store.selectSnapshot(
      AppDefState.getPage(instanceId),
    );

    return page ? page.moblet_name : null;
  }

  private registerPageAccess(nav: { instanceId; itemId }): void {
    const page: Page = this.store.selectSnapshot(
      AppDefState.getPage(nav.instanceId),
    );

    if (nav.itemId) {
      const mobletItem = this.store.selectSnapshot(
        MobletsState.getMobletItem(nav.instanceId, nav.itemId?.toString()),
      );

      if (mobletItem) {
        this.analyticsService.triggerMobletNavigation(
          mobletItem.title,
          nav.itemId,
        );
      }
    } else {
      if (page) {
        this.analyticsService.triggerMobletNavigation(
          page.name,
          nav.instanceId,
        );
      }
    }
  }
}
