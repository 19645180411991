import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { File } from '@ionic-native/file/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicImageLoaderComponent } from './ionic-image-loader.component';
import { ImageLoaderConfigService } from './services/image-loader-config.service';
import { ImageLoaderService } from './services/image-loader.service';

@NgModule({
  imports: [IonicModule, CommonModule],
  declarations: [IonicImageLoaderComponent],
  exports: [IonicImageLoaderComponent],
  providers: [File, ImageLoaderConfigService, ImageLoaderService],
})
export class IonicImageLoaderModule {}
