<ion-item
  detail="false"
  (click)="clicked.emit(true)"
  button
  class="item-container"
>
  <ion-avatar *ngIf="item?.user?.image_url" class="avatar">
    <img-loader [src]="item.user.image_url"></img-loader>
  </ion-avatar>
  <ion-label class="item-content">
    <div class="headline">{{ item?.user?.name }}</div>
    <div class="subheadline">@{{ item?.user?.screen_name }}</div>
    <span class="dot">·</span>
    <span class="date">{{ item?.date | relativeTime }}</span>
    <div class="desc">
      {{ item?.description }}
    </div>
  </ion-label>
</ion-item>
