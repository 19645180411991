import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

interface feedbackOptionInterface {
  label: string;
  code: number;
}

@Component({
  selector: 'app-make-rating',
  templateUrl: './make-rating.component.html',
  styleUrls: ['./make-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MakeRatingComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() styleType: string = 'basic';
  @Input() disabled: boolean = false;
  @Output() rate: EventEmitter<any> = new EventEmitter();
  @Input() initialRatingInStars: number = 0;
  @Input() feedbackOptions: feedbackOptionInterface[];
  @Input() haveFeedback: boolean = false;
  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.initialRatingInStars?.currentValue) {
      this.initialRatingInStars = changes.initialRatingInStars.currentValue;
    }
    this.cd.markForCheck();
  }

  toRate(stars: number): void {
    this.initialRatingInStars = stars;
    this.cd.detectChanges();
    this.rate.emit(stars);
  }
}
