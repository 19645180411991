import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AdRedirectTypeEnum } from '@core/enums/fabapp-ad/ad-redirect-type.enum';
import { FabAppAdTypeEnum } from '@core/enums/fabapp-ad/fab-app-ad-type.enum';
import { FAB_Ad } from '@core/models/fabapp-ads/ad.model';
import { InAppBrowserService, NavigationService } from '@core/services';
import { AdmobAdsService } from '@core/services/ads';
import {
  FabappAdsState,
  RemoveListenersOfAds,
  SetAdsAsClicked,
} from '@core/state/ads';
import { AppDefState } from '@core/state/appdef';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { BannerAndModalOfDivulgation } from './interfaces/banner.model';

@Component({
  selector: 'fabapp-banner-footer',
  templateUrl: './banner-footer.component.html',
  styleUrls: ['./banner-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerFooterComponent implements OnInit, OnDestroy {
  @HostBinding('style.max-height') maxHeight = '3.5rem';

  public showBanner: boolean = false;
  public grumftBannerScript: string;
  public grumftBannerScrip = '';
  public grumftBannerScrips = {
    2252519: 'https://api.grumft.com/gt/ZONA_IAB_320x50_1/c4186ef426733dda9a94e17c60e25b2a.js',
    2564778: 'https://api.grumft.com/gt/ZONA_IAB_320x50_1/6bed9b7f7d11fe866782e944a92248c3.js',
    2599569: 'https://api.grumft.com/gt/ZONA_IAB_320x50_1/0366c74fd1a4c12279a9a9ac93bb155e.js',
    default:
      'https://api.grumft.com/gt/ZONA_IAB_320x50_1/f7bc943e087dd2809ee6d89f1c849256.js',
  };
  bannerSubscription: Subscription;
  public bannerData$: Observable<BannerAndModalOfDivulgation>;

  constructor(
    public adsService: AdmobAdsService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private navigationService: NavigationService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.loadBanner();
    this.loadGrumftBanner();
    this.createSubscriptionOfAdmobBanner();
  }

  async ngOnDestroy(): Promise<void> {
    this.store.dispatch(new RemoveListenersOfAds()).toPromise();
    if (this.bannerSubscription) {
      this.bannerSubscription.unsubscribe();
    }
  }

  /**
   * @description
   * se o banner do admob estiver sendo mostrado
   * será emitido uma que ele está sendo mostrado
   *
   * @returns void
   */
  public createSubscriptionOfAdmobBanner(): void {
    this.bannerSubscription = this.store
      .select(FabappAdsState.isShowingBanner)
      .subscribe((banner: boolean) => {
        this.showBanner = banner;
        this.cdr.detectChanges();
      });
  }

  public loadBanner(): void {
    this.bannerData$ = this.store.select(FabappAdsState.banner);
  }

  public loadGrumftBanner(): void {
    const { default_status } = this.store.selectSnapshot(
      AppDefState.getFromAppDef(['default_status']),
    );

    let scriptFromGrumft = this.grumftBannerScrips[window['appId']];
    if (default_status) {
      scriptFromGrumft = this.grumftBannerScrips['default'];
    }

    if (!!scriptFromGrumft) {
      const container = document.getElementById('ads-container');
      const scriptTag: HTMLScriptElement = document.createElement('script');
      scriptTag.id = 'grumft';
      scriptTag.src = scriptFromGrumft;
      container.style.width = '320px';
      container.style.margin = '0 auto';
      container.style.padding = '5px 0';
      this.maxHeight = '4rem';
      container.appendChild(scriptTag);
    }
  }

  public async openLink(ad: FAB_Ad): Promise<void> {
    this.store.dispatch(new SetAdsAsClicked(FabAppAdTypeEnum.BANNER));

    if (ad.adRedirectId == AdRedirectTypeEnum.NOT_DIRECT) {
      return;
    }

    if (ad.adRedirectId == AdRedirectTypeEnum.INTERNAL_LINK) {
      this.navigationService.internalNavigation(ad.link);
      return;
    }

    if (ad.link && typeof ad.link !== 'number') {
      InAppBrowserService.windowOpen(ad.link, '_blank');
    }
  }
}
