<div
  class="card"
  *ngFor="let coupon of coupons; trackBy: trackByIndex"
  [class.-small-padding]="enableButton"
>
  <div class="card-header">
    <div class="icon-box">
      <ion-icon class="couponicon -coupon"></ion-icon>
    </div>
    <div class="couponcode">{{ coupon.code }}</div>
  </div>
  <div *ngIf="coupon?.description" class="card-content">
    {{ coupon?.description }}
  </div>
  <div class="card-footer" *ngIf="!enableButton">
    <strong
      >Válido de {{ coupon.startDate | date: 'dd/MM' }} até
      {{ coupon.endDate | date: 'dd/MM' }}
    </strong>
  </div>
  <ng-container *ngIf="enableButton">
    <ion-button
      *ngIf="
        couponSelected?.id !== coupon.id && cartTotal >= coupon.minOrderAmount
      "
      fill="clear"
      [style.--color]="'var(--primarycolor-or-black)'"
      (click)="apply(coupon)"
    >
      Aplicar cupom
    </ion-button>
    <ion-button
      *ngIf="
        couponSelected?.id !== coupon.id && cartTotal < coupon.minOrderAmount
      "
      fill="clear"
      [disabled]="true"
      [style.--color]="'var(--primarycolor-or-black)'"
    >
      Condições mínimas não atingidas
    </ion-button>
    <ion-button
      *ngIf="couponSelected?.id === coupon.id"
      fill="clear"
      [style.--color]="'var(--primarycolor-or-black)'"
      (click)="remove()"
    >
      Remover cupom
    </ion-button>
  </ng-container>
</div>
