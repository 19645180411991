import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'meterToKilometer' })
export class MeterToKilometerPipe implements PipeTransform {
  transform(value: string | number): any {
    value = value.toString().replace('.', '');
    const meter: number = +value;
    if (meter >= 1000) {
      return +(meter / 1000).toFixed(2) + ' km';
    }

    return +meter.toFixed(2) + ' m';
  }
}
