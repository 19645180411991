import { environment } from '@environments/environment';
import { Socket } from 'ngx-socket-io';

// @Injectable()
export class SocketDeliveryService extends Socket {
  constructor(appId: string, storeId: string, orderId: string) {
    super({
      url: environment.shop.websocket,
      options: {
        query: {
          appId: appId,
          storeId: storeId,
          orderId: orderId,
        },
        reconnectionAttempts: 3,
      },
    });
  }
}
