import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { HadesService } from '@core/services/hades/hades.service';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { AuthState } from '../auth';
import { DoPing } from './hades.action';

@State({
  name: 'hades',
})
@Injectable()
export class HadesState implements NgxsOnInit {
  constructor(private hadesService: HadesService, private store: Store) {}

  ngxsOnInit(ctx?: StateContext<any>) {
    ctx.dispatch(new DoPing());

    Capacitor.Plugins.App.addListener('appStateChange', (state) => {
      if (state.isActive) {
        ctx.dispatch(new DoPing());
      }
    });
  }

  @Action(DoPing)
  doPing(): void {
    if (window['preview']) {
      return;
    }

    const userId: string =
      this.store.selectSnapshot(AuthState.getUser)?.id || null;

    this.hadesService.ping({
      appId: window['appId'],
      userId,
    });
  }
}
