<ion-progress-bar
  [style.--color]="'var(--primarycolor-or-black)'"
  *ngIf="isLoading"
  type="indeterminate"
></ion-progress-bar>
<!-- area da imagem e descrição do audio -->
<ng-content></ng-content>
<!-- area da imagem e descrição do audio -->

<div
  class="player-container"
  [class.withbanner]="hasBanner | async"
  *ngIf="!isReactNative"
>
  <audio
    #player
    class="ion-hide"
    (ended)="playNext()"
    [src]="item?.audio"
  ></audio>
  <!-- control bar -->

  <ion-range
    min="0"
    [max]="durationNumber"
    (ionChange)="seek()"
    [formControl]="range"
  ></ion-range>

  <div class="display-info">
    <span class="info">{{ currentTime }}</span>
    <span class="info">{{ duration }}</span>
  </div>

  <ion-buttons mode="md" class="ion-justify-content-center">
    <ion-button
      [disabled]="!showPrevious"
      (click)="onPrevious()"
      class="action-buttons"
    >
      <ion-icon name="play-skip-back-sharp" slot="icon-only"></ion-icon>
    </ion-button>

    <ion-button (click)="toogle()" class="action-buttons -play">
      <ion-icon
        [name]="isPlaying ? 'pause-sharp' : 'play-sharp'"
        slot="icon-only"
      ></ion-icon>
    </ion-button>

    <ion-button
      [disabled]="!showNext"
      (click)="onNext()"
      class="action-buttons"
    >
      <ion-icon name="play-skip-forward-sharp" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-buttons>
</div>
