<div class="detail">
  <p class="title">Resumo de pedido</p>

  <ng-container
    *ngIf="
      dataOrder.paymentMethod.id === paymentMethodEnum.PIX &&
      dataOrder.statusId === statusDeliveryEnum.PENDING
    "
  >
    <p class="subtitle">Copiar chave PIX</p>
    <div class="input-pix">
      <div class="input">
        <span class="pix-key" *ngIf="pixKey">{{ pixKey }}</span>
        <div class="button-pix">
          <button class="button" (click)="copyToClipboard(pixKey)">
            <ion-icon
              class="icon"
              color="primary"
              name="link-outline"
            ></ion-icon>
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="dataOrder?.address">
    <p class="subtitle">Endereço de entrega</p>
    <p class="address">
      {{ dataOrder.address.address }}, {{ dataOrder.address.number }}
    </p>
    <p class="neighborhood">{{ dataOrder.address.neighborhood }}</p>
    <p class="state">{{ dataOrder.address.state }}</p>
  </ng-container>
  <ng-container
    *ngIf="
      storeInfo?.address &&
      dataOrder.deliveryMethodId == deliveryMethodEnum.TAKE_AWAY
    "
  >
    <p class="subtitle">Endereço de retirada</p>
    <p class="address">
      {{ storeInfo.address.address }}, {{ storeInfo.address.number }}
    </p>
    <p class="neighborhood">{{ storeInfo.address.neighborhood }}</p>
    <p class="state">{{ storeInfo.address.state }}</p>
  </ng-container>
</div>

<div class="prices">
  <p class="subtitle">Pedido</p>

  <div class="box-order" *ngFor="let product of dataOrder.items">
    <div class="information">
      <div class="product">
        {{ product.quantity }}x {{ product.productName }}
      </div>
      <div class="price">{{ product.amount | currency: 'BRL' }}</div>
    </div>

    <div class="complement-wrapper" *ngIf="product.complements?.length > 0">
      <div
        class="complement-item"
        *ngFor="let complement of product.complements"
      >
        <div class="complement-number">{{ complement.quantity }}</div>
        <div class="complement-text">
          {{ complement.complementName }}
        </div>
      </div>
    </div>
  </div>

  <div class="payment">
    <div class="information">Subtotal</div>
    <div class="price">{{ dataOrder.subtotal | currency: 'BRL' }}</div>
  </div>

  <div class="payment" *ngIf="dataOrder.discount > 0">
    <div class="information">Desconto</div>
    <div class="price">{{ dataOrder.discount / 100 | currency: 'BRL' }}</div>
  </div>

  <div class="payment">
    <div class="information">Taxa de entrega</div>
    <div class="price">
      {{ dataOrder.shippingTax | currency: 'BRL' }}
    </div>
  </div>

  <div class="payment">
    <div class="information -bold">Total</div>
    <div class="price -bold">
      {{ dataOrder.amountFinal | currency: 'BRL' }}
    </div>
  </div>
</div>
<div class="formpayment">
  <div class="information">Forma de pagamento</div>
  <div class="form">
    {{ dataOrder.paymentMethod?.option?.title }} -
    {{ dataOrder.paymentMethod?.title }}
  </div>
</div>

<div class="contact">
  <ion-button
    class="button"
    [style.color]="'var(--primarycolor-or-black)'"
    shape="round"
    expand="full"
    target="_blank"
    (click)="goToWhatsapp()"
  >
    Pedir ajuda
  </ion-button>
</div>
