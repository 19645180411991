import { Injectable } from '@angular/core';
import { HttpService } from '@core/http/http.service';
import { MercurioPagination } from '@core/models/moblets/fabapp-delivery/mercurio-pagination.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { PaymentMethodForCreate } from '../models/payment-method-for-save.mode';
import { FDPaymentMethod } from '../models/payment-method.model';

@Injectable({
  providedIn: 'root',
})
export class FDPaymentMethodService extends HttpService<any> {
  protected url = `${environment.shop.url}/app/users/credit-card`;

  create(data: PaymentMethodForCreate) {
    return this.request('POST', '', data);
  }

  edit(id: string, nickname: string) {
    return this.request('PUT', `/${id}`, { nickname });
  }

  listPaymentMethods() {
    return this.request('GET', '') as Observable<
      MercurioPagination<FDPaymentMethod>
    >;
  }

  deletePaymentMethod(id: string) {
    return this.delete(id);
  }
}
