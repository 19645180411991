<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="modalCtrl.dismiss()">
        <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Falha no pagamento</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <h2 class="title">Falha ao completar o pedido</h2>
  <p class="description">
    Para completar seu pedido escolha outra forma de pagamento ou entre em
    contato com seu banco.
  </p>
  <fabi-payment-method-item
    *ngIf="paymentMethod$ | async as paymentMethod"
    [brandIcon]="paymentMethod?.card?.brand || paymentMethod?.method?.imagePath"
    [title]="paymentMethod?.card?.nickname || paymentMethod?.method.title"
    [code]="
      paymentMethod?.card?.lastDigits || (paymentMethod?.cashback | currency)
    "
    [censorCode]="!!paymentMethod.card"
  >
  </fabi-payment-method-item>
  <h4 class="subtitle">Outras formas depagamento disponíveis</h4>
  <ion-slides
    [options]="{
      slidesPerView: 'auto',
      spaceBetween: 8
    }"
    #slider
  >
    <ng-container *ngFor="let paymentMethodItem of fabappPayMethods">
      <ng-container
        *ngFor="let method of paymentMethodItem.methods; index as i"
      >
        <ion-slide *ngIf="method.id !== paymentMethodEnum.ONLINE_CREDIT_CARD">
          <fabi-payment-method-item
            button-type="squared"
            [title]="method.title"
            [brandIcon]="method.imagePath"
            (onClick)="savePaymentMethod(method)"
          >
          </fabi-payment-method-item>
        </ion-slide>
        <ng-container
          *ngIf="method.id === paymentMethodEnum.ONLINE_CREDIT_CARD"
        >
          <ng-container *ngFor="let card of method.userCreditCards; index as i">
            <ion-slide>
              <fabi-payment-method-item
                button-type="squared"
                [title]="card.nickname"
                [brandIcon]="card.brand"
                [code]="card.lastDigits"
                (onClick)="savePaymentMethod(method, card)"
              >
              </fabi-payment-method-item>
            </ion-slide>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ion-slides>

  <div class="possible-causes-list">
    <div class="possible-causes-item">
      <h4 class="subtitle">Veja o que pode ter causado isso</h4>
    </div>
    <div
      class="possible-causes-item"
      *ngFor="let possibleCause of possibleCauses"
    >
      <span>{{ possibleCause }}</span>
    </div>
  </div>
  <ion-button (click)="openPaymentModal()" fill="outline" expand="block">
    Escolher outra forma de pagamento
  </ion-button>
</ion-content>
