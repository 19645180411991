<ng-container *ngIf="partialAppDef$ | async as partialAppDef">
  <ng-container *isHomePage="'menu'">
    <!-- div imitando a statusbar pra dispositivos com franja e iphones no geral -->
    <div
      class="fake-statusbar"
      *ngIf="!partialAppDef?.layout?.show_header"
    ></div>

    <ng-container *ngIf="partialAppDef?.layout?.show_header">
      <!-- TODO: adicionar parametro headerPattern -->
      <ion-toolbar
        [style.--background]="
          partialAppDef?.header_pattern
            ? partialAppDef.header_pattern
            : 'var(--ion-color-headercolor)'
        "
        [style.--color]="'var(--ion-color-headercolor-contrast)'"
        mode="md"
      >
        <ion-buttons
          slot="start"
          [style.--color]="'var(--ion-color-headercolor-contrast)'"
        >
          <ion-menu-button
            [style.--color]="'var(--ion-color-headercolor-contrast)'"
          ></ion-menu-button>
        </ion-buttons>

        <!--
          nota:
            [slot]="partialAppDef.layout.header_logo_alignment !== 'center' ? partialAppDef.layout.header_logo_alignment : ''"
            slot="center" não é um valor valido
        -->
        <!-- TODO: fazer migração de center para string vazia no backend na propriedade `header_logo_alignment` -->
        <ion-title
          class="header-title"
          [slot]="
            partialAppDef.layout.header_logo_alignment !== 'center'
              ? partialAppDef.layout.header_logo_alignment
              : ''
          "
          [class.-center]="
            partialAppDef.layout.header_logo_alignment === '' ||
            partialAppDef.layout.header_logo_alignment === 'center'
          "
          [class.no-menu]="!(menuEnabled$ | async)"
          [class.-rightbutton]="
            partialAppDef.search &&
            partialAppDef.layout.search_field === 'header'
          "
        >
          <img
            *ngIf="partialAppDef.logo; else appName"
            class="header-logo"
            [src]="partialAppDef.logo"
            [alt]="partialAppDef.info.name"
          />
          <!-- TODO: 
            quando for headerImage não mostrar titulo (FAZER QUANDO TIVER O NOVO PARAMETRO DE HEADER_PATTERN)
          -->
          <ng-template #appName>
            {{ partialAppDef.info.name }}
          </ng-template>
        </ion-title>

        <ion-buttons
          slot="end"
          *ngIf="
            partialAppDef.search &&
            partialAppDef.layout.search_field === 'header'
          "
        >
          <ion-button
            (click)="openSearchModal()"
            [style.--color]="'var(--ion-color-headercolor-contrast)'"
          >
            <ion-icon slot="icon-only" name="search"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ng-container>
    <ion-toolbar
      *ngIf="
        partialAppDef?.layout?.search_field === 'top' && partialAppDef?.search
      "
      [color]="color"
      [style.--ion-color-base]="
        partialAppDef?.header_pattern
          ? partialAppDef.header_pattern
          : 'var(--ion-color-headercolor)'
      "
    >
      <ng-container *ngIf="searchPlaceholder | async as placeholder">
        <ion-searchbar
          [placeholder]="placeholder"
          class="fake-search"
          [style.--background]="'#fff'"
          (click)="openSearchModal(false)"
        >
        </ion-searchbar>
      </ng-container>
    </ion-toolbar>
  </ng-container>

  <ion-toolbar [color]="color" *isHomePage="'back'">
    <ion-buttons slot="start" *ngIf="!forceHideBackButton">
      <ion-back-button
        [defaultHref]="defaultHref || navigationService.getHomeRoute()"
      >
      </ion-back-button>
    </ion-buttons>
    <ng-content select="ion-title"></ng-content>
    <ng-content select="ion-buttons"></ng-content>
  </ion-toolbar>

  <ion-toolbar [color]="color" *ngIf="isModal">
    <ng-content select="ion-title"></ng-content>
    <ng-content select="ion-buttons"></ng-content>
    <ion-buttons slot="end">
      <ion-button
        fill="clear"
        (click)="modalCtrl.dismiss()"
        [style.--color]="'var(--primarycolor-or-black)'"
      >
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ng-container>
