<ng-container *ngFor="let item of items">
  <fabapp-group-list-layout
    [class.ion-hide]="item.layoutType !== layoutType.LIST"
    (navigate)="navigate.emit($event)"
    [group]="item.entries"
    [layout]="layout"
  ></fabapp-group-list-layout>
  <fabapp-group-grid-layout
    [class.ion-hide]="item.layoutType !== layoutType.GRID"
    (navigate)="navigate.emit($event)"
    [group]="item.entries"
    [layout]="item.layout"
  ></fabapp-group-grid-layout>
</ng-container>
