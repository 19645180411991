<ion-grid>
  <ion-row>
    <ion-col
      sizeXs="12"
      sizeMd="6"
      sizeLg="6"
      sizeXl="4"
      *ngFor="let item of items; trackBy: trackById"
    >
      <ion-card
        (click)="handle(item)"
        class="card-container"
        [ngStyle]="{
          'background-image': 'url(' + (item?.listItemImages)[0]?.imageUrl + ')'
        }"
      >
        <!-- content -->
        <div class="inner-wrapper">
          <ion-card-title class="headline">{{ item.title }}</ion-card-title>
          <ion-card-subtitle class="subheadline">{{
            item?.resume
          }}</ion-card-subtitle>
          <app-rating-of-item
            *ngIf="listDef?.ratings"
            (rate)="handle(item, true)"
            [average]="item?.ratingsavg"
            [ratingsTotal]="item?.ratingstotal"
            [withSeparator]="item?.distance"
          ></app-rating-of-item>
          <span *ngIf="item?.distance" class="distance">{{
            item.distance | meterToKilometer
          }}</span>
        </div>
        <!-- content -->
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>
