import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@core/guards/auth-guard.service';
import { AuthSegmentedRestrictionGuard } from '@core/guards/auth-segmented-restriction/auth-segmented-restriction.guard';
import { FDAuthGuardGuard } from '@core/guards/FD-auth-guard.service.ts/fd-auth-guard.guard';
import { PixGuardService } from '@core/guards/pix-guard.service';
import { WrongHomeGuard } from '@core/guards/wrong-home-guard';
import { CustomPreloadingStrategy } from '@core/preloading-strategy';
import { OrderDetailPage } from '@fabapp-delivery/pages/order-detail/order-detail.page';
import { OrderHistoryPage } from '@fabapp-delivery/pages/order-history/order-history.page';
import { SearchPageComponent } from './components/search/search-page.component';

// tslint:disable: typedef
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordPageModule,
      ),
  },
  {
    path: 'apple-signin',
    loadChildren: () =>
      import('./pages/apple-signin/apple-signin.module').then(
        (m) => m.AppleSignInPageModule,
      ),
  },
  {
    path: 'login-by-token',
    loadChildren: () =>
      import('./pages/login-by-token/login-by-token.module').then(
        (m) => m.LoginByTokenPageModule,
      ),
  },
  {
    path: '',
    canActivateChild: [AuthGuardService, AuthSegmentedRestrictionGuard],
    children: [
      {
        path: 'settings',
        loadChildren: () =>
          import('./pages/settings/settings.module').then(
            (m) => m.SettingsPageModule,
          ),
        canActivate: [FDAuthGuardGuard],
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomePageModule),
        canActivate: [WrongHomeGuard],
      },
      {
        path: 'album',
        loadChildren: () =>
          import('./moblets/album/album.module').then(
            (m) => m.AlbumMobletModule,
          ),
      },
      {
        path: 'catalog',
        loadChildren: () =>
          import('./moblets/catalog/catalog.module').then(
            (m) => m.CatalogMobletModule,
          ),
      },
      {
        path: 'fabapp-commerce',
        loadChildren: () =>
          import('./moblets/fabapp-commerce/fabapp-commerce.module').then(
            (m) => m.FabAppCommerceMobletModule,
          ),
      },
      {
        path: 'blank',
        loadChildren: () =>
          import('./moblets/blank/blank.module').then(
            (m) => m.BlankPageMobletModule,
          ),
      },
      {
        path: 'list',
        loadChildren: () =>
          import('./moblets/list/list.module').then((m) => m.ListPageModule),
      },
      {
        path: 'group',
        loadChildren: () =>
          import('./moblets/group/group.module').then(
            (m) => m.GroupMobletModule,
          ),
      },
      {
        path: 'ratings',
        loadChildren: () =>
          import('./pages/ratings/ratings.module').then(
            (m) => m.RatingsPageModule,
          ),
      },
      {
        path: 'twitter',
        loadChildren: () =>
          import('./moblets/twitter-hashtag/twitter.module').then(
            (m) => m.TwitterPageModule,
          ),
      },
      {
        path: 'web',
        loadChildren: () =>
          import('./moblets/web/web.module').then((m) => m.WebPageModule),
      },
      {
        path: 'videos',
        loadChildren: () =>
          import('./moblets/videos/videos.module').then(
            (m) => m.VideosPageModule,
          ),
      },
      {
        path: 'rss',
        loadChildren: () =>
          import('./moblets/rss/rss.module').then((m) => m.RssPageModule),
      },
      {
        path: 'podcast',
        loadChildren: () =>
          import('./moblets/podcast/podcast.module').then(
            (m) => m.PodcastPageModule,
          ),
      },
      {
        path: 'mercado-livre',
        loadChildren: () =>
          import('./moblets/mercado-livre/mercado-livre.module').then(
            (m) => m.MercadoLivrePageModule,
          ),
      },
      {
        path: 'youtube',
        loadChildren: () =>
          import('./moblets/youtube/youtube.module').then(
            (m) => m.YoutubePageModule,
          ),
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('./moblets/contact/contact.module').then(
            (m) => m.ContactPageModule,
          ),
      },
      {
        path: 'flickr',
        loadChildren: () =>
          import('./moblets/flickr/flickr.module').then((m) => m.FlickrModule),
      },
      {
        path: 'mrss',
        loadChildren: () =>
          import('./moblets/mrss/mrss.module').then((m) => m.MRSSModule),
      },
      {
        path: 'audio-gallery',
        loadChildren: () =>
          import('./moblets/audio-gallery/audio-gallery.module').then(
            (m) => m.AudioGalleryPageModule,
          ),
      },
      {
        path: 'forms',
        loadChildren: () =>
          import('./moblets/forms/forms.module').then((m) => m.FormsPageModule),
      },
      {
        path: 'mural',
        loadChildren: () =>
          import('./moblets/mural/mural.module').then((m) => m.MuralPageModule),
      },
      {
        path: 'rewards',
        loadChildren: () =>
          import('./moblets/rewards/rewards.module').then(
            (m) => m.RewardsModule,
          ),
        data: { noPreload: true },
      },
      {
        path: 'google-calendar',
        loadChildren: () =>
          import('./moblets/google-calendar/google-calendar.module').then(
            (m) => m.GoogleCalendarPageModule,
          ),
      },
      {
        path: 'order-history',
        children: [
          {
            path: '',
            component: OrderHistoryPage,
            canActivate: [FDAuthGuardGuard],
          },
          {
            path: ':id/order-detail',
            component: OrderDetailPage,
            canActivate: [FDAuthGuardGuard, PixGuardService],
          },
        ],
      },
      {
        path: 'fabapp-delivery',
        loadChildren: () =>
          import('./moblets/fabapp-delivery/fabapp-delivery.module').then(
            (m) => m.FabappDeliveryPageModule,
          ),
      },
      {
        path: 'scheduling',
        loadChildren: () =>
          import('./moblets/scheduling/scheduling.module').then(
            (m) => m.SchedulingPageModule,
          ),
      },
      {
        path: 'search',
        component: SearchPageComponent,
      },
    ],
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(
        (m) => m.NotFoundModule,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingStrategy,
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuardService],
})
export class AppRoutingModule {}
