import { Injectable } from '@angular/core';
import { HttpService } from '@core/http/http.service';
import { environment } from '@environments/environment';
import { Promotions } from '@fabapp-delivery/models/promotions/coupon/promotions.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PromotionsService extends HttpService<any> {
  protected url = `${environment.shop.url}/app`;

  public promotions(): Observable<Promotions> {
    return this.request('GET', '/promotions');
  }

  public verifyCoupon(code): Observable<any> {
    return this.request('POST', '/coupons/verify', { code });
  }
}
