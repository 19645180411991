<ion-item class="additionalitem-selection">
  <ion-label class="label-wrapper">
    <div class="label" *ngIf="label">
      {{ label }}
    </div>
    <div *ngIf="description" class="description">{{ description }}</div>
    <div *ngIf="aggregateValue" class="aggregatevalue">
      +{{ aggregateValue / 100 | currency: currencyId }}
    </div>
  </ion-label>
  <ion-icon
    (click)="setQuantity(initialQuantity)"
    class="selectone"
    [class.-disabled]="quantity >= counterRangeMax || !canAddMore"
    *ngIf="quantity <= defaultValue"
    name="add"
  ></ion-icon>
  <fabapp-input-quantity
    [canAddMore]="canAddMore"
    [counterRangeMax]="counterRangeMax"
    [counterRangeMin]="counterRangeMin"
    [class.ion-hide]="quantity <= defaultValue"
    class="minor"
    [value]="quantity"
    (change)="setQuantity($event)"
  >
  </fabapp-input-quantity>
</ion-item>
