export class LoadFDPaymentMethods {
  static readonly type = '[Fabi Delivery Payment Methods] load';
  constructor() {}
}

export class FDPaginatePaymentMethods {
  static readonly type = '[Fabi Delivery Payment Methods] Paginate';
  constructor(public page = 1) {}
}

export class FDDeleteBrand {
  static readonly type = '[Fabi Delivery Payment Methods] Delete Brand';
  constructor(public brandId: string) {}
}
