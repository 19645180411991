import { Component, Input, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  templateUrl: './control-error.component.html',
  styleUrls: ['./control-error.component.scss'],
  selector: 'control-error',
})
export class ControlErrorComponent implements OnInit, OnDestroy {
  @Input('label') label: string;
  @Input('control') control: FormControl;

  private _nameError = 'required';
  domElement: any;

  controlsSubscriptions: Subscription[] = [];
  constructor(el: ElementRef) {
    this.domElement = el.nativeElement;
  }

  ngOnInit(): void {
    const statusSubscripiton: Subscription = this.control.statusChanges.subscribe(
      (status: any) => this.setClassIonItem,
    );
    const valueSubscripiton: Subscription = this.control.valueChanges.subscribe(
      (value: any) => {
        if (this.control.valid) {
          this.setClassIonItem('VALID');
        }
      },
    );

    this.controlsSubscriptions.push(statusSubscripiton, valueSubscripiton);
  }

  ngOnDestroy(): void {
    this.controlsSubscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe(),
    );
  }

  getIonItem(): HTMLIonItemElement {
    let sibling = this.domElement.previousElementSibling;

    while (sibling) {
      if (sibling.matches('ion-item')) return sibling;
      sibling = sibling.previousElementSibling;
    }
    console.error('ADICIONE O INPUT DENTRO DE UM ION-ITEM!!!');
  }

  setClassIonItem(status: any): string {
    const ionItem: HTMLIonItemElement = this.getIonItem();
    const VALID_CLASSES: string[] = ['valid', 'ion-valid', 'ion-touched'];
    const INVALID_CLASSES: string[] = ['invalid', 'ion-invalid', 'ion-touched'];

    ionItem.classList.remove(...VALID_CLASSES, ...INVALID_CLASSES);
    if (status === 'INVALID') {
      ionItem.classList.add(...INVALID_CLASSES);
      return (this._nameError = this.getNameError());
    }
    ionItem.classList.add(...VALID_CLASSES);
  }

  get keyI18n(): string {
    this.setClassIonItem('INVALID');
    return `validations.${this._nameError}`;
  }

  get i18nParams(): any {
    const label: string = this.label.toLowerCase();
    /**
     * @description
     * errorFromValidator: valor da chave do erro
     */

    const errorFromValidator: any = Object.entries(this.control.errors)[0][1];
    return { label, ...errorFromValidator };
  }

  getNameError(): string {
    return Object.entries(this.control.errors)[0][0];
  }
}
