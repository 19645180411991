import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ProductStatus } from '@core/enums/product-status.enum';
import { AuthHelperService } from '@core/guards/auth-helper.service';
import { AuthState } from '@core/state/auth';
import { CoreState } from '@core/state/core/core.state';
import { ThemingColor } from '@core/types';
import { NoteModalComponent } from '@fabapp-delivery/components/note-modal/note-modal.component';
import { InventoryStatus } from '@fabapp-delivery/enums/inventory-status.enum';
import { FDI_CartItem } from '@fabapp-delivery/models/cart/cart.model';
import { FDI_Product } from '@fabapp-delivery/models/product/product.model';
import { FDI_Store } from '@fabapp-delivery/models/store';
import { DeliveryCartActions } from '@fabapp-delivery/state/cart/cart.actions';
import { CartState } from '@fabapp-delivery/state/cart/cart.state';
import { FabappDeliveryItem } from '@fabapp-delivery/state/fabapp-delivery-item/fabapp-delivery-item.action';
import { FabappDeliveryItemState } from '@fabapp-delivery/state/fabapp-delivery-item/fabapp-delivery-item.state';
import {
  ModalController,
  NavController,
  IonContent,
  ToastController,
} from '@ionic/angular';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CartModal } from '../cart-modal/cart.modal';

@Component({
  selector: 'app-fabapp-delivery-item',
  templateUrl: './fabapp-delivery-item.component.html',
  styleUrls: ['./fabapp-delivery-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: 'fabapp-delivery', alias: 'fabappDelivery' }, // Carrega a tradução quando for modal
    },
  ],
})
export class FabappDeliveryItemComponent implements OnInit, OnDestroy {
  @ViewChild(IonContent) ionContent: IonContent;
  @Select(FabappDeliveryItemState.getTotal) public total$: Observable<number>;
  public productQuantity: number;
  public product: FDI_Product;
  @ViewChild('pointer') pointer;
  public showTitleInHeader: boolean = false;

  /** A propriedade `productId` é obrigatório para o GET de produtos */
  @Input() productId: string;
  /** Caso o produto esteja no carrinho a propriedade `productIsInCart` será `true` */
  @Input() public productIsInCart: boolean = false;
  /** Caso a propriedade `productIsInCart` for `true` - significa que o usuário pode remover o item do carrinho  */
  public minimumQuantityOfProducts: number =
    this.getMinimumQuantityOfProducts();

  public subscriptions: Array<Subscription> = [];
  public readonly form: FormGroup;
  inventoryStatusEnum = InventoryStatus;

  public isPage = false;
  public store: FDI_Store;
  private readonly offsetTopMin = 16;
  productStatusEnum = ProductStatus;
  constructor(
    public readonly modalController: ModalController,
    private readonly cdr: ChangeDetectorRef,
    private readonly $store: Store,
    private authHelperService: AuthHelperService,
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private readonly toastController: ToastController,
  ) {
    this.form = new FormGroup({
      productId: new FormControl(this.productId),
      complements: new FormGroup({}),
      observation: new FormControl(''),
      quantity: new FormControl(1),
    });
  }

  async ngOnInit(): Promise<void> {
    this.setInitialProperties();

    this.$store
      .dispatch(new FabappDeliveryItem.Get(this.productId))
      .toPromise();
    this.cdr.markForCheck();
    this.loadProduct();
    this.createSubscriptionInGetItem();
    this.subscriptionForGetCartBeforeAfterLogin();
  }

  private setInitialProperties() {
    this.isPage = !!this.route.snapshot.params?.itemId;
    this.productId = this.productId
      ? this.productId
      : this.route.snapshot.params?.itemId;

    this.minimumQuantityOfProducts = this.getMinimumQuantityOfProducts();
    this.form.get('productId').setValue(this.productId);
    this.store = this.$store.selectSnapshot(CoreState.currentStore);
  }

  private subscriptionForGetCartBeforeAfterLogin() {
    const subscription: Subscription = this.$store
      .select(AuthState.isAuthenticated)
      .pipe(
        filter(
          (isAuthenticated) =>
            isAuthenticated && !this.$store.selectSnapshot(CartState.getCartId),
        ),
      )
      .subscribe(() => this.$store.dispatch(new DeliveryCartActions.Load()));
    this.subscriptions.push(subscription);
  }

  private getMinimumQuantityOfProducts(): number {
    return this.productIsInCart ? 0 : 1;
  }

  private createSubscriptionInGetItem(): void {
    const subscription: Subscription = this.$store
      .select(FabappDeliveryItemState.getFabappDeliveryItem)
      .subscribe(({ quantity, total }) => {
        this.productQuantity = quantity;
        this.cdr.markForCheck();
      });
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    this.$store.dispatch(new FabappDeliveryItem.Reset());
  }

  private loadProduct(): void {
    const subscription: Subscription = this.$store
      .select(FabappDeliveryItemState.getProduct)
      .pipe(filter((item) => !!item))
      .subscribe((product) => {
        this.product = product;
        this.cdr.markForCheck();
      });
    this.subscriptions.push(subscription);
  }

  public async goToNote(): Promise<void> {
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: NoteModalComponent,
      componentProps: {
        noteValue: this.form.get('observation').value,
      },
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data) {
      this.form.get('observation').setValue(data);
      this.cdr.markForCheck();
    }
  }

  public setQuantity(quantity: number): void {
    this.$store.dispatch(new FabappDeliveryItem.SetQuantity(quantity));
    this.cdr.markForCheck();
  }

  public logScrolling(e: CustomEvent) {
    const scrolled: number = e.detail.scrollTop;
    const pointerYPosition: number =
      this.pointer.nativeElement.offsetTop || this.offsetTopMin;
    scrolled >= pointerYPosition
      ? (this.showTitleInHeader = true)
      : (this.showTitleInHeader = false);
    this.cdr.markForCheck();
  }

  public async addToCart() {
    const isAuthenticated = this.$store.selectSnapshot(
      AuthState.isAuthenticated,
    );

    try {
      if (this.form.invalid) {
        return;
      }

      if (!isAuthenticated) {
        this.authHelperService.goToLogin(this.router.routerState.snapshot.url);
        return;
      }

      const complements = this.$store.selectSnapshot(
        FabappDeliveryItemState.getComplement,
      );

      const itemInCart: FDI_CartItem = new FDI_CartItem(
        this.form.getRawValue(),
        complements,
      );
      const quantity = this.$store.selectSnapshot(
        FabappDeliveryItemState.getQuantityProduct,
      );

      await this.$store
        .dispatch(
          new DeliveryCartActions.Item.Add({
            ...itemInCart,
            quantity,
          }),
        )
        .toPromise();
      this.back(true);
    } catch (err) {
      this._treatError(err);
      console.error('Error in addToCart(): ', err);
    }
  }

  private _treatError(error: HttpErrorResponse) {
    if (error?.status !== 422 || !error?.error?.error) {
      this.feedbackToaster('Houve um erro desconhecido', 'warning');
      return;
    }

    this.feedbackToaster(error?.error?.error, 'danger');
  }

  public async removeFromCart() {
    // TODO: Implementar remover do carrinho
    Capacitor.Plugins.Toast.show({
      text: 'Implementar remover do carrinho',
      duration: 'short',
      position: 'bottom',
    });
  }

  public async back(openCart = false) {
    if (!this.isPage) {
      this.modalController.dismiss({ openCart });
      return;
    }

    if (openCart) {
      await this.openCart();
    }

    this.navCtrl.navigateBack('/');
  }

  private async openCart() {
    const modal = await this.modalController.create({
      component: CartModal,
    });

    await modal.present();
  }

  toScroll(scroll: number) {
    this.ionContent.scrollToPoint(0, scroll, 500);
  }

  private async feedbackToaster(
    message: string,
    theme: ThemingColor = 'danger',
    position?: 'top' | 'bottom',
  ): Promise<void> {
    const toast: HTMLIonToastElement = await this.toastController.create({
      message,
      color: theme || 'top',
      position,
      duration: 3000,
      buttons: [
        {
          text: 'ok',
        },
      ],
    });
    toast.present();
  }
}
