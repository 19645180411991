import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PwaService } from '@core/services/pwa.service';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Platform } from '@ionic/angular';

const TRANSLATION_SCOPE = 'pwa-install';

@Component({
  selector: 'fabapp-pwa-install',
  templateUrl: 'pwa-install.component.html',
  styleUrls: ['pwa-install.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: TRANSLATION_SCOPE, alias: 'pwaInstall' },
    },
  ],
})
export class PwaInstallComponent {
  hide = false;

  constructor(public pwaService: PwaService, private platform: Platform) {
    if (this.platform.is('capacitor')) {
      this.hide = true;
    }
  }

  hideWrapper(): void {
    this.hide = true;
  }
}
