import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Address } from '@core/models/address/address.model';
import { GoogleMapsAutocompleteService } from '@core/services/google-maps.service';
import {
  DeleteCurrentAddress,
  LoadAddresses,
  RegisterAddress,
  SetCurrentAddress,
  UpdateAddress,
} from '@core/state/address/address.action';
import { AddressState } from '@core/state/address/address.state';
import { CoreState } from '@core/state/core/core.state';
import { LoadStore } from '@core/state/moblets';
import { ThemingColor } from '@core/types';
import { IsEmptyValidator } from '@core/validators/isEmpty.validator';
import { environment } from '@environments/environment';
import { FDI_Store } from '@fabapp-delivery/models/store';
import { StoresState } from '@fabapp-delivery/state/stores/stores.state';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslocoService, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SelectLocationComponent } from '../select-location/select-location.component';

const scope: string = 'settings';
@Component({
  selector: 'app-address-create-and-edit',
  templateUrl: './address-create-and-edit.component.html',
  styleUrls: ['./address-create-and-edit.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: scope }],
})
export class AddressCreateAndEditComponent implements OnInit, OnDestroy {
  @Input() public itemId: string;
  @ViewChild('inputSearch') inputSearch: HTMLInputElement;

  public addresses: any;
  public address: Address;
  public addressValid;
  public readonly form: FormGroup;
  public readonly fields = {
    ADDRESS: 'full_address',
    COMPLEMENT: 'complement',
  };
  public isSubmitting: boolean = false;
  public destroy$: Subject<boolean> = new Subject();
  subscribeRegister: Subscription;
  subscribeEditable: Subscription;
  private scopeAndCurrentLang: string;
  private readonly toastButtons = [
    {
      text: 'ok',
    },
  ];

  withoutNumber = false;

  modalSelectAddress: HTMLIonModalElement;

  store: FDI_Store;
  public readonly DEFAULT_MAPS_KEY: string = environment.maps.key;
  constructor(
    private readonly store$: Store,
    private readonly toast: ToastController,
    private readonly translocoService: TranslocoService,
    public readonly modalController: ModalController,
    public readonly googleMapsAutocompleteService: GoogleMapsAutocompleteService,
    public readonly cdr: ChangeDetectorRef,
    private actions$: Actions,
  ) {
    this.scopeAndCurrentLang = `${scope}/${this.translocoService.getActiveLang()}`;
    this.form = new FormGroup({
      [this.fields.ADDRESS]: new FormControl('', [
        Validators.required,
        IsEmptyValidator,
      ]),
      address: new FormControl(''),
      number: new FormControl(''),
      neighborhood: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      country: new FormControl(''),
      zip_code: new FormControl(''),
      lat: new FormControl(''),
      lng: new FormControl(''),
      [this.fields.COMPLEMENT]: new FormControl(''),
    });
  }

  async ngOnInit() {
    this.store = this.store$.selectSnapshot(CoreState.currentStore);

    this.getAddress();
    this._watchAddressList();
    await this.store$.dispatch(new LoadAddresses()).toPromise();
  }

  private async setListenerAddress() {
    if (!this.modalSelectAddress) {
      return;
    }

    const editable = this.store$.selectSnapshot(
      AddressState.getEditableAddress,
    );

    if (editable) {
      this.subscribeEditable = this.actions$
        .pipe(ofActionDispatched(UpdateAddress))
        .subscribe(async () => {
          await this.modalSelectAddress.dismiss();
          this.closeModal();
        });
    } else {
      this.subscribeRegister = this.actions$
        .pipe(ofActionDispatched(RegisterAddress))
        .subscribe(async () => {
          await this.modalSelectAddress.dismiss();
          this.closeModal();
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private _watchAddressList() {
    this.googleMapsAutocompleteService.addresses$.subscribe((addresses) => {
      this.addresses = addresses;
      this.cdr.detectChanges();
    });
  }

  async selectAddress(address) {
    const newAddress = await this.googleMapsAutocompleteService.getPlaceDetails(
      address.place_id,
    );

    if (
      address.types.includes('route') ||
      address.types.includes('geocode') ||
      address.types.includes('street_address') ||
      address.types.includes('premise') ||
      address.types.includes('subpremise')
    ) {
      // this.addressValid = newAddress;
      newAddress.addressForm.currentLocation = false;
      newAddress.addressForm.place_id = address.place_id;
      this.store$.dispatch(new SetCurrentAddress(newAddress.addressForm));
      this.googleMapsAutocompleteService.clearAddressList();
      this.selectLocation();

      return;
    }

    this.form
      .get(this.fields.ADDRESS)
      .setValue(`${address.structured_formatting.main_text}`);
  }

  private getAddress(): void {
    if (!this.itemId) {
      return;
    }

    this.store$
      .select(AddressState.getAddress(this.itemId))
      .pipe(takeUntil(this.destroy$))
      .pipe(filter((address) => !!address))
      .subscribe((address) => {
        this.address = address;
        this.form.patchValue(address);
      });
  }

  public async submit(): Promise<void> {
    if (this.isSubmitting || this.form.invalid) {
      return;
    }

    const data: Address = this.form.getRawValue();
    data.number = data.number!.toString();

    try {
      this.isSubmitting = true;

      if (this.address) {
        await this.editAddress(data);
      } else {
        await this.registerAddress(data);
      }

      this.closeModal();
    } catch (error) {
      this.feedbackToaster(error.message);
    } finally {
      this.isSubmitting = false;
    }
  }

  async closeModal() {
    await this.store$.dispatch(new DeleteCurrentAddress()).toPromise();
    const modal = await this.modalController.getTop();
    modal.dismiss();
  }

  private async editAddress(data: Address): Promise<void> {
    const { updatedAddress } = await this.translocoService
      .selectTranslateObject('addressPage', {}, this.scopeAndCurrentLang)
      .toPromise();

    try {
      await this.store$
        .dispatch(new UpdateAddress({ ...data, id: this.address.id }))
        .toPromise();
      this.feedbackToaster(updatedAddress, 'success');
    } catch (error) {
      throw error;
    }
  }

  public backToEdit() {
    this.addressValid = undefined;
  }

  private async registerAddress(data: Address): Promise<void> {
    const { addedAddress } = await this.translocoService
      .selectTranslateObject('addressPage', {}, this.scopeAndCurrentLang)
      .toPromise();
    try {
      await this.store$.dispatch(new RegisterAddress(data)).toPromise();
      this.feedbackToaster(addedAddress, 'success');
    } catch (error) {
      throw error;
    }
  }

  private async feedbackToaster(
    message: string,
    theme: ThemingColor = 'danger',
  ): Promise<void> {
    const toast: HTMLIonToastElement = await this.toast.create({
      message,
      color: theme,
      position: 'top',
      duration: 3000,
      buttons: this.toastButtons,
    });
    toast.present();
  }

  public setError(error: { [key: string]: boolean }): void {
    this.form.get(this.fields.ADDRESS).setErrors(error);
  }

  async selectLocation() {
    this.modalSelectAddress = await this.modalController.create({
      component: SelectLocationComponent,
      componentProps: {
        gMapsKey: this.store?.needsSelfMapsKey
          ? this.store?.gMapsKey
          : this.DEFAULT_MAPS_KEY,
      },
    });

    await this.setListenerAddress();
    await this.modalSelectAddress.present();
  }
}
