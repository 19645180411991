import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'fabapp-complement-header',
  templateUrl: './complement-header.component.html',
  styleUrls: ['./complement-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComplementHeaderComponent implements OnInit {
  @Input('showRequiredLabel')
  public set setShowRequiredLabel(value: boolean) {
    this.showRequiredLabel = value;
    this.cdr.detectChanges();
  }
  public showRequiredLabel: boolean = false;

  @Input('minValue')
  public set setMinValue(value: number) {
    this.minValue = value;
    this.cdr.markForCheck();
  }
  public minValue: number;

  @Input('maxValue')
  public set setMaxValue(value: number) {
    this.maxValue = value;
    this.cdr.markForCheck();
  }
  public maxValue: number;

  @Input('required')
  public set setRequired(value: boolean) {
    this.required = value;
    this.cdr.detectChanges();
  }
  public required: boolean = false;

  @Input() public title: string = '';
  @Input() public subtitle: string = '';
  @Input() public sticky: boolean = true;
  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}
}
