export class Navigate {
  static readonly type = '[Navigation] navigate';
  constructor(public instanceId: any, public itemId?: any) {}
}
export class InternalNavigation {
  static readonly type = '[Navigation] navigate internal';
  constructor(public path: string) {}
}

export class Back {
  static readonly type = '[Navigation] Back navigation';
  constructor(public instanceId: any, public itemId?: any) {}
}
export class ReturnToHomePage {
  static readonly type = '[Navigation] Return to home';
  constructor() {}
}

export class HandleLinkClick {
  static readonly type = '[Navigation] Handle Link Click';
  constructor(public event: any) {}
}
