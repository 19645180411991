import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AngularCropperModule } from '../angular-cropper/angular-cropper.module';
import { CropperImageComponent } from './cropper-image.component';

@NgModule({
  declarations: [CropperImageComponent],
  imports: [CommonModule, IonicModule, AngularCropperModule],
  exports: [CropperImageComponent],
})
export class CropperImageModule {}
