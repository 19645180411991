import { Injectable } from '@angular/core';
import { GroupLayoutDef, Page } from '@core/models';
import { GroupCarousel } from '@core/models/group';
import { LoadGroup } from '@core/state/moblets';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { filter, pluck, tap, map } from 'rxjs/operators';
import { BaseMobletService } from '../base-moblet.service';

enum GroupVars {
  BACKGROUND = '--page-background',
  PADDING_T = '--page-padding-top',
  PADDING_L = '--page-padding-left',
  PADDING_R = '--page-padding-right',
  COL_PADDING = '--col-padding',
  ITEM_BACKGROUND = '--item-background',
  FONT_COLOR = '--font-color',
  ITEM_P = '--item-padding',
  ITEM_BORDER_RADIUS = '--item-border-radius',
}

@Injectable()
export class GroupMobletService extends BaseMobletService<any> {
  currentLayout: GroupLayoutDef;
  get moblet(): Observable<Page> {
    return this._moblet.asObservable().pipe(filter((data: any) => data));
  }

  // hack para o empty state funcionar
  // adiciona a entries na chave items
  get entries(): Observable<{ items: Page[] }> {
    return this._moblet.asObservable().pipe(
      filter((data: any) => data),
      pluck('entries'),
      map((entries: Page[]) => {
        return {
          items: entries || [],
        };
      }),
    );
  }

  layouts(ref: HTMLElement): Observable<GroupLayoutDef> {
    return this._moblet.asObservable().pipe(
      filter((data: any) => data),
      pluck('layout'),
      tap((layout: GroupLayoutDef) => this.setLayoutVars(layout, ref)),
    );
  }

  get carosels(): Observable<GroupCarousel[]> {
    return this._moblet.asObservable().pipe(
      filter((data: any) => data),
      pluck('carosels'),
    );
  }

  async loadMoblet(id: number): Promise<void> {
    this._mobletId = id;
    await this.store.dispatch([new LoadGroup(id)]).toPromise();

    if (!this._mobletSubscription) {
      this.setMobletSubscription();
    }
  }

  /**
   * @description
   * o parametro ref é referente a
   * tag do componente do grupo de abas
   * sendo assim o escopo do estilo fica dentro do componente
   * @returns void
   */
  private setLayoutVars(layout: GroupLayoutDef, ref: HTMLElement): void {
    // se o layout for igual não muda os valores
    if (!layout || isEqual(this.currentLayout, layout)) {
      return;
    }

    const {
      showpageBackgroundImage,
      pageBackgroundImage,
      pageBackgroundColor,
      paddingTop,
      horizontalPadding,
    } = layout;

    const layouts: any = {
      [GroupVars.BACKGROUND]: `${pageBackgroundColor}`,
      [GroupVars.PADDING_T]: `${this.convertToRem(paddingTop)}rem`,
      [GroupVars.PADDING_L]: `${this.convertToRem(horizontalPadding)}rem`,
      [GroupVars.PADDING_R]: `${this.convertToRem(horizontalPadding)}rem`,
      [GroupVars.COL_PADDING]: `${this.convertToRem(layout.itemPadding)}rem`,
      [GroupVars.ITEM_BORDER_RADIUS]: `${this.convertToRem(
        layout.itemBorderRadius,
      )}rem`,
      [GroupVars.ITEM_BACKGROUND]: `${layout.itemsBackgroundColor}`,
      [GroupVars.FONT_COLOR]: `${layout.itemsFontColor}`,
      [GroupVars.ITEM_P]: `${this.convertToRem(layout.itemPadding)}rem`,
      [GroupVars.ITEM_BORDER_RADIUS]: `${this.convertToRem(
        layout.itemBorderRadius,
      )}rem`,
    };

    Object.keys(layouts).forEach((key: string) =>
      ref.style.setProperty(key, layouts[key]),
    );

    let bgImage: string = '';
    if (showpageBackgroundImage && pageBackgroundImage) {
      /** concatena o background-color com a url da imagem */
      bgImage = `${pageBackgroundColor} url(${layout.pageBackgroundImage})`;
    }

    // se tiver imagem sobrescreve a variavel `GroupVars.BACKGROUND`
    if (bgImage) {
      ref.style.setProperty(GroupVars.BACKGROUND, bgImage);
    }

    this.currentLayout = layout;
  }

  private convertToRem(value: number): number {
    return (value * 100) / 16 / 100;
  }
}
