import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { BehaviorSubject, Observable } from 'rxjs';

const { Toast } = Plugins;

@Injectable({ providedIn: 'root' })
export class PwaService {
  deferredPrompt: any;
  private canShowPopup = new BehaviorSubject<boolean>(false);

  constructor() {}

  setInstallPrompt(e: any): void {
    console.log('onBeforeInstallPrompt');
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;

    this.canShowPopup.next(true);
  }

  public showPopup(): Observable<boolean> {
    return this.canShowPopup.asObservable();
  }

  addToHomeScreen(): void {
    if (!this.deferredPrompt) {
      return;
    }
    this.showToast('Preparando o app...');
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
        this.canShowPopup.next(false);
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      this.deferredPrompt = null;
    });
  }

  private async showToast(text: string): Promise<void> {
    await Toast.show({
      text,
      duration: 'long',
      position: 'bottom',
    });
  }
}
