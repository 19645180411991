<div class="rating-tile">
  <div class="title">Avalie a entrega</div>

  <ion-buttons class="rating-stars" mode="md">
    <ion-button class="star" (click)="alterDeliverySatisfaction(true)">
      <ion-icon
        [style.color]="deliverySatisfaction === true ? '#FE950C' : '#000'"
        name="thumbs-up"
      ></ion-icon>
    </ion-button>
    <ion-button class="star" (click)="alterDeliverySatisfaction(false)">
      <ion-icon
        [style.color]="deliverySatisfaction === false ? '#FE950C' : '#000'"
        name="thumbs-down"
      ></ion-icon>
    </ion-button>
  </ion-buttons>

  <div class="feedback">
    <p class="label" *ngIf="deliverySatisfaction">O que mais gostou?</p>
    <p class="label" *ngIf="!deliverySatisfaction">O que podemos melhorar?</p>

    <div class="options-feedback">
      <ion-chip
        *ngFor="let option of tagsDelivery; let i = index"
        (click)="selectOptionFeedbackOrder(option.id, i)"
        [style.--color]="option?.selected ? '#fff' : '#706F6F'"
        [style.--background]="option?.selected ? '#FE950C' : '#FFF'"
        [style.border]="option?.selected ? 'none' : '1px solid #706F6F'"
      >
        <ion-label>{{ option.title }}</ion-label>
      </ion-chip>
    </div>
    <p class="label">Deixe um comentário</p>

    <ion-item class="input">
      <ion-input
        [value]="comment"
        (ionChange)="inputChanged($event)"
        placeholder="Comentário (opcional)"
        [maxlength]="150"
      ></ion-input>
    </ion-item>
    <p class="count-comment">{{ comment.length }}/150</p>
  </div>
</div>
