import { Injectable } from '@angular/core';
import { FirebaseConfig } from '@core/models';
import { Plugins } from '@capacitor/core';

import '@capacitor-community/firebase-analytics';

const { FirebaseAnalytics, Device } = Plugins;

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  isWeb = false;
  webEnabled = false;

  constructor() {}

  async init(firebaseConfig?: FirebaseConfig) {
    const platform: string = (await Device.getInfo()).platform;
    this.isWeb = platform === 'web';

    if (
      this.isWeb &&
      firebaseConfig &&
      !this.webEnabled &&
      !window['preview']
    ) {
      FirebaseAnalytics.initializeFirebase(firebaseConfig);
      this.webEnabled = true;
    }
  }

  /**
   * @deprecated This method should not be used (LGPD)
   */
  setUser(userIdentification: string) {
    if (this.isWeb && !this.webEnabled) return;
    FirebaseAnalytics.setUserId({
      userId: userIdentification,
    });
  }

  triggerMobletNavigation(pageTitle: string, pageId: number | string) {
    if (this.isWeb && !this.webEnabled) return;
    this.customEvent('navigation', {
      pageTitle,
      pageId,
    });
  }

  customEvent(name: string, params: {}) {
    if (this.isWeb && !this.webEnabled) return;
    FirebaseAnalytics.logEvent({
      name,
      params,
    });
  }
}
