import { NgModule } from '@angular/core';
import { GetUrlPipe } from './get-url/get-url.pipe';
import { MeterToKilometerPipe } from './meterToKilometer/meter-to-kilometer.pipe';
import { ObsLoading } from './obs-loading/obs-loading.pipe';
import { RelativeTimePipe } from './relative-time/relative-time.pipe';
import { SafePipe } from './safe-style-or-url/safe-style-or-url.pipe';
import { SanitizeHTMLPipe } from './sanitize-html/sanitize-html.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { ConvertToBooleanPipe } from './convert-to-boolean/convert-to-boolean.pipe';
import { CurrencyFormatPipe } from './current-format/current-format.pipe';
import { CalendarSubheaderPipe } from './calendar-subheader/calendar-subheader.pipe';
import { TimeBySecondsPipe } from './relative-time/time-by-seconds.pipe';

@NgModule({
  declarations: [
    TruncatePipe,
    MeterToKilometerPipe,
    SanitizeHTMLPipe,
    SafePipe,
    GetUrlPipe,
    RelativeTimePipe,
    ObsLoading,
    ConvertToBooleanPipe,
    CalendarSubheaderPipe,
    CurrencyFormatPipe,
    TimeBySecondsPipe,
  ],
  exports: [
    TruncatePipe,
    MeterToKilometerPipe,
    SanitizeHTMLPipe,
    SafePipe,
    GetUrlPipe,
    RelativeTimePipe,
    ObsLoading,
    ConvertToBooleanPipe,
    CalendarSubheaderPipe,
    CurrencyFormatPipe,
    TimeBySecondsPipe,
  ],
})
export class PipesModule {}
