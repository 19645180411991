import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { LibsLoaderEnum } from '@core/enums';
import { AppInfo } from '@core/models';
import { NavigationService } from '@core/services/navigation.service';
import { ScriptLoaderService } from '@core/services/script-loader.service';
import { AppDefState } from '@core/state/appdef';
import { AppleLogin } from '@core/state/auth';
import { environment } from '@environments/environment';
import { Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';

declare var AppleID: any;

@Injectable()
export class AppleSignInService {
  appInfo: AppInfo;

  constructor(
    private store: Store,
    private platform: Platform,
    private navigationService: NavigationService,
    private scriptLoader: ScriptLoaderService,
    private locationStrategy: LocationStrategy,
  ) {
    this.scriptLoader.loadLib(LibsLoaderEnum.APPLE).then((isReady: boolean) => {
      if (isReady) {
        this.init();
      }
    });
  }

  private init(): void {
    this.appInfo = this.store.selectSnapshot(AppDefState.getInfo);

    if (!this.appInfo) {
      return this.init();
    }

    let baseHref: string = this.locationStrategy.getBaseHref();
    // Em ambiente de desenvolvimento a base é `/`
    if (baseHref.length === 1) {
      baseHref = '';
    }

    AppleID.auth.init({
      clientId: `${this.appInfo.ios_bundle_identifier}.client`,
      scope: 'name email',
      redirectURI: `${environment.appleSignin.redirectURI}/app/login/apple`,
      state: JSON.stringify({
        appId: window['appId'],
        redirectURI: `${window.location.origin}${baseHref}/apple-signin`,
      }),
    });
  }

  async signIn(): Promise<void> {
    try {
      if (this.platform.is('ios')) {
        await this.iosLogin();
        this.navigationService.returnToHomePage();
      } else {
        this.webLogin();
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  webLogin(): void {
    AppleID.auth.signIn();
  }

  async iosLogin(): Promise<void> {
    try {
      const { SignInWithApple } = Plugins;
      const res = await SignInWithApple.Authorize();

      if (!res?.response?.identityToken) {
        return;
      }

      await this.store
        .dispatch(new AppleLogin(res.response.identityToken))
        .toPromise();
    } catch (err) {
      throw err;
    }
  }
}
