import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationService } from '@core/services';

@Component({
  selector: 'fabapp-content-encapsulator',
  encapsulation: ViewEncapsulation.ShadowDom,
  template: `
    <slot [innerHTML]="content"></slot>
  `,
  styles: [
    `
      img,
      iframe {
        max-width: 100%;
        border: 0;
      }
      a {
        color: -webkit-link;
        cursor: pointer;
      }
    `,
  ],
})
export class ContentEncapsulatorComponent implements AfterViewInit, OnChanges {
  /**
   * @property recebe o conteudo do parent component já tratado
   */
  @Input() content: any;
  tagName: string;
  @Input() public styles: string;

  constructor(
    private el: ElementRef,
    public navigationService: NavigationService,
  ) {
    this.tagName = el.nativeElement.tagName.toLowerCase();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.content?.currentValue) {
      this.content = changes.content.currentValue;
    }
    if (changes?.styles?.currentValue) {
      this.styles = changes.styles.currentValue;
      this._applyStyleInShadowRoot();
    }
  }

  ngAfterViewInit(): void {
    const shadowDomRef: any =
      this.el.nativeElement.shadowRoot ||
      this.el.nativeElement.attachShadow({ mode: 'open' });
    setTimeout(() => this.eventDetector(shadowDomRef), 1000);
  }

  private _applyStyleInShadowRoot() {
    if (!this.styles) {
      return;
    }

    const styleElmt = this.el.nativeElement.shadowRoot.querySelector('style');
    if (styleElmt) {
      styleElmt.append(this.styles);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(this.styles);
      this.el.nativeElement.shadowRoot.appendChild(barStyle);
    }
  }

  eventDetector(shadowDomRef: any): void {
    shadowDomRef.addEventListener('click', (target: any) => {
      // hack para pegar o primeiro elemento no path ao clicar nos elementos
      // target.composedPath() para compatibilidade com o firefox
      const path: any =
        target.path || (target.composedPath && target.composedPath());

      let item;

      // loop no caminho do clique para encontrar algum elemento com algo relevante
      // neste caso são os atributos data-moblet, data-root e href
      // faz o mesmo trabalho do event bubbling dos navegadores (alguns deles)
      for (const element of path) {
        // para o loop caso encontre um elemento com o mesmo nome deste componente
        // assim evita de acionar algo fora do encapsulator
        if (element.nodeName?.toLowerCase() === this.tagName) {
          break;
        }

        if (
          element?.dataset?.moblet ||
          element?.dataset?.root ||
          element?.target?.href ||
          element?.href
        ) {
          item = element;
          break;
        }
      }

      if (!item) {
        return;
      }

      // bloqueia a ação default de hyperlinks
      if (item?.href) {
        target.preventDefault();
      }

      this.navigationService.handleLinkClick({ target: item });
    });
  }
}
