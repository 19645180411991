import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { StatusDelivery } from '@core/enums/status-delivery.enum';
import { DeliveryInfoOrder } from '@core/models/moblets/fabapp-delivery/delivery-info-order.model';
import { PixPaymentPage } from '@fabapp-delivery/pages/pix-payment/pix-payment.page';
import { OrdersService } from '@fabapp-delivery/services';
import { ModalController, NavController } from '@ionic/angular';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PixGuardService implements CanActivate {
  constructor(
    private ordersService: OrdersService,
    private modalController: ModalController,
    private router: Router,
    private navCtrl: NavController,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const orderId = route.queryParamMap.get('orderid');

    return this.ordersService.getInfoProducts(orderId).pipe(
      map((order: DeliveryInfoOrder) => {
        if (
          order.lastTransaction &&
          order.status.id === StatusDelivery.WAITING_PAYMENT
        ) {
          this.openPixModal(order);
        }

        return true;
      }),
    );
  }

  async openPixModal(order: DeliveryInfoOrder): Promise<any> {
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: PixPaymentPage,
      componentProps: {
        order,
      },
    });

    await modal.present();

    const result = await modal.onDidDismiss();

    if (result?.data?.replicate) {
      await this.navCtrl.navigateBack(['/order-history']);
      return this.router.navigate(['/']);
    }

    if (result?.data?.goToOrder) {
      return true;
    }

    return this.navCtrl.navigateBack(['/order-history']);
  }
}
