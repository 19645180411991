import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  DeliveryMethod,
  PaymentMethod,
  PaymentMethod as PaymentMethodEnum,
} from '@core/enums';
import { DeliveryInfoOrder } from '@core/models/moblets/fabapp-delivery/delivery-info-order.model';
import { NavigationService } from '@core/services';
import {
  RegisterAddress,
  UpdateAddress,
} from '@core/state/address/address.action';
import { AddressState } from '@core/state/address/address.state';
import { AuthState } from '@core/state/auth';
import { CoreState } from '@core/state/core/core.state';
import { LoadStore, MobletsState } from '@core/state/moblets';
import { ThemingColor } from '@core/types';
import {
  FDI_Cart,
  FDI_PaymentMethodWithCard,
} from '@fabapp-delivery/models/cart/cart.model';
import { FDI_CartFees } from '@fabapp-delivery/models/cart/fees.model';
import { Coupon } from '@fabapp-delivery/models/promotions/coupon/coupon.model';
import { FDI_Store } from '@fabapp-delivery/models/store';
import { PromotionsPage } from '@fabapp-delivery/pages/promotions/promotions.page';
import { OrdersService } from '@fabapp-delivery/services';
import { DeliveryCartActions } from '@fabapp-delivery/state/cart/cart.actions';
import { CartState } from '@fabapp-delivery/state/cart/cart.state';
import {
  ClearSelectedCoupon,
  LoadCoupons,
} from '@fabapp-delivery/state/promotions/promotions.action';
import { PromotionsState } from '@fabapp-delivery/state/promotions/promotions.state';
import { TaxState } from '@fabapp-delivery/state/taxes/tax.state';
import {
  AlertController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FabappBottomSheetComponent } from 'src/app/components/fabapp-bottom-sheet/fabapp-bottom-sheet.component';
import { AddressComponent } from 'src/app/pages/settings/address/address.component';
import { PaymentFailureModalComponent } from '../payment-failure-modal/payment-failure-modal.component';
import { PaymentMethodModalComponent } from '../payment-method-modal/payment-method-modal.component';
import { CartDeliveryTypeComponent } from './cart-delivery-type/cart-delivery-type.component';
import { ModalPixKeyComponent } from './modal-pix-key/modal-pix-key.component';

enum NeededFieldsForCardEnum {
  DOCUMENT = 'document',
  EMAIL = 'email',
}
@Component({
  selector: 'app-cart',
  templateUrl: './cart-modal.html',
  styleUrls: ['./cart-modal.scss'],
})
export class CartModal implements OnInit, OnDestroy {
  @ViewChild(CartDeliveryTypeComponent)
  cartDeliveryType: CartDeliveryTypeComponent;
  @ViewChild(FabappBottomSheetComponent, { read: FabappBottomSheetComponent })
  fabappBottomSheet: FabappBottomSheetComponent;
  @Select(CartState.getCart) cart$: Observable<FDI_Cart>;
  @Select(CartState.getFees) fees$: Observable<FDI_CartFees>;
  selectedPaymentMethod: FDI_PaymentMethodWithCard;
  loading = false;
  public minPurchaseAmount: number;
  private _mobletSubscription: Subscription;

  paymentMethodEnum = PaymentMethodEnum;
  @Select(PromotionsState.getPromotionsCount)
  promotionsCount$: Observable<number>;

  @Select(PromotionsState.getCouponSelected)
  getCouponSelected$: Observable<Coupon>;
  addressSubscription: Subscription;
  @Select(TaxState.getDeliveryEnabled) deliverable$: Observable<boolean>;

  public deliveryMethodEnum = DeliveryMethod;
  public initialDeliveryMethod: DeliveryMethod = DeliveryMethod.DELIVERY;
  @Select(CartState.getDeliveryMethod)
  deliveryMethod$: Observable<DeliveryMethod>;
  public store: FDI_Store;
  placingOrder: boolean = false;
  addressPage: HTMLIonModalElement;
  registerOrEditableSubscription: Subscription;
  paymentMethodSubscription: Subscription;

  public neededFields = NeededFieldsForCardEnum.DOCUMENT;
  public neededFieldsEnum = NeededFieldsForCardEnum;

  @Input() itCameFromResearch: boolean;
  storeId: number;

  storeSubscription: Subscription;
  constructor(
    public navigationService: NavigationService,
    public ordersService: OrdersService,
    public readonly modalController: ModalController,
    private readonly toastController: ToastController,
    private router: Router,
    public $store: Store,
    private alertCtrl: AlertController,
    private actions$: Actions,
  ) {}

  async ngOnInit() {
    // Adquire os dados da loja o mais atualizado possível
    this.storeSubscription = this.$store
      .select(CoreState.currentStore)
      .pipe(filter((s) => !!s?.id))
      .subscribe((store) => (this.store = store));

    // Armazenando a storeId aqui pois em determinados fluxos `this.store` estará nulo
    // problema encontrado no bug => https://fabapp.atlassian.net/browse/FDA-1722
    this.storeId = this.$store.selectSnapshot(CoreState.currentStoreId);
    // Atualiza a store, para garantir a obtenção de dados como `cartão de crédito` do usuário logado
    this.$store.dispatch(new LoadStore(this.storeId));

    this.getInitialDeliveryMethod();
    this._setFavoriteAddressSubscription();
    this.setMobletSubscription();
    this.setPaymentMethodListener();
  }

  private async setListenerAddress() {
    if (!this.addressPage) {
      return;
    }

    const editable = this.$store.selectSnapshot(
      AddressState.getEditableAddress,
    );

    this.registerOrEditableSubscription = this.actions$
      .pipe(ofActionDispatched(editable ? UpdateAddress : RegisterAddress))
      .subscribe(async () => {
        this.addressPage.dismiss();
      });
  }

  private setPaymentMethodListener() {
    this.paymentMethodSubscription = this.$store
      .select(CartState.getSelectedPaymentMethod)
      .subscribe((selectedPaymentMethod) => {
        this.selectedPaymentMethod = selectedPaymentMethod;
      });
  }

  private getInitialDeliveryMethod() {
    this.initialDeliveryMethod = this.$store.selectSnapshot(
      CartState.getDeliveryMethod,
    );
  }

  ngOnDestroy() {
    this._mobletSubscription.unsubscribe();
    if (this.addressSubscription) {
      this.addressSubscription.unsubscribe();
    }

    if (this.registerOrEditableSubscription) {
      this.registerOrEditableSubscription.unsubscribe();
    }

    this.paymentMethodSubscription?.unsubscribe();
    this.$store.dispatch(new DeliveryCartActions.ClearPaymentMethod());

    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
  }

  private _setFavoriteAddressSubscription(): void {
    this.addressSubscription = this.$store
      .select(AddressState.getFavoriteAddress)
      .subscribe(() => {
        this.$store.dispatch(new DeliveryCartActions.Load());
      });
  }

  public setMobletSubscription(): void {
    const storeId = this.$store.selectSnapshot(CoreState.currentStoreId);

    this._mobletSubscription = this.$store
      .select(MobletsState.getMoblet(storeId))
      .subscribe((store: FDI_Store) => {
        this.minPurchaseAmount = store?.amountMin;
      });
  }

  trackByIndex(index: number, item: any): number {
    return item.id ? item.id : index;
  }

  async placeOrder() {
    this.placingOrder = true;

    if (!this.selectedPaymentMethod?.method) {
      this.openPaymentModal();
      this.placingOrder = false;
      return;
    }

    if (!this.$store.selectSnapshot(CartState.getCart)?.items?.length) {
      this.feedbackToaster('Seu carrinho esta vazio!', 'danger', 'top');
      this.placingOrder = false;
      return;
    }

    const fees = this.$store.selectSnapshot(CartState.getFees);
    const minpurchase =
      this.minPurchaseAmount > fees?.total - fees?.shippingTax;

    if (minpurchase) {
      this.feedbackToaster(
        'Você ainda nao atingiu o valor mínimo do pedido!',
        'danger',
        'top',
      );
      this.placingOrder = false;
      return;
    }

    const deliveryMethod = this.$store.selectSnapshot(
      CartState.getDeliveryMethod,
    );
    const favoriteAddress = this.$store.selectSnapshot(
      AddressState.getFavoriteAddress,
    );
    if (deliveryMethod == DeliveryMethod.DELIVERY && !favoriteAddress) {
      this.openAddress();
      this.placingOrder = false;
      return;
    }

    const isFabappPayAndCard =
      this.selectedPaymentMethod.method.id ===
      PaymentMethodEnum.ONLINE_CREDIT_CARD;

    try {
      const user = this.$store.selectSnapshot(AuthState.getUser);

      if (!user.document_number && isFabappPayAndCard) {
        this.neededFields = NeededFieldsForCardEnum.DOCUMENT;
        const { error } = await this.fabappBottomSheet.openBottomSheet();

        if (error) {
          this.placingOrder = false;
          return;
        }
      }

      if (!user?.email && isFabappPayAndCard) {
        this.neededFields = NeededFieldsForCardEnum.EMAIL;
        const { error } = await this.fabappBottomSheet.openBottomSheet();

        if (error) {
          this.placingOrder = false;
          return;
        }
      }

      const storeId = this.$store.selectSnapshot(CoreState.currentStoreId);
      const data = this._getOrder();

      const orderResponse: DeliveryInfoOrder = await this.ordersService
        .placeOrder(data)
        .toPromise();

      await this.$store
        .dispatch([new DeliveryCartActions.Load(), new ClearSelectedCoupon()])
        .toPromise();

      const checkMethodPix =
        this.selectedPaymentMethod.method.id === PaymentMethodEnum.PIX;

      this.feedbackToaster(
        'Pedido realizado com sucesso!',
        'success',
        checkMethodPix ? 'top' : 'bottom',
      );

      this.modalController.dismiss({ order: orderResponse.id });

      await this.router.navigate([`/order-history/${storeId}/order-detail`], {
        queryParams: { orderid: orderResponse.id },
      });

      if (checkMethodPix) {
        await this.openModalPix();
      }
    } catch (err) {
      this.feedbackToaster(err?.error?.error, 'danger');
      this.$store.dispatch(new DeliveryCartActions.Load());
      if (isFabappPayAndCard) {
        this.openPaymentFailureModal();
      }
    } finally {
      this.placingOrder = false;
    }
  }

  async openAddress() {
    this.addressPage = await this.modalController.create({
      component: AddressComponent,
    });

    await this.setListenerAddress();
    await this.addressPage.present();
  }

  async openModalPix() {
    const modalPix = await this.modalController.create({
      component: ModalPixKeyComponent,
    });

    await modalPix.present();
  }

  private _getOrder() {
    const cartId = this.$store.selectSnapshot(CartState.getCartId);
    const address = this.$store.selectSnapshot(AddressState.getFavoriteAddress);
    const fees = this.$store.selectSnapshot(CartState.getFees);

    let data = {
      cartId,
      paymentMethodId: this.selectedPaymentMethod.method.id,
      deliveryMethodId: this.cartDeliveryType.deliveryMethodSelected,
      amountFinal: fees.total,
      cashback: !this.selectedPaymentMethod.noCashBack
        ? this.selectedPaymentMethod.cashback * 100
        : 0,
    };

    if (
      this.cartDeliveryType.deliveryMethodSelected == DeliveryMethod.DELIVERY
    ) {
      data['addressId'] = address.id;
    }

    if (
      this.selectedPaymentMethod.card &&
      this.selectedPaymentMethod.method.id ===
        PaymentMethodEnum.ONLINE_CREDIT_CARD
    ) {
      data['creditCardId'] = this.selectedPaymentMethod.card.id;
    }

    return data;
  }

  async clear() {
    await this.presentClearCartAlert();
  }

  async presentClearCartAlert(): Promise<void> {
    const alertPopup: HTMLIonAlertElement = await this.alertCtrl.create({
      message: 'Deseja limpar o carrinho ?',
      buttons: [
        {
          text: 'Não',
          handler: () => alertPopup.dismiss(false),
        },
        {
          text: 'Sim',
          handler: () => {
            alertPopup.dismiss(true);
            this.$store.dispatch(new DeliveryCartActions.Clear());
          },
        },
      ],
    });

    alertPopup.present();

    await alertPopup.onDidDismiss();
  }

  async openPaymentModal() {
    const modal = await this.modalController.create({
      component: PaymentMethodModalComponent,
    });

    await modal.present();
  }

  async openPaymentFailureModal() {
    const modalRef = await this.modalController.create({
      component: PaymentFailureModalComponent,
    });

    await modalRef.present();

    const modal = await modalRef.onWillDismiss();

    if (modal.data?.openPaymentModal) {
      this.openPaymentModal();
    }
  }

  async openPromotions(): Promise<void> {
    const modal = await this.modalController.create({
      component: PromotionsPage,
      componentProps: {
        inCart: true,
      },
    });

    await modal.present();
  }

  private async feedbackToaster(
    message: string,
    theme: ThemingColor = 'danger',
    position?: 'top' | 'bottom',
  ): Promise<void> {
    const toast: HTMLIonToastElement = await this.toastController.create({
      message,
      color: theme || 'top',
      position,
      duration: 3000,
      buttons: [
        {
          text: 'ok',
        },
      ],
    });
    toast.present();
  }

  back() {
    if (this.itCameFromResearch) {
      this.navigationService.navigate({ instanceId: this.storeId });
    }

    this.modalController.dismiss();
  }
}
