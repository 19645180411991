<div class="header">
  <p class="title">Endereços</p>
  <ion-buttons slot="start" class="buttons-close">
    <ion-button class="button" (click)="closeModal()">
      <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
    </ion-button>
  </ion-buttons>
</div>

<ion-content>
  <fabapp-address-list
    (favoriteAddress)="setFavoriteAddress($event)"
    (editAddress)="goToAddress($event)"
    (removeAddress)="removeAddress($event)"
  >
  </fabapp-address-list>
</ion-content>
<ion-footer class="ion-padding ion-no-border">
  <ion-button
    *transloco="let t"
    class="nav-button"
    color="primary"
    fill="outline"
    expand="block"
    (click)="openCreateAndEdit()"
  >
    {{ t('settings.addressPage.addAddress') }}
  </ion-button>
</ion-footer>
