import {
  PushNotification,
  PushNotificationActionPerformed,
} from '@capacitor/core';
import { User } from '@core/models';

export class PushRequestPermision {
  static readonly type = '[Push] Request permission';
  constructor() {}
}
export class PushUpdateToken {
  static readonly type = '[Push] Update token';
  constructor(public token: string) {}
}

export class PushDeviceRegister {
  static readonly type = '[Push] Device register';
  constructor() {}
}

export class PushReceived {
  static readonly type = '[Push] Notification received';
  constructor(public payload: PushNotification) {}
}

export class PushClicked {
  static readonly type = '[Push] Notification clicked';
  constructor(public payload: PushNotificationActionPerformed) {}
}
