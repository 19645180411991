<ion-slides
  *ngIf="carouselItems"
  [class.swiper-no-swiping]="carouselItems.length === 1"
  [pager]="carouselItems.length > 1"
  #slider
  (ionSlideTouchStart)="slideChanged(slider)"
  (ionSlideTouchEnd)="enableAutoPlay(slider)"
  (ionSlidesDidLoad)="slidesDidLoad(slider)"
>
  <ion-slide *ngFor="let item of carouselItems; trackBy: trackByIndex">
    <div
      class="image"
      [style.backgroundImage]="
        (item.res_url || item.resourceUrl) &&
        'url(' + (item.res_url ? item?.res_url : item?.resourceUrl) + ')'
      "
      [style.backgroundColor]="item.background_color"
      (click)="carouselNavigation(item)"
    >
      <span class="caption" *ngIf="item.text">{{ item?.text }}</span>
    </div>
  </ion-slide>
</ion-slides>
