<ion-grid>
  <ion-row>
    <ion-col
      sizeXs="12"
      sizeMd="6"
      sizeLg="6"
      sizeXl="4"
      *ngFor="let item of items; trackBy: trackById"
    >
      <div class="carousel-card">
        <ion-slides [options]="slideOpts">
          <ion-slide
            (click)="handle(item)"
            class="img-wrapper ion-activatable"
            *ngFor="let image of item.listItemImages"
            [ngStyle]="{
              'background-image': 'url(' + image?.imageUrl + ')'
            }"
          >
            <ion-ripple-effect></ion-ripple-effect>
          </ion-slide>
        </ion-slides>
        <ion-label class="inner-wrapper">
          <h4 class="headline" (click)="handle(item)">{{ item.title }}</h4>
          <p class="description" (click)="handle(item)">{{ item?.resume }}</p>
          <app-rating-of-item
            *ngIf="listDef?.ratings"
            (rate)="handle(item, true)"
            [average]="item?.ratingsavg"
            [ratingsTotal]="item?.ratingstotal"
            [withSeparator]="item?.distance"
          ></app-rating-of-item>
          <span *ngIf="item.distance" class="distance">{{
            item.distance | meterToKilometer
          }}</span>
        </ion-label>
      </div>
    </ion-col>
  </ion-row></ion-grid
>
