import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@core/pipes/pipes.module';
import { IonicModule } from '@ionic/angular';
import { CardItemComponent } from './card-item.component';
import { IonicImageLoaderModule } from '../image-loader/ionic-image-loader.module';

@NgModule({
  declarations: [CardItemComponent],
  imports: [CommonModule, IonicModule, PipesModule, IonicImageLoaderModule],
  exports: [CardItemComponent],
})
export class CardItemModule {}
