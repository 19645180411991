import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { MakeRatingComponent } from './make-rating.component';

@NgModule({
  declarations: [MakeRatingComponent],
  imports: [CommonModule, IonicModule],
  exports: [MakeRatingComponent],
})
export class MakeRatingModule {}
