import { Component, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss'],
})
export class TabsPage {
  @ViewChild('#homeTab') homeTab: any;

  constructor() {}

  tabsChanged(event: { tab: string }) {
    //hack para selecionar a tab, o selected só é adicionado
    //naturalmente se a url for exatamente a da aba
    if (event.tab === 'fabapp-delivery') {
      document.querySelector('.home-tab').classList.add('tab-selected');
    } else {
      document.querySelector('.home-tab').classList.remove('tab-selected');
    }
  }
}
