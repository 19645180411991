import { CoreState } from './../../core/state/core/core.state';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeLayoutEnum } from '@core/enums';
import { AppDef, AppLayout, GroupLayoutDef, Page } from '@core/models';
import { GroupCarousel } from '@core/models/group';
import { NavigationService } from '@core/services/navigation.service';
import { AppDefState, LoadAppDef } from '@core/state/appdef';
import { LoadGroup } from '@core/state/moblets';
import { ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { SearchPageComponent } from '../../components/search/search-page.component';
import { GroupLayoutType } from './enums/group-layout-type.enum';
import { GroupMobletService } from './group.service';

@Component({
  selector: 'group-moblet',
  templateUrl: './group.page.html',
  styleUrls: ['./group.page.scss'],
  providers: [GroupMobletService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupMobletPage implements OnInit {
  static route: string = 'group';

  public layout: GroupLayoutDef;
  public moblet$: Observable<{ items: Page[] }>;

  public page$: Observable<Page>;

  public layoutHome: AppLayout;
  public readonly homeLayoutEnum = HomeLayoutEnum;

  @Select(AppDefState.getLayout) layout$: Observable<AppLayout>;
  @Select(AppDefState.getFromAppDef(['colors']))
  partialAppDef$: Observable<Partial<AppDef>>;

  subscriptions: Subscription[] = [];
  layoutType = GroupLayoutType;

  carousel: GroupCarousel;
  mobletId: number;
  isHome: boolean;

  constructor(
    private groupMobletService: GroupMobletService,
    private route: ActivatedRoute,
    private store: Store,
    public navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
    private modalCtrl: ModalController,
    private router: Router,
  ) {
    const homeRoute = this.store
      .selectSnapshot(CoreState.getHomeRoute)
      .join('');
    const currentRoute = this.router.url;

    this.isHome = homeRoute == currentRoute;
  }

  ngOnInit(): void {
    this.mobletId = +this.route.snapshot.params.id;

    this.page$ = this.store.select(AppDefState.getPage(this.mobletId));
    this.groupMobletService.loadMoblet(this.mobletId);
    this.loadGroupEntries();
    this._watchLayout();
  }

  private _watchLayout(): void {
    const subscription = this.layout$.subscribe((appLayout: AppLayout) => {
      this.layoutHome = appLayout;
    });
    this.subscriptions.push(subscription);
  }

  ionViewDidEnter(): void {
    this.loadMobletGroup();
  }

  ionViewWillLeave(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe(),
    );
  }

  loadMobletGroup(): void {
    this.getLayoutSettings();
    this.loadCarousels();
  }

  /**
   * requisição para pegar o layout do grupo de abas
   * @returns void
   */
  getLayoutSettings(): void {
    const subscription: Subscription = this.groupMobletService
      .layouts(this.el.nativeElement)
      .subscribe(
        (layout: GroupLayoutDef) => {
          this.layout = layout;
          this.cdr.markForCheck();
        },
        (err: any) => {
          this.layout = null;
          this.cdr.detectChanges();
          console.log('err', err);
        },
      );
    this.subscriptions.push(subscription);
  }

  /**
   * pega da memoria as paginas dentro do grupo de abas
   * @returns void
   */
  loadGroupEntries(): void {
    this.moblet$ = this.groupMobletService.entries;
  }

  loadCarousels(): void {
    const subscription: Subscription =
      this.groupMobletService.carosels.subscribe(
        (carousels: GroupCarousel[]) => {
          // TODO: remover getter do primeiro carousel
          if (carousels?.length > 0) {
            [this.carousel] = carousels;
          }
          this.cdr.markForCheck();
        },
      );
    this.subscriptions.push(subscription);
  }

  navigate(moblet: Page): void {
    const instanceId: any = moblet.instance.id;
    this.navigationService.navigate({
      instanceId,
    });
  }

  async doRefresh(ionRefresher: any): Promise<void> {
    const id: number = +this.route.snapshot.params.id;

    try {
      await this.store
        .dispatch([new LoadAppDef(), new LoadGroup(id)])
        .toPromise();
    } catch (error) {
      console.log('err', error);
    } finally {
      ionRefresher.target.complete();
    }
  }

  async openSearchModal(animated: boolean = true): Promise<void> {
    const modal: HTMLIonModalElement = await this.modalCtrl.create({
      animated,
      component: SearchPageComponent,
      componentProps: {
        groupId: this.mobletId,
      },
    });

    return await modal.present();
  }
}
