import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UserFormComponent } from './user-form.component';
import { PhoneEmailInputModule } from '../phone-email-input/phone-email-input.module';
import { ControlErrorModule } from '../control-error/control-error.module';
import { TranslocoModule } from '@ngneat/transloco';
import { DirectivesModule } from '@core/directives/directives.module';

@NgModule({
  declarations: [UserFormComponent],
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
    FormsModule,
    TranslocoModule,
    ControlErrorModule,
    ReactiveFormsModule,
    PhoneEmailInputModule,
  ],
  exports: [UserFormComponent],
})
export class UserFormModule {}
