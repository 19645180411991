import { Injectable, OnDestroy } from '@angular/core';
import { BaseMoblet } from '@core/models/moblets';
import { AddressState } from '@core/state/address/address.state';
import { AuthState } from '@core/state/auth';
import { SetCurrentStore } from '@core/state/core/core.actions';
import { LoadStore, MobletsState } from '@core/state/moblets';
import { LoadCategorizedProducts } from '@fabapp-delivery/state/stores/stores.actions';
import { StoresState } from '@fabapp-delivery/state/stores/stores.state';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BaseMobletService } from '../base-moblet.service';
import { FDI_Category } from './models/product/product.model';
import { FDI_Store } from './models/store';
import { DeliveryCartActions } from './state/cart/cart.actions';
import { LoadCoupons } from './state/promotions/promotions.action';
import { LoadTaxes } from './state/taxes/tax.action';

@Injectable()
export class FabappDeliveryService
  extends BaseMobletService<FDI_Store>
  implements OnDestroy
{
  protected _loadTaxesSubscription: Subscription;
  protected _categoriesSubscription: Subscription;
  protected _categories: BehaviorSubject<FDI_Category[]> = new BehaviorSubject<
    FDI_Category[]
  >(null);
  protected newCategoriesValue: FDI_Category[];

  private readonly cannotShowModalAddressKEY = `showed_address_modal_${window['appId']}`;
  public get cannotShowAddressModal(): boolean {
    return localStorage.getItem(this.cannotShowModalAddressKEY) == 'true';
  }

  public set cannotShowAddressModal(value: boolean) {
    localStorage.setItem(this.cannotShowModalAddressKEY, `${value}`);
  }

  get moblet(): Observable<BaseMoblet<FDI_Store>> {
    return this._moblet.asObservable().pipe(filter((data: any) => data));
  }

  get categories(): Observable<FDI_Category[]> {
    return this._categories.asObservable().pipe(
      filter((categoriesList: FDI_Category[]) => !!categoriesList),
      map((categoriesList: FDI_Category[]) =>
        categoriesList.filter((category) => category.products.length > 0),
      ),
    );
  }

  ngOnDestroy(): void {
    if (this._mobletSubscription) {
      this._mobletSubscription.unsubscribe();
    }
    if (this._mobletItemSubscription) {
      this._mobletItemSubscription.unsubscribe();
    }
    if (this._categoriesSubscription) {
      this._categoriesSubscription.unsubscribe();
    }
  }

  async loadMoblet(id: number): Promise<void> {
    this._mobletId = id;

    this.store.dispatch([new LoadStore(id), new LoadCategorizedProducts(id)]);

    const isAuthenticated = this.store.selectSnapshot(
      AuthState.isAuthenticated,
    );

    if (isAuthenticated) {
      this.store.dispatch(new DeliveryCartActions.Load());
    }

    this.store.dispatch(new LoadCoupons());

    if (!this._mobletSubscription) {
      this.setMobletSubscription();
    }

    if (!this._categoriesSubscription) {
      this.setCategoriesSubscription();
    }

    if (!this._loadTaxesSubscription) {
      this.setLoadTaxesSubscription();
    }
  }

  private setLoadTaxesSubscription(): void {
    this._loadTaxesSubscription = this.store
      .select(AddressState.getFavoriteAddress)
      .subscribe((address) => {
        this.store.dispatch(new LoadTaxes(address?.id));
      });
  }

  private setCategoriesSubscription(): void {
    this._categoriesSubscription = this.store
      .select(StoresState.getCategorizedProducts(this._mobletId))
      .subscribe((categories: FDI_Category[]) => {
        this._categories.next(categories);
      });
  }

  public setMobletSubscription(): void {
    this._mobletSubscription = this.store
      .select(MobletsState.getMoblet(this._mobletId))
      .subscribe((moblet: FDI_Store) => {
        this._moblet.next(moblet);
        this.refreshing = false;
      });
  }
}
