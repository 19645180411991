import { Component, Input } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import {
  ResetPasswordCodeRequest,
  ResetPasswordConfirmationCode,
  ResetPasswordState,
} from '@core/state/reset-password';
import {
  LoadingController,
  ModalController,
  NavController,
  ToastController,
} from '@ionic/angular';
import {
  translate,
  TranslocoService,
  TRANSLOCO_SCOPE,
} from '@ngneat/transloco';
import { Store } from '@ngxs/store';

const TRANSLATION_SCOPE = 'confirmation-code';
let LANG: string;
@Component({
  selector: 'fabapp-confirmation-code',
  templateUrl: 'confirmation-code.page.html',
  styleUrls: ['confirmation-code.page.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: TRANSLATION_SCOPE, alias: 'confirmationCode' },
    },
  ],
})
export class ConfirmationCodePage {
  @Input() isModal = false;

  isSubmitting = false;

  translationScope: string;

  resetType: 'email' | 'phone' = 'email';
  username: string;

  form = this.fb.group({
    code: ['', Validators.required],
  });

  baseTimeToResend = 30;
  timeToResend = 0;
  interval;

  constructor(
    private navCtrl: NavController,
    private fb: FormBuilder,
    public toastController: ToastController,
    private translocoService: TranslocoService,
    public loadingController: LoadingController,
    private store: Store,
    public modalController: ModalController,
  ) {
    LANG = this.translocoService.getActiveLang();

    this.username = this.store.selectSnapshot(ResetPasswordState.username);
    this.resetType = !/^\d+$/.test(this.username) ? 'email' : 'phone';
  }

  ionViewWillEnter(): void {
    if (!this.username) {
      this.goToCodeRequest();
    }
    this.startInterval();
  }

  ionViewWillLeave(): void {
    clearInterval(this.interval);
  }

  goToCodeRequest(): void {
    this.navCtrl.navigateRoot('/reset-password/code-request');
  }

  startInterval(): void {
    this.timeToResend = this.baseTimeToResend;
    this.interval = setInterval(() => {
      this.timeToResend--;
      if (this.timeToResend == 0) {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  get code(): AbstractControl {
    return this.form.get('code');
  }

  async presentLoading(): Promise<any> {
    if (this.timeToResend > 0) {
      return;
    }
    const loading = await this.loadingController.create({
      message: translate(
        'messages.resendingCode',
        {},
        `${TRANSLATION_SCOPE}/${LANG}`,
      ),
      duration: 2000,
    });

    this.store
      .dispatch(new ResetPasswordCodeRequest(this.username))
      .toPromise()
      .then();

    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    this.baseTimeToResend *= 2;
    this.startInterval();
  }

  async presentToast(translateKey: string): Promise<any> {
    const toast = await this.toastController.create({
      message: translate(translateKey, {}, `${TRANSLATION_SCOPE}/${LANG}`),
      color: 'danger',
      position: 'top',
      buttons: [
        {
          text: 'ok',
        },
      ],
    });
    toast.present();
  }

  async confimCode(): Promise<any> {
    try {
      this.isSubmitting = true;

      await this.store
        .dispatch(
          new ResetPasswordConfirmationCode(this.username, this.code.value),
        )
        .toPromise();

      this.goToResetPassword();
    } catch (err) {
      let msg = 'messages.';
      if (err.status == 404) {
        msg += 'notFound';
      } else {
        msg += 'genericError';
      }
      return this.presentToast(msg);
    } finally {
      this.isSubmitting = false;
    }
  }

  goToResetPassword(): void {
    clearInterval(this.interval);
    if (this.isModal) {
      this.modalController.dismiss({ goToResetPassword: true });
    } else {
      this.navCtrl.navigateRoot('/reset-password/reset-password');
    }
  }
}
