import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import {
  Plugins,
  registerWebPlugin,
  StatusBarAnimation,
} from '@capacitor/core';
import { AppDef, Colors, Page, User } from '@core/models';
import { InAppBrowserService } from '@core/services';
import { AdmobAdsService } from '@core/services/ads';
import { CommunicatorTest } from '@core/services/communicator-of-editor/communicator-mock.test';
import { CommunicatorService } from '@core/services/communicator-of-editor/communicator.service';
import { NavigationService } from '@core/services/navigation.service';
import { PwaService } from '@core/services/pwa.service';
import { FabappAdsState, LoadAds } from '@core/state/ads';
import { AppDefState } from '@core/state/appdef/';
import { AuthState, ReloadUser } from '@core/state/auth';
import { CoreState } from '@core/state/core/core.state';
import { PushRequestPermision } from '@core/state/push';
import {
  IonicHandler,
  IonicIsLoaded,
  IonicPageHeight,
  NativeCanBack,
  NativeConfigApp,
} from '@core/state/react-native/react-native.actions';
import { ReactNativeState } from '@core/state/react-native/react-native.state';
import { CheckIfExistTermToAccept } from '@core/state/themis/themis.action';
import { environment } from '@environments/environment';
import {
  IonRouterOutlet,
  isPlatform,
  MenuController,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import { getSupportedLang, setCssVariables } from '@utils';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { InterstitialGrumftModalComponent } from './components/interstitial-grumft-modal/interstitial-grumft-modal.component';
import { removeScrollbarInPreview } from './utils/hide-scrollbar-if-is-preview';
const { StatusBar, SplashScreen, App } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(IonRouterOutlet) routerOutlet: IonRouterOutlet;

  @Select(AppDefState.getPages) pages$: Observable<Page[]>;
  @Select(CoreState.menuEnabled) menuEnabled$: Observable<boolean>;
  @Select(AppDefState.getFromAppDef(['info', 'layout']))
  partialAppDef$: Observable<Partial<AppDef>>;

  @Select(AuthState.getUser) user$: Observable<User>;
  colorsSubscription: Subscription;
  splashSubscribe: Subscription;
  navSubscribe: Subscription;

  currentPlatform: 'IOS' | 'ANDROID';

  isReactNative = false;

  @HostListener('window:message', ['$event'])
  onMessage({ data }: MessageEvent): void {
    if (data.for === 'fabapp') {
      history.back = function () {};
      this.communicatorService.eventCall(data);
    }

    if (data.for === 'test') {
      history.back = function () {};
      const communicatorTest: CommunicatorTest = new CommunicatorTest(
        this.communicatorService,
        this.store,
      );
      communicatorTest[data.method]();
    }
  }

  @HostListener('window:react-native', ['$event'])
  onReactNative(e): void {
    console.log('onReactNative', e.detail);
    const { eventName, data } = e.detail;
    this.store.dispatch(new IonicHandler(eventName, data));
  }

  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    private store: Store,
    private navigationService: NavigationService,
    private menu: MenuController,
    private translocoService: TranslocoService,
    public adsService: AdmobAdsService,
    private communicatorService: CommunicatorService,
    private router: Router,
    private pwaService: PwaService,
    private inAppBrowserService: InAppBrowserService,
    private modalCtrl: ModalController,
  ) {
    registerWebPlugin(FacebookLogin);
    this.initializeApp();
    this.closeAppWhenPressBackButton();
    this.translocoService.setActiveLang(getSupportedLang());
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  beforeinstallprompt(e: any): void {
    this.pwaService.setInstallPrompt(e);
  }

  initializeApp(): void {
    this.isReactNative = this.store.selectSnapshot(
      ReactNativeState.getIsReactNative,
    );

    this.platform.ready().then(async () => {
      // this.store.dispatch(new ConfigureHeap());
      this.store.dispatch(new PushRequestPermision());

      if (this.platform.is('capacitor')) {
        window.screen.orientation.lock('portrait');
      }

      this.overrideWindowOpen();
      this.setColorsSubscription();
      window.addEventListener('keyboardWillShow', () => {
        document.body.classList.add('keyboard-is-open');
      });
      window.addEventListener('keyboardWillHide', () => {
        document.body.classList.remove('keyboard-is-open');
      });
      this.adsService.initialize();
    });
    this.store.dispatch(new LoadAds());
    this.store.dispatch(new ReloadUser());
    if (this.isReactNative) {
      this.partialAppDef$.pipe(take(1)).subscribe(() => {
        this.store.dispatch(new NativeConfigApp());
      });
      this.store.select(FabappAdsState.isShowingBanner).subscribe(() => {
        this.store.dispatch(new NativeConfigApp());
      });
    }
  }

  /**
   * @description
   * Encerra o aplicativo ao pressionar o botão Voltar do hardware.
   *
   * @note também pode ser mostrado um dialog para fechar o app
   */
  private closeAppWhenPressBackButton(): void {
    this.platform.backButton.subscribeWithPriority(-1, () => {
      if (!this.routerOutlet.canGoBack()) {
        App.exitApp();
      }
    });
  }

  async ngOnInit(): Promise<any> {
    if (this.platform.is('capacitor')) {
      this.currentPlatform = this.platform.is('android') ? 'ANDROID' : 'IOS';

      this.hideSplashScreenWeb();
    }

    this._watchNavigationsEnd();

    this.splashSubscribe = this.router.events.subscribe((evt: Event) => {
      if (evt instanceof NavigationEnd) {
        setTimeout(async () => {
          await this._actionsAfterFirstNavigationEnd();
          if (this.splashSubscribe) {
            this.splashSubscribe.unsubscribe();
          }
        }, 2500);

        if (this.isReactNative) {
          setTimeout(async () => {
            this.store.dispatch(new IonicIsLoaded());
            this.store.dispatch(new IonicPageHeight());
          }, 500);
        }
      }
    });

    if (this.isReactNative) {
      // Avisa React sobre o backbutton em aparelhos android
      this.navSubscribe = this.router.events.subscribe((evt: Event) => {
        if (evt instanceof NavigationEnd) {
          const homeRoute = this.store
            .selectSnapshot(CoreState.getHomeRoute)
            .join('');
          const currentRoute = this.router.url;

          if (homeRoute === currentRoute || currentRoute === '/home') {
            this.store.dispatch(new NativeCanBack(false));
          } else {
            this.store.dispatch(new NativeCanBack(true));
          }
        }
      });
    }
  }

  private async _actionsAfterFirstNavigationEnd() {
    this.notifyEditor();
    await this.hideSplashScreen();
    this.hideSplashScreenWeb();

    // default_status igual a `true` => plano gratuito
    const { default_status } = this.store.selectSnapshot(
      AppDefState.getFromAppDef(['default_status']),
    );
    if (default_status && !window['preview']) {
      const ref = await this.modalCtrl.create({
        component: InterstitialGrumftModalComponent,
        animated: false,
      });
      await ref.present();
    }

    this.store.dispatch(new CheckIfExistTermToAccept());
  }

  /**
   * @description
   * Customiza/Remove o scrollbar de todo `Ion-Content` presentes na View,
   * Dentro de um iframe (Galeria ou Preview) não será mostrado o scrollbar (menos o firefox)
   */
  private _watchNavigationsEnd() {
    this.router.events
      .pipe(
        filter((evt) => evt instanceof NavigationEnd && isPlatform('desktop')),
      )
      .subscribe(() => removeScrollbarInPreview());
  }

  notifyEditor(): void {
    if (window['preview']) {
      window.parent.postMessage('appDefLoaded', '*');
    }
  }

  private overrideWindowOpen(): void {
    this.inAppBrowserService.overrideWindowOpen();
  }

  setColorsSubscription(): void {
    this.colorsSubscription = this.store
      .select(AppDefState.getColors)
      .pipe(filter((colors: Colors) => !!colors))
      .subscribe((colors: Colors) => {
        setCssVariables(colors);
      });
  }

  login(): void {
    this.navCtrl.navigateForward('/auth/login');
    this.menu.close();
  }

  goSettings(): void {
    this.navCtrl.navigateForward('/settings');
    this.menu.close();
  }

  setHomePage(): void {
    this.navigationService.returnToHomePage();
  }

  navigate(page: Page): void {
    this.navigationService.navigate({ instanceId: +page.instance.id });
  }

  async hideSplashScreen(): Promise<void> {
    if (this.platform.is('capacitor')) {
      await StatusBar.show({
        animation: StatusBarAnimation.Fade,
      });
      await SplashScreen.hide();
    }
  }

  hideSplashScreenWeb(): void {
    const splash: Element = document.querySelector('#splashscreen-home');
    if (splash) {
      splash.classList.add('-hide');
    }
  }

  trackByIndex(index: number, item: Page): number {
    return item.id ? item.id : index;
  }

  goToTerms(): void {
    InAppBrowserService.windowOpen(environment.useTermsUrl, '_system');
  }

  goToPrivacy(): void {
    InAppBrowserService.windowOpen(environment.privacyPolicyUrl, '_system');
  }

  goToGooglePlay(googlePlayUrl: string): void {
    InAppBrowserService.windowOpen(googlePlayUrl, '_system');
  }

  goToAppStore(appleUrl: string): void {
    InAppBrowserService.windowOpen(appleUrl, '_system');
  }
}
