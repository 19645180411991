import { Component, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { StatusDelivery } from '@core/enums/status-delivery.enum';
import { BaseMoblet } from '@core/models/moblets';
import { DeliveryInfoOrder } from '@core/models/moblets/fabapp-delivery/delivery-info-order.model';
import { CoreState } from '@core/state/core/core.state';
import { FabappDeliveryService } from '@fabapp-delivery/fabapp-delivery.service';
import { FDI_Store } from '@fabapp-delivery/models/store';
import { OrdersService } from '@fabapp-delivery/services';
import { RatingService } from '@fabapp-delivery/services/rating.service';
import { TagRatingService } from '@fabapp-delivery/services/tag-rating.service';
import { AlertController, ToastController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.page.html',
  styleUrls: ['./order-detail.page.scss'],
  providers: [FabappDeliveryService],
})
export class OrderDetailPage implements OnInit {
  public storeInfo: FDI_Store;
  orderId: string;
  orderNumber: number;
  rating: number;
  dataOrder: DeliveryInfoOrder;
  storeId: number;
  subscribeStoreInfo: Subscription;
  completeRequestOrder: boolean;
  statusDelivery = StatusDelivery;
  hasClassification: boolean;

  form = this.$fb.group({
    rating: new FormControl(null),
    commentDelivery: new FormControl(''),
    commentOrder: new FormControl(''),
    ratingTagsOrder: new FormControl([]),
    ratingTagsDelivery: new FormControl([]),
    deliverySatisfaction: new FormControl(null),
  });

  constructor(
    private ordersService: OrdersService,
    public activatedRoute: ActivatedRoute,
    public fabappDeliveryService: FabappDeliveryService,
    private store: Store,
    private $fb: FormBuilder,
    private alertController: AlertController,
    private ratingService: RatingService,
    private toastController: ToastController,
  ) {}

  ngOnInit() {
    this.storeId = +this.activatedRoute.snapshot.params.id;
    this.fabappDeliveryService.loadMoblet(this.storeId);

    this.getData();
    this.storeId = this.store.selectSnapshot(CoreState.currentStoreId);

    this.subscribeStoreInfo = this.fabappDeliveryService.moblet.subscribe(
      (storeInfo: any) => {
        if (storeInfo) {
          this.hasClassification = storeInfo['hasClassification'];
          this.storeInfo = storeInfo;
        }
      },
    );
  }

  getData() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.completeRequestOrder = false;
      this.orderId = params['orderid'];
      this.orderNumber = params['orderNumber'];

      this.getInfoProduct();
    });
  }

  async getInfoProduct() {
    const order = await this.ordersService
      .getInfoProducts(this.orderId)
      .toPromise();
    this.dataOrder = order;
    this.orderNumber = this.dataOrder.orderNumber;

    this.dataOrder.amountFinal = this.formatAmountFinal(
      this.dataOrder.amountFinal,
    );
    this.dataOrder.shippingTax = this.formatAmountFinal(
      this.dataOrder.shippingTax,
    );
    this.dataOrder.subtotal = this.formatAmountFinal(this.dataOrder.subtotal);
    this.dataOrder.items = this.dataOrder.items.map((item) => {
      item.amount = this.formatAmountFinal(item.amount);
      return item;
    });

    this.completeRequestOrder = true;
  }

  private formatAmountFinal(price) {
    return (price / 100).toString();
  }

  async presentAlertConfirmRating() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Tem certeza?!',
      message: 'Deseja finalizar a avaliação?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Confirmar',
          handler: () => {
            this.submitRating();
          },
        },
      ],
    });

    await alert.present();
  }

  public async submitRating() {
    const ratingData = this.form.getRawValue();

    if (ratingData.rating === null) {
      const toast: HTMLIonToastElement = await this.toastController.create({
        message: 'Indique o número de estrelas que deseja avaliar!',
        duration: 2000,
        position: 'top',
      });

      toast.present();
      return;
    }

    const response = await this.ratingService
      .registerRating({
        ...ratingData,
        orderId: this.dataOrder.id,
      })
      .toPromise();

    this.dataOrder.rating = response;
  }

  public changeRating(rating: number) {
    this.form.get('rating').setValue(rating);
  }

  updateCommentDelivery(commentDelivery) {
    this.form.get('commentDelivery').setValue(commentDelivery);
  }

  updateCommentOrder(commentOrder) {
    this.form.get('commentOrder').setValue(commentOrder);
  }

  feedbackOrderOptionsUpdate(ratingTagsOrder) {
    this.form.get('ratingTagsOrder').setValue(ratingTagsOrder);
  }

  feedbackDeliveryOptionsUpdate(ratingTagsDelivery) {
    this.form.get('ratingTagsDelivery').setValue(ratingTagsDelivery);
  }

  updateDeliverySatisfaction(deliverySatisfaction) {
    this.form.get('deliverySatisfaction').setValue(deliverySatisfaction);
  }
}
