import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AdOptions } from '@capacitor-community/admob';
import { Plugins, Capacitor } from '@capacitor/core';
import { AppInfo } from '@core/models';
import { FabappAdsState, ShowAdInterstitial } from '@core/state/ads';
import { AppDefState } from '@core/state/appdef';
import { CoreState } from '@core/state/core/core.state';
import { isPlatform, Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { filter, skip, tap } from 'rxjs/operators';
import { DivulgationModalComponent } from 'src/app/components/divulgation-modal/divulgation-modal.component';
import { AdsValidationsHelper } from './ads-validations-helper';
import { BannerBuilder } from './banner-builder';
import { InterstitialBuilder } from './interstitial-builder';
const { AdMob, Toast } = Plugins;

type BannerActions = 'hide' | 'resume' | 'show' | 'remove';
type InterstitialActions = 'load' | 'show';
/**
 * Nome dos eventos do banner
 *
 * @see https://github.com/capacitor-community/admob#event-listener
 */
enum BannerEventsNameEnum {
  'onAdLoaded' = 'onAdLoaded',
  'onAdFailedToLoad' = 'onAdFailedToLoad',
  'onAdOpened' = 'onAdOpened',
  'onAdClosed' = 'onAdClosed',
  'onAdSize' = 'onAdSize',
}

@Injectable({
  providedIn: 'root',
})
export class AdmobAdsService extends AdsValidationsHelper {
  /**
   * @description
   * array de strings ou RegExp (rotas para esconder o banner do admob)
   */
  private routesForHide: (string | RegExp)[] = [
    '/auth',
    '/reset-password',
    '/settings',
    '/ratings',
    '/forms',
    '/mural',
    /youtube\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+/,
    /videos\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+/,
    /videos\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+/,
    '/catalog',
    '/rewards',
    '/fabapp-commerce',
    '/fabapp-delivery',
  ];
  private info: AppInfo;
  private canShowBannerAdmob: boolean = false;
  private shouldNotShowAd: boolean = false;
  private canShowInterstitial: boolean = false;
  private failedToShowBanner: boolean = false;

  private fabappAdsCounter = { steps: 2, step: 2, count: 0, max: 6 };
  private admobInterstitialCounter = { count: 0, max: 3 };

  private _appMargin: number = 0;
  public get appMargin(): number {
    return this._appMargin;
  }
  public set appMargin(value: number) {
    this._appMargin = value;
    const app: HTMLElement = document.querySelector('ion-router-outlet');
    app.style.marginBottom = this.appMargin + 'px';
  }

  static get isAdmobAvailable(): boolean {
    return Capacitor.isPluginAvailable('AdMob');
  }

  isPrepareInterstitial = false;
  constructor(
    private store: Store,
    protected platform: Platform,
    private router: Router,
  ) {
    super();
    if (AdmobAdsService.isAdmobAvailable) {
      AdMob.initialize({ requestTrackingAuthorization: true });
    }
    this.startAdCounters();
    this.setInterstitialIntervalSubscription();
    this.setAdInterstitialCampaignSubscription();
  }

  /**
   * @description
   * para inicializar o admob é necessário ter
   * id da plataforma (ios/android) e
   * seus respectivos banners
   *
   * @returns void
   */
  public async initialize(): Promise<boolean> {
    // initialize Admob banner events
    this.initializeAdmobEventListeners();
    const info: AppInfo = this.getInfoAdmob();
    // se não tiver o plugin disponivel retorna
    if (!info || !AdmobAdsService.isAdmobAvailable) {
      return;
    }

    try {
      return this.loadAds();
    } catch (error) {
      console.log('Cannot init admob: ', error);
      this.errorToLoad();
    }
  }

  private setInterstitialIntervalSubscription() {
    this.store.select(AppDefState.getInfo).subscribe((info) => {
      this.admobInterstitialCounter.max = parseInt(
        info.admob_interstitial_interactions,
        10,
      );
    });
  }

  private setAdInterstitialCampaignSubscription() {
    this.store
      .select(FabappAdsState.adInterstitialCampaign)
      .subscribe((campaign) => {
        if (campaign?.maxInteractions) {
          this.fabappAdsCounter.max = campaign.maxInteractions;
          this.fabappAdsCounter.step = 1;
          this.fabappAdsCounter.steps = 1;
        }
      });
  }

  public hasAdmobConfigured(): boolean {
    return this.hasAppId(this.info);
  }

  private async loadAds(): Promise<boolean> {
    try {
      const { value } = await AdMob.initialize();

      this.loadInterstitial();
      this.showBanner();
      return value;
    } catch (error) {
      throw error;
    }
  }

  private getInfoAdmob(): AppInfo {
    const { info } = this.store.selectSnapshot(
      AppDefState.getFromAppDef(['info', 'default_status']),
    );

    this.info = info;
    if (!this.hasAppId(info) || !isPlatform('capacitor')) {
      return null;
    }

    this.canShowBannerAdmob = this.bannerActive(info);
    this.canShowInterstitial = this.interstitialActive(info);
    this.shouldNotShowAd =
      !this.canShowInterstitial && !this.canShowBannerAdmob;

    if (this.shouldNotShowAd) {
      return null;
    }

    return info;
  }

  private async loadInterstitial(): Promise<void> {
    return await this.interstitialActions('load');
  }

  /**
   * @description
   * after show interstitial load other interstitial
   */
  private async showInterstitial(): Promise<void> {
    if (!AdmobAdsService.isAdmobAvailable) {
      return;
    }

    try {
      if (!this.isPrepareInterstitial) {
        await this.loadInterstitial();
      }
      await this.interstitialActions('show');
    } catch (error) {
      console.log('Cannot show interstitital:', error);
    } finally {
      this.isPrepareInterstitial = false;
    }
  }

  private async showBanner(): Promise<void> {
    try {
      const result: any = await this.bannerActions('show');
      return result;
    } catch (error) {
      console.log('Cannot show banner: ', error);
      throw error;
    }
  }

  public async resumeBanner(): Promise<void> {
    return await this.bannerActions('resume');
  }

  /**
   * @description
   * se houve uma falha anteriomente
   * o banner já foi removido
   * logo não é possivel chamar o hide
   * por isso o:
   * if (this.failedToShowBanner) { return; }
   * @returns Promise
   */
  public async hideBanner(): Promise<void> {
    if (this.failedToShowBanner) {
      return;
    }

    return await this.bannerActions('hide');
  }

  /**
   * @description
   * remove instance of banner
   * @returns Promise
   */
  private async removeBanner(): Promise<void> {
    return await this.bannerActions('remove');
  }

  /**
   * @description
   * apresenta toast com erro genérico,
   * sendo apresentado apenas no preview
   * @returns Promise
   */
  private async errorToLoad(errorCode?: number): Promise<void> {
    if (!window['preview'] && isPlatform('capacitor')) {
      return;
    }

    await Toast.show({
      text: 'Os anúncios do Admob só apareceram no compilado',
      duration: 'long',
      position: 'bottom',
    });
    return;
  }

  private initializeAdmobEventListeners(): void {
    if (!isPlatform('capacitor') || !AdmobAdsService.isAdmobAvailable) {
      return;
    }

    AdMob.addListener(BannerEventsNameEnum.onAdFailedToLoad, (info: any) => {
      this.errorToLoad();
      this.failedToShowBanner = true;
      this.removeBanner();
    });

    AdMob.addListener(BannerEventsNameEnum.onAdClosed, (info: any) => {
      this._removeMargin();
    });

    AdMob.addListener(
      BannerEventsNameEnum.onAdSize,
      (info: { width: string; height: string }) => {
        this.appMargin = parseInt(info.height, 10);
        if (this.appMargin > 0) {
          this.setSafeArea();
        }
      },
    );

    AdMob.addListener('onInterstitialAdLoaded', () => {
      this.isPrepareInterstitial = true;
    });
  }

  private async interstitialActions(
    action: InterstitialActions,
  ): Promise<void> {
    if (!this.canShowInterstitial || !isPlatform('capacitor')) {
      return;
    }

    let options: AdOptions;
    if (action === 'load') {
      options = new InterstitialBuilder()
        .adIdAndroid(this.info.admob_android_publisher_id)
        .adIdIos(this.info.admob_ios_publisher_id)
        .isTesting(false)
        .build();
    }

    const actions: any = {
      show: async () => await AdMob.showInterstitial(),
      load: async () => await AdMob.prepareInterstitial(options),
    };

    if (!actions[action]) {
      return null;
    }

    return await actions[action]();
  }

  private async bannerActions(action: BannerActions): Promise<void> {
    // se fabapp-delivery estiver habilitado não executará nenhuma ação
    const isFabappDeliveryEnabled = this.store.selectSnapshot(
      CoreState.isFabappDeliveryEnabled,
    );
    if (
      !this.canShowBannerAdmob ||
      !isPlatform('capacitor') ||
      !AdmobAdsService.isAdmobAvailable ||
      isFabappDeliveryEnabled
    ) {
      return null;
    }

    // quando houver erro ao mostrar o banner
    // e for chamado o resume não deve chamar o método do plugin
    if (action === 'resume' && this.failedToShowBanner) {
      return null;
    }

    let banner: AdOptions;
    if (action === 'show') {
      banner = new BannerBuilder()
        .adIdAndroidBanner(this.info.admob_android_banner_id)
        .adIdIosBanner(this.info.admob_ios_banner_id)
        .isTesting(false)
        .build();
    }

    return await this.dispatchAction(action, banner);
  }

  private async dispatchAction(
    action: BannerActions,
    banner?: AdOptions,
  ): Promise<void> {
    const actions: any = {
      hide: async () => {
        this._removeMargin();
        await AdMob.hideBanner();
      },
      resume: async () => {
        await AdMob.resumeBanner();
        this.setSafeArea();
      },
      show: async () => {
        await AdMob.showBanner(banner);
        this.setSafeArea();
      },
      remove: async () => {
        this._removeMargin();
        await AdMob.removeBanner();
      },
    };

    if (!actions[action]) {
      return null;
    }

    return await actions[action]();
  }

  private startAdCounters(): void {
    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          this.triggerBanner(event.url);
          return event;
        }),
        skip(1),
      )
      .subscribe(() => {
        this.fabappAdsCounter.count += 1;
        this.admobInterstitialCounter.count += 1;

        if (
          this.admobInterstitialCounter.count ===
          this.admobInterstitialCounter.max
        ) {
          this.admobInterstitialCounter.count = 0;
          this.triggerInterstitial();
        }

        if (this.fabappAdsCounter.count >= this.fabappAdsCounter.max) {
          this.fabappAdsCounter.count = 0;
          this.triggerFabappModal();
        }
      });
  }

  /**
   * @description
   * chama os eventos de mostrar quando o banner foi escondido
   * banner ou esconder banner
   * de acordo com rota usando uma string para o match ou RegExp
   * @returns void
   */
  private triggerBanner(url: string): void {
    const canHideBanner: boolean = this.routesForHide.some((route: string) =>
      url.match(route),
    );

    if (canHideBanner) {
      this.hideBanner();
    } else if (this.appMargin === 0) {
      this.resumeBanner();
    }
  }

  private triggerInterstitial(): void {
    this.showInterstitial();
  }

  private triggerFabappModal(): void {
    if (!window['preview']) {
      this.store.dispatch(new ShowAdInterstitial(DivulgationModalComponent));
    }
  }

  /**
   * @description
   * Cria um margin-bottom no ion-router-outlet que seja do tamanho
   * da `--ion-safe-area-bottom` + o `height` do anúncio
   */
  private setSafeArea() {
    const body = document.querySelector('body');
    const bodyStyles = window.getComputedStyle(body);
    const safeAreaBottom = bodyStyles.getPropertyValue(
      '--ion-safe-area-bottom',
    );

    const app: HTMLElement = document.querySelector('ion-router-outlet');
    const gap: number = 8;
    app.style.marginBottom = `calc(${safeAreaBottom} + ${gap}px + ${this.appMargin}px)`;
    body.style.backgroundColor = `var(--ion-color-primary)`;
  }

  private _removeMargin() {
    this.appMargin = 0;
  }
}
