import {
  animate,
  AnimationTriggerMetadata,
  group,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

const slideDownAnimation: AnimationTriggerMetadata = trigger('slideDown', [
  state('0', style({ height: '0px', opacity: 0 })),
  state('1', style({ height: '*', opacity: 1 })),
  transition('0 => 1', [
    style({ height: '0', opacity: 0 }),

    group([
      animate(300, style({ height: '*' })),
      animate('400ms ease-in-out', style({ opacity: '1' })),
    ]),
  ]),
  transition('1 => 0', [
    style({ height: '*', opacity: 1 }),

    group([
      animate(300, style({ height: 0 })),
      animate('200ms ease-in-out', style({ opacity: '0' })),
    ]),
  ]),
]);

export default slideDownAnimation;
