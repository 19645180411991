import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Coupon } from '@fabapp-delivery/models/promotions/coupon/coupon.model';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'fabapp-promotion-in-cart',
  templateUrl: './promotion-in-cart.component.html',
  styleUrls: ['./promotion-in-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromotionInCartComponent implements OnInit {
  @Input('couponSelected')
  public set setCouponSelected(value: Coupon) {
    this.couponSelected = value;
  }
  public couponSelected: Coupon;

  @Input('promotionsCount')
  public set setPromotionsCount(value: number) {
    this.promotionsCount = value;
  }
  public promotionsCount: number;
  @Output() openPromotions = new EventEmitter<any>();
  constructor(public readonly modalController: ModalController) {}

  ngOnInit(): void {}

  open() {
    this.openPromotions.emit();
  }
}
