import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { environment } from '@environments/environment';
import { isPlatform } from '@ionic/angular';
const { Device, Storage } = Plugins;

type PingParams = {
  appId: number;
  uniqueId?: string;
  userId?: string;
  platform?: 'web' | 'native';
};

@Injectable({ providedIn: 'root' })
export class HadesService {
  public deviceId: string;

  constructor(private httpClient: HttpClient) {
    this.getPlatForm();
  }

  private get headers(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  async ping(data: PingParams): Promise<void> {
    await this.getPlatForm();
    data.uniqueId = this.deviceId;
    data.platform = isPlatform('capacitor') ? 'native' : 'web';

    const url = `${environment.hades.url}/ping`;
    try {
      await this.httpClient
        .post(url, data, {
          headers: this.headers,
        })
        .toPromise();
    } catch (error) {
      console.log('Error on ping: ', error.message);
    }
  }

  private async setDeviceId(uuid: string): Promise<void> {
    this.deviceId = (await Storage.get({ key: 'fd_device_id' })).value;
    if (!this.deviceId) {
      this.deviceId = uuid;
      await Storage.set({
        key: 'fd_device_id',
        value: this.deviceId,
      });
    }
  }

  private async getPlatForm(): Promise<void> {
    const { uuid } = await Device.getInfo();
    this.setDeviceId(uuid);
  }
}
