import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthState } from '@core/state/auth';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/pages/auth/services/auth.service';
import { RefreshTokenService } from './refresh-token.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  refreshTokenService: RefreshTokenService;

  constructor(public store: Store, private authService: AuthService) {
    this.refreshTokenService = new RefreshTokenService(store, authService);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token: string = this.store.selectSnapshot(AuthState.accessToken);
    const currentRouteNeedAuth: boolean = this.routeNeedAuth(request);

    if (token && currentRouteNeedAuth) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.refreshTokenService.handleResponseError(error, request, next),
        ),
      );
  }

  private routeNeedAuth(request: HttpRequest<any>) {
    const isGetMethod: boolean = request.method.toLowerCase() === 'get';
    const isProductsRoute: boolean =
      request.url === `${environment.shop.url}/app/products` && isGetMethod;
    const isStoresRoute: boolean =
      request.url === `${environment.shop.url}/app/stores` && isGetMethod;
    if (!isProductsRoute) {
      return true;
    }

    return false;
  }
}
