import { AdOptions } from '@capacitor-community/admob';
import { isPlatform } from '@ionic/core';

export class InterstitialBuilder {
  private _adIdAndroid: string = '';
  private _adIdIos: string = '';
  private _isTesting: boolean = false;

  build(): AdOptions {
    return {
      adId: isPlatform('android') ? this._adIdAndroid : this._adIdIos,
      isTesting: this._isTesting,
      margin: 0,
    };
  }

  public isTesting(value: boolean): this {
    this._isTesting = value;
    return this;
  }

  public adIdAndroid(value: string): this {
    this._adIdAndroid = value;
    return this;
  }

  public adIdIos(value: string): this {
    this._adIdIos = value;
    return this;
  }
}
