import { GridNameSize } from '@core/enums';

export function getTextSize(fontSizePx: number): number {
  if (fontSizePx >= 8 && fontSizePx <= 16) {
    return GridNameSize.Small;
  } else if (fontSizePx >= 17 && fontSizePx <= 32) {
    return GridNameSize.Medium;
  } else if (fontSizePx >= 33) {
    return GridNameSize.Large;
  }
}
