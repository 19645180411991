import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CpfCnpjValidator } from '@core/validators';
import { CreditCardValidators } from 'angular-cc-library';
import { PaymentMethodForCreate } from '../../models/payment-method-for-save.mode';
import { FDPaymentMethod } from '../../models/payment-method.model';

@Component({
  selector: 'fabi-payment-method-form',
  templateUrl: './payment-method-form.component.html',
  styleUrls: ['./payment-method-form.component.scss'],
})
export class PaymentMethodFormComponent implements OnInit {
  @Input('isEditing')
  set setIsEditing(value: boolean) {
    this.isEditing = value;
    if (this.isEditing) {
      this.disableFieldsThatMustNotBeEdited();
    }
  }
  isEditing = false;

  readonly FIELDS = {
    CARD_HOLDER_NAME: 'cardHolderName',
    CARD_NUMBER: 'cardNumber',
    CARD_EXPIRATION_DATE: 'cardExpirationDate',
    CARD_CVV: 'cardCvv',
    NICKNAME: 'nickname',
    DOCUMENT_NUMBER: 'documentNumber',
  };

  fieldsMaxLength = {
    [this.FIELDS.CARD_HOLDER_NAME]: 150,
    [this.FIELDS.CARD_CVV]: 4,
    [this.FIELDS.NICKNAME]: 50,
    [this.FIELDS.CARD_EXPIRATION_DATE]: 7,
    [this.FIELDS.DOCUMENT_NUMBER]: 14,
  };

  FIELDS_VALIDATORS = {
    [this.FIELDS.CARD_HOLDER_NAME]: [
      Validators.required,
      Validators.maxLength(this.fieldsMaxLength[this.FIELDS.CARD_HOLDER_NAME]),
    ],
    [this.FIELDS.CARD_NUMBER]: [
      Validators.required,
      CreditCardValidators.validateCCNumber,
    ],
    [this.FIELDS.NICKNAME]: [
      Validators.maxLength(this.fieldsMaxLength[this.FIELDS.NICKNAME]),
    ],
    [this.FIELDS.DOCUMENT_NUMBER]: [
      Validators.required,
      CpfCnpjValidator.validate,
    ],
    [this.FIELDS.CARD_CVV]: [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(this.fieldsMaxLength[this.FIELDS.CARD_CVV]),
    ],
    [this.FIELDS.CARD_EXPIRATION_DATE]: [
      Validators.required,
      CreditCardValidators.validateExpDate,
    ],
  };
  form: FormGroup = new FormGroup({});
  constructor() {
    this._fillForm();
  }

  private disableFieldsThatMustNotBeEdited() {
    for (const key in this.form.controls) {
      const isNotNickName = key !== this.FIELDS.NICKNAME;
      const hasControl = Object.prototype.hasOwnProperty.call(
        this.form.controls,
        key,
      );
      if (hasControl && isNotNickName) {
        this.form.controls[key].disable();
        this.form.controls[key].clearValidators();
      }
    }
  }

  ngOnInit() {}

  private _fillForm(): void {
    for (const key in this.FIELDS_VALIDATORS) {
      if (Object.prototype.hasOwnProperty.call(this.FIELDS_VALIDATORS, key)) {
        const validator = this.FIELDS_VALIDATORS[key];
        this.form.addControl(key, new FormControl('', validator));
      }
    }
  }

  mergeValue(data: FDPaymentMethod): void {
    const {
      documentNumber,
      expirationDate,
      holderName,
      nickname,
      lastDigits,
    } = data;
    this.form.get(this.FIELDS.DOCUMENT_NUMBER).setValue(documentNumber);
    this.form.get(this.FIELDS.CARD_EXPIRATION_DATE).setValue(expirationDate);
    this.form.get(this.FIELDS.CARD_HOLDER_NAME).setValue(holderName);
    this.form.get(this.FIELDS.NICKNAME).setValue(nickname);
    this.form.get(this.FIELDS.CARD_NUMBER).setValue('***' + lastDigits);
    this.form.get(this.FIELDS.CARD_CVV).setValue('***');
  }

  get isInvalid() {
    return this.form.invalid;
  }

  get formValue(): PaymentMethodForCreate {
    const value = this.form.value;
    if (this.isEditing) {
      return value;
    }

    const cardNumber = this._removeNonDigits(value[this.FIELDS.CARD_NUMBER]);
    const cardExpirationDate = this._removeNonDigits(
      value[this.FIELDS.CARD_EXPIRATION_DATE],
    );
    const documentNumber = this._removeNonDigits(
      value[this.FIELDS.DOCUMENT_NUMBER],
    );

    return {
      ...value,
      [this.FIELDS.CARD_NUMBER]: cardNumber,
      [this.FIELDS.CARD_EXPIRATION_DATE]: cardExpirationDate,
      [this.FIELDS.DOCUMENT_NUMBER]: documentNumber,
    };
  }

  _removeNonDigits(value: string) {
    return value.replace(/[^\d]+/g, '');
  }
}
