<ion-header class="ion-no-border">
  <fabapp-header [isModal]="isModal" color="transparent"> </fabapp-header>
</ion-header>

<ng-container *transloco="let t">
  <ion-content class="ion-padding-horizontal">
    <div class="vertical-center">
      <div class="content">
        <div class="head ion-text-center">
          <h1 class="ion-padding-top featured">{{t('resetPassword.title')}}</h1>
          <p>{{t('resetPassword.message')}}</p>
        </div>

        <form [formGroup]="form">
          <ion-list class="ion-no-padding" lines="none">
            <ion-item>
              <ion-label position="floating"
                >{{t('resetPassword.fields.password.label')}}</ion-label
              >
              <ion-input
                outline
                formControlName="password"
                [type]="passwordType"
                maxlength="20"
              ></ion-input>
              <ion-icon
                slot="end"
                class="ion-align-self-center"
                [name]="passwordType == 'password' ? 'eye' : 'eye-off'"
                (click)="togglePasswordMode()"
              >
              </ion-icon>
            </ion-item>
            <ion-item>
              <ion-label position="floating"
                >{{t('resetPassword.fields.password_confirmation.label')}}</ion-label
              >
              <ion-input
                outline
                formControlName="password_confirmation"
                [type]="passwordConfirmationType"
                maxlength="20"
              ></ion-input>
              <ion-icon
                slot="end"
                class="ion-align-self-center"
                [name]="passwordConfirmationType == 'password' ? 'eye' : 'eye-off'"
                (click)="togglePasswordConfirmationTypeMode()"
              >
              </ion-icon>
            </ion-item>
          </ion-list>
        </form>
      </div>
    </div>
  </ion-content>

  <ion-footer class="ion-no-border">
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col col-12>
            <ion-button
              [disabled]="isSubmitting"
              expand="block"
              color="primary"
              [validate]="form"
              (click)="resetPass()"
            >
              {{t('resetPassword.buttons.save')}}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-footer>
</ng-container>
