import { Component, OnDestroy, OnInit } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { StatusDelivery } from '@core/enums/status-delivery.enum';
import { DeliveryInfoOrder } from '@core/models/moblets/fabapp-delivery/delivery-info-order.model';
import { SocketDeliveryService } from '@fabapp-delivery/services/socket-delivery.service';
import { ModalController, ToastController } from '@ionic/angular';
import { legacyCopyToClipboard } from '@utils';
import { differenceInSeconds } from 'date-fns';

const { Clipboard } = Plugins;

@Component({
  selector: 'app-pix-payment',
  templateUrl: './pix-payment.page.html',
  styleUrls: ['./pix-payment.page.scss'],
})
export class PixPaymentPage implements OnInit, OnDestroy {
  expired = true;
  order: DeliveryInfoOrder;
  countdownInterval: NodeJS.Timeout;
  countdownSeconds: number;
  currentTime: Date;
  socketDelivery: any;

  constructor(
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
  ) {}

  ngOnInit(): void {
    this.attemptCountdown();

    if (this.socketDelivery) {
      this.socketDelivery.disconnect();
      this.socketDelivery = null;
    }

    const { appId, storeId, id: orderId } = this.order;

    this.socketDelivery = new SocketDeliveryService(
      appId,
      storeId.toString(),
      orderId,
    );

    this.socketDelivery.on(`${appId}:${storeId}:${orderId}:status`, (data) => {
      this.handleStatusChangeEvent(data?.statusId);
    });
  }

  ngOnDestroy() {
    this.socketDelivery!.disconnect();
    this.socketDelivery = null;
  }

  async copyToClipboard(code: string) {
    Clipboard.write({
      string: code,
    });

    if (navigator.clipboard) {
      await navigator.clipboard.writeText(code);
    }

    legacyCopyToClipboard(code);

    const toast: HTMLIonToastElement = await this.toastCtrl.create({
      message: 'Copiado para área de transfêrencia',
      duration: 2000,
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
          side: 'end',
        },
      ],
    });

    await toast.present();
  }

  attemptCountdown() {
    const diff = differenceInSeconds(
      new Date(this.order.lastTransaction?.pixExpirationDate),
      new Date(),
    );

    if (diff > 0) {
      this.startCountdown(diff);
    }

    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  }

  startCountdown(seconds: number) {
    this.countdownSeconds = seconds;
    this.countdownInterval = setInterval(() => {
      this.countdownSeconds = this.countdownSeconds - 1;
      if (this.countdownSeconds < 0) {
        clearInterval(this.countdownSeconds);
      }
    }, 1000);
  }

  replicateOrder() {
    // irá replicar o carrinho com os mesmos produtos
    this.close({ replicate: true });
  }

  close(data?: any) {
    this.modalCtrl.dismiss(data);
  }

  handleStatusChangeEvent(orderStatus: StatusDelivery) {
    if (
      orderStatus === StatusDelivery.CANCELED ||
      orderStatus === StatusDelivery.PENDING
    ) {
      return this.close({ goToOrder: true });
    }
  }
}
