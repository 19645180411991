import { AppInfo } from '@core/models';
import { isPlatform } from '@ionic/angular';

export class AdsValidationsHelper {
  protected hasAppId({ admob_android_id, admob_ios_id }: AppInfo): boolean {
    if (isPlatform('android')) {
      return !!admob_android_id;
    }

    if (isPlatform('ios')) {
      return !!admob_ios_id;
    }

    return false;
  }

  /**
   * @description
   * valida se existe ID do interstitial
   * @returns boolean
   */
  protected interstitialActive({
    admob_android_id,
    admob_android_publisher_id,
    admob_ios_id,
    admob_ios_publisher_id,
  }: AppInfo): boolean {
    if (isPlatform('android')) {
      return !!admob_android_id && !!admob_android_publisher_id;
    }

    if (isPlatform('ios')) {
      return !!admob_ios_publisher_id && !!admob_ios_id;
    }

    return false;
  }

  /**
   * @description
   * valida se admob está ativado
   *
   * @returns boolean
   */
  protected bannerActive({
    admob_android_id,
    admob_android_banner_id,
    admob_ios_banner_id,
    admob_ios_id,
  }: AppInfo): boolean {
    if (isPlatform('android')) {
      return !!(admob_android_id && admob_android_banner_id);
    }

    if (isPlatform('ios')) {
      return !!(admob_ios_banner_id && admob_ios_id);
    }

    return false;
  }
}
