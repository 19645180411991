import { Injectable } from '@angular/core';
import { CancelLoadStore } from '@core/state/moblets';
import { FDI_Category } from '@fabapp-delivery/models/product/product.model';
import { ProductService } from '@fabapp-delivery/services';
import {
  Action,
  Actions,
  createSelector,
  ofAction,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { takeUntil, tap } from 'rxjs/operators';
import { LoadCategorizedProducts } from './stores.actions';

export interface StoresStateModel {
  [storeId: number]: FDI_Category[];
}

@State<StoresStateModel>({
  name: 'stores',
  defaults: {},
})
@Injectable()
export class StoresState {
  constructor(
    private productService: ProductService,
    private actions$: Actions,
  ) {}

  static getCategorizedProducts(id: number): (state: StoresStateModel) => any {
    return createSelector([StoresState], (state: StoresStateModel) => {
      return state[id];
    });
  }

  @Selector()
  static getAllStores(state: StoresStateModel): StoresStateModel {
    return state;
  }

  @Action(LoadCategorizedProducts)
  LoadCategorizedProducts(
    ctx: StateContext<any>,
    { storeId }: LoadCategorizedProducts,
  ) {
    const state: any = ctx.getState();
    return this.productService.getCategorizedProducts(storeId).pipe(
      tap((productsFromApi: FDI_Category[]) => {
        ctx.setState({
          ...state,
          [storeId]: productsFromApi,
        });
      }),
      takeUntil(this.actions$.pipe(ofAction(CancelLoadStore))),
    );
  }
}
