<ion-header translucent *transloco="let t">
  <ion-toolbar mode="md">
    <ion-buttons *ngIf="!hasDelivery" slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ t('search.searchTitle') }}</ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <form (ngSubmit)="search($event)">
      <ion-searchbar
        [placeholder]="t('search.searchPlaceholder')"
        searchIcon="search"
        debounce="800"
        type="search"
      >
      </ion-searchbar>
    </form>
  </ion-toolbar>
</ion-header>

<ng-container *transloco="let t">
  <ion-content fullscreen>
    <ion-list *ngIf="results && results.length > 0 && !loading">
      <ion-item
        [detail]="false"
        button
        *ngFor="let result of results; trackBy: trackById"
        (click)="goToItem(result)"
      >
        <ion-thumbnail slot="start">
          <img [src]="result.item.image || result.item.image_url" />
        </ion-thumbnail>
        <ion-label>
          <h2>{{ result.item.title }}</h2>
          <p [innerHTML]="result.item.description"></p>
          <!-- product price -->
          <span
            class="price"
            [class.old]="result.item?.shops_promoValue"
            *ngIf="result.item?.shops_value"
            >{{
              result.item.shops_value
                | currencyformat: result.item?.shops_currency
            }}</span
          >
          <span class="price" *ngIf="result.item?.shops_promoValue">{{
            result.item.shops_promoValue
              | currencyformat: result.item?.shops_currency
          }}</span>
          <!-- product price -->

          <!-- mercado livre price -->
          <span class="price" *ngIf="result.item?.mercado_livre_footer">{{
            result.item?.mercado_livre_footer
          }}</span>
        </ion-label>
      </ion-item>
    </ion-list>

    <!-- Loader -->
    <div class="vertical-center ion-text-center" *ngIf="loading">
      <div class="content">
        <ion-spinner></ion-spinner>
      </div>
    </div>

    <!-- TODO: change empty state -->
    <div class="empty-state ion-text-center" *ngIf="isEmpty && !loading">
      <ion-label
        [innerHTML]="'search.empty' | transloco: { filter: filter }"
      ></ion-label>
    </div>
  </ion-content>
</ng-container>

<fabapp-banner-footer *ngIf="!hasDelivery"></fabapp-banner-footer>
