import { FabAppAdTypeEnum } from '@core/enums/fabapp-ad/fab-app-ad-type.enum';
import { FAB_Ad } from '@core/models/fabapp-ads/ad.model';

export class LoadAds {
  static readonly type = '[app] Load Ads';
}

export class SetAdsAsClicked {
  static readonly type = '[app] Set Ads as Clicked';
  constructor(public ad?: FabAppAdTypeEnum, public adData?: FAB_Ad) {}
}

export class SetAdsAsViewed {
  static readonly type = '[app] Set Ads as Viewed';
  constructor(public ad?: FabAppAdTypeEnum, public adData?: FAB_Ad) {}
}

export class RemoveListenersOfAds {
  static readonly type = '[app] Remove Listeners';
}

export class ShowAdInterstitial {
  static readonly type = '[app] Show Modal FabApp';
  constructor(public component?: any) {}
}

export class SetIsShowingBanner {
  static readonly type = '[app] Show Banner';
  constructor(public isShow?: boolean) {}
}

export namespace NextAd {
  export class Banner {
    static readonly type = '[app] Next Ad Banner';
  }
  export class Interstitial {
    static readonly type = '[app] Next Ad Interstitial';
  }
}
