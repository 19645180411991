import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Plugins } from '@capacitor/core';
import { DeliveryMethod, PaymentMethod } from '@core/enums';
import { StatusDelivery } from '@core/enums/status-delivery.enum';
import { DeliveryInfoOrder } from '@core/models/moblets/fabapp-delivery/delivery-info-order.model';
import { InAppBrowserService } from '@core/services';
import { CoreState } from '@core/state/core/core.state';
import { environment } from '@environments/environment';
import { FDI_Store } from '@fabapp-delivery/models/store';
import { SocketDeliveryService } from '@fabapp-delivery/services/socket-delivery.service';
import { isPlatform, ToastController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { legacyCopyToClipboard } from '@utils';
import { Subscription } from 'rxjs';
import { FabappDeliveryService } from '../../fabapp-delivery.service';
const { Clipboard } = Plugins;

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FabappDeliveryService],
})
export class OrderSummaryComponent implements OnInit {
  @Input('storeInfo')
  public set setStoreInfo(value: FDI_Store) {
    this.storeInfo = value;
  }
  public storeInfo: FDI_Store;
  @Input() dataOrder: DeliveryInfoOrder;
  pixKey: string;
  storeSubscription: Subscription;
  socketDelivery: any;
  appId: string;
  storeId: number;
  orderId: string;
  public deliveryMethodEnum = DeliveryMethod;
  public paymentMethodEnum = PaymentMethod;
  public statusDeliveryEnum = StatusDelivery;

  constructor(
    public fabappDeliveryService: FabappDeliveryService,
    private toast: ToastController,
    public store: Store,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.getKeyPix();
    this.initSocket();
  }

  private initSocket() {
    this.appId = this.dataOrder.appId;
    this.storeId = this.dataOrder.storeId;
    this.orderId = this.dataOrder.id;

    if (this.socketDelivery) {
      this.socketDelivery.disconnect();
      this.socketDelivery = null;
    }

    this.socketDelivery = new SocketDeliveryService(
      this.appId,
      this.storeId.toString(),
      this.orderId,
    );

    this.socketDelivery.on(
      `${this.appId}:${this.storeId}:${this.orderId}:status`,
      (data) => {
        console.log(data);
        this.dataOrder.treadmill.push({
          date: data.date,
          orderId: this.orderId,
          statusId: data.statusId,
        });

        this.dataOrder.statusId = data.statusId;

        this.cdr.markForCheck();
      },
    );
  }

  ngOnDestroy() {
    this.storeSubscription.unsubscribe();
    this.socketDelivery.disconnect();
  }

  goToWhatsapp() {
    if (!this.storeInfo || !this.storeInfo['ddi'] || !this.storeInfo['phone']) {
      this.feedbackToaster();
      return;
    }

    const isSafari: boolean =
      navigator.userAgent.search('Safari') >= 0 &&
      navigator.userAgent.search('Chrome') < 0;

    if (isPlatform('ios') && isPlatform('mobileweb') && isSafari) {
      window.location.href = `${environment.wppApi.url}?phone=${
        this.storeInfo['ddi'] + this.storeInfo['phone'].trim()
      }`;
      return;
    }

    InAppBrowserService.windowOpen(
      `${environment.wppApi.url}?phone=${
        this.storeInfo['ddi'] + this.storeInfo['phone'].trim()
      }`,
    );
  }

  private async feedbackToaster(): Promise<void> {
    const toast: HTMLIonToastElement = await this.toast.create({
      message: 'Esta loja não tem telefone cadastrado!',
      position: 'top',
      duration: 3000,
      buttons: [
        {
          text: 'Ok',
        },
      ],
    });
    toast.present();
  }

  private async getKeyPix() {
    this.storeSubscription = this.store
      .select(CoreState.currentStore)
      .subscribe((store) => {
        if (store?.pixKey) {
          this.pixKey = store.pixKey;
        }
      });
  }

  async copyToClipboard(code: string) {
    Clipboard.write({
      string: code,
    });

    if (navigator.clipboard) {
      await navigator.clipboard.writeText(code);
    }

    legacyCopyToClipboard(code);

    const toast: HTMLIonToastElement = await this.toast.create({
      message: 'Copiado para área de transferência',
      duration: 2000,
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
          side: 'end',
        },
      ],
    });

    await toast.present();
  }
}
