import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserStatus } from '@core/enums';
import { ErrorApiReturns, User } from '@core/models';
import { HeraService, NavigationService } from '@core/services';
import { AuthState, Login } from '@core/state/auth';
import { PushDeviceRegister } from '@core/state/push';
import {
  LoadingController,
  ModalController,
  NavController,
  ToastController,
} from '@ionic/angular';
import {
  translate,
  TranslocoService,
  TRANSLOCO_SCOPE,
} from '@ngneat/transloco';
import { Store } from '@ngxs/store';

const TRANSLATION_SCOPE = 'login-by-token';
let LANG: string;

@Component({
  selector: 'app-login-by-token',
  templateUrl: './login-by-token.page.html',
  styleUrls: ['./login-by-token.page.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: TRANSLATION_SCOPE, alias: 'loginByToken' },
    },
  ],
})
export class LoginByTokenPage implements OnInit {
  @Input() isModal = false;
  @Input() ddi: string;
  @Input()
  public set phoneNumber(value: string) {
    this.phoneNumberToShow = value;
    this._phoneNumber = value;
  }

  public get phoneNumber(): string {
    return this._phoneNumber;
  }

  private _phoneNumber: string = '';

  isSubmitting: boolean = false;

  translationScope: string;

  resetType: 'email' | 'phone' = 'email';

  form: FormGroup = this.fb.group({
    code: new FormControl('', [
      Validators.required,
      Validators.maxLength(6),
      Validators.minLength(6),
    ]),
  });

  // Um minuto e meio
  baseTimeToResend: number = 60 * 1.5;
  timeToResend: number = 0;
  timeToShow: string = '';

  interval: ReturnType<typeof setTimeout>;

  set phoneNumberToShow(value: string) {
    let data = '*****';
    const index =
      value.indexOf('@') > 0
        ? value.indexOf('@')
        : Math.floor(value.length / 2);
    if (index) {
      data += value.substring(index);
    }
    this._phoneNumberToShow = data;
  }
  get phoneNumberToShow(): string {
    return this._phoneNumberToShow;
  }
  private _phoneNumberToShow: string = '';
  constructor(
    private activatedRoute: ActivatedRoute,
    public modalController: ModalController,
    private navigationService: NavigationService,
    private translocoService: TranslocoService,
    private fb: FormBuilder,
    private navCtrl: NavController,
    public toastController: ToastController,
    public loadingController: LoadingController,
    private heraService: HeraService,
    private store: Store,
  ) {
    LANG = this.translocoService.getActiveLang();
    this.phoneNumber =
      this.activatedRoute.snapshot.queryParams?.phoneNumber || this.phoneNumber;
    this.ddi = this.activatedRoute.snapshot.queryParams?.ddi || this.ddi;
    this.resetType = !/^\d+$/.test(this.phoneNumber) ? 'email' : 'phone';
  }

  ngOnInit(): void {}

  ionViewWillEnter(): void {
    if (!this.phoneNumber) {
      this.goToTokenRequest();
    }
    this.sendToken();
  }

  ionViewWillLeave(): void {
    clearInterval(this.interval);
  }

  goToTokenRequest(): void {
    this.navCtrl.navigateRoot('/login-by-token');
  }

  startInterval(): void {
    this.timeToResend = this.baseTimeToResend;
    this.timeToShow = this.defineTime(this.timeToResend);
    this.interval = setInterval(() => {
      this.timeToResend--;
      this.timeToShow = this.defineTime(this.timeToResend);

      if (this.timeToResend == 0) {
        this.timeToShow = null;
        this.baseTimeToResend *= 2;
        clearInterval(this.interval);
      }
    }, 1000);
  }

  private defineTime(time: number): string {
    let minutes: number | string = parseInt(`${time / 60}`, 10);
    let seconds: number | string = parseInt(`${time % 60}`, 10);

    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return minutes + ':' + seconds;
  }

  get code(): AbstractControl {
    return this.form.get('code');
  }

  async sendToken(): Promise<void> {
    if (this.timeToResend > 0) {
      return;
    }

    const message: string = await this.translocoService
      .selectTranslate(
        'messages.resendingCode',
        {},
        `${TRANSLATION_SCOPE}/${LANG}`,
      )
      .toPromise();

    const loading = await this.loadingController.create({
      message,
    });

    try {
      await loading.present();
      await this.heraService
        .sendTokenToPhoneNumber(this.phoneNumber, this.ddi)
        .toPromise();

      this.startInterval();
    } catch (err) {
      let msg = 'messages.';
      if (err.status == 404) {
        msg += 'notFound';
      } else {
        msg += 'genericError';
      }

      if (err?.error?.error_code?.toLowerCase() === 'no_sms_credits') {
        msg = 'messages.noSMSCredits';
      }

      return this.presentToast(msg);
    } finally {
      await loading.dismiss();
    }
  }

  async presentToast(translateKey: string, color = 'danger'): Promise<void> {
    const toast = await this.toastController.create({
      message: translate(translateKey, {}, `${TRANSLATION_SCOPE}/${LANG}`),
      color,
      position: 'top',
      buttons: [
        {
          text: 'ok',
        },
      ],
    });
    toast.present();
  }

  async confimCode(): Promise<void> {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    try {
      this.isSubmitting = true;

      const data = {
        email: `${this.ddi}${this.phoneNumber}`,
        code: this.form.get('code').value,
      };
      await this.store.dispatch(new Login(data)).toPromise();
      await this.store.dispatch(new PushDeviceRegister()).toPromise();

      const user: User = this.store.selectSnapshot(AuthState.getUser);
      const isWaitingApproval: boolean = user.status_id === UserStatus.Waiting;

      this.presentToast('messages.loginSuccess', 'success');
      this.goToHome(isWaitingApproval);
    } catch (err) {
      this.treatError(err);
    } finally {
      this.isSubmitting = false;
    }
  }

  goToHome(isWaitingApproval = false): void {
    clearInterval(this.interval);

    if (this.isModal) {
      this.modalController.dismiss({ navigate: true });
      return;
    }

    if (isWaitingApproval) {
      this.navCtrl.navigateRoot('/auth/user-awaiting-approval');
      return;
    }

    this.navigationService.returnToHomePage();
  }

  private treatError(err: HttpErrorResponse): void {
    const keyReturned: string | number =
      err.error.error || err.error.error_code;

    if (keyReturned === 'user_waiting') {
      return this.goToHome();
    }

    const apiReturns: ErrorApiReturns = {
      invalid_credentials: 'invalidCredentials',
      user_suspended: 'userSuspended',
      user_closed: 'userRemoved',
      provider_not_configured: 'providerNotConfigured',
    };

    const keyMessage: string = apiReturns[keyReturned] || 'genericError';

    this.presentToast(`messages.${keyMessage}`);
  }
}
