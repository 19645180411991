import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TagRatingService } from '@fabapp-delivery/services/tag-rating.service';

interface feedbackOptionInterface {
  label: string;
  code: number;
}

@Component({
  selector: 'app-rating-feedback',
  templateUrl: './rating-feedback.component.html',
  styleUrls: ['./rating-feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingFeedbackComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() styleType: string = 'basic';
  @Input() disabled: boolean = false;
  @Output() rate: EventEmitter<any> = new EventEmitter();
  @Output() updateComment: EventEmitter<any> = new EventEmitter();
  @Output() feedbackOrderOptionsUpdate: EventEmitter<any> = new EventEmitter();
  @Input() initialRatingInStars: number = 0;
  @Input() feedbackOptions: feedbackOptionInterface[];
  @Input() haveFeedback: boolean = false;
  @Input() comment: string = '';

  feedbackOrderOptions = [];

  tagDelivery: any;
  tagOrder: any;
  constructor(
    private cd: ChangeDetectorRef,
    private tagRatingService: TagRatingService,
  ) {}

  ngOnInit(): void {
    this.getTagsRating();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.initialRatingInStars?.currentValue) {
      this.toRate(changes.initialRatingInStars.currentValue);
    }

    this.cd.markForCheck();
  }

  toRate(stars: number): void {
    this.initialRatingInStars = stars;
    this.cd.detectChanges();
    this.cd.markForCheck();
    this.rate.emit(stars);
  }

  inputChanged(event: any): void {
    const comment: string = event.detail.value;
    this.comment = comment;
    this.updateComment.emit(comment);
  }

  async getTagsRating() {
    this.tagOrder = await this.tagRatingService.getTagOrder().toPromise();

    this.cd.detectChanges();
    this.cd.markForCheck();
  }

  selectOptionFeedbackOrder(id, index) {
    if (this.feedbackOrderOptions.find((tag) => tag == id)) {
      this.feedbackOrderOptions = this.feedbackOrderOptions.filter(
        (tags) => tags != id,
      );

      this.tagOrder[index]['selected'] = false;

      this.feedbackOrderOptionsUpdate.emit(this.feedbackOrderOptions);
      return;
    }

    this.feedbackOrderOptions.push(id);
    this.tagOrder[index]['selected'] = true;
    this.feedbackOrderOptionsUpdate.emit(this.feedbackOrderOptions);
  }
}
