<ion-header class="ion-no-border">
  <ion-toolbar
    [appShadowCss]="true"
    shadowCustomCss=".toolbar-background {transition: .2s all ease; }"
    mode="md"
    [class.-transparent]="!showTitleInHeader"
  >
    <!-- back-button -->
    <ion-buttons slot="start">
      <ion-button (click)="back()" [class.ion-hide]="!showTitleInHeader">
        <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
      </ion-button>
      <div
        (click)="back()"
        class="ion-activatable fakebutton -back"
        [class.ion-hide]="showTitleInHeader"
      >
        <ion-icon name="arrow-back-outline"></ion-icon>
        <ion-ripple-effect type="unbounded"></ion-ripple-effect>
      </div>
    </ion-buttons>
    <!-- /back-button -->
    <!-- title -->
    <ion-title class="headline" *ngIf="showTitleInHeader">
      {{ product.title }}
    </ion-title>
    <!-- /title -->
  </ion-toolbar>
</ion-header>

<ion-content
  [scrollEvents]="true"
  (ionScroll)="logScrolling($event)"
  *ngIf="product; else loader"
>
  <fabapp-product-photo-carousel
    *ngIf="product?.images?.length > 0"
    [productImages]="product?.images"
  >
  </fabapp-product-photo-carousel>
  <div class="gap" *ngIf="product?.images?.length > 0"></div>

  <div *ngIf="product" class="block" #pointer [formGroup]="form">
    <div class="product-header ion-padding">
      <h3 class="name">{{ product.title }}</h3>
      <p
        class="description"
        [innerHTML]="product.description | sanitizeHtml"
      ></p>
    </div>

    <fabapp-complement-groups
      (toScroll)="toScroll($event)"
      [form]="form"
      [complements]="product.complements"
      [currencyId]="store?.currencyId || 'BRL'"
    >
      <!-- observation -->
      <fabapp-complement-header
        [sticky]="false"
        class="-observations"
        *transloco="let t"
        [subtitle]="t('fabappDelivery.anyObservations')"
      >
      </fabapp-complement-header>

      <div class="note ion-padding">
        <ion-item lines="none" (click)="goToNote()">
          <ion-textarea
            formControlName="observation"
            readonly
            [autoGrow]="true"
            outline
            [maxlength]="150"
          >
          </ion-textarea>
        </ion-item>
      </div>
      <!-- /observation -->
    </fabapp-complement-groups>
  </div>
</ion-content>
<!-- loader -->
<ng-template #loader>
  <div class="spinner-wrapper">
    <ion-spinner></ion-spinner>
  </div>
</ng-template>
<!-- end of loader -->

<ion-footer *ngIf="product">
  <ion-grid class="grid" class="ion-padding">
    <ion-row class="ion-align-items-center">
      <ion-col class="ion-no-padding" size="4">
        <fabapp-input-quantity
          [defaultValue]="0"
          [counterRangeMin]="minimumQuantityOfProducts"
          [value]="productQuantity"
          [counterRangeMax]="store?.inventoryControl ? product.inventory : null"
          (change)="setQuantity($event)"
        ></fabapp-input-quantity>
      </ion-col>
      <div class="divider"></div>
      <ion-col class="ion-no-padding" *transloco="let t">
        <ng-container *ngIf="productQuantity > 0; else removeProduct">
          <ion-button
            class="buy-button"
            (click)="addToCart()"
            [disabled]="
              form.invalid ||
              (store?.inventoryControl && product.inventory === 0) ||
              product?.statusId === productStatusEnum.PAUSED
            "
          >
            <div class="inner-wrapper">
              <span class="text">
                {{ t('fabappDelivery.add') }}
              </span>
              <span class="value">
                {{
                  total$ | async | currency: store?.currencyId || 'BRL'
                }}</span
              >
            </div>
          </ion-button>
        </ng-container>
        <ng-template #removeProduct>
          <ion-button class="buy-button" (click)="removeFromCart()">
            <div class="inner-wrapper ion-justify-content-center">
              <span class="text">
                {{ t('fabappDelivery.remove') }}
              </span>
            </div>
          </ion-button>
        </ng-template>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
