import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ListExibitionType } from '../../enums';
import { List, ListDef } from '../../models';

@Component({
  selector: 'app-text-list',
  templateUrl: './text-list.component.html',
  styleUrls: ['./text-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextListComponent implements OnInit, OnChanges {
  @Input() items: List[];
  @Input() listDef: ListDef;
  exibitionType = ListExibitionType;

  // output for emit a navigation
  @Output() navigate: EventEmitter<any> = new EventEmitter();
  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items?.currentValue) {
      this.items = changes.items.currentValue;
      this.cd.markForCheck();
    }

    if (changes.listDef?.currentValue) {
      this.listDef = changes.listDef.currentValue;
      this.cd.markForCheck();
    }
  }

  handle(item: any, seeRating: boolean = false): void {
    if (seeRating) {
      this.navigate.emit({ ...item, seeRating });
      return;
    }

    this.navigate.emit(item);
  }

  trackById(index: number, item: any): number | void {
    return index;
  }
}
