// TO-DO: definir tipagem para as propriedades com 'any'

export interface AppInfo {
  about: any;
  admob_android_banner_id: any;
  admob_android_id: any;
  admob_android_publisher_id: any;
  admob_interstitial_interactions: string;
  admob_ios_banner_id: any;
  admob_ios_id: any;
  admob_ios_publisher_id: any;
  adword_code: any;
  allow_android: string;
  allow_ios: string;
  allow_splash: string;
  android_package: string;
  appDefUrl: string;
  app_share_url: string;
  google_play_url: string;
  app_store_url: string;
  background: string;
  custom_banner_image: any;
  custom_banner_url: any;
  description: string;
  descriptionHtml: string;
  enable_cache: boolean;
  enable_content_share: boolean;
  enable_custom_ads: boolean;
  enable_custom_banner: boolean;
  enable_sign_in: boolean;
  fb_access_key: null;
  headerImage: string;
  icon: string;
  ios_bundle_identifier: string;
  id: string;
  language: string;
  login_approved_only: boolean;
  login_description: any;
  login_enable: boolean;
  google_client_id: string;
  login_facebook_active: boolean;
  login_google_active: boolean;
  login_apple_active: boolean;
  login_heading: any;
  login_image: any;
  login_option: any;
  login_required: boolean;
  logins: string[];
  majorVersion: string;
  mediaApi: string;
  metrics_url: string;
  minorVersion: string;
  name: string;
  needs_payment: boolean;
  push_sender_id: string;
  push_url: string;
  remove_menu: boolean;
  safeName: string;
  search: string;
  secureAppDefUrl: string;
  secureMediaApi: string;
  splash: string;
  theme: string;
  webHeaderImage: string;
  web_header_size: string;
}
