<div class="progress">
  <div class="information">
    <div class="icon">
      <img
        class="img"
        [src]="'/assets/icons/fabapp-delivery/' + currentIconStage + '.svg'"
      />
    </div>
    <div class="status">
      <p class="paragraf">{{ labelCurrentStage }}</p>
    </div>
  </div>

  <div
    class="progress-bar"
    *ngIf="
      currentStatus != this.statusDeliveryEnum.DELIVERED &&
        currentStatus != this.statusDeliveryEnum.CANCELED;
      else onlyTextInformationHour
    "
  >
    <p class="paragraf -delivery" *ngIf="dataOrder?.deliveryForecast">
      Previsão de entrega {{ dataOrder.deliveryForecast | date: 'HH:mm' }}
    </p>
    <div class="progress-wrapper">
      <div
        class="bar -small"
        *ngIf="
          currentStatus === statusDeliveryEnum.PREPARING ||
          currentStatus === statusDeliveryEnum.DELIVERING ||
          currentStatus === statusDeliveryEnum.STAND_BY
        "
      ></div>
      <div
        class="bar"
        *ngIf="
          currentStatus === statusDeliveryEnum.DELIVERING ||
          currentStatus === statusDeliveryEnum.STAND_BY
        "
      ></div>
      <ion-progress-bar
        type="indeterminate"
        [class.-preparing]="currentStatus === statusDeliveryEnum.PREPARING"
        [class.-delivering]="currentStatus === statusDeliveryEnum.DELIVERING"
        [class.-delivering]="currentStatus === statusDeliveryEnum.STAND_BY"
      ></ion-progress-bar>
    </div>

    <div [ngSwitch]="currentStatus" class="box">
      <p class="paragraf" *ngSwitchCase="statusDeliveryEnum.PENDING">
        Aguardando aprovação do pedido feito às
        {{ hourCurrentStage | date: 'HH:mm' }}
      </p>
      <p class="paragraf" *ngSwitchCase="statusDeliveryEnum.PREPARING">
        O seu pedido já está sendo preparado
      </p>
      <p class="paragraf" *ngSwitchCase="statusDeliveryEnum.STAND_BY">
        Aguardando o motoboy pegar o pedido
      </p>
      <p class="paragraf" *ngSwitchCase="statusDeliveryEnum.DELIVERING">
        <ng-container
          *ngIf="dataOrder.deliveryMethodId == deliveryMethod.DELIVERY"
        >
          Seu pedido saiu para entrega às {{ hourCurrentStage | date: 'HH:mm' }}
        </ng-container>
        <ng-container
          *ngIf="dataOrder.deliveryMethodId == deliveryMethod.TAKE_AWAY"
        >
          Seu pedido já está pronto para retirada
        </ng-container>
      </p>
    </div>
  </div>

  <ng-template #onlyTextInformationHour>
    <div class="finish-order">
      <p class="paragraf">
        Realizado às {{ dataOrder.createdAt | date: "HH'h'mm - dd/MM/yyyy" }}
      </p>
      <p
        class="paragraf"
        *ngIf="currentStatus == this.statusDeliveryEnum.DELIVERED"
      >
        Entregue às {{ hourCurrentStage | date: "HH'h'mm" }}
      </p>
      <p
        class="paragraf"
        *ngIf="currentStatus == this.statusDeliveryEnum.CANCELED"
      >
        Cancelado às {{ hourCurrentStage | date: "HH'h'mm " }}
      </p>
    </div>
  </ng-template>
</div>

<div class="contact">
  <ion-button
    class="button"
    [style.color]="'var(--primarycolor-or-black)'"
    shape="round"
    expand="full"
    *ngIf="currentStatus == this.statusDeliveryEnum.DELIVERING"
    target="_blank"
    (click)="confirmOrder()"
  >
    Confirmar recebimento do pedido
  </ion-button>
</div>
