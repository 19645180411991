<ion-app>
  <ng-container *ngIf="menuEnabled$ | async">
    <ion-split-pane contentId="main-content" *transloco="let t">
      <ion-menu
        contentId="main-content"
        type="overlay"
        *ngIf="partialAppDef$ | async as partialAppDef"
      >
        <div
          class="fabapp-menu-header"
          [class.offset-top]="partialAppDef?.info?.login_enable"
        >
          <ng-container *ngIf="partialAppDef?.info?.login_enable">
            <div class="fabapp-menu-header__user" (click)="goSettings()">
              <ng-container *ngIf="user$ | async as user">
                <ion-avatar slot="start" class="user-circle">
                  <img-loader
                    *ngIf="!user.picture"
                    src="/assets/icons/profile.svg"
                  ></img-loader>
                  <img-loader
                    *ngIf="user.picture"
                    [src]="user.picture"
                  ></img-loader>
                </ion-avatar>
                <div class="name-user">
                  <div>
                    {{ user.name }}
                  </div>
                  <div class="navigate-profile">
                    {{ t('profile') }}
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="fabapp-menu-header__actions">
              <ion-button
                *ngIf="!(user$ | async)"
                color="light"
                fill="clear"
                (click)="login()"
              >
                {{ t('logIn') }}
                <ion-icon slot="end" name="log-in-outline"></ion-icon>
              </ion-button>
            </div>
          </ng-container>
        </div>
        <ion-content
          class="adjust-ioncontent"
          [class.offset-top]="partialAppDef?.info?.login_enable"
        >
          <div class="fabapp-menu-content">
            <ion-menu-toggle auto-hide="false">
              <ion-list [class.has-admob]="adsService.hasAdmobConfigured()">
                <ion-item
                  lines="none"
                  [routerDirection]="'root'"
                  (click)="setHomePage()"
                >
                  <ion-icon name="home" slot="start"></ion-icon>
                  <ion-label>{{ t('home') }}</ion-label>
                </ion-item>
                <ng-container
                  *ngFor="
                    let page of pages$ | async;
                    trackBy: trackByIndex;
                    let i = index
                  "
                >
                  <!-- [hidden]="!(partialAppDef.layout | hideDuplicatedHome: i)"
                    * esconde o primeiro item quando `layout_type` for igual a `page_home` -->

                  <ion-item
                    *ngIf="page.visible === 'true' && page.name.trim()"
                    [hidden]="!(partialAppDef.layout | hideDuplicatedHome: i)"
                    lines="none"
                    [routerDirection]="'forward'"
                    (click)="navigate(page)"
                  >
                    <ion-avatar slot="start" *ngIf="page.icon">
                      <img-loader [src]="page.icon"></img-loader>
                    </ion-avatar>
                    <ion-label>{{ page.name }}</ion-label>
                  </ion-item>
                </ng-container>
                <hr class="line" />
                <ion-item lines="none" (click)="goToTerms()">
                  <ion-label>{{ t('termsOfUse') }}</ion-label>
                </ion-item>
                <ion-item lines="none" (click)="goToPrivacy()">
                  <ion-label>{{ t('privacyPolicy') }}</ion-label>
                </ion-item>
                <ion-item
                  lines="none"
                  *ngIf="
                    partialAppDef?.info?.google_play_url &&
                    currentPlatform == 'ANDROID'
                  "
                  (click)="goToGooglePlay(partialAppDef?.info?.google_play_url)"
                >
                  <ion-label>{{ t('evaluateGooglePlay') }}</ion-label>
                </ion-item>
                <ion-item
                  lines="none"
                  *ngIf="
                    partialAppDef?.info?.app_store_url &&
                    currentPlatform == 'IOS'
                  "
                  (click)="goToAppStore(partialAppDef?.info?.app_store_url)"
                >
                  <ion-label>{{ t('evaluateAppStore') }}</ion-label>
                </ion-item>
                <ion-item class="app-name" disabled lines="none">
                  <ion-label>{{ partialAppDef.info?.name }}</ion-label>
                </ion-item>
              </ion-list>
            </ion-menu-toggle>
            <fabapp-pwa-install></fabapp-pwa-install>
          </div>
        </ion-content>
      </ion-menu>
      <fabapp-pwa-install-banner></fabapp-pwa-install-banner>
      <ion-router-outlet id="main-content"> </ion-router-outlet>
    </ion-split-pane>
  </ng-container>

  <ng-container *ngIf="!(menuEnabled$ | async)">
    <fabapp-pwa-install-banner></fabapp-pwa-install-banner>
    <ion-router-outlet id="main-content"> </ion-router-outlet>
    <app-tabs></app-tabs>
  </ng-container>
</ion-app>
