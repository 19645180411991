import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '@core/models';
import { AuthState, UpdateUser } from '@core/state/auth';
import { ThemingColor } from '@core/types';
import { ModalController, ToastController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { PaymentMethodFormComponent } from '../../components/payment-method-form/payment-method-form.component';
import { PaymentMethodForCreate } from '../../models/payment-method-for-save.mode';
import { FDPaymentMethod } from '../../models/payment-method.model';
import { FDPaymentMethodService } from '../../services/payment-methods.service';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent implements OnInit {
  @ViewChild('form') paymentMethodForm: PaymentMethodFormComponent;
  @Input() id: string;
  @Input() paymentMethod: FDPaymentMethod;

  isSubmitting = false;
  public readonly brands = [
    '/assets/images/fabapp-delivery/brands/mastercard.svg',
    '/assets/images/fabapp-delivery/brands/visa.svg',
    '/assets/images/fabapp-delivery/brands/elo.svg',
    '/assets/images/fabapp-delivery/brands/hipercard.svg',
    '/assets/images/fabapp-delivery/brands/amex.svg',
    '/assets/images/fabapp-delivery/brands/diners.svg',
  ];

  formGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  user: User;
  constructor(
    public modalController: ModalController,
    private paymentMethodService: FDPaymentMethodService,
    private toast: ToastController,
    private store: Store,
  ) {}

  ngOnInit() {
    this.user = this.store.selectSnapshot(AuthState.getUser);
  }

  ngAfterViewInit(): void {
    if (this.paymentMethod) {
      this.paymentMethodForm.mergeValue(this.paymentMethod);
    }
  }

  async onSubmit() {
    if (this.paymentMethodForm.isInvalid) {
      this.paymentMethodForm.form.markAllAsTouched();
      return;
    }

    const isValidEmail = await this.isValidEmail();
    if (!isValidEmail) {
      return;
    }

    if (this.id) {
      await this.edit();
      return;
    }

    await this.create();
  }

  async create() {
    const data: PaymentMethodForCreate = this.paymentMethodForm.formValue;
    try {
      this.isSubmitting = true;
      await this.paymentMethodService.create(data).toPromise();
      this.feedbackToaster('Forma de pagamento salva com sucesso !', 'success');
      this.modalController.dismiss({ reload: true });
    } catch (error) {
      console.error('Error for create payment method: ', error);
      this.feedbackToaster('Algo deu errado, tente novamente.', 'warning');
    } finally {
      this.isSubmitting = false;
    }
  }

  async edit() {
    const { nickname }: PaymentMethodForCreate =
      this.paymentMethodForm.formValue;

    try {
      this.isSubmitting = true;
      await this.paymentMethodService.edit(this.id, nickname).toPromise();
      this.feedbackToaster('Alteração salva com sucesso !', 'success');
      this.modalController.dismiss({ reload: true });
    } catch (error) {
      console.error('Error for create payment method: ', error);
      this.feedbackToaster('Algo deu errado, tente novamente.', 'warning');
    } finally {
      this.isSubmitting = false;
    }
  }

  private async isValidEmail(): Promise<boolean> {
    const hasEmail = this.store.selectSnapshot(AuthState.getUser)?.email;
    if (hasEmail) {
      return true;
    }

    const invalid = this.formGroup.get('email').invalid;
    if (!hasEmail && invalid) {
      this.feedbackToaster(
        'O Email é necessário para que o antifraude funcione corretamente',
        'warning',
      );
      this.formGroup.get('email').markAsTouched();
      return false;
    }

    return await this._saveEmail();
  }

  private async _saveEmail() {
    const field = this.formGroup.get('email');
    try {
      this.isSubmitting = true;
      await this.store
        .dispatch(new UpdateUser({ email: field.value }))
        .toPromise();
      return true;
    } catch (err: any) {
      console.error('Error to save email: ', err);
      this.feedbackToaster(
        'Aconteceu um erro ao salvar o e-mail, tente novamente',
        'warning',
      );
      return false;
    } finally {
      this.isSubmitting = false;
    }
  }

  private async feedbackToaster(
    message: string,
    theme: ThemingColor = 'danger',
  ): Promise<void> {
    const toast: HTMLIonToastElement = await this.toast.create({
      message,
      color: theme,
      position: 'top',
      duration: 1000,
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
          side: 'end',
        },
      ],
    });
    toast.present();
  }
}
