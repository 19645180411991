import { Coupon } from '@fabapp-delivery/models/promotions/coupon/coupon.model';

export class LoadCoupons {
  static readonly type = '[coupons] Load Coupons';
}
export class ApplyCoupon {
  static readonly type = '[coupons] Apply Coupon';
  constructor(public coupon: Coupon) {}
}

export class SetSelectedCoupon {
  static readonly type = '[coupons] Set Selected Coupon';
  constructor(public coupon: Partial<Coupon>) {}
}

export class ClearSelectedCoupon {
  static readonly type = '[coupons] Clear Selected Coupon';
}

export class RemoveCoupon {
  static readonly type = '[coupons] Remove Coupon';
}

export class ShowConfirmationToRemoveCoupon {
  static readonly type = '[coupons] Show Confirmation to Remove Coupon';
}
