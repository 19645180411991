import { Component, Input } from '@angular/core';
import { FDI_CartItem } from '@fabapp-delivery/models/cart/cart.model';
import { ComplementFromItemState } from '@fabapp-delivery/models/item';
import { DeliveryCartActions } from '@fabapp-delivery/state/cart/cart.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'fabapp-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
})
export class CartItemComponent {
  @Input('inventoryControl')
  set setInventoryControl(inventoryControl: boolean) {
    this.inventoryControl = inventoryControl;
  }
  public inventoryControl: boolean = false;

  @Input('currencyId')
  set setCurrencyId(currencyId: string) {
    this.currencyId = currencyId || 'BRL';
  }
  public currencyId: string = 'BRL';

  @Input('item')
  set setItem(value: FDI_CartItem) {
    const complementsPrice = this.calculateComplementsValue(
      value.complements as Array<ComplementFromItemState>,
    );

    this.price =
      (value.product.promoPrice !== null
        ? value.product.promoPrice
        : value.product.price) + complementsPrice;

    this.item = value;
    this.quantity = value.quantity;
  }
  item: FDI_CartItem;
  price: number;
  quantity = 1;
  disabled = false;
  showRemoveButton = false;

  constructor(private store: Store) {}

  amountChanged(quantity: number) {
    if (quantity === 0 || this.item.product.inventory === 0) {
      this.showRemoveButton = true;
      return;
    }
    this.showRemoveButton = false;

    if (quantity === this.item.quantity) {
      return;
    } else if (quantity > this.item.quantity) {
      return this.incrementItem();
    } else {
      return this.decrementItem();
    }
  }

  calculateComplementsValue(complements: Array<ComplementFromItemState>) {
    const reducer = (accumulator, c) =>
      c.quantity * c.complementItem.price + accumulator;
    return complements.reduce(reducer, 0);
  }

  async incrementItem() {
    try {
      this.disabled = true;
      await this.store
        .dispatch(new DeliveryCartActions.Item.Increment(this.item))
        .toPromise();
    } catch (error) {
      console.error(error);
    } finally {
      this.disabled = false;
    }
  }

  async decrementItem() {
    try {
      this.disabled = true;
      await this.store
        .dispatch(new DeliveryCartActions.Item.Decrement(this.item))
        .toPromise();
    } catch (error) {
      console.error(error);
    } finally {
      this.disabled = false;
    }
  }

  async removeItemFromCart() {
    try {
      this.disabled = true;
      await this.store
        .dispatch(new DeliveryCartActions.Item.Remove(this.item))
        .toPromise();
    } catch (error) {
      console.error(error);
    } finally {
      this.disabled = false;
    }
  }
}
