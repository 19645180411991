<ion-header class="ion-no-border">
  <ion-toolbar mode="md">
    <ion-title>{{
      type === 'terms' ? ('lgpd.term' | transloco) : ('lgpd.policy' | transloco)
    }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fixed>
  <ng-container *ngIf="!isLoading">
    <span class="content" [innerHTML]="content"></span>
  </ng-container>

  <!-- loader -->
  <div class="spinner-wrapper" style="height: 100%" *ngIf="isLoading">
    <ion-spinner></ion-spinner>
  </div>
  <!-- end of loader -->
  <ng-container *ngIf="!isLoading && content">
    <div class="btn-wrapper">
      <ion-button
        (click)="acceptTerm()"
        [disabled]="isSubmitting || success"
        expand="block"
      >
        <ng-container *ngIf="!success && !isSubmitting">
          {{ 'lgpd.accept' | transloco }}
        </ng-container>
        <ion-icon
          *ngIf="success && !isSubmitting"
          name="checkmark-circle-outline"
        ></ion-icon>
        <ion-spinner *ngIf="isSubmitting"></ion-spinner>
      </ion-button>
    </div>
  </ng-container>
</ion-content>
