import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UpdateUser } from '@core/state/auth';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-ask-user-email',
  templateUrl: './ask-user-email.component.html',
  styleUrls: ['./ask-user-email.component.scss'],
})
export class AskUserEmailComponent {
  @Output() closeBottomSheet: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  emailField = new FormControl('', [Validators.required, Validators.email]);

  isSubmitting = false;
  constructor(public store: Store) {}

  async validateDocument() {
    try {
      this.isSubmitting = true;
      await this.store
        .dispatch(
          new UpdateUser({
            email: this.emailField.value,
          }),
        )
        .toPromise();

      this.closeBottomSheet.emit(false);
    } catch (err: any) {
      this.closeBottomSheet.emit(true);
    } finally {
      this.isSubmitting = false;
    }
  }
}
