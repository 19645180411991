<ion-item class="product-item">
  <ion-avatar slot="start" *ngIf="item.product?.images[0]?.url as url">
    <img [src]="url" />
  </ion-avatar>
  <ion-label class="product-info">
    <h3 class="product-title">{{ item.product.title }}</h3>

    <div class="complement-wrapper" *ngIf="item.complements?.length > 0">
      <div class="complement-item" *ngFor="let complement of item.complements">
        <div class="complement-number">{{ complement.quantity }}</div>
        <div class="complement-text">
          {{ complement.complementItem.name }}
        </div>
      </div>
    </div>

    <div class="complement-wrapper" *ngIf="item?.observation">
      <div class="complement-item">
        <div class="description-text">Descrição: {{ item?.observation }}</div>
      </div>
    </div>

    <h4 class="product-price">
      {{ (price * item.quantity) / 100 | currency: currencyId }}
    </h4>
  </ion-label>
  <div class="actions">
    <fabapp-input-quantity
      [counterRangeMin]="0"
      class="minor"
      [disabled]="disabled"
      (change)="amountChanged($event)"
      [(value)]="item.quantity"
      [counterRangeMax]="inventoryControl ? item?.product?.inventory : null"
    >
    </fabapp-input-quantity>
    <ion-button
      *ngIf="showRemoveButton"
      (click)="removeItemFromCart()"
      size="small"
      color="danger"
      class="remove-button"
    >
      Remover
    </ion-button>
  </div>
</ion-item>
<span class="soldout" *ngIf="item?.product.inventory === 0">
  Produto fora de estoque
</span>
