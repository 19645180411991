import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { List, ListDef } from '../../models';

@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageListComponent implements OnInit {
  @Input() items: List[];
  // output for emit a navigation
  @Output() navigate = new EventEmitter();

  @Input() listDef: ListDef;
  constructor() {}

  ngOnInit(): void {}

  /**
   * emite o item clicado
   */
  handle(item: any, seeRating: boolean = false): void {
    if (seeRating) {
      this.navigate.emit({ ...item, seeRating });
      return;
    }
    this.navigate.emit(item);
  }

  trackById(index: number, item: any): number | void {
    return index;
  }
}
