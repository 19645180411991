import { Injectable } from '@angular/core';
import { FDI_CartItemComplements } from '@fabapp-delivery/models/cart/cart.model';
import { ComplementFromItemState } from '@fabapp-delivery/models/item';
import {
  FDI_Complement,
  FDI_ComplementItem,
  FDI_Product,
} from '@fabapp-delivery/models/product/product.model';
import { ProductService } from '@fabapp-delivery/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FabappDeliveryItem } from './fabapp-delivery-item.action';

interface FabappDeliveryItemStateModel {
  product: FDI_Product;
  quantity: number;
  total: number;
  complements: Array<ComplementFromItemState>;
  complementsValue: number;
  complementKeys: FDI_CartItemComplements;
}

const DEFAULTS: FabappDeliveryItemStateModel = {
  complements: [],
  product: null,
  quantity: 1,
  total: 0,
  complementsValue: 0,
  complementKeys: null,
};

@State<FabappDeliveryItemStateModel>({
  name: 'fabappDeliveryItemState',
  defaults: DEFAULTS,
})
@Injectable()
export class FabappDeliveryItemState {
  constructor(private readonly productsService: ProductService) {}

  @Selector()
  static getFabappDeliveryItem(
    state: FabappDeliveryItemStateModel,
  ): FabappDeliveryItemStateModel {
    return state;
  }

  @Selector()
  static getProduct(state: FabappDeliveryItemStateModel): FDI_Product {
    return state.product;
  }

  @Selector()
  static getQuantityProduct(state: FabappDeliveryItemStateModel): number {
    return state.quantity;
  }

  @Selector()
  static getComplement(
    state: FabappDeliveryItemStateModel,
  ): ComplementFromItemState[] {
    return state.complements;
  }

  @Selector()
  static getTotal(state: FabappDeliveryItemStateModel): number {
    const productMoreComplements = state.total + state.complementsValue;
    const convertValue = productMoreComplements / 100;
    return convertValue * state.quantity;
  }

  @Action(FabappDeliveryItem.SetQuantity)
  setQuantity(
    ctx: StateContext<FabappDeliveryItemStateModel>,
    { quantity }: FabappDeliveryItem.SetQuantity,
  ) {
    ctx.patchState({ quantity });
  }

  @Action(FabappDeliveryItem.Get)
  public async getProduct(
    ctx: StateContext<FabappDeliveryItemStateModel>,
    { id }: FabappDeliveryItem.Get,
  ) {
    const product: FDI_Product = await this.productsService
      .getOneProduct(id)
      .toPromise();

    this.treatComplementKeys(ctx, product.complements);
    ctx.patchState({
      product,
      quantity: 1,
      total: product.promoPrice !== null ? product.promoPrice : product.price,
    });
  }

  treatComplementKeys(
    ctx: StateContext<FabappDeliveryItemStateModel>,
    complementGroups: FDI_Complement[],
  ) {
    let complementKeys = {};
    const complementItems: FDI_ComplementItem[] = complementGroups.reduce(
      (previous: FDI_ComplementItem[], complement: FDI_Complement) => {
        previous.push(...complement.items);
        return previous;
      },
      [],
    );
    complementItems.forEach(
      ({ id, ...item }: FDI_ComplementItem) => (complementKeys[id] = item),
    );
    ctx.patchState({ complementKeys });
  }

  @Action(FabappDeliveryItem.Reset)
  public reset(ctx: StateContext<FabappDeliveryItemStateModel>) {
    ctx.setState(DEFAULTS);
  }

  private _currentComplementsValueTotal(
    complements: Array<ComplementFromItemState>,
  ): number {
    return complements.reduce(
      (previous: number, currentValue) =>
        (previous += currentValue.price * currentValue.quantity),
      0,
    );
  }

  @Action(FabappDeliveryItem.UpdateComplements)
  updateComplements(
    ctx: StateContext<FabappDeliveryItemStateModel>,
    { complements }: FabappDeliveryItem.UpdateComplements,
  ) {
    const complementKeys: FDI_CartItemComplements = ctx.getState()
      .complementKeys;
    const treatedComplements: Array<ComplementFromItemState> = this.treatComplements(
      complements,
      complementKeys,
    );

    const complementsValue = this._currentComplementsValueTotal(
      treatedComplements,
    );
    ctx.patchState({ complements: treatedComplements, complementsValue });
  }

  treatComplements(
    complements: FDI_CartItemComplements,
    keys: FDI_CartItemComplements,
  ): Array<ComplementFromItemState> {
    return Object.values(complements).reduce(
      (previous, complement: ComplementFromItemState[] | string) => {
        if (!complement) {
          return previous;
        }

        if (complement instanceof Array) {
          previous.push(...complement);
          return previous;
        }

        let complementFound: ComplementFromItemState = keys[complement];
        previous.push({
          complementItemId: complement,
          price: complementFound.price,
          quantity: 1,
        });
        return previous;
      },
      [],
    );
  }
}
