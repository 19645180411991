import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpService } from '../../../core/http/http.service';

@Injectable({
  providedIn: 'root',
})
export class TagRatingService extends HttpService<any> {
  protected url = `${environment.shop.url}/tags`;

  getTagDelivery() {
    return this.request('GET', '?delivery=true');
  }

  getTagOrder() {
    return this.request('GET', '?order=true');
  }
}
