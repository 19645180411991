import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'fabapp-phone-secure',
  templateUrl: 'phone-secure.component.html',
  styleUrls: ['phone-secure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneSecureComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
