import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { InteractionTypeEnum } from '@core/enums/fabapp-ad/ad-interaction-type.enum';
import { FAB_AdCampaign, User } from '@core/models';
import { FAB_Ad } from '@core/models/fabapp-ads/ad.model';
import { AuthState } from '@core/state/auth';
import { environment } from '@environments/environment';
import { Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { SetAdOrClick } from 'src/app/components/banner-footer/interfaces/set-ads.model';
import { FabAppAdTypeEnum } from '../../../enums/fabapp-ad/fab-app-ad-type.enum';
const { Device, Storage } = Plugins;
const URL: string = environment.heimdallApi.url;

interface CampaignsAds {
  banner?: FAB_AdCampaign;
  carousel?: FAB_AdCampaign;
  interstitial?: FAB_AdCampaign;
}

@Injectable()
export class FabappAdsService {
  private defaultHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  public deviceId: string;
  private currentPlatform: string;
  constructor(
    private httpClient: HttpClient,
    private platform: Platform,
    private store: Store,
  ) {
    this.getPlatForm();
  }

  /**
   * @description
   * retorna um Promise.all
   * com os banners e os modals
   */
  public async loadAds(): Promise<CampaignsAds> {
    try {
      return await this.loadAd();
    } catch (error) {
      throw error;
    }
  }

  /**
   * @description
   * carrega todos os ADs {banner, carousel, interstitial}
   * @returns Promise
   */
  private async loadAd(): Promise<CampaignsAds> {
    const segment: string = `/app/campaings/ads`;
    try {
      return await this.httpClient
        .get<CampaignsAds>(`${URL}${segment}`, {
          headers: this.defaultHeaders,
        })
        .toPromise();
    } catch (error) {
      return null;
    }
  }

  public async setAdAsClicked(ad: FAB_Ad): Promise<void> {
    if (window['preview'] || !ad?.id) {
      return;
    }
    await this.solver(ad, InteractionTypeEnum.CLICK);
  }

  public async setAdAsViewed(ad: FAB_Ad): Promise<FAB_AdCampaign> {
    if (window['preview'] || !ad?.id) {
      return;
    }
    await this.solver(ad, InteractionTypeEnum.EXHIBITION);
  }

  private async solver(
    ad: FAB_Ad,
    interactionId: InteractionTypeEnum,
  ): Promise<void> {
    const segment: string = `/app/campaings/ads/${ad.id}/interaction`;
    const user: User = this.store.selectSnapshot(AuthState.getUser);

    const data: SetAdOrClick = {
      interactionId,
      userId: user ? user.id : null,
      platform: this.currentPlatform,
      deviceId: this.deviceId,
    };

    try {
      await this.httpClient
        .post(`${URL}${segment}`, data, {
          headers: this.defaultHeaders,
        })
        .toPromise();
    } catch (ex) {
      console.log('Cannot resolve request', ex);
      throw ex;
    }
  }

  /**
   * @description
   * get platform for ad
   *
   * @returns Promise
   */
  private async getPlatForm(): Promise<void> {
    const { uuid } = await Device.getInfo();
    const suffix: string = !this.platform.is('capacitor') ? '-web' : '';

    this.currentPlatform = this.platform.is('ios')
      ? 'ios' + suffix
      : this.platform.is('android')
      ? 'android' + suffix
      : 'web';

    /**
     * uuid: fornece pela API capacitor
     */

    this.setDeviceId(uuid);
  }

  /**
   * @description
   * set deviceId or get Device id
   *
   * @param  {string} uuid
   * @returns Promise
   */
  private async setDeviceId(uuid: string): Promise<void> {
    this.deviceId = (await Storage.get({ key: 'fd_device_id' })).value;
    if (!this.deviceId) {
      this.deviceId = uuid;
      await Storage.set({
        key: 'fd_device_id',
        value: this.deviceId,
      });
    }
  }
}
