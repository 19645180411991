import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AdmobAdsService } from '@core/services/ads';
import { ModalController } from '@ionic/angular';
import { LoginPage } from 'src/app/pages/auth/login/login.page';
import { SignupPage } from 'src/app/pages/auth/signup/signup.page';
import { LoginByTokenPage } from 'src/app/pages/login-by-token/login-by-token/login-by-token.page';
import { CodeRequestPage } from 'src/app/pages/reset-password/code-request/code-request.page';
import { ConfirmationCodePage } from 'src/app/pages/reset-password/confirmation-code/confirmation-code.page';
import { ResetPasswordSuccessPage } from 'src/app/pages/reset-password/reset-password-success/reset-password-success.page';
import { ResetPasswordPage } from 'src/app/pages/reset-password/reset-password/reset-password.page';

export interface LoginParams {
  navigate?: boolean;
  openRecoveryCode?: boolean;
  openSignUp?: boolean;
  openConfirmationCode?: boolean;
  goToResetPassword?: boolean;
  goToResetSuccessfully?: boolean;
  goToLoginByToken?: boolean;
  phoneOrEmail?: boolean;
  loginParams: { ddi: string; phoneNumber: string };
}

@Injectable({ providedIn: 'root' })
export class AuthHelperService {
  private previousUrl: string;
  private currentUrl: string;
  constructor(
    private router: Router,
    private adsService: AdmobAdsService,
    private modalController: ModalController,
  ) {
    this.currentUrl = this.router.url;

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  async goToLogin(intentUrl?: string, isntModal = false): Promise<boolean> {
    if (isntModal) {
      return this.router.navigate(['/auth/login'], {
        queryParams: { intentUrl },
      });
    }

    if (intentUrl) {
      // hide banner admob for login modal
      this.adsService.hideBanner();

      const loginModal: LoginParams = await this.openModalByComponent(
        LoginPage,
        intentUrl,
      );

      if (loginModal?.navigate) {
        return this.router.navigateByUrl(intentUrl);
      }

      if (loginModal?.openRecoveryCode) {
        await this.flowToPasswordRecovery(intentUrl);
        return;
      }

      if (loginModal?.goToLoginByToken) {
        await this.flowToLoginByToken(intentUrl, loginModal);
        return;
      }

      if (loginModal?.openSignUp) {
        const signUpModal: any = await this.openModalByComponent(
          SignupPage,
          intentUrl,
        );

        if (signUpModal?.navigate) {
          return this.router.navigateByUrl(intentUrl);
        }
      }

      if (!this.previousUrl) {
        this.router.navigate(['/']);
      }
    } else {
      return this.router.navigate(['/auth/login'], {
        queryParams: { intentUrl },
      });
    }
  }

  private async flowToPasswordRecovery(intentUrl: string) {
    let data: LoginParams;

    data = await this.openModalByComponent(CodeRequestPage, intentUrl);

    if (data?.openConfirmationCode) {
      data = await this.openModalByComponent(ConfirmationCodePage, intentUrl);
    }

    if (data?.goToResetPassword) {
      data = await this.openModalByComponent(ResetPasswordPage, intentUrl);
    }

    if (data?.goToResetSuccessfully) {
      data = await this.openModalByComponent(
        ResetPasswordSuccessPage,
        intentUrl,
      );

      if (data?.navigate) {
        return this.router.navigateByUrl(intentUrl);
      }
    }
  }

  private async flowToLoginByToken(
    intentUrl: string,
    info: Pick<LoginParams, 'loginParams'>,
  ) {
    let data: LoginParams;

    data = await this.openModalByComponent(
      LoginByTokenPage,
      intentUrl,
      info.loginParams,
    );

    if (data?.navigate) {
      return this.router.navigateByUrl(intentUrl);
    }
  }

  private async openModalByComponent(
    component: any,
    intentUrl: string,
    componentProps = {},
  ): Promise<LoginParams> {
    const modal: HTMLIonModalElement = await this.modalController.create({
      component,
      componentProps: {
        isModal: true,
        intentUrl,
        ...componentProps,
      },
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    return data;
  }
}
