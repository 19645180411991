import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
  ElementRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CountryDDIModalComponent } from '../country-ddi-modal/country-ddi-modal.component';
import { PhoneHelper } from '../user-form/user-phone-helper.service';

@Component({
  selector: 'fabapp-phone-email-input',
  templateUrl: 'phone-email-input.component.html',
  styleUrls: ['phone-email-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneEmailInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneEmailInputComponent),
      multi: true,
    },
  ],
})
export class PhoneEmailInputComponent
  implements ControlValueAccessor, Validator
{
  @Output() setMask = new EventEmitter();
  @Output() getCallingCode = new EventEmitter();
  @Input() label = '';
  @Input() isEmail = false;
  @Input() required = false;
  @Input() disabled = false;
  @Input() type = 'text';

  @HostBinding('style.opacity')
  get opacity(): number {
    return this.disabled ? 0.25 : 1;
  }

  /**
   * @default
   * '55' (Brazil)
   */
  @Input() callingCode = '55';
  regionCode = 'BR';
  phoneNumber = '';
  mask = '';

  get value(): string {
    return this.phoneNumber;
  }

  constructor(
    private modalController: ModalController,
    private cdr: ChangeDetectorRef,
    private _elRef: ElementRef,
  ) {}

  onChanged: any = () => {};
  onTouched: any = () => {};

  writeValue(value): void {
    this.phoneNumber = value;
    this.setNativeElementValue(value);
  }

  setNativeElementValue(value): void {
    this._elRef.nativeElement.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  async openCountriesModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: CountryDDIModalComponent,
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data) {
      this.callingCode = data.callingCodes[0];
      this.getCallingCode.emit(this.callingCode);
      this.regionCode = data.alpha2Code;
      this.cdr.markForCheck();
      this.mask = PhoneHelper.getMask(this.regionCode);
      this.setMask.emit(this.mask);
    }
  }

  inputChanged(event: any): void {
    const phoneNumber: string = event.detail.value;

    if (!this.disabled) {
      this.phoneNumber = phoneNumber;
      this.onChanged(this.phoneNumber);
      this.onTouched();
    }
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors {
    if (this.isEmail) {
      return null;
    }

    const fn = PhoneHelper.validatorFn(this.regionCode);
    return fn(control);
  }
}
