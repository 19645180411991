import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDefState } from '@core/state/appdef';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DaiaService {
  private baseUrl: string;
  private appId: number;
  constructor(private httpClient: HttpClient, private store: Store) {
    this.appId = window['appId'];
    this.baseUrl = environment.daia.url;
  }

  private headers(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  public getAlbumComments(itemId: number, page: number = 1): Observable<any> {
    const url: string = `${this.baseUrl}/app/${this.appId}/album-item/${itemId}/comments?page=${page}&perPage=25`;
    return this.httpClient.get<any>(url, { headers: this.headers() });
  }

  public getAlbumLikes(instanceId: number, itemId: number): Observable<any> {
    const url: string = `${this.baseUrl}/app/${this.appId}/album-item/${itemId}/likes`;
    return this.httpClient.get<any>(url, { headers: this.headers() });
  }

  public toggleAlbumLike(instanceId: number, itemId: number): Observable<any> {
    const url: string = `${this.baseUrl}/app/${this.appId}/album-item/${itemId}/likes`;
    // TODO: deverá ser mudado o body recebido porque ao inves de usar um pageId o backEnd usa o instanceId
    return this.httpClient.post<any>(
      url,
      { pageId: instanceId },
      { headers: this.headers() },
    );
  }

  public createAlbumComment(
    mobletId: number,
    itemId: number,
    comment: string,
  ): Observable<any> {
    const url: string = `${this.baseUrl}/${this.appId}/m-album/${mobletId}/${itemId}/comments`;

    return this.httpClient.post<any>(
      url,
      {
        body: {
          comment,
          user: true,
          date: true,
        },
      },
      { headers: this.headers() },
    );
  }

  // tslint:disable-next-line: typedef
  public searchApp({
    filter,
    mobletId,
    groupId,
  }: {
    filter: string;
    mobletId?: number;
    groupId?: number;
  }): Observable<any> {
    let url: string = `${this.baseUrl}/${this.appId}/search?q=${filter}&size=999`;
    if (mobletId) {
      url += `&moblet=${mobletId}`;
    }
    if (groupId) {
      url += `&group=${groupId}`;
    }
    return this.httpClient.get<any>(url, { headers: this.headers() });
  }
}
