<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end" *ngIf="(cart$ | async)?.items?.length">
      <ion-button
        (click)="clear()"
        [style.--color]="'var(--primarycolor-or-black)'"
        >Limpar</ion-button
      >
    </ion-buttons>
    <ion-title>Carrinho</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-bottom ion-padding-horizontal">
  <fabapp-cart-delivery-type
    [deliveryMethodSelected]="initialDeliveryMethod"
    (openAddressEmitter)="openAddress()"
  ></fabapp-cart-delivery-type>

  <hr class="separator" />

  <!-- products -->
  <ion-list
    lines="none"
    class="ion-padding-top product-list"
    *ngIf="cart$ | async as cart"
  >
    <fabapp-cart-item
      *ngFor="let item of cart.items; trackBy: trackByIndex"
      [item]="item"
      [inventoryControl]="store?.inventoryControl"
      [currencyId]="store?.currencyId"
    >
    </fabapp-cart-item>
  </ion-list>
  <ion-button
    class="keep-buying btn-full"
    fill="transparent"
    (click)="modalController.dismiss();"
    [style.--color]="'var(--primarycolor-or-black)'"
    >Adicionar mais itens</ion-button
  >
  <ng-container *ngIf="(fees$ | async) as fees">
    <!-- /products -->
    <div
      class="preview-total ion-padding-horizontal ion-margin-bottom"
      *ngIf="!loading"
    >
      <div class="item">
        <div class="label">Subtotal</div>
        <div class="price">
          {{ (fees.subtotal / 100) | currency: store?.currencyId || 'BRL' }}
        </div>
      </div>
      <div class="gap"></div>
      <ng-container *ngIf="fees?.discount">
        <div class="item">
          <div class="label">Cupom</div>
          <div class="price -positive">
            -{{ (fees?.discount / 100) | currency: store?.currencyId || 'BRL' }}
          </div>
        </div>
        <div class="gap"></div>
      </ng-container>
      <div class="item">
        <div class="label">Taxa de entrega</div>
        <div class="price freeshipping" *ngIf="fees?.shippingTax === 0">
          Grátis
        </div>
        <div class="price" *ngIf="fees?.shippingTax > 0">
          {{ (fees.shippingTax / 100) | currency: store?.currencyId || 'BRL' }}
        </div>
      </div>
    </div>
    <!-- total -->
    <div class="cart-total">
      <div class="total-text">Total</div>
      <div class="total-price">
        {{ (fees.total / 100) | currency: store?.currencyId || 'BRL' }}
      </div>
    </div>
    <div
      class="min-price-warning"
      *ngIf="minPurchaseAmount > fees.total - fees?.shippingTax"
    >
      <span class="label"
        >O pedido mínimo é {{ minPurchaseAmount / 100 | currency:
        store?.currencyId || 'BRL'}} sem contar com a taxa de entrega</span
      >
    </div>
  </ng-container>

  <hr class="separator" />
  <!-- total -->
  <!-- payment method -->
  <div class="payment-method">
    <ion-list>
      <fabapp-promotion-in-cart
        (openPromotions)="openPromotions()"
        [promotionsCount]="promotionsCount$ | async"
        [couponSelected]="getCouponSelected$|async"
      >
      </fabapp-promotion-in-cart>

      <!-- selected -->

      <fabi-payment-method-item
        *ngIf="selectedPaymentMethod?.method as method"
        (onClick)="openPaymentModal()"
        (onAction)="openPaymentModal()"
        [brandIcon]="selectedPaymentMethod?.card?.brand || selectedPaymentMethod?.method?.imagePath"
        [title]="selectedPaymentMethod.card ? selectedPaymentMethod.card.nickname : method.title"
        [code]="selectedPaymentMethod.card ? selectedPaymentMethod.card.lastDigits : (selectedPaymentMethod.cashback | currency: store?.currencyId || 'BRL')"
        [censorCode]="!!selectedPaymentMethod.card"
        button-type="full"
        titleSize="small"
        icon="chevron-forward-outline"
        iconSize="small"
      >
      </fabi-payment-method-item>

      <!-- not selected -->

      <fabi-payment-method-item
        *ngIf="!selectedPaymentMethod?.method"
        (onAction)="openPaymentModal()"
        (onClick)="openPaymentModal()"
        button-type="full"
        title="Escolha uma forma de pagamento"
        titleSize="small"
        icon="chevron-forward-outline"
        iconSize="small"
      >
      </fabi-payment-method-item>
    </ion-list>
  </div>
  <!-- /payment method -->
  <ng-container
    *ngIf="!(deliverable$ | async) && (deliveryMethod$ | async) == deliveryMethodEnum.DELIVERY; else deliverable"
  >
    <ion-button class="btn-full" [disabled]="true"
      >Não entregamos no endereço</ion-button
    >
  </ng-container>
  <ng-template #deliverable>
    <ion-button
      class="btn-full"
      (click)="placeOrder()"
      [disabled]="placingOrder"
      >Fazer pedido</ion-button
    >
  </ng-template>
</ion-content>

<fabapp-bottom-sheet>
  <app-ask-user-document
    *ngIf="neededFields === neededFieldsEnum.DOCUMENT"
    (closeBottomSheet)="fabappBottomSheet.closeBottomSheet($event);"
  ></app-ask-user-document>
  <app-ask-user-email
    *ngIf="neededFields === neededFieldsEnum.EMAIL"
    (closeBottomSheet)="fabappBottomSheet.closeBottomSheet($event);"
  ></app-ask-user-email>
</fabapp-bottom-sheet>
