import {
  ComplementFromItemState,
  SavedProduct,
} from '@fabapp-delivery/models/item';

export namespace FabappDeliveryItem {
  export class Get {
    static readonly type = '[Fabapp Delivery] Get Product';
    constructor(public id: string) {}
  }
  export class Reset {
    static readonly type = '[Fabapp Delivery] Reset Product';
  }
  export class UpdateComplements {
    static readonly type = '[Fabapp Delivery] Update Complements';
    constructor(
      public complements: {
        [key: string]: Array<ComplementFromItemState>;
      },
    ) {}
  }
  export class SetQuantity {
    static readonly type = '[Fabapp Delivery] Set Quantity';
    constructor(public quantity: number) {}
  }
}
