import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  createSelector,
} from '@ngxs/store';
import { Cart } from '@core/models';
import { AddToCart, RemoveProduct, ClearCart } from './cart.actions';
import { CatalogProduct } from '@core/models/moblets';

@State<Cart>({
  name: 'cart',
  defaults: {
    products: [],
    quantity: 0,
    total: 0,
  },
})
@Injectable()
export class CartState {
  constructor() {}

  @Selector()
  static getCart(state: Cart): Cart {
    return state;
  }

  static getQuantityByProduct(id: number | string): (state: Cart) => number {
    return createSelector([CartState], (state: Cart) => {
      const product: CatalogProduct = this._productExistInCart(
        state.products,
        id,
      );
      // Mandamos um valor para o produto ter uma quantidade default
      // caso não esse produto não esteja no carrinho
      return product ? product.quantity : 1;
    });
  }

  static _productExistInCart(
    products: CatalogProduct[],
    productId: number | string,
  ): CatalogProduct {
    return products.find(({ id }: CatalogProduct) => id === productId);
  }

  @Action(ClearCart)
  clearCart(ctx: StateContext<Cart>): void {
    ctx.setState({
      catalogId: null,
      products: [],
      quantity: 0,
      total: 0,
    });
  }

  @Action(RemoveProduct)
  removeProduct(
    ctx: StateContext<Cart>,
    { product, catalogId }: AddToCart,
  ): void {
    const state: Cart = ctx.getState();
    const products: CatalogProduct[] = state.products.filter(
      ({ id }: CatalogProduct) => id !== product.id,
    );

    const quantity: number = this._calcTotalCartQuantity(products);
    const total: number = this._calcTotalCart(products);

    ctx.setState({
      catalogId: products.length ? catalogId : null,
      quantity,
      products,
      total,
    });
  }

  @Action(AddToCart)
  addToCart(ctx: StateContext<Cart>, { product, catalogId }: AddToCart): void {
    const state: Cart = ctx.getState();
    let products: CatalogProduct[] = [...state.products];

    if (!CartState._productExistInCart(state.products, product.id)) {
      products.push({
        ...product,
        amount: this._calcTotalProduct(product),
        productName: product.title,
        productId: product.id.toString(),
      });
    } else {
      products = this._updateProduct(state.products, product);
    }

    const quantity: number = this._calcTotalCartQuantity(products);
    const total: number = this._calcTotalCart(products);

    ctx.setState({
      catalogId,
      quantity,
      products,
      total,
    });
  }

  private _updateProduct(
    products: CatalogProduct[],
    product: CatalogProduct,
  ): CatalogProduct[] {
    return products.map((productItem: CatalogProduct) => {
      if (productItem.id === product.id) {
        return {
          ...product,
          quantity: product.quantity,
          productName: product.title,
          productId: product.id.toString(),
          amount: this._calcTotalProduct(product),
        };
      }
      return productItem;
    });
  }

  private _calcTotalCart(products: CatalogProduct[]): number {
    return products.reduce(
      (acc: number, product: CatalogProduct) => (acc += product.amount),
      0,
    );
  }

  private _calcTotalCartQuantity(products: CatalogProduct[]): number {
    return products.reduce(
      (acc: number, product: CatalogProduct) => (acc += product.quantity),
      0,
    );
  }

  private _calcTotalProduct(product: CatalogProduct): number {
    let finalValue: any = product.promoValue
      ? product.promoValue
      : product.value;

    // Se o cliente não mudar os valores dos preços, virá
    // no formato string
    if (typeof finalValue === 'string') {
      const valueParsed: string = finalValue.replace(/,/g, '.');
      finalValue = parseFloat(valueParsed);
    }

    return product.quantity * finalValue;
  }
}
