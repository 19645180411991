import { DynamicFormField, GroupLayoutDef } from '@core/models';
import { Form } from 'src/app/moblets/forms/models';

export class CommunicateUpdateOnSignupForm {
  static readonly type = '[moblet] Communicate Update On Signup Form to AppDef';
  constructor(public form: DynamicFormField[]) {}
}

export class CommunicateUpdateOnFormMoblet {
  static readonly type = '[moblet] Communicate Update On Form Moblet to AppDef';
  constructor(public formMoblet: Form) {}
}

export class CommunicateUpdateOnGroupPageLayout {
  static readonly type = '[moblet] Communicate Update On Group Page Layout';
  constructor(public id: number, public layout: GroupLayoutDef) {}
}
