import { AudioGalleryItem } from '@core/models/moblets';

export class IonicHandler {
  static readonly type = '[Ionic] Handler Action';
  constructor(public eventName: string, public data?: any) {}
}

export class IonicIsLoaded {
  static readonly type = '[Ionic] Ionic Is Loaded';
  constructor() {}
}

export class IonicNavigate {
  static readonly type = '[Ionic] Navigate';
  constructor(public path: string) {}
}

export class IonicBack {
  static readonly type = '[Ionic] IonicBack';
  constructor() {}
}

export class IonicChangeApp {
  static readonly type = '[Ionic] IonicChangeApp';
  constructor(public appId: string) {}
}

export class IonicPageHeight {
  static readonly type = '[Ionic] Page Height';
  constructor() {}
}

export class NativeOpenWebView {
  static readonly type = '[Native] Open WebView';
  constructor(public path: string) {}
}

export class NativeLog {
  static readonly type = '[Native] Log';
  constructor(public message: string | Object) {}
}

export class NativeConfigApp {
  static readonly type = '[Native] Config App';
  constructor() {}
}

export class NativeCanBack {
  static readonly type = '[Native] Can press Back button';
  constructor(public canBack: boolean) {}
}

export class NativePlaylistAudio {
  static readonly type = '[Native] Prepare Audio Player';
  constructor(
    public audios: AudioGalleryItem[],
    public currentAudio?: AudioGalleryItem,
  ) {}
}

export class IonicPushDeviceRegister {
  static readonly type = '[Native] Push Device Register';
  constructor(public token: string) {}
}

export class IonicPushReceived {
  static readonly type = '[Native] Push Received';
  constructor(public notification: any) {}
}

export class IonicPushClicked {
  static readonly type = '[Native] Push Clicked';
  constructor(public notification: any) {}
}
