import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SocialLoginProvider } from '@core/enums/social-login-provider.enum';
import { SocialLoginAuthentcation } from '@core/models/social-login-auth.model';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
  appId: string;
  constructor(private httpClient: HttpClient) {
    if (window['appId']) {
      this.appId = window['appId'].toString();
    }
  }

  me(): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/users/me`;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'app-id': this.appId,
    });

    return this.httpClient.get<any>(url, { headers });
  }

  login(data: {
    email: string;
    password?: string;
    code?: string;
  }): Observable<any> {
    // tslint:disable-next-line: typedef
    const url = `${environment.hera.url}/app/v3/auth/login`;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'app-id': this.appId,
    });

    return this.httpClient.post<any>(url, data, { headers });
  }

  logout(): Observable<any> {
    return of(() => {
      return true;
    });
  }

  refreshToken({ refresh_token }): Observable<any> {
    const url = `${environment.hera.url}/app/auth/refresh`;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'app-id': this.appId,
    });

    return this.httpClient.post<any>(url, { refresh_token }, { headers });
  }

  checkEmailOrPhone({ email }): Observable<any> {
    const url = `${environment.hera.url}/app/auth/check-email`;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'app-id': this.appId,
    });

    return this.httpClient.post<any>(url, { email }, { headers });
  }

  /**
   *
   * @description method for social login
   *
   * @method
   * @param  {string} payload
   * @returns Observable
   * @memberof AuthService
   */
  socialLogin(payload: SocialLoginAuthentcation): Observable<any> {
    const URL: string = `${environment.hera.url}/app/login/social`;
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const body: string = JSON.stringify({
      provider: SocialLoginProvider.GOOGLE,
      ...payload,
    });

    return this.httpClient.post(URL, body, {
      headers,
    });
  }
}
