import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HermesService {
  constructor(private httpClient: HttpClient) {}

  private headers(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  notificationRead(
    notificationId: string,
    platform: string,
    userId: string,
  ): Observable<any> {
    const url: string = `${environment.hermes.url}/notifications/push-notifications/${notificationId}/read`;
    const body: any = {
      platform,
      user_id: userId ? userId : null,
      user_agent: navigator.userAgent,
    };
    return this.httpClient.post<any>(url, body, { headers: this.headers() });
  }
}
