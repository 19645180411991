import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { FDI_OrderObject } from '@fabapp-delivery/models/cart/cart.model';
import { HttpService } from '../../../core/http/http.service';

@Injectable({
  providedIn: 'root',
})
export class OrdersService extends HttpService<any> {
  protected url = `${environment.shop.url}/app/orders`;

  getProducts(page: number = 1) {
    return this.request('GET', `/?page=${page}`);
  }

  getInfoProducts(produtid: string) {
    return this.request('GET', `/${produtid}`);
  }

  placeOrder(orderObject: FDI_OrderObject) {
    return this.request('POST', '', orderObject);
  }

  receivedOrder(idOrder: string) {
    return this.request('PUT', `/${idOrder}/received-order`);
  }
}
