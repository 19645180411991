<div class="rating-tile" [class]="styleType">
  <div class="title" *ngIf="title">{{ title }}</div>
  <div class="subtitle" *ngIf="subtitle">{{ subtitle }}</div>

  <ion-buttons class="rating-stars" mode="md">
    <ion-button
      [disabled]="disabled"
      class="star"
      *ngFor="let index of [1, 2, 3, 4, 5]"
      (click)="toRate(index); $event.stopPropagation()"
    >
      <ion-icon
        slot="icon-only"
        [name]="index <= initialRatingInStars ? 'star' : 'star-outline'"
      ></ion-icon>
    </ion-button>
  </ion-buttons>

  <div class="feedback" *ngIf="haveFeedback">
    <p class="label">O que podemos melhorar?</p>

    <div class="options-feedback">
      <ion-chip *ngFor="let option of feedbackOptions">
        <ion-label>{{ option.label }}</ion-label>
      </ion-chip>
    </div>

    <p class="label">Deixe um comentário</p>

    <ion-item class="input">
      <ion-input placeholder="Comentário (opcional)"></ion-input>
    </ion-item>
  </div>
</div>
