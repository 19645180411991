<ion-progress-bar type="indeterminate" *ngIf="isSubmitting"></ion-progress-bar>
<ion-content class="ion-padding">
  <form [formGroup]="form">
    <ion-list *transloco="let t">
      <ng-container *ngIf="!addressValid">
        <ion-item slot="start">
          <button class="button-input" (click)="closeModal()">
            <ion-icon class="icon" name="chevron-back-outline"></ion-icon>
          </button>
          <fabapp-address-autocomplete
            placeholder="Digite o nome da rua e número "
            [formControlName]="fields.ADDRESS"
            [gMaps]="
              store?.needsSelfMapsKey ? store?.gMapsKey : DEFAULT_MAPS_KEY
            "
          ></fabapp-address-autocomplete>
        </ion-item>
        <span
          class="hint"
          *ngIf="
            !form.get(fields.ADDRESS).errors?.required ||
            form.get(fields.ADDRESS).errors?.isEmpty
          "
        >
          {{ 'settings.addressPage.hint' | transloco }}
        </span>
        <control-error
          *ngIf="!form.get(fields.ADDRESS).errors?.requiredNumber"
          [label]="t('settings.addressPage.address')"
          [control]="form.get(fields.ADDRESS)"
        ></control-error>

        <div class="address-list" *ngIf="addresses && addresses.length">
          <ion-label> Selecione o seu endereço </ion-label>
          <ion-item
            *ngFor="let address of addresses"
            (click)="selectAddress(address)"
          >
            <ion-icon slot="start" name="location-sharp"></ion-icon>
            <ion-label>
              <h3>{{ address.structured_formatting.main_text }}</h3>
              <p>{{ address.structured_formatting.secondary_text }}</p>
            </ion-label>
          </ion-item>
        </div>
      </ng-container>

      <ng-container *ngIf="addressValid">
        <div class="divider"></div>
        <ion-label class="ion-margin-top">{{
          t('settings.addressPage.number')
        }}</ion-label>
        <ion-item class="ion-no-margin">
          <ion-input [formControlName]="'number'" outline type="number">
          </ion-input>
        </ion-item>

        <control-error
          [label]="t('settings.addressPage.number')"
          [control]="form.get('number')"
        >
        </control-error>

        <div class="divider"></div>
        <ion-label class="ion-margin-top">{{
          t('settings.addressPage.complement')
        }}</ion-label>
        <ion-item class="ion-no-margin">
          <ion-input
            [formControlName]="fields.COMPLEMENT"
            outline
            type="text"
          ></ion-input>
        </ion-item>

        <control-error
          [label]="t('settings.addressPage.complement')"
          [control]="form.get(fields.COMPLEMENT)"
        >
        </control-error>
      </ng-container>
    </ion-list>
  </form>
</ion-content>
<ion-footer class="ion-padding ion-no-border">
  <p class="observation-location">Não encontrou o endereço?</p>

  <ion-button
    *transloco="let t"
    type="submit"
    fill="outline"
    [style.--color]="'var(--primarycolor-or-black)'"
    (click)="selectLocation()"
    expand="block"
    >Usar minha localização</ion-button
  >
</ion-footer>
