<ion-content fixed>
  <div class="content-wrapper">
    <div class="heading">
      <div (click)="dismiss()" class="go-to-app-wrapper ion-activatable">
        <ion-ripple-effect></ion-ripple-effect>
        <img
          *ngIf="appInfo?.icon"
          class="app-icon"
          [src]="appInfo?.icon"
          [alt]="appInfo?.name"
        />
        <div class="label-wrapper">
          <div class="label">
            <div class="go-to">
              {{ 'interstitialGrumft.goToApp' | transloco }}
            </div>
            <div class="appname">{{ appInfo.name }}</div>
          </div>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
      </div>
    </div>

    <div class="inner-wrapper ion-text-center">
      <div class="ad-info">{{ 'interstitialGrumft.title' | transloco }}</div>
      <div id="script-container"></div>
    </div>
  </div>
</ion-content>
