import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DaiaService } from '../../services/daia.service';
import { Search } from './search.actions';

export interface SearchModel {
  filter: string;
  mobletId: number;
  groupId: number;
  loading: boolean;
  items: [];
}

@State<SearchModel>({
  name: 'search',
  defaults: {
    filter: '',
    mobletId: null,
    groupId: null,
    loading: false,
    items: [],
  },
})
@Injectable()
export class SearchState {
  constructor(private daiaService: DaiaService) {}

  @Selector()
  static filter(state: SearchModel): string {
    return state.filter;
  }

  @Selector()
  static items(state: SearchModel): [] {
    return state.items;
  }

  @Action(Search)
  async search(ctx: StateContext<SearchModel>, search: Search): Promise<any> {
    this.resetState(ctx);
    const data: any = await this.daiaService
      .searchApp(search.payload)
      .toPromise();
    ctx.patchState({ ...search.payload, items: data.results });
  }

  private resetState(ctx: StateContext<SearchModel>): void {
    ctx.patchState({
      loading: false,
      filter: '',
      mobletId: null,
      groupId: null,
      items: [],
    });
  }
}
