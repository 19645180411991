import { Injectable, NgZone } from '@angular/core';
import { UserStatus } from '@core/enums';
import { SocialLoginProvider } from '@core/enums/social-login-provider.enum';
import { RestrictionSegmented } from '@core/guards/auth-segmented-restriction/restriction-segmented';
import { User } from '@core/models/user.model';
import { HeraService } from '@core/services/hera.service';
import { NavController } from '@ionic/angular';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { convertToBoolean } from '@utils';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../../pages/auth/services/auth.service';
import { LoadAddresses } from '../address/address.action';
import { CoreState } from '../core/core.state';
import {
  AppleLogin,
  AppleWebLogin,
  DeleteAvatar,
  DeleteUser,
  FacebookLogin,
  GoogleLogin,
  LoadRestrictedPages,
  Login,
  Logout,
  RefreshToken,
  RegularSignUp,
  ReloadUser,
  RemoveRestrictedPages,
  SetAuthToken,
  SetPhoneToVerifyFirstAccess,
  UpdateRestrictedPagesWhenLogged,
  UpdateUser,
  UploadAvatar,
} from './auth.actions';

export interface AuthStateModel {
  loading: boolean;
  user: User;
  access_token: string;
  refresh_token: string;
  isRefreshing: boolean;
  restrictedPages?: object;
  phoneNumber?: string;
}

@State<AuthStateModel>({
  name: `auth_${window['appId']}`,
  defaults: {
    loading: false,
    user: null,
    access_token: null,
    refresh_token: null,
    isRefreshing: false,
    restrictedPages: null,
    phoneNumber: null,
  },
})
@Injectable()
export class AuthState {
  private readonly restrictionSegmented: RestrictionSegmented;
  constructor(
    private heraService: HeraService,
    private authService: AuthService,
    private store: Store,
    private navCtrl: NavController,
    private ngZone: NgZone,
  ) {
    // cria a instancia do restriction-segmented
    this.restrictionSegmented = new RestrictionSegmented(store, heraService);
  }

  static canAccessPage(instanceId: number): (state: AuthStateModel) => boolean {
    return createSelector(
      [AuthState],
      ({ restrictedPages }: AuthStateModel) => {
        // se o item procurado for undefined retorna true porque
        // se caso o usuário não estiver logado todo retorna poderá user undefined
        if (restrictedPages[instanceId] === undefined) {
          return true;
        }
        return restrictedPages[instanceId];
      },
    );
  }

  @Selector()
  static isRefreshing(state: AuthStateModel): boolean {
    return state.isRefreshing;
  }

  @Selector()
  static accessToken(state: AuthStateModel): string {
    return state.access_token;
  }

  @Selector()
  static refreshToken(state: AuthStateModel): string {
    return state.refresh_token;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.access_token;
  }

  @Selector()
  static getUser(state: AuthStateModel): User {
    return state.user;
  }

  @Selector()
  static getPhoneNumber(state: AuthStateModel): string {
    return state.phoneNumber;
  }

  @Selector()
  static acceptsPush(state: AuthStateModel): Partial<User> {
    return { accepts_push: convertToBoolean(state.user.accepts_push) };
  }

  @Action(SetAuthToken)
  async setAuthToken(
    ctx: StateContext<AuthStateModel>,
    { payload }: SetAuthToken,
  ) {
    ctx.patchState(payload);
  }

  @Action(SetPhoneToVerifyFirstAccess)
  async SetPhoneToVerifyFirstAccess(
    ctx: StateContext<AuthStateModel>,
    { phoneNumber }: SetPhoneToVerifyFirstAccess,
  ) {
    ctx.patchState({ phoneNumber });
  }

  @Action(RefreshToken)
  async refreshToken(
    ctx: StateContext<AuthStateModel>,
    {}: any,
  ): Promise<any> | null {
    const state: AuthStateModel = ctx.getState();

    if (!state.user || !state.refresh_token || state.isRefreshing) {
      return null;
    }

    try {
      ctx.patchState({ isRefreshing: true });
      await this.authService
        .refreshToken({
          refresh_token: state.refresh_token,
        })
        .pipe(
          tap((payload: AuthStateModel) => {
            ctx.patchState(payload);
          }),
        )
        .toPromise();
      ctx.patchState({ isRefreshing: false });
    } catch (error) {
      this.store.dispatch(new Logout());
      this.returnToHomePage();
      throw error;
    }
  }

  private returnToHomePage(): void {
    const homeRoute: any[] = this.store.selectSnapshot(CoreState.getHomeRoute);
    this.ngZone.run(() => this.navCtrl.navigateRoot(homeRoute));
  }

  @Action(RegularSignUp)
  async regularSignUp(
    ctx: StateContext<AuthStateModel>,
    { user }: any,
  ): Promise<any> {
    const state: AuthStateModel = ctx.getState();
    const response: any = await this.heraService
      .regularSignUp(user)
      .toPromise();

    let email = response.user.email;
    if (this.store.selectSnapshot(CoreState.isFabappDeliveryEnabled)) {
      email = user.phone_number;
    }

    await ctx
      .dispatch(new Login({ email, password: user.password }))
      .toPromise();
  }

  @Action(UpdateUser)
  async updateUser(
    ctx: StateContext<AuthStateModel>,
    { user }: any,
  ): Promise<any> {
    const response: any = await this.heraService.updateUser(user).toPromise();

    ctx.patchState({ user: response });
  }

  @Action(ReloadUser)
  async reloadUser(ctx: StateContext<AuthStateModel>): Promise<any> {
    const state: AuthStateModel = ctx.getState();
    if (!state.user || !state.access_token || state.isRefreshing) {
      return null;
    }

    const response: User = await this.authService.me().toPromise();
    const { status_id } = response;

    const status = {
      [UserStatus.Closed]: true,
      [UserStatus.Suspended]: true,
    };

    if (!status[status_id]) {
      /**  atualiza as informações do usuário se não estiver `Closed` ou `Suspended` @see UserStatus */
      ctx.dispatch(new LoadAddresses());
      ctx.patchState({ user: response });
      return;
    }

    // logout do usuário
    await this.logoutAndRedirect();
  }

  @Action(UploadAvatar)
  async uploadAvatar(
    ctx: StateContext<AuthStateModel>,
    { avatar }: any,
  ): Promise<any> {
    const response: any = await this.heraService
      .uploadAvatar(avatar)
      .toPromise();
    const user: User = {
      ...ctx.getState().user,
      ...{ picture: response.avatarUrl },
    };
    ctx.patchState({ user });
  }

  @Action(DeleteAvatar)
  async deleteAvatar(ctx: StateContext<AuthStateModel>): Promise<void> {
    try {
      await this.heraService.deleteAvatar().toPromise();
      const user: User = {
        ...ctx.getState().user,
        ...{ picture: '' },
      };

      ctx.patchState({ user });
    } catch (error) {
      console.log('Error for delete avatar');
    }
  }

  @Action(DeleteUser)
  async deleteUser(ctx: StateContext<AuthStateModel>): Promise<any> {
    await this.heraService.deleteUser().toPromise();
  }

  @Action(Login)
  async login(ctx: StateContext<AuthStateModel>, action: Login) {
    const payload = await this.authService
      .login(action.payload as any)
      .toPromise();

    ctx.patchState(payload);
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    if (window['heap']) {
      window['heap'].resetIdentity();
    }
    return this.authService.logout().pipe(
      tap(() => {
        ctx.setState({
          loading: false,
          access_token: null,
          refresh_token: null,
          user: null,
          isRefreshing: false,
        });
      }),
    );
  }

  /**
   * @param  {StateContext<AuthStateModel>} ctx
   * @param  {GoogleLogin} action
   * @returns any
   */
  @Action(GoogleLogin)
  googleLogin(ctx: StateContext<AuthStateModel>, action: GoogleLogin): any {
    return this.authService
      .socialLogin({
        token: action.payload.token,
        provider: SocialLoginProvider.GOOGLE,
      })
      .pipe(
        tap((payload: AuthStateModel) => {
          ctx.patchState(payload);
        }),
      );
  }

  /**
   * @param  {StateContext<AuthStateModel>} ctx
   * @param  {FacebookLogin} action
   * @returns any
   */
  @Action(FacebookLogin)
  facebookLogin(ctx: StateContext<AuthStateModel>, action: FacebookLogin): any {
    return this.authService
      .socialLogin({
        token: action.payload.token,
        provider: SocialLoginProvider.FACEBOOK,
      })
      .pipe(
        tap((payload: AuthStateModel) => {
          ctx.patchState(payload);
        }),
      );
  }

  @Action(AppleLogin)
  appleLogin(
    ctx: StateContext<AuthStateModel>,
    { accessToken }: AppleLogin,
  ): Observable<any> {
    return this.authService
      .socialLogin({
        token: accessToken,
        provider: SocialLoginProvider.APPLE,
      })
      .pipe(
        tap((payload: AuthStateModel) => {
          ctx.patchState(payload);
        }),
      );
  }

  @Action(AppleWebLogin)
  async appleWebLogin(
    ctx: StateContext<AuthStateModel>,
    { refreshToken }: AppleWebLogin,
  ): Promise<any> | null {
    ctx.patchState({ refresh_token: refreshToken });

    await this.authService
      .refreshToken({ refresh_token: refreshToken })
      .pipe(
        tap((payload: AuthStateModel) => {
          ctx.patchState(payload);
        }),
      )
      .toPromise();
  }

  // restricted pages

  @Action(LoadRestrictedPages)
  async triggerForLoadRestrictedPages(
    ctx: StateContext<AuthStateModel>,
  ): Promise<void> {
    const { restrictedPages, access_token } = ctx.getState();
    // se já tiver as paginas salvas retorna
    if (restrictedPages) {
      return;
    }

    // se não tiver logado retorna e não faz a requisição para pegar as pages
    if (!access_token) {
      ctx.patchState({ restrictedPages: null });
      return;
    }

    const pages: any = await this.restrictionSegmented.loadRestrictedPages();
    ctx.patchState({ restrictedPages: pages });
  }

  @Action(UpdateRestrictedPagesWhenLogged)
  async updateRestrictedPages(
    ctx: StateContext<AuthStateModel>,
  ): Promise<void> {
    const { access_token } = ctx.getState();

    if (!access_token) {
      return;
    }

    const pages: any = await this.restrictionSegmented.loadRestrictedPages();
    ctx.patchState({ restrictedPages: pages });
  }

  @Action(RemoveRestrictedPages)
  remove(ctx: StateContext<AuthStateModel>): void {
    ctx.patchState({ restrictedPages: null });
  }

  private async logoutAndRedirect() {
    await this.store.dispatch(new Logout()).toPromise();
    const homeRoute: any[] = this.store.selectSnapshot(CoreState.getHomeRoute);
    this.ngZone.run(() => this.navCtrl.navigateRoot(homeRoute));
  }
}
