<!-- list of text and list with thumbnail -->
<ion-list class="list">
  <ion-item
    class="list-item"
    *ngFor="let item of items; trackBy: trackById"
    (click)="handle(item)"
  >
    <!-- thumbnail -->
    <ion-thumbnail
      slot="start"
      class="thumbnail"
      *ngIf="
        (item?.listItemImages)[0]?.imageUrl &&
        exibitionType.BASIC === listDef?.exibitionType?.name
      "
    >
      <img-loader [src]="item.listItemImages[0].imageUrl"></img-loader>
    </ion-thumbnail>
    <!-- end thumbnail -->

    <!-- content -->
    <ion-label class="inner-wrapper">
      <h2 class="headline">{{ item.title }}</h2>
      <p class="description">{{ item?.resume }}</p>
      <app-rating-of-item
        *ngIf="listDef?.ratings"
        (rate)="handle(item, true)"
        [average]="item.ratingsavg"
        [ratingsTotal]="item.ratingstotal"
        [withSeparator]="item?.distance"
      ></app-rating-of-item>
      <span *ngIf="item.distance" class="distance">{{
        item.distance | meterToKilometer
      }}</span>
    </ion-label>
    <!-- content -->
  </ion-item>
</ion-list>
<!-- list of text and list with thumbnail -->
