export class LoadCatalogAndConcat {
  static readonly type = '[moblet] Load Catalog';
  constructor(
    public id: number,
    public categoryId: string | number = 0,
    public page: number = 1,
    public version: number = 3,
    public extraParams?: object,
    public fabAppVersion = 3,
  ) {}
}

export class RefreshCatalog {
  static readonly type = '[moblet] Refresh Catalog';
  constructor(
    public id: number,
    public categoryId: string | number = 0,
    public page: number = 1,
    public version: number = 3,
    public extraParams?: object,
    public fabAppVersion = 3,
  ) {}
}

export class ResetCatalog {
  static readonly type = '[moblet] Reset Catalog';
}
