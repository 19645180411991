import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserStatus } from '@core/enums';
import { AppDef, ErrorApiReturns, User } from '@core/models';
import { NavigationService } from '@core/services/navigation.service';
import { AppDefState } from '@core/state/appdef';
import {
  AuthState,
  Login,
  SetPhoneToVerifyFirstAccess,
} from '@core/state/auth';
import { CoreState } from '@core/state/core/core.state';
import { PushDeviceRegister } from '@core/state/push';
import { FDI_Store } from '@fabapp-delivery/models/store';
import {
  isPlatform,
  ModalController,
  NavController,
  ToastController,
} from '@ionic/angular';
import {
  translate,
  TranslocoService,
  TRANSLOCO_SCOPE,
} from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AppleSignInService } from '../services/apple-signin.service';
import { FacebookService } from '../services/facebook.service';
import { GoogleSignInService } from '../services/google-signin.service';
import slideDownAnimation from './slide-down.animation';
import { AuthService } from '../../../pages/auth/services/auth.service';

const TRANSLATION_SCOPE: string = 'login';
let LANG: string;
const MASKS = {
  email: { mask: '', type: 'text' },
  phone: { mask: '99 99999-9999', type: 'tel' },
};
const EMAIL_REGEXP = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

@Component({
  selector: 'fabapp-login',
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.scss'],
  animations: [slideDownAnimation],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: TRANSLATION_SCOPE,
    },
    GoogleSignInService,
    AppleSignInService,
  ],
})
export class LoginPage implements OnInit, OnDestroy {
  @Input() isModal = false;
  @Input() intentUrl: string;
  @Select(AppDefState.getFromAppDef(['info', 'logo']))
  partialAppDef$: Observable<Partial<AppDef>>;
  isSubmitting = false;
  isLoft: boolean = false;
  translationScope: string;
  showPassword = false;
  subscription: Subscription;
  passwordType: 'password' | 'text' = 'password';
  mask = MASKS.email;
  isEmail = true;
  checkEmailExist: boolean = false;
  /** DDI default é do Brazil */
  callingCode: string = '55';
  emailValidations = [
    Validators.required,
    Validators.email,
    Validators.pattern(EMAIL_REGEXP),
  ];
  phoneValidations = [Validators.required];

  form = this.fb.group({
    email: ['', this.emailValidations],
    password: ['', this.phoneValidations],
  });

  /**
   * @description
   * propriedade usada para não esconder
   * o botão de voltar do header do login no preview
   */
  public readonly isPreview: boolean;
  public deliveryEnabled = false;
  public userNameTranslationKey: string = 'login.fields.username.label';

  currentStore: FDI_Store;
  constructor(
    private navigationService: NavigationService,
    private navCtrl: NavController,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private store: Store,
    public modalController: ModalController,
    public toastController: ToastController,
    private translocoService: TranslocoService,
    public facebook: FacebookService,
    private googleSignInService: GoogleSignInService,
    private appleSignInService: AppleSignInService,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {
    LANG = this.translocoService.getActiveLang();

    this.subscription = this.email.statusChanges.subscribe(() => {
      if (this.checkEmailExist) {
        this.showPassword = false;
        this.checkEmailExist = false;
        this.cdr.detectChanges();
      }
    });
    this.isPreview = window['preview'];
  }

  ngOnInit(): void {
    this.currentStore = this.store.selectSnapshot(CoreState.currentStore);
    this._initDeliveryMode();

    this.facebook.init();
    this.intentUrl = this.intentUrl
      ? this.intentUrl
      : this.route.snapshot.queryParams?.intentUrl;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private _initDeliveryMode() {
    this.deliveryEnabled = this.store.selectSnapshot(
      CoreState.isFabappDeliveryEnabled,
    );
  }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  isAndroid(): boolean {
    return isPlatform('capacitor') && isPlatform('android');
  }

  setMask(mask: string): void {
    this.mask = { mask, type: 'tel' };
  }

  onInputUsername(value: string): void {
    value = value.replace(/[\n\r\s\t]+/g, '').replace(/\-/g, '');

    if (value.length < 2) {
      this.isEmail = true;
      this.mask = MASKS.email;
      this.form.get('email').setValidators(this.emailValidations);
      this.form.get('email').setValue(value);
      this.cdr.detectChanges();
      return;
    }
    // Não mudou nada
    if (this.isEmail !== /^\d+$/.test(value)) {
      return;
    }
    this.isEmail = !/^\d+$/.test(value);

    this.form
      .get('email')
      .setValidators(
        this.isEmail ? this.emailValidations : this.phoneValidations,
      );

    if (!this.isEmail) {
      this.mask = MASKS.phone;
    } else {
      this.mask = MASKS.email;
      this.email.setValue(value);
    }

    this.cdr.detectChanges();
  }

  private async presentToast(translateKey: string): Promise<void> {
    const toast: HTMLIonToastElement = await this.toastController.create({
      message: translate(
        translateKey,
        {},
        `${TRANSLATION_SCOPE}/${this.translocoService.getActiveLang()}`,
      ),
      duration: 2000,
    });
    toast.present();
  }

  togglePasswordMode(): void {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
  }

  getCallingCode(callingCode: string): void {
    this.callingCode = callingCode;
  }

  async checkEmailOrPassword(): Promise<void> {
    try {
      this.isSubmitting = true;
      const data: any = this._treatData();

      const verifyEmail = await this.authService
        .checkEmailOrPhone({ email: data.email })
        .toPromise();

      if (verifyEmail.completed) {
        this.showPassword = true;
        this.checkEmailExist = true;
        this.cdr.detectChanges();
        return;
      }

      if (this.isEmail === true){
        this.presentToast('messages.errorCreatePasswordWithEmail');
        return;
      }

      await this.store
        .dispatch(new SetPhoneToVerifyFirstAccess(data.email))
        .toPromise();

      await this.navCtrl.navigateForward('/auth/check-identify');
    } catch (error) {
      if (error.status === 404) {
        this.presentToast('messages.emailNotFound');
      }
    } finally {
      this.isSubmitting = false;
    }
  }

  async login(): Promise<void> {
    try {
      const data: any = this._treatData();
      this.isSubmitting = true;
      await this.store.dispatch(new Login(data)).toPromise();
      await this.store.dispatch(new PushDeviceRegister()).toPromise();

      const user: User = this.store.selectSnapshot(AuthState.getUser);

      if (user.status_id === UserStatus.Waiting) {
        this.goToUserAwaitingApproval();
        return;
      }

      this.presentToast('messages.loginSuccess');

      this.redirectOrDismiss();
    } catch (err) {
      this.treatError(err);
    } finally {
      this.isSubmitting = false;
    }
  }

  private _treatData(): { email?: string; password?: string } {
    const data: any = this.form.getRawValue();

    if (!this.isEmail) {
      data.email = this.callingCode + data.email.replace(/[^\d]+/g, '');
    }
    return data;
  }

  async appleLogin(): Promise<any> {
    this.isSubmitting = true;
    try {
      await this.appleSignInService.signIn();
      // TODO: TROCAR `dismissModalWithoutData` para o método `dismissModal` para ter o redirect apos o login
      this.dismissModalWithoutData();
    } catch (err) {
      console.error(err);
      this.treatError(err);
    } finally {
      this.isSubmitting = false;
    }
  }

  /**
   * @description method call signIn() from param googleSignInService
   *
   * @returns Promise
   */
  async googleLogin(): Promise<void> {
    this.isSubmitting = true;
    try {
      await this.googleSignInService.signIn();
      this.redirectOrDismiss();
    } catch (error) {
      this.treatError(error);
    } finally {
      this.isSubmitting = false;
    }
  }

  /**
   * @description method call signIn() from param googleSignInService
   *
   * @returns Promise
   */
  async facebookLogin(): Promise<void> {
    this.isSubmitting = true;
    try {
      await this.facebook.signIn();
      // TODO: TROCAR `dismissModalWithoutData` para o método `dismissModal` para ter o redirect apos o login
      this.dismissModalWithoutData();
    } catch (error) {
      console.error(error);
      this.treatError(error);
    } finally {
      this.isSubmitting = false;
    }
  }

  async goToNewAccount() {
    if (this.isModal) {
      return await this.modalController.dismiss({ openSignUp: true });
    }

    await this.navCtrl.navigateForward('/auth/signup');
  }

  goToUserAwaitingApproval(): void {
    this.navCtrl.navigateRoot('/auth/user-awaiting-approval');
  }

  recoveryCode(): void {
    if (this.isModal) {
      this.modalController.dismiss({ openRecoveryCode: true });
    } else {
      this.navCtrl.navigateForward('/reset-password/code-request');
    }
  }

  /**
   * @description Method treat error for show generic message or redirect user
   * @private
   * @param  {HttpErrorResponse} err
   * @returns void
   * @memberof GoogleSignInService
   *
   */
  private treatError(err: HttpErrorResponse): void {
    const keyReturned: string | number =
      err.error.error || err.error.error_code;

    if (keyReturned === 'user_waiting') {
      return this.goToUserAwaitingApproval();
    }

    const apiReturns: ErrorApiReturns = {
      invalid_credentials: 'invalidCredentials',
      user_suspended: 'userSuspended',
      user_closed: 'userRemoved',
      provider_not_configured: 'providerNotConfigured',
    };

    const keyMessage: string = apiReturns[keyReturned] || 'genericError';

    this.presentToast(`messages.${keyMessage}`);
  }

  /**
   * @description
   * diferente do `dismissModalWithoutData` esse método
   * redireciona o usuário
   */
  private async redirectOrDismiss() {
    if (this.isModal) {
      this.modalController.dismiss({ navigate: true });
      return;
    }

    if (this.intentUrl) {
      const isOk = await this.navCtrl.navigateRoot(this.intentUrl);
      /** Caso a rota seja bloqueada por restrição personalizada, retorna para home */
      if (isOk) {
        return;
      }
    }

    this.navigationService.returnToHomePage();
  }

  private dismissModalWithoutData(): void {
    if (this.isModal) {
      this.modalController.dismiss();
    }
  }

  loginByToken() {
    const data = this.form.getRawValue();
    const route: string = '/login-by-token';
    const loginParams = {
      phoneNumber: data?.email.replace(/[^\d]+/g, ''),
      ddi: this.callingCode,
    };
    const modalData: { [key: string]: any } = {
      goToLoginByToken: true,
      loginParams,
    };

    if (this.isModal) {
      this.modalController.dismiss(modalData);
    } else {
      this.navCtrl.navigateForward(route, {
        queryParams: loginParams,
      });
    }
  }
}
