import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { Address } from '@core/models/address/address.model';
import { GeolocationService } from '@core/services/geolocation.service';
import {
  DeleteCurrentAddress,
  RegisterAddress,
  SetCurrentAddress,
  UpdateAddress,
} from '@core/state/address/address.action';
import { AddressState } from '@core/state/address/address.state';
import { ModalController } from '@ionic/angular';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InformationsAddressComponent } from '../informations-address/informations-address.component';

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss'],
})
export class SelectLocationComponent implements OnInit {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  positionCenterGoogleMaps: google.maps.LatLngLiteral = {
    lat: 0,
    lng: 0,
  };

  zoomViewGoogleMaps: number = 17;

  optionsControlGoogleMaps: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    scrollwheel: true,
    disableDoubleClickZoom: true,
    disableDefaultUI: true,
  };

  markerOptionsGoogleMaps: google.maps.MarkerOptions = {
    clickable: true,
    draggable: true,
  };

  markerPositionsGoogleMaps: google.maps.LatLngLiteral;

  height: number;
  center: google.maps.LatLngLiteral;
  currentLocateUser: any = {
    position: { lat: 0, lng: 0 },
  };

  currentStreet: any;
  addressUser: Address;

  modalInformationAddress: HTMLIonModalElement;

  subscribeRegister: Subscription;
  subscribeEditable: Subscription;

  @Input() gMapsKey: string;
  constructor(
    private geolocationService: GeolocationService,
    private store: Store,
    public modalController: ModalController,
    private actions$: Actions,
  ) {}

  async ngOnInit() {
    if (this.checkDevice()) {
      this.height = window.screen.height;
    }

    const storeAddress = this.store.selectSnapshot(
      AddressState.getCurrentAddress,
    );

    if (storeAddress) {
      this.currentStreet = storeAddress.address;
      this.addMarker(
        parseFloat(storeAddress.lat),
        parseFloat(storeAddress.lng),
      );

      this.addressUser = storeAddress;
      return;
    }
    this.currentLocationUser();
  }

  private async setListenerAddress() {
    if (!this.modalInformationAddress) {
      return;
    }

    const editable = this.store.selectSnapshot(AddressState.getEditableAddress);

    if (editable) {
      this.subscribeEditable = this.actions$
        .pipe(ofActionDispatched(UpdateAddress))
        .subscribe(async () => {
          this.modalInformationAddress.dismiss();
        });
    } else {
      this.subscribeRegister = this.actions$
        .pipe(ofActionDispatched(RegisterAddress))
        .subscribe(async () => {
          this.modalInformationAddress.dismiss();
        });
    }
  }

  ngOnDestroy(): void {
    if (this.subscribeEditable) {
      this.subscribeEditable.unsubscribe();
    }

    if (this.subscribeRegister) {
      this.subscribeRegister.unsubscribe();
    }
  }

  async closeModal() {
    await this.store.dispatch(new DeleteCurrentAddress()).toPromise();
    const modal = await this.modalController.getTop();
    modal.dismiss();
  }

  async currentLocationUser() {
    if (this.gMapsKey) {
      const localeUser = await this.geolocationService.getPosition();
      this.addMarker(
        parseFloat(localeUser.latitude),
        parseFloat(localeUser.longitude),
      );
    } else {
      this.currentLocateUser = null;
    }

    await this.informationAddress();
  }

  addMarker(lat: number, lng: number) {
    this.optionsControlGoogleMaps.center = {
      lat: lat,
      lng: lng,
    };

    this.currentLocateUser.position = {
      lat: lat,
      lng: lng,
    };

    this.currentLocateUser.options = {
      animation: google.maps.Animation.DROP,
      draggable: true,
    };

    this.center = {
      lat: lat,
      lng: lng,
    };
  }

  clickMap(event: any) {
    this.center = event.latLng.toJSON();
    this.addMarker(this.center.lat, this.center.lng);
    this.informationAddress();
  }

  draggableMap() {
    this.center = this.map.getCenter().toJSON();
    this.informationAddress();
  }

  zoomMap() {
    this.center = this.map.getCenter().toJSON();
    this.informationAddress();
  }

  draggableMarker(event) {
    this.center = event.latLng.toJSON();
    this.addMarker(this.center.lat, this.center.lng);
    this.informationAddress();
  }

  async informationAddress() {
    let response;
    let requestMaps;

    try {
      requestMaps = await fetch(
        `${environment.maps.api.geocode}?latlng=${this.center.lat},${this.center.lng}&key=${this.gMapsKey}`,
        {
          headers: {},
          method: 'GET',
        },
      );

      response = await requestMaps.json();
    } catch (e) {
      console.error(e);
    }

    const address = response.results.filter((result) => {
      return result.types.includes('street_address') === true;
    });

    let responseAddress = null;

    if (address.length > 0) {
      responseAddress = address[0];
    } else {
      responseAddress = response.results[0];
    }

    this.currentStreet = responseAddress.formatted_address.split('-')[0];
    this.addressUser = this.setAddress(responseAddress);

    return response.results[0];
  }

  async confirm() {
    this.store.dispatch(new SetCurrentAddress(this.addressUser));
    this.modalInformationAddress = await this.modalController.create({
      component: InformationsAddressComponent,
    });

    await this.setListenerAddress();
    await this.modalInformationAddress.present();
  }

  setAddress(addressProp) {
    const addressFormat = {
      address: null,
      number: null,
      neighborhood: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
      full_address: '',
      place_id: '',
      lat: null,
      lng: null,
    };

    addressProp.address_components.forEach((address) => {
      address.types.forEach((type) => {
        switch (type) {
          case 'street_number':
            addressFormat.number = address.long_name;
            break;
          case 'route':
            addressFormat.address = address.long_name;
            break;

          case 'sublocality':
          case 'neighborhood':
            addressFormat.neighborhood = address.long_name;
            break;

          case 'administrative_area_level_2':
            addressFormat.city = address.long_name;
            break;

          case 'administrative_area_level_1':
            addressFormat.state = address.short_name;
            break;

          case 'postal_code':
            addressFormat.zip_code = address.long_name;
            break;

          case 'country':
            addressFormat.country = address.long_name;
            break;

          case 'country':
            addressFormat.full_address = address.long_name;
            break;
          default:
            break;
        }
      });
    });

    addressFormat.full_address = addressProp.formatted_address;
    addressFormat.place_id = addressProp.place_id;
    addressFormat.lat = this.center.lat;
    addressFormat.lng = this.center.lng;

    if (!addressFormat.address) {
      addressFormat.address = this.currentStreet;
    }

    return addressFormat;
  }

  checkDevice() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
