import { Plugins, StatusBarStyle } from '@capacitor/core';
import { Colors } from '@core/models';
import { isPlatform } from '@ionic/angular';
import * as tinycolor from 'tinycolor2';

const { StatusBar } = Plugins;

/*  Lista de cores do portal
 *
 *  Cabeçalho: header_color
 *  Títulos: font_color
 *  Fundo: background_color
 *  Navegação: navigation_color
 */

/**
 * Advanced Mode:
 * usuário escolhe: header_color,
 * font_color, background_color e navigation_color
 * e o app gera as variações da cor primária
 *
 * Normal Mode:
 * usuário escolhe cor primária:
 * o app gera as cores do header_color, font_color,
 * background_color e navigation_color
 */

/**
 * Nota: o .replace(/rgb|[{()}]/g, '')
 *
 * remove o rgb e os parenteses das strings, porque o ionic necessita do padrão
 * 255, 255, 255 (ou seja, sem parenteses e a palavra rgb)
 */

interface CustomColorBlock {
  property: string;
  color: string;
}

export function setCssVariables(colors: Colors): void {
  if (colors.advanced_colors_color) {
    advancedMode(colors);
    return;
  }

  normalMode(colors);
}

function setStatusBarColor(shade: string): void {
  StatusBar.setStyle({
    style: tinycolor(shade).isDark()
      ? StatusBarStyle.Dark
      : StatusBarStyle.Light,
  });
  StatusBar.setBackgroundColor({
    color: shade,
  });

  // Display content under transparent status bar (Android only)
  StatusBar.setOverlaysWebView({
    overlay: false,
  });
}

/**
 * @description
 * gera as cores a partir da primary_color
 * ou
 * utiliza as as variaveis:
 * header_color: para o header,
 * font_color: para com dos textos das paginas na home,
 * background_color: cor dos items na home
 * @returns void
 */
function advancedMode(colors: Colors): void {
  const { navigation_color, font_color, background_color } = colors;

  const primary_color = colors?.header_color || colors.primary_color;

  const tint: string = tinycolor
    .mix('#ffffff', primary_color, 90)
    .toHexString();
  const contrast: any = tinycolor(primary_color).isDark()
    ? tinycolor('#ffffff')
    : tinycolor('#000000');

  const cores: any = {
    '--ion-color-headercolor': primary_color,
    '--ion-color-headercolor-rgb': tinycolor(primary_color)
      .toRgbString()
      .replace(/rgb|[{()}]/g, ''),
    '--ion-color-headercolor-contrast': navigation_color
      ? navigation_color
      : contrast.toHexString(),
    '--title-color': font_color ? font_color : contrast.toHexString(),
    '--background-color': background_color ? background_color : tint,
    '--font-color': font_color ? font_color : contrast.toHexString(),
    '--home-grid-font-color': font_color ? font_color : contrast.toHexString(),
    '--primarycolor-or-black': tinycolor(primary_color).isLight()
      ? '#000000'
      : primary_color,
  };

  generatePrimaryColor(primary_color);
  Object.keys(cores).map((key: string) => {
    document.documentElement.style.setProperty(key, cores[key]);
  });
  generateContrastColors(colors).map((block: CustomColorBlock) => {
    document.documentElement.style.setProperty(block.property, block.color);
  });
}

function generateContrastColors(colors: Colors): CustomColorBlock[] {
  const headerColor: any = tinycolor(colors.header_color);
  const navigationColor: any = tinycolor(colors.navigation_color);

  return [
    {
      property: '--fabapp-white-brackground-primary-color',
      color: headerColor.isDark()
        ? headerColor
        : navigationColor.isDark()
        ? navigationColor
        : '#000',
    },
    {
      property: '--fabapp-white-brackground-navigation-color',
      color: navigationColor.isDark()
        ? navigationColor
        : headerColor.isDark()
        ? headerColor
        : '#000',
    },
  ];
}

/**
 * @description
 * gera as variaveis no modo normal:
 * --ion-color-headercolo (e variações): usado no header da aplicação
 * --title-color,
 * --font-color,
 * --background-color
 * --primarycolor-or-black (variavel auxiliar para temas claros)
 */
function normalMode({ primary_color }: Colors): void {
  generatePrimaryColor(primary_color);
  const shade: string = tinycolor
    .mix('#000000', primary_color, 88)
    .toHexString();
  const tint: string = tinycolor
    .mix('#ffffff', primary_color, 90)
    .toHexString();
  const contrast: any = tinycolor(primary_color).isDark()
    ? tinycolor('#ffffff')
    : tinycolor('#000000');

  const colors: any = {
    '--ion-color-headercolor': primary_color,
    '--ion-color-headercolor-rgb': tinycolor(primary_color)
      .toRgbString()
      .replace(/rgb|[{()}]/g, ''),
    '--ion-color-headercolor-contrast': contrast.toHexString(),
    '--ion-color-headercolor-shade': shade,
    '--ion-color-headercolor-tint': tint,
    '--title-color': contrast.toHexString(),
    '--background-color': tint,
    '--font-color': contrast.toHexString(),
    '--home-grid-font-color': contrast.toHexString(),
    '--primarycolor-or-black': tinycolor(primary_color).isLight()
      ? '#000000'
      : primary_color,
  };

  Object.keys(colors).map((key: string) => {
    document.documentElement.style.setProperty(key, colors[key]);
  });
}

/**
 * @description
 * gera as variações da cor primária
 * @returns void
 */
function generatePrimaryColor(hex: string): void {
  const shade: string = tinycolor.mix('#000000', hex, 88).toHexString();
  const tint: string = tinycolor.mix('#ffffff', hex, 90).toHexString();
  const contrast: any = tinycolor(hex).isDark()
    ? tinycolor('#ffffff')
    : tinycolor('#000000');
  const colors = {
    '--ion-color-primary': hex,
    '--ion-color-primary-rgb': tinycolor(hex)
      .toRgbString()
      .replace(/rgb|[{()}]/g, ''),
    '--ion-color-primary-contrast': contrast.toHexString(),
    '--ion-color-primary-contrast-rgb': contrast
      .toRgbString()
      .replace(/rgb|[{()}]/g, ''),
    '--ion-color-primary-shade': shade,
    '--ion-color-primary-tint': tint,
  };

  if (isPlatform('capacitor')) {
    setStatusBarColor(shade);
  }

  Object.keys(colors).map((key: string) => {
    document.documentElement.style.setProperty(key, colors[key]);
  });
}

export function getContrastByOpacity(opacity: number): void {
  const opacityPercent: number = opacity * 100;
  const color: string = tinycolor
    .mix('#ffffff', '#000000', opacityPercent)
    .toHexString();
  const contrast: any = tinycolor(color).isLight()
    ? tinycolor('#ffffff')
    : tinycolor('#000000');
  document.documentElement.style.setProperty(
    '--home-grid-font-color',
    contrast.toHexString(),
  );
}
