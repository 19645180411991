import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Colors } from '@core/models';
import { Coords } from '@core/models/maps';
import { AppDefState } from '@core/state/appdef';
import { environment } from '@environments/environment';
import { Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { List } from 'src/app/moblets/list/models';
import * as tinycolor from 'tinycolor2';
import { LibsLoaderEnum } from '../enums';
import { InAppBrowserService } from './in-app-browser.service';
import { ScriptLoaderService } from './script-loader.service';
const { Geolocation } = Plugins;

declare const OverlappingMarkerSpiderfier: any;

interface ItemsWithCoordinates {
  latitude: string;
  longitude: string;
  id?: string;
}

type Cluster = {
  position: google.maps.LatLng | google.maps.ReadonlyLatLngLiteral;
  count: number;
};

@Injectable({ providedIn: 'root' })
export class GeolocationService {
  idle$: Subject<google.maps.Map> = new Subject();
  centerChanged$: Subject<boolean> = new Subject();

  mapsUrl: string = environment.maps.url;
  private _pinPurple: string = '/assets/icons/person_pin.svg';
  private _userMarkerIcon: string = '/assets/icons/pin-user.png';

  userCurrentCoords: Coords;
  pinSelected: Subject<List> = new Subject();
  mapReference: google.maps.Map;
  private _oms: any;
  private _markerClusterer: any;

  private pinsRef: { [key: string]: boolean } = {};

  resetPinsRef() {
    this.pinsRef = {};
  }

  resetClusterer() {
    if (this._markerClusterer) {
      this._markerClusterer.clearMarkers();
      this._markerClusterer = null;
    }
  }
  constructor(
    private platform: Platform,
    private store: Store,
    private scriptLoader: ScriptLoaderService,
  ) {}

  async getPosition(): Promise<ItemsWithCoordinates> {
    try {
      const { coords } = await Geolocation.getCurrentPosition();

      this.userCurrentCoords = {
        latitude: coords.latitude,
        longitude: coords.longitude,
      };
      return {
        latitude: coords.latitude.toString(),
        longitude: coords.longitude.toString(),
      };
    } catch (error) {
      this.userCurrentCoords = null;
      throw error;
    }
  }

  private async initMap(googleMapsApiKey: string): Promise<any> {
    if (!navigator.onLine) {
      return;
    }

    const mapsInstanceNotExist: boolean =
      typeof google === 'undefined' || typeof google.maps === 'undefined';
    if (mapsInstanceNotExist) {
      window['initMap'] = () => {
        return;
      };

      if (!document.body.children['googleMaps']) {
        await this.scriptLoader.loadLib(LibsLoaderEnum.GMAPS, {
          key: googleMapsApiKey,
          callback: 'initMap',
        });
        await this.scriptLoader.loadLib(LibsLoaderEnum.GMAPS_MARKER);
      }
      return;
    }
  }

  async createMap(target: HTMLElement, googleMapsApiKey: string): Promise<any> {
    await this.scriptLoader.loadLib(LibsLoaderEnum.OMS);

    // inicia o mapa passando KEY do google maps
    await this.initMap(googleMapsApiKey);
    const map: google.maps.Map<HTMLElement> = this._newMap(target);
    if (OverlappingMarkerSpiderfier) {
      this._overlappingConfig(map);
    }

    if (window['markerClusterer']) {
      this._markerClusterer = new window['markerClusterer'].MarkerClusterer({
        map,
        renderer: {
          render: (cluster): google.maps.Marker => this._getGroupPin(cluster),
        },
      });
    }

    this.mapReference = map;
    // This event is fired when the map becomes idle after panning or zooming.
    let time;
    google.maps.event.addListener(map, 'idle', (e: any) => {
      if (time) {
        clearTimeout(time);
      }

      time = setTimeout(() => {
        this.idle$.next(this.mapReference);
      }, 1000);
    });

    google.maps.event.addListener(map, 'center_changed', () =>
      this.centerChanged$.next(true),
    );
    google.maps.event.addListener(map, 'dragend', () =>
      this.centerChanged$.next(false),
    );

    return map;
  }

  // https://stackoverflow.com/a/37576519
  getBoundsRadius(): number {
    const bounds: google.maps.LatLngBounds = this.mapReference.getBounds();

    // r = radius of the earth in km
    const r: number = 6378.8;
    // degrees to radians (divide by 57.2958)
    const ne_lat: number = bounds.getNorthEast().lat() / 57.2958;
    const ne_lng: number = bounds.getNorthEast().lng() / 57.2958;
    const c_lat: number = bounds.getCenter().lat() / 57.2958;
    const c_lng: number = bounds.getCenter().lng() / 57.2958;
    // distance = circle radius from center to Northeast corner of bounds
    const r_km: number =
      r *
      Math.acos(
        Math.sin(c_lat) * Math.sin(ne_lat) +
          Math.cos(c_lat) * Math.cos(ne_lat) * Math.cos(ne_lng - c_lng),
      );
    return r_km * 1000; // radius in meters
  }

  async createMapDelivery(
    target: HTMLElement,
    googleMapsApiKey: string,
  ): Promise<google.maps.Map<HTMLElement>> {
    // inicia o mapa passando KEY do google maps
    await this.initMap(googleMapsApiKey);
    const map: google.maps.Map<HTMLElement> = this._newMap(target);
    this.mapReference = map;

    return map;
  }

  /**
   * cria nova instancia do mapa
   * @param  {HTMLElement} target
   * @returns any
   */
  private _newMap(target: HTMLElement): google.maps.Map<HTMLElement> {
    return new google.maps.Map(target, {
      center: {
        lat: +this.userCurrentCoords.latitude,
        lng: +this.userCurrentCoords.longitude,
      },
      zoom: 16,
      disableDefaultUI: true,
      minZoom: 8,
    });
  }

  private _overlappingConfig(map: google.maps.Map<HTMLElement>): void {
    this._oms = new OverlappingMarkerSpiderfier(map, {
      markersWontMove: true,
      markersWontHide: true,
      basicFormatEvents: true,
      ignoreMapClick: true,
    });
  }

  /* 
    // Cria objetos Markers
    // retorna um Marker
   */
  private _addMarker(
    config: google.maps.ReadonlyMarkerOptions,
  ): google.maps.Marker {
    const marker: google.maps.Marker = new google.maps.Marker(config);
    return marker;
  }

  addUserMaker(userLocation: google.maps.LatLng): google.maps.Marker {
    return this._addMarker({
      position: userLocation,
      icon: this._userMarkerIcon,
      map: this.mapReference,
      animation: google.maps.Animation.DROP,
    });
  }

  addLocationsMarkers(items: ItemsWithCoordinates[] | any): void {
    const markers: google.maps.Marker[] = [];
    items.forEach((location: ItemsWithCoordinates) => {
      if (!location.longitude && !location.latitude) {
        return;
      }

      // Valida se já existe um pin criado
      if (
        this.pinsRef[
          `${location.longitude},${location.latitude},${location.id}`
        ]
      ) {
        return;
      }

      const options: google.maps.ReadonlyMarkerOptions =
        this.getMarkerLocationConfig(location);
      const marker: google.maps.Marker = this._addMarker(options);
      this.addInfo(marker, location);
      markers.push(marker);
      // Adiciona a referência do pin com true
      this.pinsRef[
        `${location.longitude},${location.latitude},${location.id}`
      ] = true;
    });
    this._clustererConfig(markers);
  }

  private getMarkerLocationConfig(location: ItemsWithCoordinates): any {
    return {
      position: { lat: +location.latitude, lng: +location.longitude },
      animation: google.maps.Animation.DROP,
      icon: this.pinMarker(),
      label: '',
    };
  }

  /**
   * configurações do Cluster
   * (docs): https://developers.google.com/maps/documentation/javascript/marker-clustering
   */
  private _clustererConfig(markers: google.maps.Marker[]): void {
    if (!markers.length) {
      return;
    }

    if (this._markerClusterer) {
      markers.forEach((marker: google.maps.Marker) =>
        this._oms.addMarker(marker),
      );
      this._markerClusterer.addMarkers(markers);
      return;
    }

    const markersList: any[] = [];

    markers.forEach((marker: google.maps.Marker) => {
      this._oms.addMarker(marker);
      markersList.push(marker); // adds the marker to the spiderfier _and_ the map
    });

    // https://googlemaps.github.io/js-markerclusterer/interfaces/MarkerClustererOptions.html#renderer
    this._markerClusterer = new window['markerClusterer'].MarkerClusterer({
      map: this.mapReference,
      markers: markersList,
      renderer: {
        render: (cluster): google.maps.Marker => this._getGroupPin(cluster),
      },
    });
  }

  private _getGroupPin(cluster: Cluster): google.maps.Marker {
    const { position, count } = cluster;

    const { primary_color } = this.store.selectSnapshot<Colors>(
      AppDefState.getColors,
    );

    return new google.maps.Marker({
      position,
      icon: {
        url: this.pinMarkerGroup(primary_color),
        scaledSize: new google.maps.Size(26, 32),
      },
      label: {
        text: String(count),
        color: 'rgba(255,255,255,0.9)',
        fontSize: '16px',
      },
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    });
  }

  private pinMarker(): google.maps.ReadonlyIcon | google.maps.ReadonlySymbol {
    const { primary_color } = this.store.selectSnapshot<Colors>(
      AppDefState.getColors,
    );
    const color: string = tinycolor(primary_color).isLight()
      ? '#000000'
      : primary_color;

    return {
      path: `M10.24 13.527a3.384 3.384 0 0 1 0-6.766 3.384 3.384 0 0 1 0 6.766zm0-12.856a9.466 9.466 0 0 0-9.473 9.473c0 7.105 9.473 17.593 9.473 17.593s9.473-10.488 9.473-17.593A9.466 9.466 0 0 0 10.24.671z`,
      fillColor: color,
      fillOpacity: 1,
      scale: 1.1,
      size: new google.maps.Size(33, 33),
      strokeWeight: 0,
    };
  }

  /**
   * pín group with primary color
   * @returns string
   */
  private pinMarkerGroup(primaryColor: string): string {
    // TODO: REFATORAR USO DA COR
    const color: string = tinycolor(primaryColor).isLight()
      ? '#000000'
      : primaryColor;
    const encoded: string = window.btoa(
      '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="56" viewBox="0 0 24 28"><g><g><path fill="' +
        color +
        '" d="M11.998.666C5.372.666-.001 6.04-.001 12.665 0 18.224 3.786 22.89 8.92 24.25l3.079 3.08 3.08-3.08c5.132-1.36 8.918-6.026 8.918-11.585 0-6.626-5.373-11.999-11.998-11.999z"/></g></g></svg>',
    );

    return 'data:image/svg+xml;base64,' + encoded;
  }

  /**
   * Adiciona ao marcador o evento de click, para notificar o Subject
   */
  addInfo(marker: google.maps.Marker, item?: any): void {
    this._oms.addMarker(marker);
    if (this._markerClusterer) {
      this._markerClusterer.addMarker(marker, true);
    }
    google.maps.event.addListener(marker, 'spider_click', (e: any) => {
      // 'spider_click', not plain 'click'
      this.selectPin = item;
      this._centerPin(marker);
    });
  }

  /**
   * Setter que notifica o Subject
   */
  set selectPin(value: any | null) {
    this.pinSelected.next(value);
  }

  /**
   * Getter que escuta as mudanças do pinSelecionado no mapa
   * retorna uma Observable<List>
   */
  get pinSelected$(): Observable<List> {
    return this.pinSelected.asObservable();
  }

  /**
   *  abre os maps disponiveis usando as coordenadas de um item, que deve
   * conter as propriedades lat e long
   */
  openAvailableMaps(item: List): void {
    const destination: string = `${item.latitude},${item.longitude}`;
    const nativeAndroid: boolean =
      this.platform.is('capacitor') && this.platform.is('android');
    const URL: string = `https://www.google.com.br/maps/dir//${destination}/`;

    if (nativeAndroid) {
      window.open(`geo:?q=${destination}`, '_system');
      return;
    }

    InAppBrowserService.windowOpen(URL);
  }

  /**
   * centraliza o marker no mapa
   * @returns void
   */
  private _centerPin(marker: any): void {
    this.mapReference.panTo(marker.getPosition());
  }
}
