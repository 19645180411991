<div *ngIf="userAddress" class="section-information">
  <google-map
    *ngIf="!editable"
    [height]="'150px'"
    width="100%"
    [zoom]="zoomViewGoogleMaps"
    [center]="center"
    [options]="optionsControlGoogleMaps"
  >
    <map-marker
      [position]="currentLocateUser?.position"
      [options]="currentLocateUser?.options"
    >
    </map-marker>
  </google-map>

  <form [formGroup]="form" (submit)="submit()">
    <div class="information" [style.margin-top]="editable ? '3rem' : ''">
      <section class="street-information" *ngIf="!editable">
        <div class="container-street">
          <p class="street">{{ form.get('address').value }}</p>
          <p class="complement">
            {{ form.get('neighborhood').value }}, {{ form.get('city').value }} -
            {{ form.get('state').value }}, {{ form.get('country').value }}
          </p>
        </div>

        <div class="button">
          <ion-button
            class="select-edit"
            [style.color]="'var(--ion-color-primary)'"
            (click)="applyEdit()"
          >
            <img class="icon-edit" src="/assets/icons/edit.svg" />
            Editar
          </ion-button>
        </div>
      </section>

      <div class="informartion-address" *ngIf="editable">
        <div class="address-value">
          <span class="label">Rua</span>
          <div class="input-address">
            <ion-input class="input-user" formControlName="address"></ion-input>
          </div>
        </div>
      </div>

      <div class="informartion-address">
        <div class="address-value small">
          <span class="label">Número</span>
          <div class="input-address">
            <ion-input class="input-user" formControlName="number"></ion-input>
          </div>
        </div>

        <div class="address-value medium">
          <span class="label">Complemento</span>
          <div class="input-address">
            <ion-input
              class="input-user"
              [formControlName]="fields.COMPLEMENT"
            ></ion-input>
          </div>
        </div>
      </div>

      <div class="informartion-address">
        <div class="address-value">
          <span class="label">Ponto de referência</span>
          <div class="input-address">
            <ion-input
              class="input-user"
              formControlName="reference"
            ></ion-input>
          </div>
        </div>
      </div>
    </div>
    <div class="button-save">
      <ion-button
        class="button"
        fill="outline"
        type="submit"
        [style.--color]="'var(--ion-color-primary-contrast)'"
        [style.--background]="'var(--ion-color-primary)'"
        expand="block"
        [disabled]="form.invalid"
        >Salvar</ion-button
      >
    </div>
  </form>
</div>

<div class="button-back">
  <ion-button class="button" (click)="closeModal()">
    <ion-icon class="icon" name="chevron-back-outline"></ion-icon>
  </ion-button>
</div>
