<div class="wrapper" *ngIf="appInfo$ | async as appInfo">
  <div class="headline">
    {{ 'lgpd.weUpdate' | transloco }}
  </div>
  <div class="info">
    {{ terms?.term?.id && 'lgpd.term' | transloco }}
    {{ terms?.term?.id && terms?.policy?.id && 'lgpd.and' | transloco }}
    {{ terms?.policy?.id && 'lgpd.policy' | transloco }}
  </div>
  <ion-button (click)="seeTerms()" mode="md">
    {{ 'lgpd.visualize' | transloco }}
  </ion-button>
</div>
