import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CarouselItem } from '@core/models';
import { InAppBrowserService } from '@core/services';
import { NavigationService } from '@core/services/navigation.service';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'fabapp-carousel',
  templateUrl: 'carousel.component.html',
  styleUrls: ['carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent {
  @Input() carouselItems: CarouselItem[];
  @Input() effect: 'fade' | 'coverflow' | 'flip';
  slidesPerView = window.innerWidth < 768 ? 1 : 3;

  constructor(private navigationService: NavigationService) {}

  slidesDidLoad(slides: IonSlides): void {
    slides.startAutoplay();
  }

  slideChanged(slides: IonSlides): void {
    slides.stopAutoplay();
  }

  enableAutoPlay(slides: IonSlides): void {
    setTimeout(() => {
      slides.startAutoplay();
    }, 1000);
  }

  carouselNavigation(item: CarouselItem): any {
    if (item.link) {
      return InAppBrowserService.windowOpen(item.link, '_system');
    }

    if (item.intent_url) {
      const [instanceId, itemId] = item.intent_url.split('/');

      return this.navigationService.navigate({
        instanceId: parseInt(instanceId, 10),
        itemId,
      });
    }
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }
}
