import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { AdRedirectTypeEnum } from '@core/enums/fabapp-ad/ad-redirect-type.enum';
import { FabAppAdTypeEnum } from '@core/enums/fabapp-ad/fab-app-ad-type.enum';
import { InAppBrowserService, NavigationService } from '@core/services';
import {
  FabappAdsState,
  SetAdsAsClicked,
  SetAdsAsViewed,
} from '@core/state/ads';
import { NextAd } from '@core/state/ads/fabapp-ads.action';
import { ModalController } from '@ionic/angular';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BannerAndModalOfDivulgation } from '../banner-footer/interfaces/banner.model';

@Component({
  templateUrl: './divulgation-modal.component.html',
  styleUrls: ['./divulgation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: 'divulgation-modal', alias: 'divulgationModal' },
    },
  ],
})
export class DivulgationModalComponent implements OnInit {
  public counter: number = 5;
  public interstitialData: BannerAndModalOfDivulgation;
  adRedirectTypeEnum = AdRedirectTypeEnum;

  destroy$ = new Subject();
  isClicked = false;
  constructor(
    private modalCtrl: ModalController,
    private cdr: ChangeDetectorRef,
    private store: Store,
    private navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new SetAdsAsViewed(FabAppAdTypeEnum.INTERSTITIAL));
    this.loadModal();
    this.counting();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  loadModal(): void {
    this.store
      .select(FabappAdsState.interstitial)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.interstitialData = data;
        this.cdr.markForCheck();
      });
  }

  private counting(): void {
    const interval: NodeJS.Timeout = setInterval(() => {
      this.counter = --this.counter;
      this.cdr.markForCheck();
      // tslint:disable-next-line: no-unused-expression
      this.counter === 0 && clearInterval(interval);
    }, 1000);
  }

  async openLink(ad: BannerAndModalOfDivulgation): Promise<void> {
    if (!this.isClicked) {
      this.isClicked = true;
      this.cdr.markForCheck();
      this.store.dispatch(new SetAdsAsClicked(FabAppAdTypeEnum.BANNER));
    }

    if (ad.adRedirectId == AdRedirectTypeEnum.NOT_DIRECT) {
      return;
    }

    if (ad.adRedirectId == AdRedirectTypeEnum.INTERNAL_LINK) {
      await this.dismiss();
      this.navigationService.internalNavigation(ad.link);
      return;
    }

    if (ad.link && typeof ad.link !== 'number') {
      InAppBrowserService.windowOpen(ad.link, '_blank');
    }
  }

  public async dismiss(): Promise<void> {
    await this.modalCtrl.dismiss();
    this.store.dispatch(new NextAd.Interstitial());
  }
}
