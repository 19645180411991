import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemingColor } from '@core/types';
import { PromotionsService } from '@fabapp-delivery/services/promotions.service';
import { ModalController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-insert-coupon',
  templateUrl: './insert-coupon.component.html',
  styleUrls: ['./insert-coupon.component.scss'],
})
export class InsertCouponComponent implements OnInit {
  form = new FormGroup({
    code: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(25),
      Validators.pattern(new RegExp('^[a-zA-Z0-9]*$')),
    ]),
  });

  constructor(
    private modalController: ModalController,
    private $promotionService: PromotionsService,
    private toast: ToastController,
  ) {}

  ngOnInit() {}

  closeModal(success = false) {
    this.modalController.dismiss(success);
  }

  async applyCoupon() {
    try {
      if (this.form.invalid) {
        this.form.markAllAsTouched();
        return;
      }

      const data = this.form.getRawValue();
      const coupon = data.code.toUpperCase();

      const request = await this.$promotionService
        .verifyCoupon(coupon)
        .toPromise();

      this.closeModal(true);
    } catch (e) {
      if (e.error) {
        this.feedbackToaster(e.error.error || 'Erro ao inserir o cupom!');
      }
    }
  }

  private async feedbackToaster(
    message: string,
    theme: ThemingColor = 'danger',
  ): Promise<void> {
    const toast: HTMLIonToastElement = await this.toast.create({
      message,
      color: theme,
      position: 'top',
      duration: 3000,
      buttons: [
        {
          text: 'ok',
        },
      ],
    });
    toast.present();
  }
}
