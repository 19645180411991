<ion-item [disabled]="disabled" [detail]="false" class="additionalitem-radio">
  <ion-label class="label-wrapper">
    <div class="label">
      {{ label }}
    </div>
    <div *ngIf="description" class="description">{{ description }}</div>
    <div *ngIf="aggregateValue" class="aggregatevalue">
      +{{ aggregateValue / 100 | currency: currencyId }}
    </div>
  </ion-label>
  <ion-radio [value]="value"></ion-radio>
</ion-item>
