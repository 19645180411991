<ng-container *ngIf="bannerData$ | async as banner">
  <ng-container *ngIf="banner?.showCustomBanner && banner?.image && showBanner">
    <!-- video  -->
    <video
      *ngIf="banner?.isVideo"
      (click)="openLink(banner)"
      [src]="banner.image"
      poster="/assets/banner/poster.svg"
      [muted]="true"
      playsinline
      loop
      autoplay
    ></video>
    <!-- imagem -->
    <!-- 
    aparece se não for video
    tiver imagem e showCustomBanner igual true
     
  -->
    <img
      *ngIf="!banner?.isVideo"
      (click)="openLink(banner)"
      [src]="banner.image"
      [alt]="banner.imageTitle"
    />
  </ng-container>
</ng-container>

<div id="ads-container" #el></div>
