import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaymentMethod as PaymentMethodEnum } from '@core/enums';
import { CoreState } from '@core/state/core/core.state';
import { MobletsState } from '@core/state/moblets';
import { FDI_PaymentMethodWithCard } from '@fabapp-delivery/models/cart/cart.model';
import {
  FDI_Store,
  FDI_StorePaymentMethod,
  PaymentMethod,
} from '@fabapp-delivery/models/store';
import { DeliveryCartActions } from '@fabapp-delivery/state/cart/cart.actions';
import { CartState } from '@fabapp-delivery/state/cart/cart.state';
import { ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { FDPaymentMethod } from 'src/app/pages/settings/payment-methods/models/payment-method.model';

@Component({
  selector: 'app-payment-failure-modal',
  templateUrl: './payment-failure-modal.component.html',
  styleUrls: ['./payment-failure-modal.component.scss'],
})
export class PaymentFailureModalComponent implements OnInit, OnDestroy {
  @Select(CartState.getSelectedPaymentMethod)
  paymentMethod$: Observable<FDI_PaymentMethodWithCard>;
  paymentMethodEnum = PaymentMethodEnum;
  fabappPayMethods: FDI_StorePaymentMethod[] = [];
  possibleCauses = [
    'Cartão sem saldo suficiente',
    'Cartão bloqueado para transações online',
    'Cartão novo ainda bloqueado',
    'Transação não aprovada pelo banco',
  ];

  private _mobletSubscription: Subscription;

  constructor(public modalCtrl: ModalController, private store: Store) {}

  ngOnInit() {
    this.setMobletSubscription();
  }

  ngOnDestroy() {
    this._mobletSubscription?.unsubscribe();
  }

  setMobletSubscription(): void {
    const storeId = this.store.selectSnapshot(CoreState.currentStoreId);

    this._mobletSubscription = this.store
      .select(MobletsState.getMoblet(storeId))
      .subscribe((store: FDI_Store) => {
        this.fabappPayMethods = store?.fabappPayMethods || [];
      });
  }

  async savePaymentMethod(
    method: PaymentMethod,
    card?: FDPaymentMethod,
  ): Promise<void> {
    this.store.dispatch(
      new DeliveryCartActions.SetPaymentMethod(method, card, null, null),
    );

    this.modalCtrl.dismiss();
  }

  openPaymentModal() {
    this.modalCtrl.dismiss({ openPaymentModal: true });
  }
}
