<ion-ripple-effect *ngIf="hasClick"></ion-ripple-effect>
<ion-img
  *ngIf="brandIcon"
  class="brand"
  [src]="brandIcon"
  slot="start"
></ion-img>
<div class="inner-wrapper">
  <div class="info">
    <div [ngClass]="'title ' + titleSize">{{ title }}</div>
    <div class="code" *ngIf="code">
      <ion-icon
        *ngIf="censorCode"
        class="horizontal"
        name="ellipsis-horizontal"
      ></ion-icon>
      {{ code }}
    </div>
  </div>
  <ion-buttons *ngIf="hasAction">
    <ion-button
      mode="md"
      [ngClass]="iconSize"
      (click)="onAction.emit(); $event.stopPropagation()"
    >
      <ion-icon slot="icon-only" [name]="icon"></ion-icon>
    </ion-button>
  </ion-buttons>
</div>
