import { Injectable, NgZone } from '@angular/core';
import { PushNotification } from '@capacitor/core';
import { Colors } from '@core/models';
import { NavController } from '@ionic/angular';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import * as tinycolor from 'tinycolor2';
import { FabappAdsState } from '../ads';
import { AppDefState, LoadAppDef } from '../appdef';
import { CoreState } from '../core/core.state';
import { InternalNavigation } from '../navigation/navigation.actions';
import {
  PushClicked,
  PushDeviceRegister,
  PushReceived,
  PushUpdateToken,
} from '../push/push.actions';
import {
  IonicBack,
  IonicChangeApp,
  IonicHandler,
  IonicIsLoaded,
  IonicNavigate,
  IonicPageHeight,
  IonicPushClicked,
  IonicPushDeviceRegister,
  IonicPushReceived,
  NativeCanBack,
  NativeConfigApp,
  NativeLog,
  NativeOpenWebView,
  NativePlaylistAudio,
} from './react-native.actions';

type platform = 'android' | 'ios';

export interface ReactNativeModel {
  isReactNative: boolean;
  platform: platform;
  nativeConfig: any;
}

type NativeEvent = {
  eventName: string;
  data?: any;
};

@State<ReactNativeModel>({
  name: 'native',
  defaults: {
    isReactNative: !!window['isReactNative'],
    platform: 'android',
    nativeConfig: {},
  },
})
@Injectable()
export class ReactNativeState {
  constructor(
    private navCtrl: NavController,
    private ngZOne: NgZone,
    private store: Store,
  ) {}

  @Selector()
  static getIsReactNative(state: ReactNativeModel): boolean {
    return state.isReactNative;
  }

  @Selector()
  static getReactNativePlatform(state: ReactNativeModel): platform {
    return state.platform;
  }

  @Action(IonicHandler)
  public ionicHandler(
    ctx: StateContext<ReactNativeModel>,
    { eventName, data },
  ) {
    const events = {
      ionicNavigate: () => {
        return new IonicNavigate(data.path);
      },
      ionicBack: () => {
        return new IonicBack();
      },
      ionicChangeApp: () => {
        return new IonicChangeApp(data.appId);
      },
      ionicPushDeviceRegister: () => {
        return new IonicPushDeviceRegister(data.token);
      },
      ionicPushReceived: () => {
        return new IonicPushReceived(data.notification);
      },
      ionicPushClicked: () => {
        return new IonicPushClicked(data.notification);
      },
    };

    if (eventName in events) {
      this.store.dispatch(events[eventName]());
    }
  }

  @Action(NativeConfigApp)
  nativeConfig(ctx: StateContext<ReactNativeModel>): void {
    const { nativeConfig } = ctx.getState();
    const { header_color: color } = this.store.selectSnapshot<Colors>(
      AppDefState.getColors,
    );
    const toolbarIsDark = tinycolor(color).isDark();
    const textColor: string = toolbarIsDark ? '#ffffff' : '#000000';

    const isShowing = this.store.selectSnapshot(FabappAdsState.isShowingBanner);

    const statusBarColor: string = tinycolor
      .mix('#000000', color, 88)
      .toHexString();
    const statusBarIsDark = tinycolor(statusBarColor).isDark();

    const newNativeConfig = {
      statusBar: { color: statusBarColor, isDark: statusBarIsDark },
      toolbar: { color, textColor, isDark: toolbarIsDark },
      button: { color, textColor },
      banner: { isShowing },
    };

    // Não envia update para o react native se o state é o mesmo
    if (JSON.stringify(nativeConfig) === JSON.stringify(newNativeConfig)) {
      return;
    }

    ctx.patchState({ nativeConfig: newNativeConfig });
    this.sendToNative({ eventName: 'NativeConfigApp', data: newNativeConfig });
  }

  @Action(NativeLog)
  nativeLog(ctx: StateContext<ReactNativeModel>, { message }): void {
    if (typeof message !== 'string') {
      message = JSON.stringify(message);
    }
    this.sendToNative({ eventName: 'NativeLog', data: { message } });
  }

  @Action(IonicNavigate)
  ionicNavigate(ctx: StateContext<ReactNativeModel>, { path }): void {
    const state: ReactNativeModel = ctx.getState();
    if (!state.isReactNative) {
      return;
    }

    this.store.dispatch(new InternalNavigation(path));
  }

  @Action(IonicBack)
  ionicBack(): void {
    this.ngZOne.run(() => this.navCtrl.pop());
  }

  @Action(IonicChangeApp)
  ionicChangeApp(ctx: StateContext<ReactNativeModel>, { appId }): void {
    this.ngZOne.run(async () => {
      window['appId'] = appId;
      await this.store.dispatch(new LoadAppDef()).toPromise();
      await this.store.dispatch(new NativeConfigApp()).toPromise();
      // await this.store.dispatch(new LoadAds()).toPromise(); // Não está funcionando

      const homeRoute: any[] = this.store.selectSnapshot(
        CoreState.getHomeRoute,
      );
      this.navCtrl.navigateRoot(homeRoute);
    });
  }

  @Action(IonicIsLoaded)
  public ionicIsLoaded() {
    this.sendToNative({ eventName: 'IonicIsLoaded' });
  }

  @Action(NativeCanBack)
  public nativeCanBack(ctx: StateContext<ReactNativeModel>, { canBack }) {
    this.sendToNative({ eventName: 'NativeCanBack', data: { canBack } });
  }

  @Action(IonicPageHeight)
  public ionicPageHeight() {
    const pageHeight = Math.max(
      document.body.offsetHeight,
      document.body.scrollHeight,
    );

    const event = { eventName: 'IonicPageHeight', data: { pageHeight } };
    this.sendToNative(event);
  }

  @Action(NativeOpenWebView)
  public nativeOpenWebView(
    ctx: StateContext<ReactNativeModel>,
    { path },
  ): void {
    const data = { path };

    this.sendToNative({ eventName: 'NativeOpenWebView', data });
  }

  @Action(NativePlaylistAudio)
  public async nativePlaylistAudio(
    ctx: StateContext<ReactNativeModel>,
    { audios, currentAudio },
  ) {
    const reactAudios = audios.map((item) => {
      return {
        id: item.id,
        url: item.audio,
        title: item.title,
        artist: item.description,
        artwork: item.image,
        isLiveStream: false,
      };
    });

    const data = {
      audios: reactAudios,
    };

    if (currentAudio) {
      data['currentAudio'] = {
        id: currentAudio.id,
        url: currentAudio.audio,
        title: currentAudio.title,
        artist: currentAudio.description,
        artwork: currentAudio.image,
        isLiveStream: false,
      };
    }

    this.sendToNative({
      eventName: 'NativePlaylistAudio',
      data,
    });
  }

  private sendToNative(event: NativeEvent) {
    const message = JSON.stringify(event);
    //@ts-ignore
    window.ReactNativeWebView.postMessage(message);
  }

  @Action(IonicPushDeviceRegister)
  public async IonicPushDeviceRegister(
    ctx: StateContext<ReactNativeModel>,
    { token },
  ) {
    await this.store.dispatch(new PushUpdateToken(token));
    await this.store.dispatch(new PushDeviceRegister());
  }

  @Action(IonicPushReceived)
  public async IonicPushReceived(
    ctx: StateContext<ReactNativeModel>,
    { notification },
  ) {
    this.store.dispatch(
      new PushReceived({ data: notification } as PushNotification),
    );
  }

  @Action(IonicPushClicked)
  public async IonicPushClicked(
    ctx: StateContext<ReactNativeModel>,
    { notification },
  ) {
    this.store.dispatch(
      new PushClicked({
        notification: { data: notification },
      } as any),
    );
  }
}
