import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { CoreState } from '@core/state/core/core.state';
import { ResetPasswordCodeRequest } from '@core/state/reset-password';
import {
  ModalController,
  NavController,
  ToastController,
} from '@ionic/angular';
import {
  translate,
  TranslocoService,
  TRANSLOCO_SCOPE,
} from '@ngneat/transloco';
import { Store } from '@ngxs/store';

const TRANSLATION_SCOPE = 'code-request';
let LANG: string;

const regexpForPhone = /^\d+$/;
export const MASKS = {
  email: { mask: '', type: 'text' },
  phone: { mask: '99 99999-9999', type: 'tel' },
};

export const EMAIL_REGEXP = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
export const isPhonePattern = (value: string) => regexpForPhone.test(value);
@Component({
  selector: 'fabapp-code-request',
  templateUrl: 'code-request.page.html',
  styleUrls: ['code-request.page.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: TRANSLATION_SCOPE, alias: 'codeRequest' },
    },
  ],
})
export class CodeRequestPage implements OnInit {
  @Input() isModal = false;

  isSubmitting = false;

  translationScope: string;
  typeReset: 'emailOrPhone' | 'email' | 'phone' = 'email';

  passwordType = 'password';
  mask = MASKS.email;
  isEmail = true;
  callingCode: string = '55';
  emailValidations = [
    Validators.required,
    Validators.email,
    Validators.pattern(EMAIL_REGEXP),
  ];
  phoneValidations = [Validators.required];

  form = this.fb.group({
    username: ['', this.emailValidations],
  });

  public deliveryEnabled = false;
  constructor(
    private navCtrl: NavController,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private store: Store,
    public toastController: ToastController,
    private translocoService: TranslocoService,
    public modalController: ModalController,
  ) {
    LANG = this.translocoService.getActiveLang();
    this._initDeliveryMode();
  }

  ngOnInit(): void {}

  private _initDeliveryMode() {
    this.deliveryEnabled = this.store.selectSnapshot(
      CoreState.isFabappDeliveryEnabled,
    );

    if (this.deliveryEnabled) {
      this.typeReset = 'emailOrPhone';
    }
  }

  get username(): AbstractControl {
    return this.form.get('username');
  }

  setMask(mask: string): void {
    this.mask = { mask, type: 'tel' };
  }

  onInputUsername(value: string) {
    value = value.replace(/[\n\r\s\t]+/g, '').replace(/\-/g, '');

    if (value.length < 2) {
      this.isEmail = true;
      this.mask = MASKS.email;
      this.username.setValidators(this.emailValidations);
      this.username.setValue(value);
      this.cdr.detectChanges();
      return;
    }
    // Não mudou nada
    if (this.isEmail !== isPhonePattern(value)) {
      return;
    }
    this.isEmail = !isPhonePattern(value) || this.typeReset == 'email';

    this.username.setValidators(
      this.isEmail ? this.emailValidations : this.phoneValidations,
    );

    if (!this.isEmail) {
      this.mask = MASKS.phone;
    } else {
      this.mask = MASKS.email;
      this.username.setValue(value);
    }

    this.cdr.detectChanges();
  }

  async presentToast(translateKey: string) {
    const toast = await this.toastController.create({
      message: translate(translateKey, {}, `${TRANSLATION_SCOPE}/${LANG}`),
      color: 'danger',
      position: 'top',
      buttons: [
        {
          text: 'ok',
        },
      ],
    });
    toast.present();
  }

  async resetPassword() {
    const username = !this.isEmail
      ? this.callingCode + this.username.value.replace(/[^\d]+/g, '')
      : this.username.value;

    try {
      this.isSubmitting = true;

      await this.store
        .dispatch(new ResetPasswordCodeRequest(username))
        .toPromise();

      this.goToConfirmationCodePage(username);
    } catch (err) {
      let msg = 'messages.';
      if (err.status == 404) {
        msg += 'notFound';
      } else {
        msg += 'genericError';
      }
      return this.presentToast(msg);
    } finally {
      this.isSubmitting = false;
    }
  }

  goToConfirmationCodePage(username: string): void {
    if (this.isModal) {
      this.modalController.dismiss({ openConfirmationCode: true });
    } else {
      this.navCtrl.navigateForward('/reset-password/confirmation-code');
    }
  }

  getCallingCode(callingCode) {
    this.callingCode = callingCode;
  }
}
