import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { List, ListDef, ListPagination } from './models';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ListService {
  // URL para a Lista vinda do Hefesto
  public URL: string = `${environment.hefestoApi.url}/app/lists`;
  protected filters: Object = {};
  protected body: Object = {};

  protected includes: string[] = [];
  private _appId = window['appId'];
  private defaultHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'app-id': `${this._appId}`,
  });

  constructor(
    private httpClient: HttpClient,
    public translateService: TranslocoService,
  ) {}

  /**
   * param mobletId: number
   * @returns Observable<ListPagination>
   */
  items(mobletId: number): Observable<ListPagination> {
    let SEGMENT = `/${mobletId}/items-with-radius`;

    const queryString = this.getQueryString();
    if (queryString !== '') {
      SEGMENT = SEGMENT + '?' + queryString;
    }

    const headers: HttpHeaders = this.defaultHeaders;
    return this.httpClient.post(
      `${this.URL}${SEGMENT}&locale=${this.currentLang}`,
      this.body,
      {
        headers,
      },
    ) as Observable<ListPagination>;
  }

  /**
   * método carrega a definição da lista apartir do mobletId
   */
  listDefinition(mobletId: number): Observable<ListDef> {
    const headers: HttpHeaders = this.defaultHeaders;
    const SEGMENT: string = `/${mobletId}?includes[0]=exibitionType&includes[1]=sortTypes&locale=${this.currentLang}&fabapp_version=3`;
    return this.httpClient.get(`${this.URL}${SEGMENT}`, {
      headers,
    }) as Observable<ListDef>;
  }

  /**
   * método carrega um item apartir do mobletId e itemId
   * @returns Observable<List>
   */
  item(mobletId: number, itemId: string | number): Observable<List> {
    const headers: HttpHeaders = this.defaultHeaders;
    const SEGMENT: string = `/${mobletId}/items/${itemId}`;
    return this.httpClient.get(`${this.URL}${SEGMENT}`, {
      headers,
    }) as Observable<List>;
  }

  /**
   * método carrega um item apartir do mobletId e itemId
   * @returns Observable<List>
   */
  itemPin(mobletId: number, itemId: string | number): Observable<List> {
    const headers: HttpHeaders = this.defaultHeaders;
    const SEGMENT: string = `/${mobletId}/pin-items/${itemId}`;
    return this.httpClient.get(`${this.URL}${SEGMENT}`, {
      headers,
    }) as Observable<List>;
  }

  /**
   * param mobletId: number
   * @returns Observable<ListPagination>
   */
  getPinsItems(mobletId: number): Observable<ListPagination> {
    let SEGMENT = `/${mobletId}/pins-items`;

    const queryString = this.getQueryString();
    if (queryString !== '') {
      SEGMENT = SEGMENT + '?' + queryString;
    }

    const headers: HttpHeaders = this.defaultHeaders;
    return this.httpClient.post(
      `${this.URL}${SEGMENT}&locale=${this.currentLang}`,
      this.body,
      {
        headers,
      },
    ) as Observable<ListPagination>;
  }

  /**
   * método transforma os filtros adicionado em queryParams
   */
  protected getQueryString(): string {
    let queryString: string = '';
    for (const i in this.filters) {
      if (this.filters.hasOwnProperty(i)) {
        queryString += i + '=' + this.filters[i] + '&';
      }
    }

    queryString = queryString.substring(0, queryString.length - 1);
    return queryString;
  }

  /**
   * adiciona filtros
   */
  public addFilter(key: string, value: any): this {
    this.filters[key] = value;
    return this;
  }

  public addOnBody(key: string, value: any): this {
    this.body[key] = value;
    return this;
  }

  public setBody(value: any): this {
    this.body = value;
    return this;
  }

  /**
   * faz o tratamento do lang, de como o hefesto necessita
   */
  get currentLang(): 'pt-BR' | 'es-ES' | 'en-US' {
    switch ((this.translateService.getActiveLang() as string).toLowerCase()) {
      case 'pt_br':
      case 'pt-br':
        return 'pt-BR';
      case 'es_es':
      case 'es-es':
        return 'es-ES';
      case 'en_en':
        return 'en-US';
      default:
        return 'en-US';
    }
  }
}
