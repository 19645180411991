<div class="relative">
  <img class="clock" src="/assets/user-awaiting-approval/clock.svg" />
  <svg
    class="clock-detail"
    xmlns="http://www.w3.org/2000/svg"
    width="48.297"
    height="49.954"
    viewBox="0 0 48.297 49.954"
  >
    <g
      id="Group_14441"
      data-name="Group 14441"
      transform="translate(-149.725 -356.41)"
    >
      <g
        id="Group_14362"
        data-name="Group 14362"
        transform="translate(149.725 356.41)"
      >
        <g
          id="Group_14359"
          data-name="Group 14359"
          transform="translate(44.917 45.231)"
        >
          <path
            id="Path_8510"
            data-name="Path 8510"
            d="M3477,7238.562l-.28,4.459q-1.548-.1-3.1-.291l.552-4.433Q3475.59,7238.473,3477,7238.562Z"
            transform="translate(-3473.624 -7238.298)"
          />
        </g>
        <g
          id="Group_14360"
          data-name="Group 14360"
          transform="translate(1.123 8.576)"
        >
          <path
            id="Path_8511"
            data-name="Path 8511"
            d="M572.669,4806.591a47.116,47.116,0,0,0,1.559,5.567l-4.22,1.467a51.387,51.387,0,0,1-1.706-6.1Zm3.789,10.906a47.119,47.119,0,0,0,2.868,5.02l-3.736,2.449a51.916,51.916,0,0,1-3.14-5.5Zm6.333,9.647a46.813,46.813,0,0,0,4.009,4.16l-3.015,3.291a51.048,51.048,0,0,1-4.4-4.561Zm24.179,15.03-1.1,4.328a51.166,51.166,0,0,1-6.031-1.932l1.624-4.16a47.254,47.254,0,0,0,5.5,1.759Zm-15.673-7.241a47.116,47.116,0,0,0,4.913,3.049l-2.116,3.934a51.449,51.449,0,0,1-5.384-3.339Z"
            transform="translate(-568.303 -4806.591)"
          />
        </g>
        <g id="Group_14361" data-name="Group 14361" transform="translate(0)">
          <path
            id="Path_8512"
            data-name="Path 8512"
            d="M498.283,4237.658q.038,1.424.163,2.834l-4.45.393q-.137-1.549-.176-3.11Z"
            transform="translate(-493.82 -4237.658)"
          />
        </g>
      </g>
    </g>
  </svg>
</div>
