import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicFormField, User } from '@core/models';
import { LoadSignupForm } from '@core/state/core/core.actions';
import { CoreState } from '@core/state/core/core.state';
import { CpfCnpjValidator } from '@core/validators';
import { environment } from '@environments/environment';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { PhoneHelper } from './user-phone-helper.service';
import { format, parseISO } from 'date-fns';
import { InAppBrowserService } from '@core/services';
import {
  BrMaskDirective,
  BrMaskModel,
} from '@core/directives/br-mask/br-mask.directive';

const TRANSLATION_SCOPE: string = 'user-form';
@Component({
  selector: 'fabapp-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: TRANSLATION_SCOPE, alias: 'userForm' },
    },
  ],
})
export class UserFormComponent implements OnInit, OnChanges, OnDestroy {
  @Output() submitForm: EventEmitter<User> = new EventEmitter();
  private _user: User;

  @Input() public set user(value: User) {
    this._user = {
      ...value,
      phone_number: this.phoneHelper.treatPhone(value.phone_number),
    };
  }

  public get user(): User {
    return this._user;
  }

  @Select(CoreState.getDynamicForm)
  dynamicForm$: Observable<DynamicFormField[]>;
  dynamicFormSubscription: Subscription;
  form: FormGroup;
  @Input() isSubmitting = false;
  loading = true;

  phoneHelper: PhoneHelper;
  public isPreview: boolean = window['preview'];
  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    public brMask: BrMaskDirective,
  ) {
    this.phoneHelper = new PhoneHelper();
  }

  ngOnInit(): void {
    this.loadForm();
  }

  ngOnDestroy(): void {
    if (this.dynamicFormSubscription) {
      this.dynamicFormSubscription.unsubscribe();
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.isSubmitting) {
      this.isSubmitting = simpleChanges.isSubmitting.currentValue;
    }
  }

  async loadForm(): Promise<void> {
    try {
      await this.store.dispatch(new LoadSignupForm()).toPromise();

      this.dynamicFormSubscription = this.store
        .select(CoreState.getDynamicForm)
        .subscribe((formItems: DynamicFormField[]) => {
          const newFormGroup: any = this.createForm(formItems);

          this.form = this.formBuilder.group(newFormGroup);

          this.margeUserDataInForm();

          if (this.form) {
            this.loading = false;
          }
        });
    } catch (err) {
      console.error(err);
    }
  }

  private createForm(formItems: DynamicFormField[]): any {
    const newFormGroup: any = { terms: [false, Validators.requiredTrue] };
    formItems.forEach((formItem: DynamicFormField) => {
      newFormGroup[formItem.name] = [
        { value: '', disabled: formItem.readonly },
        this.getValidators(formItem.rules),
      ];
    });
    newFormGroup['ddi'] = ['', []];
    return newFormGroup;
  }

  private margeUserDataInForm(): void {
    if (this.user) {
      this.form.removeControl('terms');
      this.form.removeControl('password');
      this.form.patchValue(this.user);

      if (this.user.document_number) {
        const config: BrMaskModel = new BrMaskModel();
        config.person = true;
        const formatedDocument = this.brMask.writeCreateValue(
          this.user.document_number,
          config,
        );
        this.form.get('document_number').setValue(formatedDocument);
      }
    }
  }

  getCallingCode(ddi: number): void {
    this.phoneHelper.ddi = ddi;
    this.form.get('ddi').setValue(ddi);
  }

  formSubmit(): void {
    // TODO: remover spread operator quando o backend aceitar ddi seoarado
    this.submitForm.emit({
      ...this.form.getRawValue(),
      phone_number: this.form.get('phone_number')
        ? this.phoneHelper.phoneWithDDI(this.form.get('phone_number').value)
        : '',
    });
  }

  setMask(mask: string): void {
    this.phoneHelper.mask = mask;
  }

  selectValue(event: any, field: DynamicFormField): void {
    const value: number[] = event.detail.value;

    if (field.rules['multiple']) {
      const numChoices: number = value.length;
      const minChoices: number = field.rules['minChoices'];
      const maxChoices: number = field.rules['maxChoices'];

      if (minChoices && numChoices < minChoices) {
        this.form.get(field.name).setErrors({ minChoices: { minChoices } });
      }
      if (maxChoices && numChoices > maxChoices) {
        this.form.get(field.name).setErrors({ maxChoices: { maxChoices } });
      }
    }
  }

  getValidators(rules: any): any[] {
    return Object.keys(rules)
      .map((value: string, index: number) => {
        if (!rules[value]) {
          return null;
        }

        const validations: any = this.getValidations(rules);

        return value in validations
          ? validations[value]
          : Validators.pattern('');
      })
      .filter((rule) => rule);
  }

  private getValidations(rules: any): any {
    return {
      required: Validators.required,
      email: Validators.email,
      cpf: CpfCnpjValidator.validate,
      minLength: Validators.minLength(rules['minLength']),
      maxLength: Validators.maxLength(rules['maxLength']),
      minValue: Validators.min(rules['minValue']),
      maxValue: Validators.max(rules['maxValue']),
      min: Validators.min(rules['min']),
      max: Validators.max(rules['max']),
    };
  }

  dateChange(field: string, value: any): void {
    const date: any = format(parseISO(value.detail.value), 'yyyy-MM-dd');
    this.form.get(field).setValue(date);
  }

  showUseTerms(): void {
    InAppBrowserService.windowOpen(environment.useTermsUrl, '_system');
  }
}
