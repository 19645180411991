<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="modalController.dismiss()">
        <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Forma de pagamento</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="heading">
    <div class="title">
      Insira os dados de um método de pagamento aceito pelo estabelecimento
    </div>
    <div class="brands">
      <img class="brand" *ngFor="let brand of brands" [src]="brand" />
    </div>
  </div>
  <fabi-payment-method-form [isEditing]="id" #form></fabi-payment-method-form>
  <ng-container *ngIf="!user?.email">
    <form [formGroup]="formGroup">
      <!-- Anti Fraud Field -->
      <ion-item>
        <ion-label position="floating">Email*</ion-label>
        <ion-input formControlName="email" type="email"></ion-input>
      </ion-item>
      <control-error
        label="Email"
        [control]="formGroup.get('email')"
      ></control-error>
      <!--/ Anti Fraud Field -->
    </form>
  </ng-container>
</ion-content>

<ion-footer class="ion-padding ion-no-border">
  <ion-button
    class="nav-button"
    color="primary"
    fill="outline"
    expand="block"
    (click)="onSubmit()"
    [disabled]="isSubmitting"
  >
    {{ id ? 'Salvar alteração' : 'Adicionar Cartão' }}
  </ion-button>
</ion-footer>
