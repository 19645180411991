import * as Types from '../../../generated/types.graphql-gen';

import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
  Date: any;
  Upload: any;
};

export type PaginationInput = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['JSON']>;
};

export type CampaignNotParticipatedFilter = {
  id: Scalars['Int'];
};

export type CampaignParticipatedFilter = {
  id: Scalars['Int'];
};

export type CampaignPointsDueDateFilter = {
  campaignId: Scalars['Int'];
  days?: Maybe<Scalars['Int']>;
};

export type CouponPointFilter = {
  campaignId: Scalars['Int'];
};

export type CouponRewardFilter = {
  campaignId: Scalars['Int'];
  rewardId: Scalars['Int'];
};

export type CampaignFilter = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type AppQrCodeInput = {
  deviceId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

/** Input de aplicação de pontos */
export type ApplyPointInput = {
  /** QrCode do único */
  campaignId: Scalars['Int'];
  /** QrCode do único */
  code: Scalars['String'];
};

/** Input de recebimento de recompensa */
export type ReceiveRewardInput = {
  /** QrCode da recompensa */
  code: Scalars['String'];
  /** Id da campanha */
  campaignId: Scalars['Int'];
  /** Id da recompensa */
  rewardId: Scalars['Int'];
};

export type TransactionFilter = {
  campaignId?: Maybe<Scalars['Int']>;
};

export type FilterSubscription = {
  userId: Scalars['Int'];
  appId: Scalars['Int'];
};

export type CampaignsTotalQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CampaignsTotalQuery = { __typename?: 'Query' } & {
  campaigns?: Types.Maybe<
    { __typename?: 'CampaignPaginate' } & {
      pageInfo?: Types.Maybe<
        { __typename?: 'PageInfo' } & Pick<Types.PageInfo, 'total'>
      >;
      data?: Types.Maybe<
        Array<
          Types.Maybe<{ __typename?: 'Campaign' } & Pick<Types.Campaign, 'id'>>
        >
      >;
    }
  >;
};

const CampaignsTotalDocument = gql`
  query campaignsTotal {
    campaigns {
      pageInfo {
        total
      }
      data {
        id
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CampaignsTotalGQL extends Apollo.Query<
  CampaignsTotalQuery,
  CampaignsTotalQueryVariables
> {
  document = CampaignsTotalDocument;
}
