<ion-grid class="grid-wrapper ">
  <ion-row class="ion-no-padding">
    <ion-col
      *ngFor="let page of group; trackBy: trackByGroupIndex"
      [size]="12 / layout?.gridColumns"
      [class.ion-hide]="!(page.visible | convertToBoolean)"
    >
      <div
        class="group-item ion-justify-content-center ion-align-items-center"
        (click)="navigateToMoblet(page)"
        [style.backgroundImage]="
          page.background ? 'url(' + page.background + ')' : 'null'
        "
        [style.border-radius.px]="layout?.itemBorderRadius"
      >
        <!-- 
          area do conteudo: 
            - icone;
            - texto 
        -->
        <div class="content">
          <!-- imagem -->
          <div class="inner -top" *ngIf="layout?.showPageGridIcon">
            <div class="item-icon-wrapper">
              <img
                class="item-icon"
                [src]="page.icon"
                [class.-with-text]="layout?.showPageGridName"
              />
            </div>
          </div>
          <!-- /imagem -->
          <!-- texto -->
          <div class="inner -bottom" *ngIf="layout?.showPageGridName">
            <div
              class="item -text ion-text-center"
              [class.-textoverflow]="layout?.gridColumns > 2"
            >
              {{ page.name }}
            </div>
          </div>
          <!-- /texto -->
        </div>
        <!-- /area do conteudo -->
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
