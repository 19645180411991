import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrMaskDirective } from './br-mask/br-mask.directive';
import { ShadowCssDirective } from './shadow-css/shadow-css.directive';
import { ValidateDirective } from './validate/validate.directive';
import { OutlineDirective } from './outline/outline.directive';
import { IsHomePageDirective } from './is-home-page/is-home-page.directive';

@NgModule({
  declarations: [
    BrMaskDirective,
    ValidateDirective,
    ShadowCssDirective,
    OutlineDirective,
    IsHomePageDirective,
  ],
  imports: [CommonModule],
  exports: [
    BrMaskDirective,
    ValidateDirective,
    ShadowCssDirective,
    OutlineDirective,
    IsHomePageDirective,
  ],
  providers: [BrMaskDirective],
})
export class DirectivesModule {}
