import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { AppInfo } from '@core/models';
import { AppDefState } from '@core/state/appdef';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';

@Component({
  templateUrl: './interstitial-grumft-modal.component.html',
  styleUrls: ['./interstitial-grumft-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InterstitialGrumftModalComponent implements OnInit {
  public scriptFromGrumft =
    'https://api.grumft.com/gt/ZONA_IAB_336x280_1/f7bc943e087dd2809ee6d89f1c849256.js';
  appInfo: AppInfo;
  constructor(
    private modalCtrl: ModalController,
    private cdr: ChangeDetectorRef,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.appInfo = this.store.selectSnapshot(AppDefState.getInfo);
    this.cdr.markForCheck();
    this.loadGrumftBanner();
  }

  public async dismiss(): Promise<void> {
    await this.modalCtrl.dismiss();
  }

  public loadGrumftBanner(): void {
    const container = document.getElementById('script-container');
    const scriptTag: HTMLScriptElement = document.createElement('script');
    scriptTag.id = 'grumft';
    scriptTag.src = this.scriptFromGrumft;
    scriptTag.style.display = 'flex';
    scriptTag.style.alignItems = 'center';
    scriptTag.style.height = '100%';

    container.style.width = '100%';
    container.style.margin = '0 auto';
    container.style.padding = '5px 0';
    container.appendChild(scriptTag);
  }
}
