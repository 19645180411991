import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState, NetworkStatus, Plugins } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { TranslocoService, translate } from '@ngneat/transloco';
import { Observable, throwError } from 'rxjs';
import { getSupportedLang } from '@utils';

const { Network, Toast, App } = Plugins;

@Injectable()
export class OfflineInterceptor implements HttpInterceptor {
  isOffline = false;
  didWarn = false;
  appActive = true;
  warnTimeout;

  constructor(
    private translocoService: TranslocoService,
    private platform: Platform,
  ) {
    this.translocoService.setActiveLang(getSupportedLang());
    this.startOfflineListener();
    this.startAppStateListener();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.offlineCondition()) {
      this.showToast();
      return throwError('no connection');
    } else {
      return next.handle(request);
    }
  }

  offlineCondition() {
    return (
      this.isOffline &&
      this.appActive &&
      !this.didWarn &&
      this.platform.is('capacitor')
    );
  }

  startOfflineListener() {
    Network.addListener(
      'networkStatusChange',
      async (status: NetworkStatus) => {
        this.isOffline = !status.connected;
        this.didWarn = false;

        if (status.connected) {
          clearTimeout(this.warnTimeout);
        }
      },
    );
  }

  startAppStateListener() {
    App.addListener('appStateChange', (state: AppState) => {
      this.appActive = state.isActive;
    });
  }

  async showToast() {
    const scopeLang: string = `${this.translocoService.getActiveLang()}`;
    const message: string = translate('network.message', {}, scopeLang);

    await Toast.show({
      text: message,
    });

    this.didWarn = true;
    this.warnTimeout = setTimeout(() => {
      this.didWarn = false;
    }, 30000);
  }
}
