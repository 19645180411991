import { Injectable } from '@angular/core';
import { Address } from '@core/models/address/address.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/http/http.service';

@Injectable({
  providedIn: 'root',
})
export class CartService extends HttpService<any> {
  protected url = `${environment.shop.url}/app/carts`;

  public getCart(): Observable<any> {
    return this.request('GET', '');
  }

  public addItem(cartId: string, item: any): Observable<any> {
    return this.request('POST', `/${cartId}/items`, item);
  }

  public getFees(cartId: string, address: Address): Observable<any> {
    return this.request('POST', `/${cartId}/amount`, {
      addressId: address?.id,
    });
  }

  public incrementItem(cartId: string, itemId: string): Observable<any> {
    return this.request('POST', `/${cartId}/items/${itemId}/increment`);
  }

  public decrementItem(cartId: string, itemId: string): Observable<any> {
    return this.request('POST', `/${cartId}/items/${itemId}/decrement`);
  }

  public removeItem(cartId: string, itemId: string): Observable<any> {
    return this.request('DELETE', `/${cartId}/items/${itemId}`);
  }

  public clearCart(cartId: string): Observable<any> {
    return this.request('DELETE', `/${cartId}/items`);
  }

  public applyCoupon(cartId: string, couponId: string) {
    return this.request('PUT', `/${cartId}/coupons/${couponId}`);
  }

  public removeCoupon(cartId: string) {
    return this.request('DELETE', `/${cartId}/coupons`);
  }
}
