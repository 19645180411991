function _applyStyleInShadowRoot(
  el: HTMLIonContentElement,
  stylesheet: string,
) {
  const styleElement = el.shadowRoot.querySelector('style');
  const hasCustomScrollbar = el.shadowRoot.querySelector(
    'style#custom-scrollbar',
  );

  if (hasCustomScrollbar) {
    return;
  }

  if (styleElement && styleElement?.id !== 'custom-scrollbar') {
    styleElement.append(stylesheet);
    return;
  }

  const barStyle = document.createElement('style');
  barStyle.id = 'custom-scrollbar';
  barStyle.append(stylesheet);
  el.shadowRoot.appendChild(barStyle);
}

/**
 * Esse workaround foi feito para todos o scrollbar do shadow root dentro do ion-content
 * A cada finalização de navegação  acessando pela platform desktop, acontece um querySelectorAll de todos ion-content da tela
 * e são customizados um por um
 *
 * A view sendo maior que `1024px` mostra o scrollbar customizado (menos firefox)
 * @note Apenas em modals esse workaround não funcionará
 * @see https://ionicframework.com/docs/theming/css-shadow-parts#vendor-prefixed-pseudo-elements - para entendimento do problema de estilizar pelo CSS
 */
export function removeScrollbarInPreview(): void {
  const body = document.querySelector('body');
  if (!body.classList.contains('is-mobileweb')) {
    body.classList.add('is-mobileweb');
  }

  const allIonContent: NodeListOf<HTMLIonContentElement> = document.querySelectorAll(
    'ion-content',
  );
  let stylesheet = `
      ::-webkit-scrollbar {
        display: none;
      }

      @media (min-width: 1024px) {
        main {
          overflow: overlay!important;
          overflow-x: hidden!important;
        }
        ::-webkit-scrollbar {
            display: block;
            width: 4px;
            background: transparent;
        }
        ::-webkit-scrollbar-thumb {
          background-color: rgba(0,0,0,.4);
        }
      }
    `;

  allIonContent.forEach((value) => _applyStyleInShadowRoot(value, stylesheet));
}
