import { Injectable } from '@angular/core';
import { LibsLoaderEnum } from '@core/enums/libs-loader.enum';
import { environment } from '@environments/environment';

export interface LibModel {
  name: LibsLoaderEnum;
  styles?: string[];
  scripts?: string[];
  loaded?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ScriptLoaderService {
  libs: LibModel[] = [
    {
      loaded: false,
      name: LibsLoaderEnum.APPLE,
      scripts: [
        `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js`,
      ],
    },
    {
      loaded: false,
      name: LibsLoaderEnum.GMAPS,
      scripts: [`${environment.maps.url}js`],
    },
    {
      loaded: false,
      name: LibsLoaderEnum.GMAPS_MARKER,
      scripts: [
        'https://unpkg.com/@googlemaps/markerclusterer/dist/index.min.js',
      ],
    },
    {
      loaded: false,
      name: LibsLoaderEnum.OMS,
      scripts: ['/assets/oms.js'],
    },
    {
      loaded: false,
      name: LibsLoaderEnum.VIDEOJS,
      styles: ['https://vjs.zencdn.net/7.8.2/video-js.min.css'],
      scripts: ['https://vjs.zencdn.net/7.8.2/video.min.js'],
    },
  ];
  constructor() {}

  private getLib(lib: LibsLoaderEnum) {
    return this.libs.find((l) => l.name === lib);
  }

  public async loadLib(libName: LibsLoaderEnum, params?: object): Promise<any> {
    const lib: LibModel = this.getLib(libName);

    if (lib.loaded) {
      return lib;
    }

    const promisesCss: Promise<any>[] = this.loadStyle(lib);
    const promisesJs: Promise<any>[] = this.loadScripts(lib, params);
    await Promise.all([...promisesJs, ...promisesCss]);
    lib.loaded = true;
    return lib;
  }

  private loadStyle(lib: LibModel): Promise<any>[] {
    const promisesCss: Promise<any>[] = [];

    if (!lib.styles || lib.styles.length < 1) {
      return promisesCss;
    }
    lib.styles.map((url: string) => {
      const linkTag: HTMLLinkElement = document.createElement('link');
      linkTag.id = lib.name;
      linkTag.href = url;
      linkTag.type = 'text/css';
      linkTag.rel = 'stylesheet';
      document.getElementsByTagName('body')[0].appendChild(linkTag);

      promisesCss.push(
        new Promise((resolve) => {
          linkTag.onload = () => {
            resolve();
          };
        }),
      );
    });
    return promisesCss;
  }

  private loadScripts(lib: LibModel, params?: object): Promise<any>[] {
    const promisesJs: Promise<any>[] = [];
    if (!lib.scripts || lib.scripts.length < 1) {
      return promisesJs;
    }

    lib.scripts.map((url: string) => {
      const scriptTag: HTMLScriptElement = document.createElement('script');
      scriptTag.id = lib.name;
      scriptTag.src = url + this.queryString(params);
      scriptTag.type = 'text/javascript';
      document.getElementsByTagName('body')[0].appendChild(scriptTag);

      promisesJs.push(
        new Promise((resolve) => {
          scriptTag.onload = () => {
            resolve();
          };
        }),
      );
    });
    return promisesJs;
  }

  private queryString(params: any): string {
    if (!params) {
      return '';
    }
    return '?' + new URLSearchParams(params).toString();
  }
}
