import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ResetPassword, ResetPasswordState } from '@core/state/reset-password';
import {
  ModalController,
  NavController,
  ToastController,
} from '@ionic/angular';
import {
  translate,
  TranslocoService,
  TRANSLOCO_SCOPE,
} from '@ngneat/transloco';
import { Store } from '@ngxs/store';

const TRANSLATION_SCOPE = 'reset-password';
let LANG: string;
@Component({
  selector: 'fabapp-reset-password',
  templateUrl: 'reset-password.page.html',
  styleUrls: ['reset-password.page.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: TRANSLATION_SCOPE, alias: 'resetPassword' },
    },
  ],
})
export class ResetPasswordPage implements OnInit {
  @Input() isModal = false;

  isSubmitting = false;

  translationScope: string;

  username: string;
  code: string;

  passwordType = 'password';
  passwordConfirmationType = 'password';

  form = this.fb.group({
    password: ['', Validators.required],
    password_confirmation: ['', Validators.required],
  });

  constructor(
    private navCtrl: NavController,
    private fb: FormBuilder,
    public toastController: ToastController,
    private translocoService: TranslocoService,
    private store: Store,
    public modalController: ModalController,
  ) {
    LANG = this.translocoService.getActiveLang();

    this.username = this.store.selectSnapshot(ResetPasswordState.username);
    this.code = this.store.selectSnapshot(ResetPasswordState.code);
  }

  ngOnInit(): void {
    if (!this.username) {
      this.goToCodeRequest();
    }
  }

  goToCodeRequest(): void {
    this.navCtrl.navigateRoot('/reset-password/code-request');
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  get password_confirmation(): AbstractControl {
    return this.form.get('password_confirmation');
  }

  togglePasswordMode(): void {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
  }

  togglePasswordConfirmationTypeMode(): void {
    this.passwordConfirmationType =
      this.passwordConfirmationType === 'text' ? 'password' : 'text';
  }

  async presentToast(translateKey: string): Promise<any> {
    const toast = await this.toastController.create({
      message: translate(translateKey, {}, `${TRANSLATION_SCOPE}/${LANG}`),
      color: 'danger',
      position: 'top',
      buttons: [
        {
          text: 'ok',
        },
      ],
    });
    toast.present();
  }

  async resetPass(): Promise<any> {
    if (this.password.value !== this.password_confirmation.value) {
      return this.presentToast('messages.matchPass');
    }
    try {
      this.isSubmitting = true;

      await this.store
        .dispatch(
          new ResetPassword(
            this.username,
            this.code,
            this.password.value,
            this.password_confirmation.value,
          ),
        )
        .toPromise();

      this.goToSuccess();
    } catch (err) {
      let msg = 'messages.';
      if (err.status == 404) {
        msg += 'notFound';
      } else {
        msg += 'genericError';
      }
      return this.presentToast(msg);
    } finally {
      this.isSubmitting = false;
    }
  }

  goToSuccess(): void {
    if (this.isModal) {
      this.modalController.dismiss({ goToResetSuccessfully: true });
    } else {
      this.navCtrl.navigateRoot('/reset-password/success');
    }
  }
}
