import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { AlertAboutNewTermAcceptComponent } from './alert-about-new-term-accept/alert-about-new-term-accept.component';
import { PreviewNewTermToAcceptComponent } from './preview-new-term-to-accept/preview-new-term-to-accept.component';

@NgModule({
  imports: [IonicModule, CommonModule, TranslocoModule],
  declarations: [
    AlertAboutNewTermAcceptComponent,
    PreviewNewTermToAcceptComponent,
  ],
})
export class LGPDModule {}
