import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from '@core/models/address/address.model';
import {
  RegisterAddress,
  UpdateAddress,
} from '@core/state/address/address.action';
import { AddressState } from '@core/state/address/address.state';
import { ThemingColor } from '@core/types';
import { IsEmptyValidator } from '@core/validators/isEmpty.validator';
import {
  AlertController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { Actions, Store } from '@ngxs/store';

@Component({
  selector: 'app-informations-address',
  templateUrl: './informations-address.component.html',
  styleUrls: ['./informations-address.component.scss'],
})
export class InformationsAddressComponent implements OnInit {
  public readonly fields = {
    ADDRESS: 'full_address',
    COMPLEMENT: 'complement',
  };

  private readonly toastButtons = [
    {
      text: 'ok',
    },
  ];

  public editable: boolean = false;

  optionsControlGoogleMaps: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    disableDoubleClickZoom: true,
    disableDefaultUI: true,
    zoomControl: false,
    scrollwheel: false,
    draggable: false,
  };

  zoomViewGoogleMaps: number = 17;

  center: google.maps.LatLngLiteral;

  currentLocateUser: any = {
    position: { lat: 0, lng: 0 },
  };
  storeId: number;

  userAddress: Address;

  form = new FormGroup({
    [this.fields.ADDRESS]: new FormControl('', [
      Validators.required,
      IsEmptyValidator,
    ]),
    address: new FormControl(''),
    number: new FormControl('', Validators.required),
    neighborhood: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    country: new FormControl(''),
    zip_code: new FormControl(''),
    lat: new FormControl(''),
    lng: new FormControl(''),
    [this.fields.COMPLEMENT]: new FormControl(''),
    reference: new FormControl(''),
  });

  constructor(
    private modalController: ModalController,
    private readonly toast: ToastController,
    public router: Router,
    private store: Store,
    private route: ActivatedRoute,
    private actions$: Actions,
    public alertController: AlertController,
  ) {}

  async ngOnInit() {
    this.storeId = +this.route.snapshot.params.id;

    this.userAddress = this.store.selectSnapshot(
      AddressState.getCurrentAddress,
    );

    if (this.userAddress) {
      this.pathValueForm();
      this.setCoords();
    }
  }

  async closeModal() {
    const modal = await this.modalController.getTop();
    modal.dismiss();
  }

  private setCoords() {
    if (!this.userAddress?.lat || !this.userAddress?.lng) {
      return;
    }

    const lat = parseFloat(this.userAddress.lat);
    const lng = parseFloat(this.userAddress.lng);

    this.currentLocateUser.position = {
      lat: lat,
      lng: lng,
    };

    this.optionsControlGoogleMaps.center = {
      lat: lat,
      lng: lng,
    };

    this.currentLocateUser.options = {
      animation: google.maps.Animation.DROP,
      draggable: false,
      clickable: false,
    };

    this.center = {
      lat: lat,
      lng: lng,
    };
  }

  private async pathValueForm() {
    this.form.patchValue(this.userAddress);

    if (this.userAddress?.lat && this.userAddress?.lng) {
      this.form.get('lat').setValue(this.userAddress.lat + '');
      this.form.get('lng').setValue(this.userAddress.lng + '');
    }
  }

  async submit() {
    this.presentAlertConfirm();
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Este é seu endereço mesmo?',
      message: `${this.form.get('address').value}, ${
        this.form.get('number').value
      }<br>${this.form.get('neighborhood').value}, ${
        this.form.get('city').value
      }<br> ${this.form.get('state').value}, ${this.form.get('country').value}`,
      buttons: [
        {
          text: 'Editar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.applyEdit(true);
          },
        },
        {
          text: 'Confirmar',
          handler: () => {
            this.registerAddress(this.form.getRawValue());
          },
        },
      ],
    });

    await alert.present();
  }

  private async registerAddress(data: Address): Promise<void> {
    try {
      const edit = this.store.selectSnapshot(AddressState.getEditableAddress);

      if (edit) {
        const id = this.store.selectSnapshot(AddressState.getCurrentIdEditable);
        data.id = id;
        await this.store.dispatch(new UpdateAddress(data)).toPromise();
        this.feedbackToaster('Endereço atualizado!', 'success');
      } else {
        await this.store.dispatch(new RegisterAddress(data)).toPromise();
        this.feedbackToaster('Endereço cadastrado!', 'success');
      }
    } catch (error) {
      throw error;
    }
  }

  private async feedbackToaster(
    message: string,
    theme: ThemingColor = 'danger',
  ): Promise<void> {
    const toast: HTMLIonToastElement = await this.toast.create({
      message,
      color: theme,
      position: 'top',
      duration: 1000,
    });
    toast.present();
  }

  applyEdit(active = null) {
    if (active === null) {
      this.editable = !this.editable;
      return;
    }
    this.editable = active;
  }
}
