import { Injectable } from '@angular/core';
import { User } from '@core/models';
import { TermAndPrivacy } from '@core/models/themis';
import { ThemisService } from '@core/services/themis/themis.service';
import { ModalController } from '@ionic/angular';
import { Action, State, Store } from '@ngxs/store';
import { distinctUntilKeyChanged, filter, take } from 'rxjs/operators';
import { AlertAboutNewTermAcceptComponent } from 'src/app/components/lgpd/alert-about-new-term-accept/alert-about-new-term-accept.component';
import { AuthState } from '../auth';
import { CheckIfExistTermToAccept } from './themis.action';

@State({
  name: 'themis',
})
@Injectable()
export class ThemisState {
  constructor(
    private modalCtrl: ModalController,
    private themisService: ThemisService,
    private store: Store,
  ) {}

  @Action(CheckIfExistTermToAccept)
  async checkTerm() {
    if (window['preview']) {
      return;
    }

    this.store
      .select(AuthState.getUser)
      .pipe(filter(Boolean), distinctUntilKeyChanged<User>('id'))
      .subscribe(async (user: User) => {
        try {
          const terms = await this.themisService.checkTerm(user.id).toPromise();
          await this.openAlert(terms);
        } catch (error) {
          console.log('Error to load current term: ', error);
        }
      });
  }

  private async openAlert(terms: {
    term: TermAndPrivacy;
    policy: TermAndPrivacy;
  }) {
    if (!terms?.policy?.id && !terms?.term?.id) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: AlertAboutNewTermAcceptComponent,
      cssClass: 'alert-about-new-term-accept-modal',
      componentProps: {
        terms,
      },
      backdropDismiss: false,
    });
    await modal.present();
  }
}
