import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomPreloadingStrategy implements PreloadingStrategy {
  preload({ data }: Route, load: () => Observable<any>): Observable<any> {
    if (data?.noPreload) {
      return EMPTY;
    }

    return load();
  }
}
