import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { HeaderModule } from '../header/header.module';
import { CountryDDIModalComponent } from './country-ddi-modal.component';

@NgModule({
  declarations: [CountryDDIModalComponent],
  imports: [CommonModule, IonicModule, HeaderModule],
  exports: [CountryDDIModalComponent],
})
export class CountryDdiModalModule {}
