import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { GeolocationService } from '@core/services/geolocation.service';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ListService } from '../../list.service';
import { List, ListDef } from '../../models';

const TRANSLATION_SCOPE: string = 'list';
@Component({
  selector: 'app-pin-preview',
  templateUrl: './pin-preview.component.html',
  styleUrls: ['./pin-preview.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: TRANSLATION_SCOPE,
    },
    ListService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PinPreviewComponent implements OnInit, OnDestroy {
  /**
   * pin seleciona/clicado no mapa
   */
  pinSelected: List;
  subscription: Subscription;
  pinSubscription: Subscription;

  @Input('listDef')
  public set setListDef(value: ListDef) {
    this.listDef = value;
  }
  listDef: ListDef;

  @Output() navigate: EventEmitter<any> = new EventEmitter();
  isLoading = true;
  constructor(
    private cdr: ChangeDetectorRef,
    private $geo: GeolocationService,
    private $list: ListService,
  ) {}

  ngOnInit() {
    this.pinSubscription = this.$geo.pinSelected$.subscribe(({ id }) =>
      this.getItem(id),
    );
  }

  private getItem(id: string) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.pinSelected?.id === id) {
      this.isLoading = false;
      this.cdr.markForCheck();
      return;
    }

    this.isLoading = true;
    this.pinSelected = null;
    this.cdr.markForCheck();

    this.subscription = this.$list
      .itemPin(this.listDef.id, id)
      .subscribe((item) => {
        this.pinSelected = item;
        this.isLoading = false;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.pinSubscription) {
      this.pinSubscription.unsubscribe();
    }
  }

  seeDetail(seeRating: boolean = false): void {
    if (seeRating) {
      this.navigate.emit({ ...this.pinSelected, seeRating });
      return;
    }

    this.navigate.emit(this.pinSelected);
  }

  openMap(): void {
    this.$geo.openAvailableMaps(this.pinSelected);
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }
}
