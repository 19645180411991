import { GroupLayoutDef, Page } from '@core/models';

export class LoadMoblet {
  static readonly type = '[moblet] Load Moblet';
  constructor(
    public id: number,
    public page: number = 1,
    public version: number = 3,
    public extraParams?: object,
    public fabAppVersion = 3,
  ) {}
}

export class LoadStore {
  static readonly type = '[moblet] Load Store';
  constructor(public id: number) {}
}

export class CancelLoadStore {
  static readonly type = '[moblet] Cancel Load Store';
}

export class RefreshMoblet {
  static readonly type = '[moblet] Refresh Moblet';
  constructor(
    public id: number,
    public page: number = 1,
    public version: number = 3,
    public extraParams?: object,
    public fabAppVersion = 3,
  ) {}
}

export class CommentAdded {
  static readonly type = '[moblet] Comment Added';
}

export class LoadGroup {
  static readonly type = '[moblet] Load Group';
  constructor(public id: number) {}
}

export class LoadMobletItem {
  static readonly type = '[moblet] Load Moblet Item';
  constructor(public id: any, public item: any) {}
}

export class UpdateGroupPageLayout {
  static readonly type = '[moblet] Update Group Page Layout';
  constructor(public id: number, public layout: GroupLayoutDef) {}
}

export class UpdatePageData {
  static readonly type = '[moblet] Update Page Data';
  constructor(public page: Page) {}
}
