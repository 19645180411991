import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { FDI_Store } from '@fabapp-delivery/models/store';
import { Tax } from '@fabapp-delivery/models/store/tax.model';
import { Observable } from 'rxjs';
import { HttpService } from '../../../core/http/http.service';

@Injectable({
  providedIn: 'root',
})
export class StoreService extends HttpService<FDI_Store> {
  protected url = `${environment.shop.url}/app/stores`;

  public getStore(): Observable<FDI_Store> {
    return this.request('GET', '/');
  }

  public saveStore(
    store: Partial<FDI_Store>,
    id: number,
  ): Observable<FDI_Store> {
    return this.request('POST', `/${id}`, store);
  }

  public taxes(addressId: string): Observable<Tax> {
    return this.request('POST', `/taxes`, { addressId });
  }
}
