import { GridIconSize } from '@core/enums';

export function getIconSize(iconSizePx: number): number {
  if (iconSizePx <= 55) {
    return GridIconSize.Small;
  } else if (iconSizePx >= 56 && iconSizePx <= 63) {
    return GridIconSize.Medium;
  } else if (iconSizePx >= 64) {
    return GridIconSize.Large;
  }
}
