import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { FabAppAdTypeEnum } from '@core/enums/fabapp-ad/fab-app-ad-type.enum';
import { AppInfo, FAB_AdCampaign } from '@core/models';
import { FAB_Ad } from '@core/models/fabapp-ads/ad.model';
import { FabappAdsService } from '@core/services/ads/fabapp-ads/fabapp-ads.service';
import { isPlatform, ModalController, Platform } from '@ionic/angular';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { BannerAndModalOfDivulgation } from 'src/app/components/banner-footer/interfaces/banner.model';
import { AppDefState } from '../appdef';
import {
  LoadAds,
  NextAd,
  RemoveListenersOfAds,
  SetAdsAsClicked,
  SetAdsAsViewed,
  SetIsShowingBanner,
  ShowAdInterstitial,
} from './fabapp-ads.action';

interface FabappAds {
  isShowingBanner: boolean;
  banner?: BannerAndModalOfDivulgation;
  interstitial?: BannerAndModalOfDivulgation;
  adInterstitialCampaign?: FAB_AdCampaign;
  adCarouselCampaign?: FAB_AdCampaign;
}
@State<FabappAds>({
  name: 'ads',
  defaults: {
    banner: {
      image: '',
      link: '',
      showCustomBanner: false,
      imageTitle: '',
      isVideo: false,
    },
    interstitial: {
      image: '',
      link: '',
      showCustomBanner: false,
      imageTitle: '',
      isVideo: false,
    },
    isShowingBanner: false,
    adInterstitialCampaign: null,
  },
})
@Injectable()
export class FabappAdsState {
  public defaultHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  private bannerCampaign: FAB_AdCampaign;
  private interstitialAdCampaign: FAB_AdCampaign;

  private currentAdBannerIndex: number = null;
  private currentAdBanner: FAB_Ad;

  private currentAdInterstitialIndex: number = null;
  private currentAdInterstitial: FAB_Ad;

  private adsTimer: NodeJS.Timeout;
  private readonly INTERVAL: number = 15000;
  private subscriptions: Subscription[] = [];
  private fabappAdsService: FabappAdsService;

  isFree = false;
  constructor(
    httpClient: HttpClient,
    private store: Store,
    private platform: Platform,
    private modalCtrl: ModalController,
  ) {
    this.fabappAdsService = new FabappAdsService(httpClient, platform, store);
  }

  @Selector()
  static adCarouselCampaign(state: FabappAds): FAB_AdCampaign {
    return state.adCarouselCampaign;
  }

  @Selector()
  static adInterstitialCampaign(state: FabappAds): FAB_AdCampaign {
    return state.adInterstitialCampaign;
  }

  @Selector()
  static banner(state: FabappAds): any {
    return { ...state.banner, isVideo: this.isVideo(state.banner.image) };
  }

  @Selector()
  static interstitial(state: FabappAds): BannerAndModalOfDivulgation {
    return {
      ...state.interstitial,
      isVideo: this.isVideo(state.interstitial.image),
    };
  }

  @Selector()
  static isShowingBanner(state: FabappAds): boolean {
    return state.isShowingBanner;
  }

  /**
   * @description
   * método checa se a url tem o mimetype de video
   * @returns boolean
   */
  static isVideo(url: string): boolean {
    if (!url) {
      return false;
    }

    const type: string = url.substr(url.lastIndexOf('.'));
    const types = {
      '.mp4': true,
      '.mov': true,
    };

    if (!types[type]) {
      return false;
    }

    return types[type];
  }

  @Action(LoadAds)
  public async loadAds(ctx: StateContext<FabappAds>): Promise<void> {
    try {
      const { banner, interstitial, carousel } =
        await this.fabappAdsService.loadAds();

      if (carousel) {
        ctx.patchState({ adCarouselCampaign: carousel });
      }

      // Identificação se é gratuito se a campanha for um array
      if (Array.isArray(banner) || Array.isArray(interstitial)) {
        this.isFree = true;
        this._freeMode(banner, interstitial, ctx);
        return;
      }

      this.interstitialAdCampaign = interstitial;
      ctx.patchState({ adInterstitialCampaign: interstitial });
      this.currentAdInterstitialIndex = null;
      if (this.interstitialAdCampaign?.ads?.length) {
        this.store.dispatch(new NextAd.Interstitial());
      }

      this.bannerCampaign = banner;
      this.currentAdBannerIndex = null;
      if (this.bannerCampaign?.ads?.length) {
        this.store.dispatch([
          new NextAd.Banner(),
          new SetAdsAsViewed(FabAppAdTypeEnum.BANNER),
        ]);
        this.listeners();
      }
    } catch (error) {
      console.log('Error to load Ads: ', error);
    }
  }

  _freeMode(
    bannerCampaign,
    interstitialCampaign,
    ctx: StateContext<FabappAds>,
  ) {
    let interstitial;
    // TODO: comentando para priorização do anúncio pela grumft
    // let banner;
    // if (bannerCampaign?.length) {
    //   const currentAdBanner: FAB_Ad = bannerCampaign[0];
    //   this.currentAdBanner = currentAdBanner;
    //   const link: string = currentAdBanner.link;
    //   const image: string = currentAdBanner.imagePath;
    //   const imageTitle: string = currentAdBanner.title;
    //   banner = {
    //     link,
    //     image,
    //     imageTitle,
    //     adRedirectId: null,
    //     showCustomBanner: true,
    //   };
    //   if (image) {
    //     this.store.dispatch(new SetAdsAsViewed(FabAppAdTypeEnum.BANNER));
    //     this.store.dispatch(new SetIsShowingBanner(true));
    //   } else {
    //     this.store.dispatch(new SetIsShowingBanner(false));
    //   }
    // }

    if (interstitialCampaign?.length) {
      const currentAdInterstitial: FAB_Ad = interstitialCampaign[0];
      this.currentAdInterstitial = currentAdInterstitial;

      const link: string = currentAdInterstitial.link;
      const image: string = currentAdInterstitial.imagePath;
      const imageTitle: string = currentAdInterstitial.title;
      interstitial = {
        link,
        image,
        imageTitle,
        adRedirectId: null,
      };
    }

    ctx.patchState({
      // banner,
      interstitial,
    });
  }

  private listeners(): void {
    this.stopInterval();
    this.startInterval();

    Capacitor.Plugins.App.addListener('appStateChange', (state) => {
      if (state.isActive) {
        this.startInterval();
      } else {
        this.stopInterval();
      }
    });
  }

  private startInterval(): void {
    if (!this.bannerCampaign || this.bannerCampaign.ads.length <= 1) {
      return;
    }

    const interval: number =
      this.bannerCampaign.exhibitionTime || this.INTERVAL;
    this.adsTimer = setInterval(() => {
      this.store.dispatch([
        new NextAd.Banner(),
        new SetAdsAsViewed(FabAppAdTypeEnum.BANNER),
      ]);
    }, interval);
  }

  private stopInterval(): void {
    if (this.adsTimer) {
      clearInterval(this.adsTimer);
    }
  }

  /**
   * @description
   * valida se admob está ativado
   *
   * @returns boolean
   */
  private admobActive({
    admob_android_id,
    admob_android_banner_id,
    admob_ios_banner_id,
    admob_ios_id,
  }: AppInfo): boolean {
    if (this.platform.is('android')) {
      return !!(admob_android_id && admob_android_banner_id);
    }

    if (this.platform.is('ios')) {
      return !!(admob_ios_banner_id && admob_ios_id);
    }

    return false;
  }

  @Action(SetAdsAsViewed)
  async setAdAsViewed(
    ctx: StateContext<FabappAds>,
    { ad, adData }: SetAdsAsViewed,
  ): Promise<void> {
    if (ad === FabAppAdTypeEnum.INTERSTITIAL) {
      await this.fabappAdsService.setAdAsViewed(this.currentAdInterstitial);
      return;
    }

    if (ad === FabAppAdTypeEnum.BANNER) {
      await this.fabappAdsService.setAdAsViewed(this.currentAdBanner);
    }

    if (ad === FabAppAdTypeEnum.CAROUSEL) {
      await this.fabappAdsService.setAdAsViewed(adData);
    }
  }

  @Action(SetAdsAsClicked)
  async setAdAsClicked(
    ctx: StateContext<FabappAds>,
    { ad, adData }: SetAdsAsClicked,
  ): Promise<void> {
    if (ad === FabAppAdTypeEnum.INTERSTITIAL) {
      await this.fabappAdsService.setAdAsClicked(this.currentAdInterstitial);
      return;
    }

    if (ad === FabAppAdTypeEnum.BANNER) {
      await this.fabappAdsService.setAdAsClicked(this.currentAdBanner);
      return;
    }

    if (ad === FabAppAdTypeEnum.CAROUSEL) {
      await this.fabappAdsService.setAdAsClicked(adData);
    }
  }

  @Action(RemoveListenersOfAds)
  public dispose(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe(),
    );
  }

  @Action(ShowAdInterstitial)
  public async showModal(
    ctx: StateContext<FabappAds>,
    { component }: ShowAdInterstitial,
  ): Promise<void> {
    const { interstitial } = ctx.getState();
    if (!interstitial?.image) {
      return;
    }

    const newModal: HTMLIonModalElement = await this.modalCtrl.create({
      component,
    });

    await newModal.present();
  }

  @Action(SetIsShowingBanner)
  showing(ctx: StateContext<FabappAds>, { isShow }: SetIsShowingBanner): void {
    ctx.patchState({ isShowingBanner: isShow });
  }

  @Action(NextAd.Banner)
  nextAdBanner(ctx: StateContext<FabappAds>): void {
    if (this.isFree) {
      return;
    }

    const banner = this._getNextBanner();
    const link: string = banner.link;
    const image: string = banner?.imagePath?.includes('http')
      ? banner.imagePath
      : null;
    const imageTitle: string = banner.title;
    const canShowBanner: boolean = this._canShowBanner();
    const adRedirectId = banner?.adRedirectId;

    if (canShowBanner && image) {
      this.store.dispatch(new SetIsShowingBanner(true));
    } else {
      this.store.dispatch(new SetIsShowingBanner(false));
    }

    ctx.patchState({
      banner: {
        link,
        image,
        imageTitle,
        showCustomBanner: canShowBanner,
        adRedirectId,
      },
    });
  }

  private _getNextBanner() {
    const adsLength = this.bannerCampaign.ads.length;
    if (
      this.currentAdBannerIndex >= adsLength ||
      this.currentAdBannerIndex + 1 >= adsLength ||
      this.currentAdBannerIndex === null
    ) {
      this.currentAdBannerIndex = -1;
    }

    this.currentAdBanner = Object.assign(
      {} as FAB_AdCampaign,
      this.bannerCampaign.ads[++this.currentAdBannerIndex],
    );
    return this.currentAdBanner;
  }

  private _canShowBanner() {
    let showCustomBanner: boolean = true;

    const appInfo: AppInfo = this.store.selectSnapshot(AppDefState.getInfo);
    const isMobileAndAdMobIsActivated: boolean =
      isPlatform('capacitor') && this.admobActive(appInfo);

    // banner do admob só aparece no mobile
    // plugin deve estar disponivel
    if (isMobileAndAdMobIsActivated && Capacitor.isPluginAvailable('AdMob')) {
      showCustomBanner = false;
    }
    return showCustomBanner;
  }

  @Action(NextAd.Interstitial)
  nextAdInterstitial(ctx: StateContext<FabappAds>): void {
    if (this.isFree) {
      return;
    }

    const interstitial = this._getNextInterstitial();
    const link: string = interstitial.link;
    const image: string = interstitial.imagePath;
    const imageTitle: string = interstitial.title;
    const adRedirectId = interstitial.adRedirectId;

    ctx.patchState({
      interstitial: { link, image, imageTitle, adRedirectId },
    });
  }

  private _getNextInterstitial() {
    const adsLength = this.interstitialAdCampaign.ads.length;
    if (
      this.currentAdInterstitialIndex >= adsLength ||
      this.currentAdInterstitialIndex + 1 >= adsLength ||
      this.currentAdInterstitialIndex === null
    ) {
      this.currentAdInterstitialIndex = -1;
    }

    this.currentAdInterstitial = Object.assign(
      {} as FAB_AdCampaign,
      this.interstitialAdCampaign.ads[++this.currentAdInterstitialIndex],
    );

    return this.currentAdInterstitial;
  }
}
