<form *ngIf="!loading" [formGroup]="form">
  <ng-container *transloco="let t">
    <ion-list lines="none">
      <ion-list-header
        *ngIf="!user"
        class="item-header ion-text-center"
        lines="none"
      >
        <h1>
          {{ t('userForm.newAccount') }}
        </h1>
        <h2>{{ t('userForm.subtitle') }}</h2>
      </ion-list-header>

      <ion-item>
        <ion-label position="floating">Nome Completo*</ion-label>
        <ion-input
          outline
          [formControlName]="FIELDS.NAME"
          required="true"
          [brmasker]="{ type: 'text' }"
        >
        </ion-input>
      </ion-item>
      <control-error
        label="Nome"
        [control]="form.get(FIELDS.NAME)"
      ></control-error>

      <ng-container *ngIf="user">
        <ion-item>
          <ion-label position="floating">CPF (Opcional)</ion-label>
          <ion-input
            outline
            [formControlName]="FIELDS.DOCUMENT_NUMBER"
            [brmasker]="{ person: true }"
            type="tel"
          >
          </ion-input>
        </ion-item>
        <control-error
          label="CPF"
          [control]="form.get(FIELDS.DOCUMENT_NUMBER)"
        ></control-error>

        <ion-item>
          <ion-label position="floating">Email (Opcional)</ion-label>
          <ion-input outline [formControlName]="FIELDS.EMAIL" type="email">
          </ion-input>
        </ion-item>
        <control-error
          label="Email"
          [control]="form.get(FIELDS.EMAIL)"
        ></control-error>
      </ng-container>

      <ion-item>
        <ion-label position="stacked">Telefone*</ion-label>
        <fabapp-phone-email-input
          outline
          [callingCode]="phoneHelper.ddi"
          (setMask)="setMask($event)"
          (getCallingCode)="getCallingCode($event)"
          [brmasker]="{ mask: phoneHelper.mask, type: 'tel' }"
          [formControlName]="FIELDS.PHONE_NUMBER"
          type="tel"
        >
        </fabapp-phone-email-input>
      </ion-item>
      <control-error
        label="Telefone"
        [control]="form.get(FIELDS.PHONE_NUMBER)"
      ></control-error>

      <ng-container *ngIf="!user">
        <ion-item>
          <ion-label position="floating">Senha*</ion-label>
          <ion-input
            outline
            [formControlName]="FIELDS.PASSWORD"
            type="password"
          >
          </ion-input>
        </ion-item>
        <control-error
          label="Senha"
          [control]="form.get(FIELDS.PASSWORD)"
        ></control-error>
      </ng-container>

      <ng-container *ngIf="!user">
        <a class="terms-link" (click)="showUseTerms()">{{
          t('userForm.termsLink')
        }}</a>
        <ion-item lines="none" class="ion-no-padding">
          <ion-label class="ion-text-wrap terms">
            {{ t('userForm.terms.part1') }} {{ t('userForm.terms.part2') }}
          </ion-label>
          <ion-checkbox
            class="ion-margin-end"
            slot="start"
            formControlName="terms"
          ></ion-checkbox>
        </ion-item>
      </ng-container>
    </ion-list>

    <ion-button
      class="btnsignup"
      [disabled]="isSubmitting"
      expand="block"
      type="submit"
      [validate]="form"
      (click)="onSubmit()"
    >
      {{ user ? t('userForm.save') : t('userForm.signup') }}
    </ion-button>
  </ng-container>
</form>

<!-- loader -->
<div class="spinner-wrapper" style="height: 100%" *ngIf="loading">
  <ion-spinner></ion-spinner>
</div>
<!-- end of loader -->
