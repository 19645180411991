import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FabappDeliveryItem } from '@fabapp-delivery/state/fabapp-delivery-item/fabapp-delivery-item.action';
import { Store } from '@ngxs/store';
import { isEmpty } from 'lodash';
import { ComplementGroup } from '../../models/complement';
import { ComplementTreated } from '../../models/item';

export function minValueComplementValidator(minValue: number): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control?.value;
    if (isEmpty(value)) {
      return null;
    }

    const currentQuantity = ComplementService.currentQuantity(value);
    return currentQuantity >= minValue ? null : { minValue: true };
  };
}

@Injectable()
export class ComplementService {
  constructor(private readonly store: Store) {}
  static currentQuantity(complements: Array<ComplementTreated>) {
    return complements.reduce(
      (previous: number, currentValue) => (previous += currentValue.quantity),
      0,
    );
  }

  public initForm(value: Array<ComplementGroup>, form: FormGroup): void {
    value.forEach((complement: ComplementGroup) => {
      let initialValue: any = this.getInitialValue(complement);

      let control = new FormControl(
        initialValue,
        this.createValidations(complement),
      );
      form.addControl(complement.id, control);
    });
    this.store.dispatch(new FabappDeliveryItem.UpdateComplements(form.value));
  }

  private getInitialValue(complement: ComplementGroup) {
    let initialValue: any = complement.maxValue === 1 ? null : [];
    const hasOneComplementItemAndRequired: boolean = this.hasOneComplementItemAndRequired(
      complement,
    );
    if (hasOneComplementItemAndRequired) {
      initialValue = complement.items[0].id;
    }
    return initialValue;
  }

  private hasOneComplementItemAndRequired(complement: ComplementGroup) {
    return (
      complement.items?.length === 1 &&
      complement.maxValue === 1 &&
      complement?.required
    );
  }

  private createValidations(complement: ComplementGroup): ValidatorFn {
    const validators: Array<ValidatorFn> = [];
    const canValidateMinValue: boolean =
      complement.minValue && complement.maxValue > 1 && complement.required;
    if (canValidateMinValue) {
      validators.push(minValueComplementValidator(complement.minValue));
    }

    if (complement?.required) {
      validators.push(Validators.required);
    }

    return Validators.compose(validators);
  }
}
