import { Injectable } from '@angular/core';
import {
  AppDef,
  AppInfo,
  AppLayout,
  CarouselItem,
  Colors,
  Page,
} from '@core/models';
import { AnalyticsService } from '@core/services/analytics.service';
import { AppDefService } from '@core/services/app-def.service';
import { HomeLayout } from '@core/types';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { convertToBoolean, flatten, getTextSize } from '@utils';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { getIconSize } from 'src/app/utils/parse-icon-size';
import { UpdateRestrictedPagesWhenLogged } from '../auth/auth.actions';
import { Menu, SetHomeRoute } from '../core/core.actions';
import {
  LoadAppDef,
  MergeAppInfo,
  MergeLayout,
  SetColors,
  SetHeaderLogo,
  SetHeaderPattern,
  SetNewMoblet,
  SetPages,
  SetSearch,
} from './appdef.actions';

@State<AppDef>({
  name: 'appdef',
})
@Injectable()
export class AppDefState {
  constructor(
    private appDefService: AppDefService,
    private analyticsService: AnalyticsService,
  ) {}

  static hasMoblet(
    superClass: string | Array<string>,
  ): (state: AppDef) => boolean {
    return createSelector([AppDefState], (state: AppDef) => {
      return state.flattened_pages.some((page: Page) =>
        Array.isArray(superClass?.length)
          ? superClass.includes(page.moblet_name)
          : page.moblet_name === superClass,
      );
    });
  }

  @Selector()
  static getPages(state: AppDef): Page[] {
    return state.pages;
  }

  @Selector()
  static getFlatenedPages(state: AppDef): Page[] {
    return state.flattened_pages;
  }

  static getPage(instanceId: number): (state: AppDef) => Page {
    return createSelector([AppDefState], (state: AppDef) => {
      return state.flattened_pages.find(
        (page: Page) => page.instance.id === `${instanceId}`,
      );
    });
  }

  static getPageByParentId(parentId: number): (state: AppDef) => Page {
    return createSelector([AppDefState], (state: AppDef) => {
      return state.flattened_pages.find(
        (page: Page) => page.page_id == parentId,
      );
    });
  }

  static getFromAppDef(keys: string[]): (state: AppDef) => Partial<AppDef> {
    return createSelector([AppDefState], (state: AppDef) => {
      const newState: Partial<AppDef> = {};

      keys.forEach((key: string) => {
        newState[key] = state[key];
      });

      return newState;
    });
  }

  @Selector()
  static getLayout(state: AppDef): AppLayout {
    const titlePx2VW: number = +state.layout.title_size * 0.24125;
    const newState: AppLayout = {
      ...state.layout,
      gradient: convertToBoolean(state.layout.gradient),
      icon_size: convertToBoolean(state.layout.advanced_icon_size)
        ? state.layout.icon_size
        : getIconSize(state.layout.icon_size),
      title_size: convertToBoolean(state.layout.advanced_font_size)
        ? titlePx2VW
        : getTextSize(+state.layout.title_size),
    };
    return newState;
  }

  @Selector()
  static getCarousel(state: AppDef): CarouselItem[] {
    return state.layout.carousel;
  }

  @Selector()
  static getColors(state: AppDef): Colors {
    const newState: Colors = {
      ...state.colors,
      advanced_colors_color: convertToBoolean(
        state.colors.advanced_colors_color,
      ),
    };
    return newState;
  }

  @Selector()
  static getSearch(state: AppDef): boolean {
    return state.search;
  }

  @Selector()
  static getInfo(state: AppDef): AppInfo {
    return state.info;
  }

  @Selector()
  static getLayoutType(state: AppDef): HomeLayout {
    return state.layout.layout_type;
  }

  @Selector()
  static getAuth(state: AppDef): HomeLayout {
    return state.layout.layout_type;
  }

  // -------------------------------------------------------------------
  // actions
  @Action(SetColors)
  setColors(ctx: StateContext<AppDef>, { colors }: SetColors): void {
    const state: AppDef = ctx.getState();
    ctx.patchState({
      colors: {
        ...state.colors,
        ...colors,
      },
    });
  }

  @Action(SetSearch)
  setSearch(ctx: StateContext<AppDef>, { search }: SetSearch): void {
    ctx.patchState({
      search,
    });
  }

  @Action(SetNewMoblet)
  setNewMoblet(ctx: StateContext<AppDef>, { moblet }: SetNewMoblet): void {
    const state: AppDef = ctx.getState();
    // se o moblet adicionado for uma aba do grupo de aba
    // apenas adiciona no flattened_pages para ser
    // acessado pelo navigationService
    if (moblet.parent_id) {
      ctx.patchState({
        flattened_pages: [...state.flattened_pages, moblet],
      });
      return;
    }

    ctx.patchState({
      pages: [...state.pages, moblet],
      flattened_pages: [...state.flattened_pages, moblet],
    });
  }

  @Action(SetPages)
  setPages(ctx: StateContext<AppDef>, { pages }: SetPages): void {
    ctx.patchState({
      pages,
      flattened_pages: flatten(pages),
    });
  }

  @Action(MergeLayout)
  mergeLayout(ctx: StateContext<AppDef>, { layout }: MergeLayout): void {
    const state: AppDef = ctx.getState();
    ctx.patchState({
      layout: {
        ...state.layout,
        ...layout,
      },
    });
  }

  @Action(MergeAppInfo)
  mergeAppInfo(ctx: StateContext<AppDef>, { appInfo }: MergeAppInfo): void {
    const state: AppDef = ctx.getState();
    ctx.patchState({
      info: {
        ...state.info,
        ...appInfo,
      },
    });
  }

  @Action(LoadAppDef)
  loadAppDef(ctx: StateContext<AppDef>): Observable<AppDef> {
    return this.appDefService.loadAppDef().pipe(
      tap((newAppDef: AppDef) => {
        const appDef: AppDef = new AppDef(newAppDef);
        ctx.patchState(appDef);
        this.analyticsService.init(appDef.google_firebase_config); //dados da config do firebase q precisa vir do appdef

        return ctx.dispatch([
          new SetHomeRoute(),
          new UpdateRestrictedPagesWhenLogged(),
        ]);
      }),
    );
  }

  @Action(SetHeaderPattern)
  setHeaderPattern(
    ctx: StateContext<AppDef>,
    { headerPattern }: SetHeaderPattern,
  ): void {
    ctx.patchState({
      header_pattern: headerPattern,
    });
  }

  @Action(SetHeaderLogo)
  public setHeaderLogo(
    ctx: StateContext<AppDef>,
    { logo }: SetHeaderLogo,
  ): void {
    ctx.patchState({
      logo,
    });
  }
}
