<ion-header class="ion-no-border">
  <ion-toolbar *ngIf="!interstitialData.adRedirectId">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon
          [class.ion-hide]="counter !== 0"
          slot="icon-only"
          name="close-outline"
        ></ion-icon>

        <span class="counter" [class.ion-hide]="counter === 0">{{
          counter
        }}</span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fixed [class.ion-padding]="!interstitialData?.adRedirectId">
  <ion-buttons *ngIf="interstitialData?.adRedirectId" class="closebutton">
    <ion-button (click)="dismiss()">
      <ion-icon
        [class.ion-hide]="counter !== 0"
        slot="icon-only"
        name="close-circle"
      ></ion-icon>

      <span class="counter" [class.ion-hide]="counter === 0">{{
        counter
      }}</span>
    </ion-button>
  </ion-buttons>

  <div
    class="vertical-center"
    [style.backgroundImage]="
      interstitialData?.adRedirectId
        ? 'url(' + interstitialData.image + ')'
        : ''
    "
    *transloco="let t"
  >
    <div
      class="inner-wrapper ion-text-center"
      *ngIf="!interstitialData?.adRedirectId"
    >
      <div class="header">
        <div
          class="headline"
          [innerHTML]="t('divulgationModal.headline')"
        ></div>
        <div
          class="subheadline"
          [innerHTML]="t('divulgationModal.subheadline')"
        ></div>
      </div>
      <div class="middle">
        <ion-img
          class="img"
          *ngIf="interstitialData.image && !interstitialData.isVideo"
          [src]="interstitialData.image"
          [alt]="interstitialData.imageTitle"
        >
        </ion-img>
        <video
          [muted]="true"
          playsinline
          loop
          autoplay
          class="video"
          *ngIf="interstitialData.image && interstitialData.isVideo"
          [src]="interstitialData.image"
        ></video>
      </div>

      <ion-button (click)="openLink(interstitialData)" class="btn">{{
        t('divulgationModal.iWantAApp')
      }}</ion-button>
    </div>
  </div>
</ion-content>
<ion-footer
  class="ion-no-border"
  *ngIf="
    interstitialData?.adRedirectId &&
    interstitialData?.adRedirectId != adRedirectTypeEnum.NOT_DIRECT
  "
>
  <ion-toolbar>
    <div class="action-wrapper">
      <ion-button
        [disabled]="isClicked"
        class="access-btn"
        (click)="openLink(interstitialData)"
        >{{ 'divulgationModal.access' | transloco }}</ion-button
      >
    </div>
  </ion-toolbar>
</ion-footer>
