<ng-container *ngIf="!loading && !empty">
  <div [ngSwitch]="listDef?.exibitionType?.name">
    <app-text-list
      *ngSwitchCase="exibitionType.BASIC"
      (navigate)="navigate.emit($event)"
      [listDef]="listDef"
      [items]="items"
    ></app-text-list>
    <app-text-list
      *ngSwitchCase="exibitionType.TEXT"
      (navigate)="navigate.emit($event)"
      [listDef]="listDef"
      [items]="items"
    ></app-text-list>
    <app-cards-list
      *ngSwitchCase="exibitionType.CARD"
      (navigate)="navigate.emit($event)"
      [listDef]="listDef"
      [items]="items"
    ></app-cards-list>
    <app-carousel-list
      *ngSwitchCase="exibitionType.CARROSEL"
      (navigate)="navigate.emit($event)"
      [items]="items"
      [listDef]="listDef"
    ></app-carousel-list>
    <app-image-list
      *ngSwitchCase="exibitionType.IMAGE"
      (navigate)="navigate.emit($event)"
      [items]="items"
      [listDef]="listDef"
    ></app-image-list>
  </div>
</ng-container>

<app-empty-state *ngIf="empty && !loading"></app-empty-state>
