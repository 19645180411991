<ion-header>
  <ion-toolbar
    [style.--background]="'var(--ion-color-headercolor)'"
    [style.--color]="'var(--ion-color-headercolor-contrast)'"
  >
    <ion-buttons slot="start">
      <ion-button
        [style.--color]="'var(--ion-color-headercolor-contrast)'"
        (click)="modalCtrl.dismiss()"
      >
        <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title
      *transloco="let t"
      [style.--color]="'var(--ion-color-headercolor-contrast)'"
      >{{ t('fabappDelivery.paymentMethod.title') }}</ion-title
    >
  </ion-toolbar>
  <ion-toolbar class="tab-toolbar">
    <ion-segment
      mode="md"
      [value]="currentTab"
      [scrollable]="
        standardPaymentMethods.length > 0 && fabappPayMethods.length > 0
      "
      (ionChange)="tabChanged($event)"
    >
      <ion-segment-button
        *ngIf="fabappPayMethods.length > 0"
        [value]="paymentMethodTypeEnum.ONLINE"
      >
        <ion-label>Pague pelo app</ion-label>
      </ion-segment-button>
      <ion-segment-button
        *ngIf="standardPaymentMethods.length > 0"
        [value]="paymentMethodTypeEnum.DELIVERY"
      >
        <ion-label>Pague na entrega</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-horizontal">
  <h2 class="page-title" *transloco="let t">
    {{ t('fabappDelivery.paymentMethod.paymentMethodTip') }}
  </h2>
  <ng-container *ngIf="currentTab == paymentMethodTypeEnum.ONLINE">
    <ion-list class="payment-method-list">
      <ng-container *ngFor="let method of fabappPayMethods; index as i">
        <fabi-payment-method-item
          *ngIf="method.id !== paymentMethodEnum.ONLINE_CREDIT_CARD"
          [title]="method.title"
          [brandIcon]="method.imagePath"
          [selected]="selectedPaymentMethod?.method?.id === method.id"
          (onClick)="savePaymentMethod(method)"
        >
        </fabi-payment-method-item>
        <ng-container
          *ngIf="method.id === paymentMethodEnum.ONLINE_CREDIT_CARD"
        >
          <ng-container *ngFor="let card of method.userCreditCards; index as i">
            <fabi-payment-method-item
              [title]="card.nickname"
              [brandIcon]="card.brand"
              [selected]="selectedPaymentMethod?.card?.id === card.id"
              [code]="card.lastDigits"
              (onClick)="savePaymentMethod(method, card)"
              (onAction)="cardAction(card)"
            >
            </fabi-payment-method-item>
          </ng-container>
        </ng-container>
      </ng-container>
    </ion-list>
  </ng-container>

  <ng-container *ngIf="currentTab == paymentMethodTypeEnum.DELIVERY">
    <ng-container *ngFor="let paymentMethodItem of standardPaymentMethods">
      <span class="category-title">{{ paymentMethodItem.title }}</span>
      <ng-container
        *ngFor="let method of paymentMethodItem.methods; index as i"
      >
        <fabi-payment-method-item
          [title]="method.title"
          [brandIcon]="method.imagePath"
          [selected]="selectedPaymentMethod?.method?.id === method.id"
          (onClick)="savePaymentMethod(method)"
        >
        </fabi-payment-method-item>
        <!-- cashback input -->
        <div
          class="change-box"
          *ngIf="
            method.id === paymentMethodEnum.MONEY &&
            selectedPaymentMethod?.method?.id === paymentMethodEnum.MONEY
          "
        >
          <h4 class="total-order" *transloco="let t">
            Valor total do pedido:
            <strong>{{
              (fees$ | async)?.total / 100 | currency: 'BRL'
            }}</strong>
          </h4>
          <h5
            class="total-order"
            [style.color]="cashback.noCashBack ? 'rgba(0, 0, 0, 0.6)' : 'black'"
          >
            Troco para
          </h5>
          <ion-item [class.-disabled]="cashback.noCashBack">
            <input
              type="tel"
              [options]="customCurrencyMaskConfig"
              [(ngModel)]="cashback.cashBackValue"
              outline
              currencyMask
            />
          </ion-item>
          <ion-item lines="none" class="ion-no-padding">
            <ion-label *transloco="let t" class="ion-text-wrap terms">
              Não precisa de troco
            </ion-label>
            <ion-checkbox
              [(ngModel)]="cashback.noCashBack"
              class="ion-margin-end"
              slot="start"
            ></ion-checkbox>
          </ion-item>
        </div>
        <!-- /cashback input -->
      </ng-container>
    </ng-container>
  </ng-container>
</ion-content>

<ion-footer
  class="ion-padding ion-no-border"
  *ngIf="
    currentTab == paymentMethodTypeEnum.DELIVERY &&
    selectedPaymentMethod?.method?.id === paymentMethodEnum.MONEY
  "
>
  <ion-button
    *transloco="let t"
    class="buy-button"
    [style.--color]="'var(--primarycolor-or-black)'"
    fill="outline"
    expand="block"
    (click)="savePaymentMethod(selectedPaymentMethod?.method)"
  >
    Salvar
  </ion-button>
</ion-footer>

<ion-footer
  class="ion-padding ion-no-border"
  *ngIf="currentTab == paymentMethodTypeEnum.ONLINE && hasCreditCard"
>
  <ion-button
    *transloco="let t"
    class="buy-button"
    [style.--color]="'var(--primarycolor-or-black)'"
    fill="outline"
    expand="block"
    (click)="openCreateOrEdit()"
  >
    {{ t('fabappDelivery.paymentMethod.addCard') }}
  </ion-button>
</ion-footer>
