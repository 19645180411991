import { NgModule } from '@angular/core';
import { ContentEncapsulatorComponent } from './content-encapsulator.component';
import { PipesModule } from '@core/pipes/pipes.module';

@NgModule({
  declarations: [ContentEncapsulatorComponent],
  imports: [PipesModule],
  exports: [ContentEncapsulatorComponent],
})
export class ContentEncapsulatorModule {}
