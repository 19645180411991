import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, HostBinding, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { SheetState } from './enums/sheet-state.enum';

@Component({
  selector: 'fabapp-bottom-sheet',
  templateUrl: './fabapp-bottom-sheet.component.html',
  styleUrls: ['./fabapp-bottom-sheet.component.scss'],
  animations: [
    trigger('toggleBottomSheet', [
      state(
        SheetState.Closed,
        style({
          transform: 'translateY(100%)',
        }),
      ),
      state(
        SheetState.Expanded,
        style({
          transform: 'translateY(0)',
        }),
      ),
      transition('closed <=> expanded', animate(150)),
    ]),
  ],
})
export class FabappBottomSheetComponent {
  @HostBinding('@toggleBottomSheet') get getBottomSheetStatus() {
    return this.sheetState === SheetState.Expanded
      ? SheetState.Expanded
      : SheetState.Closed;
  }

  @Input() height: number = 234;
  _sheetState: BehaviorSubject<SheetState> = new BehaviorSubject(
    SheetState.Closed,
  );

  sheetStateEnum = SheetState;

  error: boolean = false;

  constructor() {}

  get sheetState() {
    return this._sheetState.value;
  }

  set sheetState(value: SheetState) {
    this._sheetState.next(value);
  }

  onBackdropClick(event) {
    this.closeBottomSheet(true);
  }

  private toggleBottomSheet() {
    this.sheetState =
      this.sheetState === SheetState.Expanded
        ? SheetState.Closed
        : SheetState.Expanded;
  }

  async openBottomSheet(): Promise<{ error: boolean }> {
    this.toggleBottomSheet();

    return new Promise((resolve, reject) => {
      this._sheetState
        .asObservable()
        .pipe(
          filter((val) => val === SheetState.Closed),
          take(1),
        )
        .subscribe((val) => resolve({ error: this.error }));
    });
  }

  public closeBottomSheet(error: boolean) {
    this.error = error;
    this.sheetState = SheetState.Closed;
  }
}
