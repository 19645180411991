import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SetCurrentStore } from '@core/state/core/core.actions';
import { CoreState } from '@core/state/core/core.state';
import { ThemingColor } from '@core/types';
import { FDI_CartFees } from '@fabapp-delivery/models/cart/fees.model';
import { Coupon } from '@fabapp-delivery/models/promotions/coupon/coupon.model';
import { FDI_Campaign } from '@fabapp-delivery/models/promotions/reward/campaign.model';
import { CartState } from '@fabapp-delivery/state/cart/cart.state';
import {
  ApplyCoupon,
  LoadCoupons,
  RemoveCoupon,
} from '@fabapp-delivery/state/promotions/promotions.action';
import { PromotionsState } from '@fabapp-delivery/state/promotions/promotions.state';
import {
  ModalController,
  NavController,
  ToastController,
} from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InsertCouponComponent } from '../insert-coupon/insert-coupon.component';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.page.html',
  styleUrls: ['./promotions.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromotionsPage implements OnInit {
  @Input() inCart: boolean = false;

  @Select(CartState.getFees) fees$: Observable<FDI_CartFees>;
  @Select(PromotionsState.getCoupons) coupons$: Observable<Array<Coupon>>;
  @Select(PromotionsState.getCouponSelected) couponSelected$: Observable<
    Partial<Coupon>
  >;
  isSubmitting = false;
  public reward: FDI_Campaign;
  public reward$: Observable<FDI_Campaign>;

  @Select(PromotionsState.getProgress) progress$: Observable<number>;
  @Select(PromotionsState.getPunctuation) punctuation$: Observable<number>;

  constructor(
    public readonly modalController: ModalController,
    public navCtrl: NavController,
    private store: Store,
    private readonly toastController: ToastController,
    private cdr: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
  ) {}

  async ngOnInit() {
    const currentStoreId = this.store.selectSnapshot(CoreState.currentStoreId);
    const id = this.route.snapshot.params?.id || currentStoreId;
    await this.store.dispatch(new SetCurrentStore(null, id)).toPromise();
    await this.store.dispatch(new LoadCoupons()).toPromise();
    this._getReward();
  }

  private _getReward() {
    this.reward$ = this.store.select(PromotionsState.getReward).pipe(
      tap((reward) => {
        this.reward = reward;
        this.cdr.markForCheck();
      }),
    );
  }

  async applyCoupon(coupon: Coupon) {
    if (this.isSubmitting) {
      return;
    }

    try {
      this.isSubmitting = true;
      this.cdr.markForCheck();

      await this.store.dispatch(new ApplyCoupon(coupon)).toPromise();
      this.feedbackToaster('Cupom Aplicado', 'success');
      this.back();
    } catch (error) {
      this.feedbackToaster(error.error.message);
    } finally {
      this.isSubmitting = false;
      this.cdr.markForCheck();
    }
  }

  async removeCoupon() {
    if (this.isSubmitting) {
      return;
    }

    try {
      this.isSubmitting = true;
      this.cdr.markForCheck();

      await this.store.dispatch(new RemoveCoupon()).toPromise();
      this.feedbackToaster('Cupom Removido', 'success');
      this.back();
    } catch (error) {
      this.feedbackToaster(error.error.message);
    } finally {
      this.isSubmitting = false;
      this.cdr.markForCheck();
    }
  }

  private async feedbackToaster(
    message: string,
    theme: ThemingColor = 'danger',
  ): Promise<void> {
    const toast: HTMLIonToastElement = await this.toastController.create({
      message,
      color: theme,
      position: 'top',
      duration: 3000,
      buttons: [
        {
          text: 'ok',
        },
      ],
    });
    toast.present();
  }

  back() {
    if (this.inCart) {
      this.modalController.dismiss();
      return;
    }

    this.navCtrl.back();
  }

  async insertCoupon() {
    const modal = await this.modalController.create({
      component: InsertCouponComponent,
    });
    await modal.present();
    const closeModal = await modal.onWillDismiss();

    if (closeModal.data) {
      this._getReward();
      await this.store.dispatch(new LoadCoupons()).toPromise();
    }
  }
}
