import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComplementGroup, ComplementItem } from '../../../models/complement';
import {
  ComplementFromItemState,
  ComplementTreated,
} from '../../../models/item';
import { FabappDeliveryItem } from '../../../state/fabapp-delivery-item/fabapp-delivery-item.action';
import { ComplementService } from '../complements.service';

interface ControlWithPosition {
  index: number;
  controlsAndPositionValue: Array<[string, number]>;
  control: string;
  wasRequired?: boolean;
}

@Component({
  selector: 'fabapp-complement-groups',
  templateUrl: './complement-groups.component.html',
  styleUrls: ['./complement-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ComplementService],
})
export class ComplementGroupsComponent implements OnInit, AfterViewInit {
  @Output() toScroll: EventEmitter<number> = new EventEmitter();

  @ViewChildren('headers') completeHeaders: QueryList<
    ElementRef<HTMLSpanElement>
  >;

  @Input('form')
  public set setForm(value: FormGroup) {
    this.form = value.get('complements') as FormGroup;
  }
  public form: FormGroup;

  @Input('complements')
  public set setComplements(value: Array<ComplementGroup>) {
    this.initForm(value);
    this.complements = value;
    this.cdr.detectChanges();
  }
  public complements: Array<ComplementGroup>;

  public readonly maxValueForRadioOption: number = 1;
  private mapper = new Map<string, number>();
  private destroy$ = new Subject();

  @Input('currencyId')
  public set setCurrencyId(value: string) {
    this.currencyId = value;
  }
  currencyId: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private complementService: ComplementService,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._createValuesChanges();
    }, 400);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initForm(value: Array<ComplementGroup>): void {
    this.complementService.initForm(value, this.form);
  }

  public updateComplements() {
    this.store.dispatch(
      new FabappDeliveryItem.UpdateComplements(this.form.value),
    );
  }

  public setQuantity(
    quantity: number,
    complement: ComplementGroup,
    { id, price }: ComplementItem,
  ): void {
    const complementGroupId: string = complement.id;
    let complements: Array<ComplementFromItemState> =
      this.getComplementGroupById(complementGroupId);
    const complementIndex: number = this.getIndexById(complements, id);

    if (complementIndex < 0) {
      complements.push({ complementItemId: id, quantity, price });
    }

    if (complementIndex >= 0) {
      this.editOrDeleteComplement(
        quantity,
        complements,
        complementIndex,
        id,
        price,
      );
    }

    this.updateComplementGroup(complementGroupId, complements);
  }

  private updateComplementGroup(complementGroupId: string, complements): void {
    this.form.get(complementGroupId).setValue(complements);
    this.store.dispatch(
      new FabappDeliveryItem.UpdateComplements(this.form.value),
    );
  }

  private editOrDeleteComplement(
    quantity: number,
    complements: Array<ComplementFromItemState>,
    complementIndex: number,
    complementItemId: string,
    price: number,
  ) {
    if (complements.length && quantity === 0) {
      complements.splice(complementIndex, 1);
      return;
    }

    complements[complementIndex] = { complementItemId, quantity, price };
  }

  private getComplementGroupById(
    complementId: string,
  ): Array<ComplementFromItemState> {
    return this.form.get(complementId).value as Array<ComplementFromItemState>;
  }

  public getIndexById(complements: Array<ComplementFromItemState>, id: string) {
    return complements.findIndex(
      (complement) => complement.complementItemId === id,
    );
  }

  trackByItems(index: number, item: any): string | number {
    return item?.id ? item.id : index;
  }

  // Métodos usado scrollar a cada complemento obrigatório que for preenchido
  private _createValuesChanges() {
    this._calcDistanceToIonContent();
    const controlsFlattened: Array<[string, number]> = [...this.mapper];
    controlsFlattened.forEach(([control, _], index, controlsAndPositionValue) =>
      this._watchComplementChanges({
        control,
        index,
        controlsAndPositionValue,
      }),
    );
  }

  private _calcDistanceToIonContent() {
    this.completeHeaders.forEach((header) => {
      if (!header?.nativeElement?.id) {
        return;
      }
      const position: number = this._getPositionToContent(header.nativeElement);
      this.mapper.set(header.nativeElement.id, position);
    });
  }

  private _watchComplementChanges(controlWithPosition: ControlWithPosition) {
    const { control, index } = controlWithPosition;

    let wasRequired: boolean = this.form.get(control).errors?.required;
    const { maxValue } = this.complements[index];

    this.form
      .get(control)
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((currentControlValue: string | Array<ComplementTreated>) => {
        const isMultipleChoices: boolean = Array.isArray(currentControlValue);

        if (isMultipleChoices) {
          const quantityTotal = ComplementService.currentQuantity(
            currentControlValue as Array<ComplementTreated>,
          );
          wasRequired = quantityTotal === maxValue;
        }

        wasRequired = this._doScroll({ ...controlWithPosition, wasRequired });
      });
  }

  private _doScroll(controlWithPosition: ControlWithPosition) {
    let { control, index, controlsAndPositionValue, wasRequired } =
      controlWithPosition;

    const nextIndex = index + 1;
    const nextControl = controlsAndPositionValue[nextIndex];
    const isValid = this.form.get(control).valid;
    const canScroll = nextControl && isValid && wasRequired;

    if (canScroll) {
      const nextControlPositionValue = nextControl[1];
      this.toScroll.emit(nextControlPositionValue);
    }

    wasRequired = this.form.get(control).errors?.required;
    return wasRequired;
  }

  private _getPositionToContent(element: any) {
    let item = element;
    let offSetTop = 0;
    while (item?.offsetParent?.nodeName?.toLowerCase() != 'ion-content') {
      offSetTop += item.offsetTop;
      item = item.offsetParent;
    }

    return offSetTop;
  }
  // ----------------------------------------------------
}
