import { FormControl } from '@angular/forms';

export function createCounterRangeValidator(maxValue, minValue): any {
  return function validateCounterRange(c: FormControl): any {
    let err = {
      rangeError: {
        given: c.value,
        max: maxValue,
        min: minValue,
      },
    };

    return c.value > +maxValue || c.value < +minValue ? err : null;
  };
}
