import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { AppInfo } from '@core/models';
import { TermAndPrivacy } from '@core/models/themis';
import { AppDefState } from '@core/state/appdef';
import { ModalController } from '@ionic/angular';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PreviewNewTermToAcceptComponent } from '../preview-new-term-to-accept/preview-new-term-to-accept.component';

const TRANSLATION_SCOPE: string = 'lgpd';

@Component({
  selector: 'app-alert-about-new-term-accept',
  templateUrl: './alert-about-new-term-accept.component.html',
  styleUrls: ['./alert-about-new-term-accept.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: { scope: TRANSLATION_SCOPE },
    },
  ],
})
export class AlertAboutNewTermAcceptComponent implements OnInit {
  @Input() terms: { term: TermAndPrivacy; policy: TermAndPrivacy };

  @Select(AppDefState.getInfo) public appInfo$: Observable<AppInfo>;
  constructor(private modaCtrl: ModalController) {}

  ngOnInit() {}

  async seeTerms() {
    await this.modaCtrl.dismiss();
    if (this.terms?.term?.id) {
      const { id } = this.terms.term;
      const ref = await this.openTerm('terms', id);
      await ref.present();
      await ref.onWillDismiss();
    }

    if (this.terms?.policy?.id) {
      const { id } = this.terms.policy;
      const ref = await this.openTerm('privacy-policies', id);
      await ref.present();
    }
  }

  openTerm(type: 'terms' | 'privacy-policies', id: string) {
    return this.modaCtrl.create({
      component: PreviewNewTermToAcceptComponent,
      mode: 'ios',
      animated: true,
      componentProps: {
        type,
        id,
      },
    });
  }
}
