<ion-header class="ion-no-border">
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-button color="light" (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ profilePhoto$ | async }}</ion-title>
    <ion-buttons slot="end">
      <ion-button color="light" (click)="save()">
        <ion-icon slot="icon-only" name="checkmark"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content color="dark">
  <angular-cropper
    #angularCropper
    [cropperOptions]="cropperOptions"
    [imageUrl]="image"
    *ngIf="image"
  ></angular-cropper>
</ion-content>

<ion-toolbar color="dark">
  <ion-buttons class="ion-justify-content-between" *ngIf="image">
    <ion-button (click)="zoom(true)">
      <ion-icon color="light" slot="icon-only" name="add"></ion-icon>
    </ion-button>
    <ion-button (click)="zoom(false)">
      <ion-icon color="light" slot="icon-only" name="remove"></ion-icon>
    </ion-button>
    <ion-button (click)="rotate()">
      <ion-icon color="light" slot="icon-only" name="refresh"></ion-icon>
    </ion-button>
    <ion-button (click)="scaleX()">
      <ion-icon
        color="light"
        slot="icon-only"
        name="swap-horizontal"
      ></ion-icon>
    </ion-button>
    <ion-button (click)="scaleY()">
      <ion-icon color="light" slot="icon-only" name="swap-vertical"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>
