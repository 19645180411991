import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
} from '@angular/forms';
import { createCounterRangeValidator } from './validators/couter-range';

@Component({
  selector: 'fabapp-input-quantity',
  templateUrl: './input-quantity.component.html',
  styleUrls: ['./input-quantity.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputQuantityComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputQuantityComponent),
      multi: true,
    },
  ],
})
export class InputQuantityComponent implements ControlValueAccessor, OnInit {
  @Input('canAddMore')
  set setCanAddMore(canAddMore: boolean) {
    this.canAddMore = canAddMore;
  }
  canAddMore: boolean = true;
  @Input() public defaultValue: number = 1;
  @Input() public disabled: boolean = false;

  @Input()
  set value(val: number) {
    this._value = val ? val : this.defaultValue;
  }

  @Input('counterRangeMax')
  set setCounterRangeMax(max: number) {
    if (max === null) {
      max = undefined;
    }
    this.counterRangeMax = max;
  }
  public counterRangeMax: number;

  @Input() counterRangeMin;
  @Output() change: EventEmitter<number> = new EventEmitter<number>();

  validateFn: (max: number, min: number) => void;
  private _value = this.defaultValue;

  propagateChange = (_: any) => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    this.validateFn = createCounterRangeValidator(
      this.counterRangeMax,
      this.counterRangeMin,
    );
  }

  writeValue(value: number): void {
    this.counterValue = value ? value : this.defaultValue;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  get counterValue(): number {
    return this._value;
  }

  set counterValue(val: number) {
    this._value = val;
    this.change.emit(this._value);
    this.propagateChange(this._value);
  }

  increment(): void {
    if (this.counterValue >= this.counterRangeMax) {
      return;
    }
    this.counterValue++;
  }

  decrement(): void {
    if (this.counterValue <= this.counterRangeMin || this.counterValue <= 0) {
      return;
    }
    this.counterValue--;
  }
}
