import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
@Directive({
  selector: '[appShadowCss]',
})
export class ShadowCssDirective implements OnChanges {
  @Input() shadowCustomCss: string;
  @Input() appShadowCss = false;

  constructor(private el: ElementRef) {}

  ngOnChanges(): void {
    const shadow: any =
      this.el.nativeElement.shadowRoot ||
      this.el.nativeElement.attachShadow({ mode: 'open' });

    if (shadow && this.appShadowCss) {
      let innerHTML: any = '';
      innerHTML += '<style>';
      innerHTML += this.shadowCustomCss;
      innerHTML += '</style>';
      shadow.innerHTML += innerHTML;
    } else {
      const style: HTMLElement = shadow.querySelector('style');
      if (style) {
        style.remove();
      }
    }
  }
}
