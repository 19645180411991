import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-rating-of-item',
  styleUrls: ['./rating-of-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      (click)="rate.emit()"
      class="ratings"
      [class.-with-separator]="withSeparator"
    >
      <ion-icon [name]="average > 0 ? 'star' : 'star-outline'"></ion-icon>

      <span *ngIf="average" class="ratingsaverage">{{
        average?.toFixed(1)
      }}</span>
      <span *ngIf="ratingsTotal" class="ratingstotal">
        ({{ ratingsTotal }})
      </span>
    </div>
  `,
})
export class RatingOfItemComponent implements OnInit, OnChanges {
  @Input() average: number;

  @Input() ratingsTotal: number;

  /**
   * determina se mostra separador (-)
   */
  @Input() withSeparator: boolean = false;
  @Output() rate: EventEmitter<any> = new EventEmitter();

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.average?.currentValue) {
      this.average = +changes.average.currentValue;
    }

    if (changes?.ratingsTotal?.currentValue) {
      this.ratingsTotal = changes.ratingsTotal.currentValue;
    }

    if (changes?.withSeparator?.currentValue) {
      this.withSeparator = changes.withSeparator.currentValue;
    }

    this.cd.markForCheck();
  }
}
