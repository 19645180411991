import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEN from '@angular/common/locales/en';
import localeES from '@angular/common/locales/es';
import localeBr from '@angular/common/locales/pt';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicImageLoaderModule } from '@components';
import { CoreModule } from '@core/core.module';
import { appDefInitializer } from '@core/initializers/appdef.initializer';
import { AppIdInterceptor } from '@core/interceptors/appId.interceptor';
import { JwtInterceptor } from '@core/interceptors/jwt.interceptor';
import { OfflineInterceptor } from '@core/interceptors/offline.interceptor';
import { StoreIdInterceptor } from '@core/interceptors/storeId.interceptor';
import { HideDuplicatedHomePipe } from '@core/pipes/hide-duplicated-home/hide-duplicated-home.pipe';
import { AddressState } from '@core/state/address/address.state';
import { FabappAdsState } from '@core/state/ads';
import { AppDefState } from '@core/state/appdef';
import { AuthState } from '@core/state/auth';
import { CartState } from '@core/state/cart/cart.state';
import {
  CommunicatorMoblets,
  CommunicatorState,
} from '@core/state/communicator';
import { CoreState } from '@core/state/core/core.state';
import { HadesState } from '@core/state/hades/hades.state';
import { MobletsState } from '@core/state/moblets';
import { NavigationState } from '@core/state/navigation/navigation.state';
import { PushState } from '@core/state/push';
import { ReactNativeState } from '@core/state/react-native/react-native.state';
import { SearchState } from '@core/state/search';
import { ThemisState } from '@core/state/themis/themis.state';
import { environment } from '@environments/environment';
import { FabappDeliveryItemState } from '@fabapp-delivery/state/fabapp-delivery-item/fabapp-delivery-item.state';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {
  TranslocoConfig,
  TranslocoModule,
  TRANSLOCO_CONFIG,
} from '@ngneat/transloco';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { getLangUtils } from '@utils';
import { ApolloModule } from 'apollo-angular';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InterstitialGrumftModalModule } from './components/interstitial-grumft-modal/interstitial-grumft-modal.module';
import { LGPDModule } from './components/lgpd/lgpd.module';
import { PwaInstallBannerModule } from './components/pwa-install-banner/pwa-install-banner.module';
import { PwaInstallModule } from './components/pwa-install/pwa-install.module';
import { CatalogsState } from './moblets/fabapp-commerce/state/catalog.state';
import { TabsPage } from './tabs/tabs.page';
import { translocoLoader } from './translocoLoader';
import { getTextOFBackButton } from './utils/back-button-text';
import { customNavAnimation } from './utils/custom-transition-animation';

registerLocaleData(localeBr, 'pt-BR');
registerLocaleData(localeES, 'es-ES');
registerLocaleData(localeEN, 'en-US');
@NgModule({
  declarations: [AppComponent, HideDuplicatedHomePipe, TabsPage],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    AppRoutingModule,
    PwaInstallModule,
    IonicModule.forRoot({
      navAnimation: customNavAnimation,
      backButtonText: getTextOFBackButton(),
    }),
    NgxsModule.forRoot(
      [
        AppDefState,
        MobletsState,
        CoreState,
        AuthState,
        NavigationState,
        FabappAdsState,
        CommunicatorState,
        CommunicatorMoblets,
        PushState,
        SearchState,
        CartState,
        AddressState,
        ReactNativeState,
        CatalogsState,
        FabappDeliveryItemState,
        HadesState,
        ThemisState,
      ],
      {
        developmentMode: !environment.production,
      },
    ),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [
        `auth_${window['appId']}`,
        'appdef.info',
        'appdef.pages',
        'appdef.flattened_pages',
      ],
      beforeSerialize: (obj, key) => {
        // console.log(key, obj)
        return obj;
      },
      afterDeserialize: (obj, key) => {
        // console.log(key, obj)
        return obj;
      },
    }),
    TranslocoModule,
    IonicImageLoaderModule,
    ApolloModule,
    HttpLinkModule,
    PwaInstallBannerModule,
    InterstitialGrumftModalModule,
    LGPDModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appDefInitializer,
      deps: [Store],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        defaultLang: 'pt-BR',
        availableLangs: ['pt-BR', 'en-US', 'es-ES'],
        fallbackLang: ['pt-BR', 'en-US', 'es-ES'],
        reRenderOnLangChange: false, // não tem troca linguagem no app
        prodMode: environment.production,
      } as TranslocoConfig,
    },
    translocoLoader,
    { provide: HTTP_INTERCEPTORS, useClass: AppIdInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: OfflineInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: StoreIdInterceptor, multi: true },
    WebView,
    { provide: LOCALE_ID, useValue: getLangUtils() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
