<ion-header>
  <ion-toolbar
    [style.--color]="'var(--ion-color-headercolor-contrast)'"
    color="primary"
  >
    <ion-title
      *transloco="let t"
      [style.--color]="'var(--ion-color-headercolor-contrast)'"
      >{{ t('fabappDelivery.anyObservations') }}</ion-title
    >
    <ion-buttons slot="end">
      <ion-button
        [style.--color]="'var(--ion-color-headercolor-contrast)'"
        (click)="modalCtrl.dismiss()"
      >
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="note">
    <ion-item lines="none">
      <ion-label *transloco="let t" position="floating">
        {{ t('fabappDelivery.note') }}
      </ion-label>
      <ion-textarea
        #noteArea
        [autoGrow]="true"
        [autofocus]="false"
        [(ngModel)]="noteValue"
        outline
        [maxlength]="150"
      ></ion-textarea>
    </ion-item>
  </div>
</ion-content>

<ion-footer class="ion-padding">
  <ion-button
    *transloco="let t"
    class="buy-button"
    [style.--color]="'var(--primarycolor-or-black)'"
    fill="outline"
    expand="block"
    (click)="save()"
  >
    {{ t('fabappDelivery.save') }}
  </ion-button>
</ion-footer>
