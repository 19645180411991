import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild, Router,
  RouterStateSnapshot
} from '@angular/router';
import { AppDefState } from '@core/state/appdef';
import { AuthState } from '@core/state/auth';
import { ThemingColor } from '@core/types';
import { NavController, ToastController } from '@ionic/angular';
import { translate, TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GroupMobletPage } from '../../moblets/group/group.page';
import { UserStatus } from '../enums';
import { AppDef, AppInfo, Page, User } from '../models';
import { AuthHelperService } from './auth-helper.service';

let lang: string;

export interface LoginParams {
  navigate?: boolean;
  openRecoveryCode?: boolean;
  openSignUp?: boolean;
  openConfirmationCode?: boolean;
  goToResetPassword?: boolean;
  goToResetSuccessfully?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivateChild {
  private appInfo: AppInfo;

  constructor(
    private store: Store,
    private router: Router,
    private toastController: ToastController,
    private navCtrl: NavController,
    private translocoService: TranslocoService,
    private authHelperService: AuthHelperService,
  ) {
    lang = this.translocoService.getActiveLang();
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    const partialAppDef: Partial<AppDef> = this.store.selectSnapshot(
      AppDefState.getFromAppDef(['info']),
    );
    this.appInfo = partialAppDef.info;
    const isFirstNavigation: boolean = this.router['navigationId'] === 1;

    if (this.isUserIncomplete() && !state.url.includes('settings')) {
      this.showToaster('validations.userIncomplete');
      this.goToProfile();
      !environment.production && console.log('AUTH-GUARD', state.url);
      return false;
    }

    if (
      this.isUserWaiting() &&
      (this.isRequiredLogin() || this.mobletRequiredAuth(route))
    ) {
      return this.goToUserAwaitingApproval();
    }

    if (this.isAuthenticated()) {
      return true;
    }

    // window['preview'] = false, serve para validar se está no editor ou não
    // para não haver o travamento no editor
    if (
      ((!this.isMoblet(route) && this.isRequiredLogin()) ||
        this.isRequiredLogin()) &&
      !window['preview']
    ) {
      if (isFirstNavigation) {
        this.authHelperService.goToLogin(state.url, true);
        !environment.production && console.log('AUTH-GUARD', state.url);
        return false;
      }

      this.authHelperService.goToLogin();
      !environment.production && console.log('AUTH-GUARD', state.url);
      return false;
    }

    // bloqueia o moblet de acces_control = true e não for o preview
    if (this.mobletRequiredAuth(route) && !window['preview']) {
      this.authHelperService.goToLogin(state.url, isFirstNavigation);
      !environment.production && console.log('AUTH-GUARD', state.url);
      return false;
    }

    return true;
  }

  private goToProfile(): void {
    this.navCtrl.navigateRoot('/settings/profile');
  }

  async goToLogin(intentUrl?: string, isntModal = false): Promise<boolean> {
    return this.authHelperService.goToLogin(intentUrl, isntModal);
  }

  goToUserAwaitingApproval(): boolean {
    this.router.navigate(['/auth/user-awaiting-approval']);
    return false;
  }

  isMoblet(route: ActivatedRouteSnapshot): boolean {
    return route.params.id ? true : false;
  }

  mobletRequiredAuth(route: ActivatedRouteSnapshot): boolean {
    if (!this.isMoblet(route)) {
      return false;
    }
    const page: Page = this.store.selectSnapshot(
      AppDefState.getPage(route.params.id),
    );
    return page?.access_control === 'true';
  }

  isRequiredLogin(): boolean {
    return this.appInfo.login_required;
  }

  isAuthenticated(): boolean {
    return this.store.selectSnapshot(AuthState.isAuthenticated);
  }

  isUserWaiting(): boolean {
    const user: User = this.store.selectSnapshot(AuthState.getUser);

    if (!user) {
      return false;
    }

    return user.status_id === UserStatus.Waiting;
  }

  isUserIncomplete(): boolean {
    const user: User = this.store.selectSnapshot(AuthState.getUser);

    if (!user) {
      return false;
    }

    return user.status_id === UserStatus.INCOMPLETE;
  }

  isGroup(route: ActivatedRouteSnapshot): boolean {
    return route.component === GroupMobletPage;
  }

  isOptionalLogin(): boolean {
    return !this.appInfo.login_enable;
  }

  private async showToaster(
    translateKey: string,
    theme: ThemingColor = 'danger',
  ): Promise<void> {
    const toast: HTMLIonToastElement = await this.toastController.create({
      message: translate(translateKey, {}, `${lang}`),
      color: theme,
      position: 'top',
      buttons: [
        {
          text: 'ok',
        },
      ],
    });

    toast.present();
  }
}
